import * as React from 'react';

import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { Form } from '@independent-software/typeui/controls/Form';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Input } from '@independent-software/typeui/controls/Input';

import { IQuestion, QuestionTree } from './Definitions';
import { QuestionContext, IQuestionContext } from './QuestionContext';
import { Community } from '../../resource';
import { Textarea } from '@independent-software/typeui/controls/Textarea';

interface IQuestionProps {
  name: string;
  update?: boolean;     // Update form when input changes
  disabled?: boolean;
  nonumber?: boolean;   // Do not add question number to label
  nolabel?: boolean;    // Do not print label at all
  communities?: Community[];
}

class Question extends React.Component<IQuestionProps, {}> {

  public static contextType = QuestionContext;

  getControl(question: IQuestion): React.ReactNode {
    switch(question.type) {
      case 'list':
        return <Dropdown 
          clearable fluid placeholder="&nbsp;" 
          data={Object.keys(question.list)} 
          label={(item) => item + ". " + question.list[item]}>
            <Dropdown.Column>{(item) => <div style={{color: item == 0 || item > 90 ? 'gray' : 'black'}}>{item + ". " + question.list[item]}</div>}</Dropdown.Column>
          </Dropdown>
      case 'check':
        return <Checkbox label={question.title}/>
      case 'date':
        return <Input type='date' fluid clearable placeholder={question.placeholder}/>
      case 'time':
        return <Input type='time' fluid clearable placeholder={question.placeholder}/>
      case 'community':
        return <Dropdown fluid clearable data={this.props.communities} label={(item:Community) => item.name} placeholder={question.placeholder}>
          <Dropdown.Column>{(item:Community) => item.name}</Dropdown.Column>
        </Dropdown>
      case "longtext":
        return <Textarea fluid rows={8} placeholder={question.placeholder}/>
      default:
        return <Input fluid clearable placeholder={question.placeholder}/>
    }
  }

  findQuestion = (name: string, group: string, group2: string): IQuestion => {
    // Find question:
    let question: IQuestion;
    if(group2) {
      question = QuestionTree[group][group2][name];
    }
    else if(group) {
      question = QuestionTree[group][name];
    } else {
      question = QuestionTree[name];
    }
    if(!question) {
      console.error("Question " + name + " not found.");
    }
    return question;
  }

  render = () => {
    let p = this.props;

    let { data, group, index, group2, index2 } = (this.context as IQuestionContext);

    // Find question:
    let question: IQuestion = this.findQuestion(p.name, group, group2);

    // Get control for question:
    let control = this.getControl(question);

    // Build name:
    let fieldname = p.name;
    if(group2) fieldname = `${group}[${index}].${group2}[${index2}].${p.name}`;
    else if(group) fieldname = `${group}[${index}].${p.name}`;

    // Find value:
    let value = data[p.name];
    // Value may not exist on data object. If so, assume null.
    if(value == undefined) value = null;

    // Create component:
    return <Form.Field
      label={p.nolabel || question.type=='check' ? null : (p.nonumber ? "" : p.name.replace(/_/g, ".") + ". ") + question.title}
      forceupdate={p.update}
      name={fieldname}
      control={control}
      disabled={p.disabled}
      value={value}
      hint={question.hint}
      required={question.required ? { message: "Required field"} : null}
      isFloat={question.type == 'number' ? { message: "Valor numérico" } : null}
      // min={question.type == 'number' ? { value: 0, message: "Valor positivo" } : null}
    />
  }
}

export { Question };
