import { StampedModel, ResourceFactory } from '../services/';

class SurveyCommunity extends StampedModel {
  public name: string = '';
  public community_id: number;
  public community: string;
  public survey_id: number;
  public survey: string;
  //public factor: number;
}

const SurveyCommunityFactory = ResourceFactory.create<SurveyCommunity>(SurveyCommunity, 'surveycommunity');

export { SurveyCommunity, SurveyCommunityFactory };