import * as React from 'react';
import axios from 'axios';

import { IAuthProps } from '../../services/Auth';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, TooltipPayload, TooltipProps, XAxis, YAxis } from 'recharts';
import { App } from '../../App';
import { CustomizedAxisTick } from './CustomizedAxisTick';
import { ChartOptions } from './ChartOptions';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { Form } from '@independent-software/typeui/controls/Form';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Community, CommunityFactory, Issuecategory, IssuecategoryFactory } from '../../resource';
import { Query } from '../../services';
import { TooltipFormatter } from './TooltipFormatter';
import { LanguageContext, Loc } from '../../modules';
import { Loader } from '@independent-software/typeui/controls/Loader';

interface IProps {
}

interface IState {
  year: number;
  quarter: number;  
  count: number;
  community: Community;
  category: Issuecategory;
  gender: string;
  data: any;
  communities: Community[];
  categories: Issuecategory[];
  loading: boolean;
}

class ChartEvolution extends React.Component<IAuthProps & IProps, IState> {
  private thisYear: number;
  private thisQuarter: number;

  constructor(props: IAuthProps & IProps) {
    super(props);

    // Determine current year and quarter:
    let d = new Date();
    this.thisYear = d.getFullYear();
    this.thisQuarter = Math.floor(d.getMonth() / 3) + 1;

    // Set things up to display the last 8 quarters.
    this.thisYear -= 2;
    this.thisQuarter += 1;
    if(this.thisQuarter > 4) {
      this.thisQuarter = 1;
      this.thisYear++;
    }
    
    this.state = {
      data: [],
      year: this.thisYear,
      quarter: this.thisQuarter,
      community: null,
      category: null,
      gender: null,
      count: 8,
      communities: [],
      categories: [],
      loading: true
    }
  }

  componentDidMount() {
    this.loadData();
    CommunityFactory.getSome(this.props.auth, 0, 999, new Query('name','asc'))
      .then((res) => this.setState({ communities: res.items }));
    IssuecategoryFactory.getSome(this.props.auth, 0, 50, new Query('name','asc'))
      .then((res) => this.setState({ categories: res.items }));      
  } 

  componentDidUpdate(newProps: IProps, newState: IState) {
    if(  this.state.year != newState.year 
      || this.state.quarter != newState.quarter 
      || this.state.count != newState.count
      || this.state.community != newState.community
      || this.state.category != newState.category
      || this.state.gender != newState.gender) {
      this.loadData();
    }
  }

  loadData = () => {
    this.setState({ loading: true });
    let path = `${App.apiURL}grievances/chart-evolution/${this.state.year}?api_token=${this.props.auth.token}&q=${this.state.quarter}&count=${this.state.count}`;
    if(this.state.community) { path = path + `&community=${this.state.community.id}`; }
    if(this.state.category) { path = path + `&category=${this.state.category.id}`; }
    if(this.state.gender) { path = path + `&gender=${this.state.gender}`; }
    axios.get(path)
    .then(response => {
      let data = response.data;
      this.setState({
        data: data
      })
    })
    .catch(error => {
    })
    .finally(() => {
       this.setState({ loading: false });
    });  
  }

  handleChangeYear = (year: number) => {
    this.setState({ year: year });
  }

  handleChangeQuarter = (quarter: number) => {
    this.setState({ quarter: quarter });
  }

  handleChangeCount = (count: number) => {
    this.setState({ count: count });
  }  

  handleChangeCommunity = (value: Community) => {
    this.setState({community: value});
  }

  handleChangeCategory = (value: Issuecategory) => {
    this.setState({category: value});
  }

  handleChangeGender = (value: string) => {
    this.setState({gender: value});
  }  

  render() {
    let p = this.props;

    // Generate years 2018..now:
    let years = [];
    let d = new Date();
    for(let i = 2018; i <= d.getFullYear(); i++) years.push(i);
    years.reverse();

    // Generate quarters:
    let quarters = [1,2,3,4];
    
    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Flex.Columns count={3}>
          <Form.Uncontrolled hint={<Loc msg="analysis_grievances_hint_year"/>}>
            <Dropdown name="year" fluid value={this.state.year} data={years} placeholder="Year" label={(item:number) => item} onChange={this.handleChangeYear}>
              <Dropdown.Column>{(item:number) => item}</Dropdown.Column>
            </Dropdown>
          </Form.Uncontrolled>
          <Form.Uncontrolled hint={<Loc msg="analysis_grievances_hint_quarter"/>}>
            <Dropdown name="quarter" fluid value={this.state.quarter} data={quarters} placeholder="Quarter" label={(item:number) => `Q${item}`} onChange={this.handleChangeQuarter}>
              <Dropdown.Column>{(item:number) => `Q${item}`}</Dropdown.Column>
            </Dropdown>
          </Form.Uncontrolled>
          <Form.Uncontrolled hint={<Loc msg="analysis_grievances_hint_numquarters"/>}>
            <Dropdown name="count" fluid value={this.state.count} data={[4,8,12,16]} placeholder="Count" label={(item:number) => item} onChange={this.handleChangeCount}>
              <Dropdown.Column>{(item:number) => item}</Dropdown.Column>
            </Dropdown>
          </Form.Uncontrolled>
        </Flex.Columns>
        <Form.Uncontrolled hint={<Loc msg="analysis_grievances_hint_community"/>}>
          <Dropdown clearable name="community" fluid value={this.state.community} data={this.state.communities} placeholder="Community" label={(item:Community) => item.name} onChange={this.handleChangeCommunity}>
            <Dropdown.Column>{(item:Community) => item.name}</Dropdown.Column>
          </Dropdown>
        </Form.Uncontrolled>
        <Form.Uncontrolled hint={<Loc msg="analysis_grievances_hint_category"/>}>
          <Dropdown clearable name="category" fluid value={this.state.category} data={this.state.categories} placeholder="Category" label={(item:Issuecategory) => item.name} onChange={this.handleChangeCategory}>
            <Dropdown.Column>{(item:Issuecategory) => item.name}</Dropdown.Column>
          </Dropdown>
        </Form.Uncontrolled>
        <Form.Uncontrolled hint={<Loc msg="analysis_grievances_hint_gender"/>}>
          <Dropdown clearable name="gender" fluid value={this.state.gender} data={['m','f','?']} placeholder="Gender" label={(item:string) => item} onChange={this.handleChangeGender}>
            <Dropdown.Column>{(item:string) => item}</Dropdown.Column>
          </Dropdown>
        </Form.Uncontrolled>        
      </Panel.Content>
    </React.Fragment>    

    return (
      <React.Fragment>
        <ChartOptions>
          <Panel.Icon icon="tools" width={400}>
            {filter}
          </Panel.Icon>
        </ChartOptions>
        <LanguageContext.Consumer>
          {({language, data}) => 
            <div style={{height:'100%', position: 'relative'}}>
              {this.state.loading == true && <Loader/>}
              {this.state.loading == false && <ResponsiveContainer width="100%" height="95%">
                <LineChart 
                  data={this.state.data}
                  margin={{ top: 30, right: 50, left: 10, bottom: 0 }}
                >
                  <CartesianGrid strokeDasharray="6 3" strokeWidth={0.5} stopOpacity={0.6} vertical={false}/>
                  <XAxis
                    dataKey="q" 
                    padding={{ left: 30, right: 30 }}
                    tickFormatter={(val:string) => 'Q' + val.substr(2)}
                    tick={<CustomizedAxisTick />}
                  />                
                  <YAxis 
                    allowDecimals={false}
                  />
                  <Tooltip content={TooltipFormatter}/>
                  <Line
                    name={Loc.getText(data, "analysis_grievances_legend_opened")}
                    dataKey="open" 
                    stroke="#8884d8"
                    type="monotone"
                    strokeWidth={2}
                  />
                  <Line
                    name={Loc.getText(data, "analysis_grievances_legend_closed")}
                    dataKey="closed" 
                    stroke="#d88488"
                    type="monotone"
                    strokeWidth={2}
                  />
                  <Line
                    name={Loc.getText(data, "analysis_grievances_legend_backlog")}
                    dataKey="log" 
                    stroke="#88d884"
                    type="monotone"
                    strokeWidth={2}
                  />                  
                  <Line
                    name={Loc.getText(data, "analysis_grievances_legend_courtcases")}
                    dataKey="litigation" 
                    stroke="#888800"
                    type="monotone"
                    strokeWidth={2}
                  />                  
                  <Legend
                    layout="horizontal"
                  />
                </LineChart>              
              </ResponsiveContainer>}
            </div>
          }          
          </LanguageContext.Consumer>
      </React.Fragment>
    );
  }
}

export { ChartEvolution };
