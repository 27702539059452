import * as React from 'react';
import axios from 'axios';
import { Auth } from '../../services/Auth';
import { RouteComponentProps } from 'react-router';
import { SigninForm, ISignin } from './SigninForm';

import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { App } from '../../App';
import { Loc } from '../../modules';

interface ILoginProps {
  className?: string;
  /** Callback when signin is successful. */
  onSignin: (auth:Auth) => void;
}

interface ILoginState {
  signin: ISignin;
  isValid: boolean;
  /** Login request in progress? Will show a loader. */
  loginLoading: boolean;
  /** Forgot password request is progress? Will show a loader. */
  forgotLoading: boolean;
}

class SignIn extends React.Component<ILoginProps & RouteComponentProps, ILoginState> {
  constructor(props: ILoginProps & RouteComponentProps) {
    super(props);
    
    this.state = {
      signin: { email: sessionStorage.getItem('email'), password: '' },
      isValid: false,
      loginLoading: false,
      forgotLoading: false
    };
  }

  handleChange = (signin: ISignin, forceupdate: boolean) => {
    this.setState({
      signin: signin
    });
  }  

  handleValidate = (valid: boolean) => {
    this.setState({
      isValid: valid
    })
  }    

  handleSubmit = () => {
    this.setState({
      loginLoading: true
    });
    setTimeout(() => {
      axios.post(`${App.apiURL}signin`, {
        email: this.state.signin.email,
        password: this.state.signin.password
      })
      .then(response => {
        let auth = new Auth(
          response.data.auth.id,
          response.data.auth.name,
          response.data.auth.email,
          response.data.auth.api_token,
          response.data.auth.rights
        );
        sessionStorage.setItem('email', this.state.signin.email);
        ToastService.toast(<Loc msg="signin_msg_success"/>);
        this.props.onSignin(auth);
        this.props.history.push("/dashboard");
      })
      .catch(error => {
        let text: React.ReactNode = '';
        if(error.response) {
          text = <Loc msg="signin_msg_invalid_user"/>;
        } else if(error.request) {
          text = <Loc msg="signin_msg_no_server_response"/>;
        } else {
          text = <Loc msg="signin_msg_send_problem"/>;
        }
        ToastService.toast(text);
        this.setState({
          loginLoading: false,
        });        
      });
    }, 500);
    // Adds a 500ms timeout to make sure that signin button
    // animation actually gets seen.    
  }

  handleForgot = () => {
    this.setState({
      forgotLoading: true
    });
    setTimeout(() => {
      axios.post(`${App.apiURL}/forgot`, {
        email: this.state.signin.email
      })
      .then(response => {
        ToastService.toast(<Loc msg="signin_msg_reset"/>);
      })
      .catch(error => {
        let text: React.ReactNode = '';
        if(error.response) {
          switch(error.response.status) {
            case 400: 
              text = <Loc msg="signin_msg_email_missing"/>;
              break;
            case 401:
              text = <Loc msg="signin_msg_email_invalid"/>;
              break;
            default:
              text = <Loc msg="signin_msg_server_problem"/>;
              break;
          }
        } else if(error.request) {
          text = <Loc msg="signin_msg_no_server_response"/>;
        } else {
          text = <Loc msg="signin_msg_send_problem"/>;
        }
        ToastService.toast(text);
      })
      .then(() => {
        this.setState({
          forgotLoading: false
        })
      });
    }, 500);
    // Adds a 500ms timeout to make sure that forgot button
    // animation actually gets seen.        
  }

  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        <SigninForm disabled={this.state.loginLoading || this.state.forgotLoading} data={this.state.signin} onChange={this.handleChange} onValidate={this.handleValidate} onSubmit={this.handleSubmit}/>
        <div style={{display: 'flex', 'justifyContent': 'space-between'}}>
          <Button secondary disabled={!this.state.isValid} onClick={this.handleForgot}>
            {this.state.forgotLoading && <Icon loading name="circle-notch"/>}
            {!this.state.forgotLoading && <Loc msg="signin_btn_forgot"/>}
            {this.state.forgotLoading && <Loc msg="signin_btn_sending"/>}
          </Button>
          <Button primary disabled={!this.state.isValid} onClick={this.handleSubmit}>
            {this.state.loginLoading && <Icon loading name="circle-notch"/>}
            {!this.state.loginLoading && <Loc msg="signin_btn_sign"/>}
            {this.state.loginLoading && <Loc msg="signin_btn_signing"/>}
          </Button>
        </div>
      </div>
    )
  }
}

export { SignIn };