import { TRight } from "./Config";
import { Auth } from "../services";

abstract class BaseRoute {
  public path: string;
  public title: string;
  public component: any;

  constructor(path: string, title: string, component: any) {
    this.path = path;
    this.title = title;
    this.component = component;
  }

  public abstract isAllowed(auth: Auth): boolean;
}

class PublicRoute extends BaseRoute {
  constructor(path: string, title: string, component: any) {
    super(path, title, component);
  }

  public isAllowed = (auth: Auth): boolean => {
    return true;
  }
}

class SecureRoute extends BaseRoute {
  public right: TRight;

  constructor(right: TRight, path: string, title: string, component: any) {
    super(path, title, component);
    this.right = right;
  }

  public isAllowed = (auth: Auth): boolean => {
    if(this.right == null) return true;
    return auth && auth.hasRight(this.right);
  }  
}

export { BaseRoute, PublicRoute, SecureRoute };