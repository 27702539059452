import { BaseRoute } from './Route'
import { ROUTES } from './Routes'

//
// A menu is a collection of submenus, each with a title.
// 
interface IMenu {
  [key:string]: ISubmenu
}

//
// A submenu is a collection of routes, each with a title.
// 
interface ISubmenu {
  [key: string]: BaseRoute
}


/*
 * The application's main menu consists of groups and items.
 * A group has a key (its name), and an object containing items.
 * Each object has:
 * - A key (the item label)
 * - A reference to a route to load.
 * Menu items are only rendered if route conditions are satisfied.
 */
const MENU: IMenu = {
  "menu_data": {
    "menu_dashboard": ROUTES['dashboard'],
    "menu_households": ROUTES["households_list"],
    "menu_actions": ROUTES['actions_list'],
    "menu_grievances": ROUTES['grievances_list'],
    "menu_requests": ROUTES['requests_list'],
    "menu_stakeholders": ROUTES['stakeholders_list'],
    "menu_attachments": ROUTES['attachments_list'],
    "menu_responses": ROUTES['responses_list']
  },
  "menu_base_data": {
    "menu_districts": ROUTES['districts_list'],
    "menu_communities": ROUTES['communities_list'],
    "menu_issuetypes": ROUTES['issuetypes_list'],
    "menu_issuecategories": ROUTES['issuecategories_list'],
    "menu_actiontypes": ROUTES['actiontypes_list'],
    "menu_surveys": ROUTES['surveys_list']
  },
  "menu_analysis": {
    "menu_analysis_grievances": ROUTES['analysis_grievances'],
    "menu_analysis_actions": ROUTES['analysis_actions'],
    "menu_analysis_surveys": ROUTES['analysis_surveys'],
    "menu_analysis_series": ROUTES['analysis_series']
  },
  "menu_users": {
    "menu_users": ROUTES['users_list'],
    "menu_profiles": ROUTES['profiles_list'],
    "menu_log": ROUTES['logs_list']
    // "menu_posts": ROUTES['posts_list']
  }
} 

export { MENU, IMenu, ISubmenu }