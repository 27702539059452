import * as React from 'react'
import axios from 'axios';
import { Grievance } from '../../../resource';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Button } from '@independent-software/typeui/controls/Button';
import { IAuthProps } from '../../../services';
import { Dropzone } from '@independent-software/typeui/controls/Dropzone';
import { App } from '../../../App';
import { Filesize } from '@independent-software/typeui/formatters/Filesize';
import { Loc } from '../../../modules';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { TLocalization } from '../../../modules/Loc/TLocalization';
import { Form } from '@independent-software/typeui/controls/Form';
import { Input } from '@independent-software/typeui/controls/Input';

interface IProps {
  grievance: Grievance;
  open: boolean;
  onClose: () => void;
  onExec: () => void;
  onFail: (error: any) => void;
}

interface IData {
  contact_type: string;
  contact_date: string;
}

interface IState {
  file: File;
  data: IData;
  dirty: boolean;
  isValid: boolean;
  saving: boolean;
}


class Implementation3ConclusionDialog extends React.Component<IAuthProps & IProps, IState> {

  constructor(props: IAuthProps & IProps) {
    super(props);
    this.state = { 
      file: null,
      data: {
        contact_type: null,
        contact_date: null
      },
      dirty: false,
      isValid: false,
      saving: false
    }
  }

  private exec = () => {
    let grievance = this.props.grievance;
    this.setState({ saving: true });

    // Sending a file requires the use of FormData. This is
    // a dictionary of string to string. We can therefore not 
    // send the entire grievance along (it's several layers deep,
    // and cannot be converted to a list of string), and we
    // don't need to, since we only need to send the file and 
    // the api_token to the endpoint.
    let formData = new FormData();
    // Attach the file:
    formData.append('file', this.state.file);
    // Attach the api_token.
    formData.append('api_token', this.props.auth.token);
    // Attach fields
    formData.append('contact_type', this.state.data.contact_type);
    formData.append('contact_date', this.state.data.contact_date);    

    axios.post(`${App.apiURL}grievance/move/${grievance.id}/implementation3_conclusion`, formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    .then(response => {
      grievance.newstate = 'implementation3_conclusion';
      this.props.onExec();
    })
    .catch(error => {
      this.props.onFail(error);
    })
    .finally(() => {
      this.setState({ saving: false });
    });
  }

  handleChange = (data: IData, forceupdate: boolean) => {
    this.setState({ data: data });
  }

  handleValidate = (valid: boolean) => {
    this.setState({
      isValid: valid
    })
  }  

  render() {
    return (
      <Dialog width={800} open={this.props.open} onClose={this.props.onClose} canClose={!this.state.saving}>
        <Dialog.Header><Loc msg="grievance_workflow_implementation3_conclusion_title"/></Dialog.Header>
        <Dialog.Content>
          <Form data={this.state.data} dirty={this.state.dirty} onChange={this.handleChange} onValidate={this.handleValidate}>

            <Form.Field 
              required={{message: <Loc msg="grievance_workflow_contact_date_required"/>}}
              width={1} label={<Loc msg="grievance_workflow_contact_date_label"/>}
              name="contact_date" 
              value={this.state.data.contact_date}
              control={(<Input type="date" nofuture placeholder="Contact date" fluid/>)}
              hint={(<React.Fragment><Loc msg="grievance_workflow_contact_date_hint"/></React.Fragment>)}/>
                          
            <Form.Field 
              required={{message: <Loc msg="grievance_workflow_contact_type_required"/>}}
              width={1} label={<Loc msg="grievance_workflow_contact_type_label"/>}
              name="contact_type" 
              value={this.state.data.contact_type}
              control={(
                <Dropdown fluid data={[ "phone", "presential" ]} placeholder="Contact type" label={(item: string) => <Loc msg={("grievance_workflow_contact_type_" + item) as TLocalization}/>}>
                  <Dropdown.Column>{(item:string) => <Loc msg={("grievance_workflow_contact_type_" + item) as TLocalization}/>}</Dropdown.Column>
                </Dropdown>
              )}
              hint={(<React.Fragment><Loc msg="grievance_workflow_contact_type_hint"/></React.Fragment>)}/>

            <Dropzone 
              message={this.state.file ? <React.Fragment>{this.state.file.name} (<Filesize value={this.state.file.size}/>)</React.Fragment> : <Loc msg="grievance_workflow_implementation3_conclusion_file"/>} 
              onAddFiles={(files: File[]) => this.setState({ file: files[0] })}/>
          </Form>
        </Dialog.Content>
        <Dialog.Footer>
          <Button disabled={!this.state.isValid || this.state.file == null || this.state.saving} primary onClick={() => this.exec()}>
            {this.state.saving && <Icon name="circle-notch" loading/>}
            <Loc msg="btn_ok"/>
          </Button>
          <Button secondary disabled={this.state.saving} onClick={this.props.onClose}><Loc msg="btn_cancel"/></Button>          
        </Dialog.Footer>
      </Dialog>
    )
  }
}

export { Implementation3ConclusionDialog }