import { StampedModel, ResourceFactory } from '../services/';

class Household extends StampedModel {
  public code: string = '';
  public state: string = '';
  public name: string = '';
  public after_resettlement: boolean = false;
  public after_resettlement_code: string = '';
  // Head of HouseHold fields:
  public hhh_name: string = '';
  public hhh_age: number = null;
  public hhh_gender: string = null;
  public hhh_phone: string = '';
  public hhh_contact: string = '';
  public hhh_civil_id: string = '';
  public hhh_account: string = '';
  public hhh_leader: boolean = false;
  // HouseHold fields:
  public members: number = null;
  public graves: number = null;
  public polygamous: boolean = null;
  public polygamous_spouses: number = null;
  public inas: boolean = null;
  public programs: string = '';
  public vulnerability_singleheaded: boolean = null;
  public vulnerability_femaleheaded: boolean = null;
  public vulnerability_orphan: boolean = null;
  public vulnerability_elderly: boolean = null;
  public vulnerability_mental: boolean = null;
  public vulnerability_physical: boolean = null;
  public vulnerability_illness: boolean = null;
  public vulnerability_income: boolean = null;
  public vulnerability_other: boolean = null;
  public vulnerability_other_description: string = '';
  public actions_count: number = 0;
  
  public community: { 
    id: number;
    name: string;
  } = null;  
}

const HouseholdFactory = ResourceFactory.create<Household>(Household, 'household');

export { Household, HouseholdFactory };