import * as React from 'react';

import { Container, Content, BottomBar, Loc } from '../../modules';
import { Response } from '../../resource/';
import { RouteComponentProps } from 'react-router';
import { ListResponses } from './ListResponses';
import { IAuthProps } from '../../services/Auth';
import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';

interface IState {
  importOpen: boolean;
}

class ResponseList extends React.Component<IAuthProps & RouteComponentProps<any>, IState> {
  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
    this.state = {
      importOpen: false
    };
  }

  handleAdd = () => {
    this.props.history.push('/responses/add');
  }  

  handleClick = (item: Response) => {
    this.props.history.push(`/responses/${item.id}`);
  }  

  render() {
    let p = this.props;
    return (
      <Container>
        <Content>
          <ListResponses auth={p.auth} name="responses" onClick={this.handleClick}/>
        </Content>
        <BottomBar>
          {this.props.auth.hasRight('can_edit_responses') && <Button primary onClick={this.handleAdd}><Icon name="plus"/> <Loc msg="responses_btn_add"/></Button>}
        </BottomBar>
      </Container>
    );
  }
}

export { ResponseList };
