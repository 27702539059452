import { TDir, Query } from './Query';

/**
 *  QueryStore stores queries in a static Map. 
 *  Queries are therefore lost on refresh, but not
 *  when switching routes.
 */
class QueryStore {
  private static queries: Map<string, Query> = new Map();

  /** 
   * Fetch a query from the Querystore, or create it if it doesn't 
   * exist, using the provided default order and dir.
   */
  public static get(key: string, defaultOrder: string, defaultDir: TDir ) {
    // Does a query with this key exist in the store?
    let hasKey = QueryStore.queries.has(key);
    // If it does, return it.
    if(hasKey) return QueryStore.queries.get(key);
    // If not create a new query and place it in the store.
    let query = new Query(defaultOrder, defaultDir);
    QueryStore.queries.set(key, query);
    return query;
  }
}

export { QueryStore };