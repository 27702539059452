import * as React from 'react';
import { css } from 'styled-components';
import styled from '@independent-software/typeui/styles/Theme';
import { lighten } from '@independent-software/typeui/helper/lighten';

interface IProps {
  className?: string;
  /** Name of file being uploaded */
  name: string;
  /** Upload progress in % */
  progress: number;
  /** True if upload failed */
  fail?: boolean;
}

class FileProgressBase extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        <div></div>
        <div>{p.name}</div>
      </div>      
    )
  }
}

const FileProgress = styled(FileProgressBase)`
  position: relative;
  height: 30px;
  margin: 3px 0px;

  &>div:first-child {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    
    ${p => p.fail && css`
      width: 100%;
      background: ${p.theme.errorColor.border};
    `}
    ${p => !p.fail && css`
      width: ${p.progress}%;
      background: ${p.progress == 100 ?  lighten(0.2, p.theme.successColor.border) : lighten(0.2, p.theme.primaryColor)};
      border-right: solid 1px ${p.progress == 100 ? p.theme.successColor.border : p.theme.primaryColor};        
    `}

    border-radius: ${p => p.theme.radius}px;
  }

  &>div:last-child {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 4px 8px;
    ${p =>  p.fail && css`color: ${p.theme.errorColor.color};`}
    ${p => !p.fail && p.progress  < 100 && css`color: ${p.theme.primaryColor};`}
    ${p => !p.fail && p.progress == 100 && css`color: ${p.theme.successColor.color};`}
  }
  
`

export { FileProgress }