import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { Auth } from '../../services/Auth';
import { Export } from '../Export';

import { Panel } from '@independent-software/typeui/controls/Panel'
import { Divider } from '@independent-software/typeui/controls/Divider'

interface IFilterProps {
  className?: string;
  children?: React.ReactNode;
  /** Export callback. If present, export button is shown in filter. */
  onExport?: (format: string) => void;  
}

interface IFilterState {
  open: boolean;
}

class Filter extends React.Component<IFilterProps, IFilterState> {
  
  constructor(props: IFilterProps) {
    super(props);
    this.state = {
      open: false
    };
  }

  // Toggle filter panel visibility.
  private handleToggle = () => {
    this.setState((prevState) => { return {
      open: !prevState.open
    }});
  }

  // Filter panel is closing.
  private handleClose = () => {
    this.setState({
      open: false
    });    
  }

  render() {
    let p = this.props;
    return (
      <FilterIcon onClick={this.handleToggle}>
        <svg>
          <title>Tools</title>
          <use xlinkHref={"spritemap.svg#tools"}></use>
        </svg>
        <Panel open={this.state.open} onClose={this.handleClose}>
          <div style={{width:'400px'}}>
            <div style={{padding: '16px'}}>        
              {p.children}
            </div>
            {p.onExport && <React.Fragment>
              <Divider fitted/>
              <div style={{padding: '8px'}}>
                {p.onExport && <Export onExport={p.onExport}/>}
              </div>
            </React.Fragment>}
          </div>
        </Panel>
      </FilterIcon>
    );
  }
}

const FilterIcon = styled('div')`
  position: absolute;
  z-index: 1;
  right: 30px;
  top: 20px;
  width: 30px;
  text-align: center;
  svg {
    width: 17px;
    height: 17px;
    fill: #888;
    cursor: pointer;
    transition: fill 0.1s ease;
    &:hover {
      fill: #333;
    }
  }
`;

export { Filter };
