import { ResourceFactory, Model, StampedModel } from '../services/';
import { Community } from './Community';
import { Survey } from './Survey';

/** 
 * Family is a StampedModel. It automatically includes created_by and 
 * updated_by fields.
 */
class Response extends StampedModel {
  public number: string;
  public date: string;
  public weight: number;
  public members: ResponseMember[] = [];
  public fields: ResponseField[] = [];
  public survey: Survey;
  public community: Community;
  public community_name: string;
  public survey_name: string;
}

class ResponseMember extends Model {
  public id: number;
}

class ResponseField extends Model {
  public id: number;
}


const ResponseFactory = ResourceFactory.create<Response>(Response, `response`);

export { Response, ResponseMember, ResponseField, ResponseFactory };