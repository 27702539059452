/**
 *  ValueStore stores values in a static Map. 
 *  Values are therefore lost on refresh, but not
 *  when switching routes.
 */
class ValueStore {
  private static values: Map<string, any> = new Map();

  /** 
   * Fetch a value by key from the ValueStore, or return 
   * a default value if the key doesn't exist.
   */
  public static get(key: string, defaultValue: any): any {
    // Does a value with this key exist in the store?
    let hasKey = ValueStore.values.has(key);
    // If it does, return it.
    if(hasKey) return ValueStore.values.get(key);
    // If not create a new query and place it in the store.
    ValueStore.values.set(key, defaultValue);
    return defaultValue;
  }

  /**
   * Add or overwrite a value in the value store.
   */
  public static set(key: string, value: any): void {
    ValueStore.values.set(key, value);
  }
}

export { ValueStore };