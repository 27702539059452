import { Loc } from "../../modules";
import { IIndicator, TCalculation } from "./definitions";

interface IData {
  respondents: number;
  data: IDataPoint[];
}

interface IDataPoint {
  [key: string]: number;
}

class ChartData {
  private indicator: IIndicator;
  private datapoints: IDataPoint[];
  private respondents: number;

  constructor(indicator: IIndicator, data: IData) {
    this.indicator = indicator;
    this.datapoints = data.data;
    this.respondents = data.respondents;
  }

  // Retrieve chart data points.
  // If relative values are required, percentages are calculated.
  // If more than one datapoint is available, Y-labels are added to each
  // datapoint.
  getData = (calc: TCalculation, langdata: any, round?: boolean): IDataPoint[] => {
    let result: IDataPoint[];

    // For relative values, calculate total, then calculate percentages.
    if(calc == 'relative') {
      result = this.datapoints.map((dp: IDataPoint) => {
        // Calculate total value of all keys
        let total = Object.values(dp).reduce((total, v) => total + v, 0);
        let converted: IDataPoint = {};
        Object.entries(dp).forEach((v) => {
          // No rounding or flooring, or things don't add up to 100.
          converted[v[0]] = v[1] * 100 / total;
          // If 0 < x < 1 then x := 1
          // (not for now)
          // if(converted[v[0]] != 0 && converted[v[0]] < 1) converted[v[0]] = 1;
          if(round) converted[v[0]] = Math.round(converted[v[0]]);
        });
        return converted;
      });
    } else {
      result = this.datapoints;
    }

    // Add Ylabels:
    if(result.length > 1) {
      return result.map((dp:any, index: number) => { 
        return {
          ...dp, 
          name: Loc.getText(langdata, ("name_" + this.indicator.name + "_" + index) as any) }; 
      });
    }
    return result;    
  }

  // Return category numbers. No duplicates are returned.
  // Y-labels are filtered out.
  getUniqueCategories = (): string[] => {
    let set = new Set<string>();
    this.datapoints.forEach((dp) => {
      Object.keys(dp).filter((c) => c != 'name').forEach((v) => set.add(v));
    });
    return Array.from(set);  
  }

  // Return number of available datapoints.
  length = (): number => {
    return this.datapoints.length;
  }

  // Return total number if respondents.
  getRespondents = (): number => {
    return this.respondents;
  }
}

export { ChartData, IDataPoint }