import * as React from 'react';
import { Action, Actiontype, ActiontypeFactory, Attachment, Community, CommunityFactory, Household, HouseholdFactory } from '../../resource/';
import { IAuthProps, Query } from '../../services/';

import { Input } from '@independent-software/typeui/controls/Input';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Form } from '@independent-software/typeui/controls/Form';
import { Section, AttachmentManager, Loc, Block } from '../../modules';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Label } from '@independent-software/typeui/controls/Label';
import { MarkdownTextarea } from '@independent-software/typeui/modules/MarkdownTextarea';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { TLocalization } from '../../modules/Loc/TLocalization';
import { Dropzone } from '@independent-software/typeui/controls/Dropzone';
import { Filesize } from '@independent-software/typeui/formatters/Filesize';
import { Divider } from '@independent-software/typeui/controls/Divider';

interface IProps {
  /** Initial form data. */
  data: Action;
  /** Called whenever form changes. */
  onChange: (data: Action, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */  
  dirty?: boolean;
}

interface IState {
  file: File;
  /** Current form data. */
  data: Action;
  /** Data for communities dropdown */
  communities: Community[];    
  /** Data for households dropdown */
  households: Household[];    
  /** Data for actiontypes dropdown */
  actiontypes: Actiontype[];
}

class ActionForm extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);

    // Intialize state.
    this.state = {
      file: null,
      data: props.data,
      communities: [],
      households: [],
      actiontypes: []
    };    
  }

  componentDidMount() {
    this.handleCommunitySearch();
    this.handleHouseholdSearch();
    this.loadActionTypes();
  }

  handleCommunitySearch = (q?:string) => {
    // Retrieve a list of communities:
    let query = new Query('name', 'asc');
    if(q) query.setFilter('q', 'like', q);
    CommunityFactory.getSome(this.props.auth, 0, 999, query)
      .then((res) => this.setState({ communities: res.items }));
  }  

  handleHouseholdSearch = (q?:string) => {
    // Retrieve a list of households:
    let query = new Query('hhh_name', 'asc');
    if(q) query.setFilter('q', 'like', q);
    HouseholdFactory.getSome(this.props.auth, 0, 16, query)
      .then((res) => this.setState({ households: res.items }));
  }  

  loadActionTypes = () => {
    let query = new Query('name', 'asc');
    ActiontypeFactory.getSome(this.props.auth, 0, 999, query)
      .then((res) => this.setState({ actiontypes: res.items }));
  }

  //
  // Get the allowable action types for the current selection of "level" and 
  // "delivery".
  // 
  getActionTypes = (): Actiontype[] => {
    return this.state.actiontypes.filter((v: Actiontype) => (v.level == this.state.data.level || v.level == 'both') && v.delivery == this.state.data.delivery);
  }

  // Get the current value for the actiontypes dropdown. If "level" or "delivery" 
  // have changed, then the action type may no longer be valid and null should 
  // be used.
  getActionType = (): Actiontype => {
    if(this.state.data.actiontype == null) return null;
    if(this.getActionTypes().some((v: Actiontype) => v.id == this.state.data.actiontype.id)) {
      return this.state.data.actiontype as Actiontype;
    }
    return null;
  }

  handleChangeAttachments = (attachments: Attachment[]) => {
    this.setState((prevState) => {
      prevState.data.attachments = attachments;
      return prevState;
    });
  }  

  render() {
    let p = this.props;
    return (
      <Form
        data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
        <Tabs underlined>
          <Tabs.Pane label={<Loc msg="actions_form_tab_data"/>}>
            <Section padded>
              <Form.Field 
                required={{message: <Loc msg="actions_fields_title_required"/>}}
                minLength={{length: 3, message: <Loc msg="actions_fields_title_minlength"/>}}
                maxLength={{length: 255, message: <Loc msg="actions_fields_title_maxlength"/>}}
                width={1} label={<Loc msg="actions_fields_title_label"/>}
                name="title" 
                value={this.state.data.title}
                control={(<Input type="text" placeholder="Name" fluid/>)}
                hint={<Loc msg="actions_fields_title_hint"/>}/>
              <Divider/>

              <Block title={<Loc msg={"actions_block_level"}/>}>
                <Flex>
                  <Flex.Row>
                    <Flex.Column width={1}>
                      <Form.Field
                        forceupdate
                        required={{message: <Loc msg="actiontypes_fields_level_required"/>}}
                        width={1} label={<Loc msg="actiontypes_fields_level_label"/>}
                        name="level" 
                        value={this.state.data.level}
                        control={(<Dropdown fluid data={['individual','community']} placeholder="Level" label={(item:string) => <Loc msg={("actiontypes_level_" + item) as TLocalization}/>}>
                          <Dropdown.Column>{(item:string) => <Loc msg={("actiontypes_level_" + item) as TLocalization}/>}</Dropdown.Column>
                        </Dropdown>)}
                        hint={<Loc msg="actiontypes_fields_level_hint"/>}/>
                    </Flex.Column>
                    <Flex.Column width={2}>
                      {this.state.data.level == 'community' && <Form.Field
                        required={{message: <Loc msg="actions_fields_community_required"/>}}
                        width={1} label={<Loc msg="actions_fields_community_label"/>}
                        name="community" 
                        value={this.state.data.community}
                        control={(<Dropdown onSearch={this.handleCommunitySearch} fluid data={this.state.communities} placeholder="Community" label={(item:Community) => item.name}>
                          <Dropdown.Column>{(item:Community) => item.name}</Dropdown.Column>
                        </Dropdown>)}
                        hint={<Loc msg="actions_fields_community_hint"/>}/>}
                      {this.state.data.level == 'individual' && <Form.Field
                        required={{message: <Loc msg="actions_fields_household_required"/>}}
                        width={1} label={<Loc msg="actions_fields_household_label"/>}
                        name="household" 
                        value={this.state.data.household}
                        control={(<Dropdown onSearch={this.handleHouseholdSearch} fluid data={this.state.households} placeholder="Household" label={(item:Household) => <React.Fragment><Label size="tiny">{item.code}</Label> {item.hhh_name}</React.Fragment>}>
                          <Dropdown.Column weight={1}>{(item:Household) => <Label size="tiny">{item.code}</Label>}</Dropdown.Column>
                          <Dropdown.Column weight={6}>{(item:Household) => item.hhh_name}</Dropdown.Column>
                        </Dropdown>)}
                        hint={<Loc msg="actions_fields_household_hint"/>}/>}
                    </Flex.Column>
                  </Flex.Row>
                </Flex>
              </Block>

              <Block title={<Loc msg={"actions_block_type"}/>}>
                <Flex>
                  <Flex.Row>
                    <Flex.Column width={1}>
                      <Form.Field
                        forceupdate
                        required={{message: <Loc msg="actiontypes_fields_delivery_required"/>}}
                        width={1} label={<Loc msg="actiontypes_fields_delivery_label"/>}
                        name="delivery" 
                        value={this.state.data.delivery}
                        control={(<Dropdown fluid data={['cash','kind','specific','benefits','livelihood','unplanned']} placeholder="Delivery" label={(item:string) => <Loc msg={("actiontypes_delivery_" + item) as TLocalization}/>}>
                          <Dropdown.Column>{(item:string) => <Loc msg={("actiontypes_delivery_" + item) as TLocalization}/>}</Dropdown.Column>
                        </Dropdown>)}
                        hint={<Loc msg="actiontypes_fields_delivery_hint"/>}/>
                    </Flex.Column>
                    <Flex.Column width={2}>
                      <Form.Field
                        forceupdate
                        disabled={!this.state.data.level || !this.state.data.delivery}
                        required={{message: <Loc msg="actions_fields_actiontype_required"/>}}
                        width={1} label={<Loc msg="actions_fields_actiontype_label"/>}
                        name="actiontype" 
                        value={this.getActionType()}
                        control={(<Dropdown fluid data={this.getActionTypes()} placeholder="Action type" label={(item:Actiontype) => item.name}>
                          <Dropdown.Column>{(item:Actiontype) => item.name}</Dropdown.Column>
                        </Dropdown>)}
                        hint={<Loc msg="actions_fields_actiontype_hint"/>}/>
                    </Flex.Column>
                  </Flex.Row>
                </Flex>
              </Block>

              <Flex.Columns count={2}>
                <React.Fragment>
                  
                  <Form.Field
                    forceupdate
                    required={{message: <Loc msg="actions_fields_responsibility_required"/>}}
                    width={1} label={<Loc msg="actions_fields_responsibility_label"/>}
                    name="responsibility" 
                    value={this.state.data.responsibility}
                    control={(<Dropdown fluid data={['pnl','government','provider']} placeholder="Responsibility" label={(item:string) => <Loc msg={("actions_responsibility_" + item) as TLocalization}/>}>
                      <Dropdown.Column>{(item:string) => <Loc msg={("actions_responsibility_" + item) as TLocalization}/>}</Dropdown.Column>
                    </Dropdown>)}
                    hint={<Loc msg="actions_fields_responsibility_hint"/>}/>
                  {this.state.data.responsibility == 'provider' && <Form.Field 
                    required={{message: <Loc msg="actions_fields_serviceprovider_required"/>}}
                    minLength={{length: 3, message: <Loc msg="actions_fields_serviceprovider_minlength"/>}}
                    maxLength={{length: 255, message: <Loc msg="actions_fields_serviceprovider_maxlength"/>}}
                    width={1} label={<Loc msg="actions_fields_serviceprovider_label"/>}
                    name="serviceprovider" 
                    value={this.state.data.serviceprovider}
                    control={(<Input type="text" placeholder="Service provider" fluid/>)}
                    hint={<Loc msg="actions_fields_serviceprovider_hint"/>}/>}
                  <Form.Field
                    required={{message: <Loc msg="actions_fields_phase_required"/>}}
                    width={1} label={<Loc msg="actions_fields_phase_label"/>}
                    name="phase" 
                    value={this.state.data.phase}
                    control={(<Dropdown fluid data={['prerelocation','relocation','postrelocation']} placeholder="Phase" label={(item:string) => <Loc msg={("actions_phases_" + item) as TLocalization}/>}>
                      <Dropdown.Column>{(item:string) => <Loc msg={("actions_phases_" + item) as TLocalization}/>}</Dropdown.Column>
                    </Dropdown>)}
                    hint={<Loc msg="actions_fields_phase_hint"/>}/>                    
                  <Form.Field 
                    required={{message: <Loc msg="actions_fields_startdate_required"/>}}
                    width={1} label={<Loc msg="actions_fields_startdate_label"/>}
                    name="startdate" 
                    value={this.state.data.startdate}
                    control={(<Input type="date" placeholder="Start date"/>)}
                    hint={<Loc msg="actions_fields_startdate_hint"/>}/>
                  {/* <Dropzone
                    message={this.state.file ? <React.Fragment>{this.state.file.name} (<Filesize value={this.state.file.size}/>)</React.Fragment> : <Loc msg="actions_fields_terms"/>} 
                    onAddFiles={(files: File[]) => { this.setState({ file: files[0] }); p.onFile(files[0]);  } }/> */}
                </React.Fragment>
                <React.Fragment>
                  <Form.Field 
                    required={{message: <Loc msg="actions_fields_description_required"/>}}
                    label={<Loc msg="actions_fields_description_label"/>}
                    name="description" value={this.state.data.description}
                    control={(<MarkdownTextarea rows={20} fluid label_text={<Loc msg="markdown_text"/>} label_preview={<Loc msg="markdown_preview"/>} label_markdown={<Loc msg="markdown_is_supported"/>}/>)}
                    hint={<Loc msg="actions_fields_description_hint"/>}/>
                </React.Fragment>
              </Flex.Columns>
            </Section>
          </Tabs.Pane>
          {/* <Tabs.Pane label={<React.Fragment><Loc msg="actions_form_tab_attachments"/> <Label size="small">{this.state.data.attachments.length}</Label></React.Fragment>}>
            <Section padded>
              <AttachmentManager 
                auth={p.auth} 
                attachments={this.state.data.attachments} 
                onChange={this.handleChangeAttachments}/>
            </Section>
          </Tabs.Pane> */}
        </Tabs>

      </Form>
    )
  }
}

export { ActionForm };
