import * as React from 'react';
import { Loc } from "../modules";

/**
 * Rights available in application.
 * 
 * WARNING WARNING: When you add a right here, also add it to resource/Profile.ts,
 * as well as to API:Rights.php !!!
 */
type TRight = 
    'can_view_users' 
  | 'can_edit_users'
  | 'can_view_profiles'
  | 'can_edit_profiles'
  | 'can_view_logs'
  | 'can_view_responses'
  | 'can_edit_responses'
  | 'can_view_communities'
  | 'can_edit_communities'
  | 'can_view_surveys'
  | 'can_edit_surveys'
  | 'can_view_attachments'
  | 'can_edit_attachments'
  | 'can_view_actions'
  | 'can_edit_actions'
  | 'can_flow_actions'
  | 'can_view_grievances'
  | 'can_edit_grievances'
  | 'can_view_requests'
  | 'can_edit_requests'
  | 'can_view_districts'
  | 'can_edit_districts'
  | 'can_view_stakeholders'
  | 'can_edit_stakeholders'
  | 'can_view_issuetypes'
  | 'can_edit_issuetypes'
  | 'can_validate_grievances'
  | 'can_validate_actions'
  | 'can_edit_workflow'
  | 'can_view_actiontypes'
  | 'can_edit_actiontypes'
  | 'can_view_households'
  | 'can_edit_households'
  | 'can_debug';
  // WARNING WARNING WARNING see above.

interface IRight {
  title: React.ReactNode;
  name: TRight;
}

interface IAccessGroup {
  title: React.ReactNode;
  rights: IRight[];
}

/**
 * These access groups are used in the Profile form.
 */
const ACCESS_GROUPS: IAccessGroup[] = [
  {
    title: <Loc msg="access_groups_users"/>,
    rights: [
      { title: <Loc msg="access_groups_view_users"/>, name: 'can_view_users' },
      { title: <Loc msg="access_groups_edit_users"/>, name: 'can_edit_users' }
    ]
  }, {
    title: <Loc msg="access_groups_profiles"/>,
    rights: [
      { title: <Loc msg="access_groups_view_profiles"/>, name: 'can_view_profiles' },
      { title: <Loc msg="access_groups_edit_profiles"/>, name: 'can_edit_profiles' }
    ]
  }, {
    title: <Loc msg="access_groups_log"/>,
    rights: [
      { title: <Loc msg="access_groups_view_logs"/>, name: 'can_view_logs'}
    ]
  }, {
    title: <Loc msg="access_groups_responses"/>,
    rights: [
      { title: <Loc msg="access_groups_view_responses"/>, name: 'can_view_responses' },
      { title: <Loc msg="access_groups_edit_responses"/>, name: 'can_edit_responses' }
    ]
  }, {
    title: <Loc msg="access_groups_communities"/>,
    rights: [
      { title: <Loc msg="access_groups_view_communities"/>, name: 'can_view_communities' },
      { title: <Loc msg="access_groups_edit_communities"/>, name: 'can_edit_communities' }
    ]
  }, {
    title: <Loc msg="access_groups_surveys"/>,
    rights: [
      { title: <Loc msg="access_groups_view_surveys"/>, name: 'can_view_surveys' },
      { title: <Loc msg="access_groups_edit_surveys"/>, name: 'can_edit_surveys' }
    ]
  }, {
    title: <Loc msg="access_groups_attachments"/>,
    rights: [
      { title: <Loc msg="access_groups_view_attachments"/>, name: 'can_view_attachments' },
      { title: <Loc msg="access_groups_edit_attachments"/>, name: 'can_edit_attachments' }
    ]
  }, {
    title: <Loc msg="access_groups_actions"/>,
    rights: [
      { title: <Loc msg="access_groups_view_actions"/>, name: 'can_view_actions' },
      { title: <Loc msg="access_groups_edit_actions"/>, name: 'can_edit_actions' },
      { title: <Loc msg="access_groups_flow_actions"/>, name: 'can_flow_actions' }
    ]
  }, {
    title: <Loc msg="access_groups_grievances"/>,
    rights: [
      { title: <Loc msg="access_groups_view_grievances"/>, name: 'can_view_grievances' },
      { title: <Loc msg="access_groups_edit_grievances"/>, name: 'can_edit_grievances' }
    ]
  }, {
    title: <Loc msg="access_groups_requests"/>,
    rights: [
      { title: <Loc msg="access_groups_view_requests"/>, name: 'can_view_requests' },
      { title: <Loc msg="access_groups_edit_requests"/>, name: 'can_edit_requests' }
    ]
  }, {
    title: <Loc msg="access_groups_districts"/>,
    rights: [
      { title: <Loc msg="access_groups_view_districts"/>, name: 'can_view_districts' },
      { title: <Loc msg="access_groups_edit_districts"/>, name: 'can_edit_districts' }
    ]
  }, {
    title: <Loc msg="access_groups_stakeholders"/>,
    rights: [
      { title: <Loc msg="access_groups_view_stakeholders"/>, name: 'can_view_stakeholders' },
      { title: <Loc msg="access_groups_edit_stakeholders"/>, name: 'can_edit_stakeholders' }
    ]
  }, {
    title: <Loc msg="access_groups_issuetypes"/>,
    rights: [
      { title: <Loc msg="access_groups_view_issuetypes"/>, name: 'can_view_issuetypes' },
      { title: <Loc msg="access_groups_edit_issuetypes"/>, name: 'can_edit_issuetypes' }
    ]
  }, {
    title: <Loc msg="access_groups_quality_control"/>,
    rights: [
      { title: <Loc msg="access_groups_validate_grievances"/>, name: 'can_validate_grievances' },
      { title: <Loc msg="access_groups_validate_actions"/>, name: 'can_validate_actions' },
      { title: <Loc msg="access_groups_edit_workflow"/>, name: 'can_edit_workflow' }
    ]
  }, {
    title: <Loc msg="access_groups_actiontypes"/>,
    rights: [
      { title: <Loc msg="access_groups_view_actiontypes"/>, name: 'can_view_actiontypes' },
      { title: <Loc msg="access_groups_edit_actiontypes"/>, name: 'can_edit_actiontypes' }
    ]
  }, {
    title: <Loc msg="access_groups_households"/>,
    rights: [
      { title: <Loc msg="access_groups_view_households"/>, name: 'can_view_households' },
      { title: <Loc msg="access_groups_edit_households"/>, name: 'can_edit_households' }
    ]
  }, {
    title: <Loc msg="access_groups_debug"/>,
    rights: [
      { title: <Loc msg="access_groups_can_debug"/>, name: 'can_debug' },
    ]
  }
];


export { TRight, IAccessGroup, IRight, ACCESS_GROUPS };