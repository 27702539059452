import * as React from 'react';

import { LabelledValue } from '@independent-software/typeui/controls/LabelledValue';

import { IQuestion, QuestionTree } from './Definitions';
import { QuestionContext, IQuestionContext } from './QuestionContext';
import { Icon } from '@independent-software/typeui/controls/Icon';

interface IAnswerProps {
  name: string;
  other?: string;
  nonumber?: boolean;    // Do not print answer code.
  decimals?: number;
}

class AnswerBase extends React.Component<IAnswerProps, {}> {

  public static contextType = QuestionContext;

  findQuestion = (name: string): IQuestion => {
    let { data, group, index, group2, index2 } = (this.context as IQuestionContext);

    // Find question:
    let question: IQuestion;
    if(group2) {
      question = QuestionTree[group][group2][name];
    }
    else if(group) {
      question = QuestionTree[group][name];
    } else {
      question = QuestionTree[name];
    }
    if(!question) {
      console.error("Question " + name + " not found.");
    }
    return question;
  }  
  
  getValue = (name: string, question: IQuestion): any => {
    const { data, group, index, group2, index2 } = (this.context as IQuestionContext);
    let value = data[name];
    switch(question.type) {
      case 'list':
        if(value != null) value = question.list[value];
        break;
      case 'check':
        value = value == 1 ? <Icon color='green' name='check'/> : <Icon color='darkred' name='times'/>;
        break;
      case 'number':
        if(value != null) value = value.toLocaleString();
        break;
      case 'community':
        if(value != null) return value.name;
        break;
      case 'longtext':
        value = <div style={{ whiteSpace: 'pre', unicodeBidi: 'embed', display: 'block' }}>{value}</div>;
        break;
      default:
        break;
    }
    return value;
  } 
}

class SimpleAnswer extends AnswerBase {
  render() {
    let p = this.props;
    // Find question(s):
    let question: IQuestion = this.findQuestion(p.name);
    let otherQuestion: IQuestion = p.other ? this.findQuestion(p.other) : null;

    // List questions perform a lookup for this list value:
    let value = this.getValue(p.name, question);
    let otherValue = p.other ? this.getValue(p.other, otherQuestion) : null;
    
    // Render answer:
    return (
      <span>{value} {(otherQuestion && otherValue) && <span>({otherValue})</span>}</span>
    )
  }
}

class Answer extends AnswerBase {
  render() {
    let p = this.props;
    // Find question(s):
    let question: IQuestion = this.findQuestion(p.name);
    let otherQuestion: IQuestion = p.other ? this.findQuestion(p.other) : null;

    // List questions perform a lookup for this list value:
    let value = this.getValue(p.name, question);
    let otherValue = p.other ? this.getValue(p.other, otherQuestion) : null;

    if(question.specialValues) {
      if (question.specialValues[value]) {
        value = question.specialValues[value];
      }
    }

    // Render answer:
    return (
      <LabelledValue label={(p.nonumber ? "" : p.name.replace(/_/g, ".") + ". ") + question.title}>
        {value} {(otherQuestion && otherValue) && <span>({otherValue})</span>}
      </LabelledValue>
    )
  }  
}

export { Answer, SimpleAnswer }