import { StampedModel, ResourceFactory } from '../services/';
import { Attachment } from './Attachment';
import { Post } from './Post';
import { Issuetype } from './Issuetype';

type TStates = 
  "reception"
| "triage"
| "triage_conclusion"
| "communication1"
| 'implementation1'
| 'implementation1_conclusion'
| "assessment1"
| "implementation2"
| "implementation2_conclusion"
| "assessment2"
| "communication2"
| "implementation3"
| "implementation3_conclusion"
| "litigation"
| "litigation_conclusion";

interface IGrievanceStep {
  state: TStates;
  type: "move" | "validation" | "rollback";
  created_at: string;
  created_by: {
    id: number;
    name: string;
    email: string;
  }
}

class Grievance extends StampedModel {
  public title: string = '';
  public date: string = '';
  public submission: string = '';
  public cso: string = '';
  public description: string = '';
  public attachments_count: number = 0;
  public posts_count: number = 0;
  public anonymous: boolean;
  public datasharing: boolean;
  public has_consent_form: boolean;
  public state: TStates;
  public newstate: TStates; 
  public issuetype: Issuetype;
  public reason: string;
  public reception_contact_type: string;
  public reception_contact_date: string;
  public triage_contact_type: string;
  public triage_contact_date: string;
  public committee_members: string;
  public communication1_contact_type: string;
  public communication1_contact_date: string;
  public panel_members: string;
  public implementation_contact_type: string;
  public implementation_contact_date: string;
  public assessment1_contact_type: string;
  public assessment1_contact_date: string;
  public decision_date: string;
  public communication2_contact_type: string;
  public communication2_contact_date: string;
  public litigation_contact_type: string;
  public litigation_contact_date: string;

  public resolved: number;
  public age: number;
  public overdue: number;

  public stakeholder: { 
    id: number;
    name: string;
  } = null;
  public steps: IGrievanceStep[];

  public attachments: Attachment[] = [];
  public posts: Post[] = [];

  /**
   * Given a state, return its set of preceding states (but not the state itself)
   */
  static getPreceding = (state: TStates): TStates[] => {
    switch(state) {
      case 'reception': return [];
      case 'triage': return ['reception'];
      case 'triage_conclusion': return ['reception', 'triage'];
      case 'communication1': return ['reception', 'triage'];
      case 'implementation1': return ['reception', 'triage', 'communication1'];
      case 'implementation1_conclusion': return ['reception', 'triage', 'communication1', 'implementation1'];
      case 'assessment1': return ['reception', 'triage', 'communication1'];
      case 'implementation2': return ['reception', 'triage', 'communication1', 'assessment1'];
      case 'implementation2_conclusion': return ['reception', 'triage', 'communication1', 'assessment1', 'implementation2'];
      case 'assessment2': return ['reception', 'triage', 'communication1', 'assessment1'];
      case 'communication2': return ['reception', 'triage', 'communication1', 'assessment1', 'assessment2'];
      case 'implementation3': return ['reception', 'triage', 'communication1', 'assessment1', 'assessment2', 'communication2'];
      case 'implementation3_conclusion': return ['reception', 'triage', 'communication1', 'assessment1', 'assessment2', 'communication2', 'implementation3'];
      case 'litigation': return ['reception', 'triage', 'communication1', 'assessment1', 'assessment2', 'communication2'];
      case 'litigation_conclusion': return ['reception', 'triage', 'communication1', 'assessment1', 'assessment2', 'communication2', 'litigation'];
      default: return [];
    }
  }

  /**
   * Returns true if earlier state precedes later state.
   */
  static isPreceding = (earlierState: TStates, laterState: TStates): boolean => {
    return Grievance.getPreceding(laterState).includes(earlierState);
  }

  /**
   * Given a state, determine if this grievance has passed it.
   */
  hasState = (state: TStates): boolean => {
    let s = this.newstate == null ? this.state : this.newstate;
    return Grievance.getPreceding(s).includes(state) || s == state;
  }

  /**
   * Returns true if current state is an end state.
   */
  isDone = (): boolean => {
    return (this.state == 'triage_conclusion' 
         || this.state == 'implementation1_conclusion' 
         || this.state == 'implementation2_conclusion' 
         || this.state == 'implementation3_conclusion' 
         || this.state == 'litigation_conclusion');
  }
}

const GrievanceFactory = ResourceFactory.create<Grievance>(Grievance, 'grievance');

export { Grievance, GrievanceFactory, TStates, IGrievanceStep };