import * as React from 'react';
import { Actiontype, ActiontypeFactory } from '../../resource/'; 
import { IAuthProps, List, IListState, IListProps, SearchFilter, Query } from '../../services/';
import { IconBar, Export, Loc } from '../../modules';

import { Form } from '@independent-software/typeui/controls/Form';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { DataTable } from '@independent-software/typeui/controls/DataTable';
import { Number } from '@independent-software/typeui/formatters/Number';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { TLocalization } from '../../modules/Loc/TLocalization';

interface IProps extends IListProps {
  /* If set, list is prefiltered by an action level and action level
   * column and filter are hidden.
   */
  level?: string;  
  /* If set, list is prefiltered by an action delivery means and action delivery
   * column and filter are hidden.
   */
  delivery?: string;  
  /**
   * Event is fired when an issue type is clicked.
   */
  onClick?: (actiontype: Actiontype) => void;
}

interface IState extends IListState<Actiontype> {
}

class ListActiontypes extends List<Actiontype, IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props, ActiontypeFactory, 'name', 'asc');

    // Initialize state (list initializes its own part of the state):
    this.state = {
      ...this.state
    };

    if(this.props.delivery) {
      this.setFilter('delivery', 'eq', { id: this.props.delivery }, true);
    }        
  }

  handleSearch = (value:string) => {
    this.setFilter('q', 'like', value);
  }  

  handleChangeLevel = (value: string) => {
    this.setFilter('level', 'eq', value);
  }

  handleChangeDelivery = (value: string) => {
    this.setFilter('delivery', 'eq', value);
  }

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Form.Uncontrolled hint={<Loc msg="actiontypes_filter_search_hint"/>}>
          <SearchFilter value={this.getFilter('q', 'like')} onSearch={this.handleSearch}/>
        </Form.Uncontrolled>
        {!p.level && 
        <Form.Uncontrolled hint={<Loc msg="actiontypes_filter_level_hint"/>}>
          <Dropdown name="level" fluid clearable value={this.getFilter('level', 'eq')} data={['individual','community','both']} placeholder="Level" label={(item:string) => <Loc msg={("actiontypes_level_" + item) as TLocalization}/>} onChange={this.handleChangeLevel}>
            <Dropdown.Column>{(item:string) => <Loc msg={("actiontypes_level_" + item) as TLocalization}/>}</Dropdown.Column>
          </Dropdown>
        </Form.Uncontrolled>}                  
        {!p.delivery && 
        <Form.Uncontrolled hint={<Loc msg="actiontypes_filter_delivery_hint"/>}>
          <Dropdown name="delivery" fluid clearable value={this.getFilter('delivery', 'eq')} data={['cash','kind','specific','benefits','livelihood','unplanned']} placeholder="Delivery" label={(item:string) => <Loc msg={("actiontypes_delivery_" + item) as TLocalization}/>} onChange={this.handleChangeDelivery}>
            <Dropdown.Column>{(item:string) => <Loc msg={("actiontypes_delivery_" + item) as TLocalization}/>}</Dropdown.Column>
          </Dropdown>
        </Form.Uncontrolled>}                  
      </Panel.Content>
      <Panel.Footer>
        <Export onExport={this.handleExport}/>
      </Panel.Footer>      
    </React.Fragment>

    return (
      <React.Fragment>
        <IconBar>
          <Panel.Icon icon="tools" width={300}>
            {filter}
          </Panel.Icon>
        </IconBar> 
        <DataTable error={this.state.error} loading={this.state.loading} scrollTop={this.state.scrollTop} onScroll={this.handleScroll} data={this.state.items} onFetch={this.handleFetch} onClick={p.onClick} onOrder={this.handleOrder} order={this.getOrder()} dir={this.getDir()}>
          <DataTable.Column weight={5} label={<Loc msg="actiontypes_list_hdr_name"/>} order="name" dir="asc">{(item:Actiontype) => <span>{item.name}</span>}</DataTable.Column>
          {!p.level && <DataTable.Column weight={3} label={<Loc msg="actiontypes_list_hdr_level"/>} order="level" dir="asc">{(item:Actiontype) => <span>{item.level ? <Loc msg={("actiontypes_level_" + item.level) as TLocalization}/> : '-'}</span>}</DataTable.Column>}
          {!p.delivery && <DataTable.Column weight={3} label={<Loc msg="actiontypes_list_hdr_delivery"/>} order="delivery" dir="asc">{(item:Actiontype) => <span>{item.delivery ? <Loc msg={("actiontypes_delivery_" + item.delivery) as TLocalization}/> : '-'}</span>}</DataTable.Column>}
        </DataTable>
        <Dialog.Xhr open={this.state.exportError != null} error={this.state.exportError} onClose={this.handleCloseDialog}/>
      </React.Fragment>
    );
  }
}

export { ListActiontypes };
