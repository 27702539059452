import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { Loader } from '@independent-software/typeui/controls/Loader';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Button } from '@independent-software/typeui/controls/Button';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Header } from '@independent-software/typeui/controls/Header';
import { Message } from '@independent-software/typeui/controls/Message';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Accordion } from '@independent-software/typeui/controls/Accordion';
import { Divider } from '@independent-software/typeui/controls/Divider';
import { Segment } from '@independent-software/typeui/controls/Segment';
import { Table } from '@independent-software/typeui/controls/Table';
import { Label } from '@independent-software/typeui/controls/Label';

import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';
import { ValueStore } from '../../services';
import { Container, Content, BottomBar, Section, Timestamp, Loc } from '../../modules';
import { Response, ResponseFactory } from '../../resource/'; 
import { Answer, SimpleAnswer } from './Answer';
import { QuestionContext } from './QuestionContext';
import { Block, Layout } from '../../controls';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { LabelledValue } from '@independent-software/typeui/controls/LabelledValue';


type TStep = 'loading' | 'loadError' | 'ready' | 'error';

interface IState {
  response: Response;
  error: any;
  step: TStep;
}

interface MatchParams {
  id: string;
}

class ViewResponse extends React.Component<IAuthProps & RouteComponentProps<MatchParams>, IState> {
  constructor(props: IAuthProps & RouteComponentProps<MatchParams>) {
    super(props);
    this.state = {
      response: null,
      step: 'loading',
      error: null
    };
    this.loadResponse();
  }

  private loadResponse() {
    ResponseFactory.get(this.props.auth, parseInt(this.props.match.params.id))
    .then((response) => {
      this.setState({
        step: 'ready',
        response: response
      });
    })
    .catch(error => {
      this.setState({
        step: 'loadError',
        error: error
      })
    });
  }  

  private handleEdit = () => {
    this.props.history.push(`/responses/edit/${this.state.response.id}`);
  }

  private handleCancelLoad = () => {
    this.setState({ step: 'error' });
  }

  private handleRetry = () => {
    this.setState({error: null, step: 'loading'});
    this.loadResponse();
  }  

  private handleTabChange = (index: number) => {
    ValueStore.set("view_response", index);
  }

  renderGeneralTab = () => {
    let obj = this.state.response as any;
    return (<>
      <Divider hidden/>
      <Block title="Inquérito">
        <LabelledValue label="Inquérito">
          {this.state.response.survey.name}
        </LabelledValue>
      </Block>
      <Block title="Questionário">
        <Layout width={2}>
          <Answer nonumber name='GEN_SURVEY_NUM'/>
          <Answer nonumber name='GEN_WEIGHT'/>
        </Layout>
        <Layout width={2}>
          <Answer nonumber name='GEN_COORDENATOR_CODE'/>
          <Answer nonumber name='GEN_INTERVIEWER_CODE'/>
        </Layout>
        <Layout width={3}>
          <Answer nonumber name='GEN_DATE'/>
          <Answer nonumber name='GEN_TIME_START'/>
          <Answer nonumber name='GEN_TIME_END'/>
        </Layout>
      </Block>
      <Block title="CAF">
        <Layout width={2}>
          {this.props.auth.hasRight('can_edit_responses') &&
            <Answer nonumber name='GEN_CAF_NAME'/>}
          {this.props.auth.hasRight('can_edit_responses') &&
            <Answer nonumber name='GEN_CAF_CONTACT'/>}
          <Answer nonumber name='GEN_CAF_AGE'/>
          <Answer nonumber name='GEN_CAF_GENDER'/>
          <Answer nonumber name='AFMONOPARENTAL'/>
          <Answer nonumber name='AFPOLIGAMO'/>
        </Layout>
      </Block>
      <Block title="Entrevistado">
        <Layout width={2}>
          {this.props.auth.hasRight('can_edit_responses') &&
            <Answer nonumber name='GEN_NAME'/>}
          <Answer nonumber name='GEN_RELATION'/>
          <Answer nonumber name='GEN_CONTACT_CONSENT'/>
          {this.props.auth.hasRight('can_edit_responses') &&
            <Answer nonumber name='GEN_CONTACT'/>}          
        </Layout>
      </Block>
      <Block title="Local">
        <Layout width={2}>
          <Answer nonumber name='GEN_COORD_LAT'/>
          <Answer nonumber name='GEN_COORD_LON'/>
          <LabelledValue label="Comunidade">
            {this.state.response.community.name}
          </LabelledValue>
          {/* <Answer nonumber name='community_id'/> */}
          <Answer nonumber name='GEN_RESIDENCE'/>
          <Answer nonumber name='GEN_BAIRRO'/>
        </Layout>
      </Block>      
      <Block title="Comentários">
        <Answer nonumber name='GEN_COMMENTS'/>
      </Block>
    </>)
  }

  renderEducationTab = () => {
    let obj = this.state.response as any;
    return (
      <>
        <Divider hidden/>
        <Block title="A1. Tamanho do agredado familiar">
          <Answer name="A1"/>
        </Block>
        {obj.members.length == 0 && 
          <Message type="info">Não há membros com idade superior ou igual a 5 anos registados.</Message>}
        {obj.members.length > 0 &&
        <>
          <Segment attached="top">
            Membros com idade superior ou igual a 5 anos <Label size="tiny">{obj.members.length}</Label>
          </Segment>
          <Accordion styled attached="bottom">
            {obj.members.map((m: any, index: number) => 
              <Accordion.Tab key={index}
                title={<QuestionContext.Provider value={{data: m, group: 'members', index: index}}>
                  {this.props.auth.hasRight('can_edit_responses') ? m.A2 : index + 1}
                </QuestionContext.Provider>}>
                <QuestionContext.Provider value={{data: m, group: 'members', index: index}}>
                  <Layout width={2}>
                    {this.props.auth.hasRight('can_edit_responses') &&
                    <Answer name="A2"/>}
                    <Answer name="A3"/>
                    <Answer name="A5"/>
                  </Layout>
                  <Layout width={2}>
                    <Answer name='B2'/>
                  </Layout>
                  {(m['B2'] && m['B2'] != 0) && 
                    <Layout width={2}>
                      <Answer name='B3'/>
                    </Layout>}
                  {(m['B2'] && m['B2'] != 0) && 
                    <Layout width={2}>
                      <Answer name='B4'/>
                      {(m['B4'] && m['B4'] == 98) && <Answer name='B4A'/>}
                    </Layout>}
                  {(m['B2'] && m['B2'] == 0) && 
                    <Layout width={2}>
                      <Answer name='B5'/>
                      {(m['B5'] && m['B5'] == 98) && <Answer name='B5A'/>}
                    </Layout>}
                </QuestionContext.Provider>
              </Accordion.Tab>
            )}
          </Accordion>
        </>}
        <Block title="B6. Acesso a serviços de educação">
          <Answer name="B6"/>
        </Block>
      </>
    )
  }

  renderHealthTab = () => {
    let s = this.state.response as any;
    return (
      <>
        <Divider hidden/>
        <Block title="C1. Doenças nos últimos 30 dias">
        <Layout width={3}>
          <Answer nonumber name="C1_1"/>
          {s["C1_1"] == true && <Answer nonumber name="C1_1A" other="C1_1AO"/>}
          {s["C1_1"] == true && s["C1_1A"] != 4 && <Answer nonumber name="C1_1B" other="C1_1BO"/>}
        </Layout>
        <Layout width={3}>
          <Answer nonumber name="C1_2"/>
          {s["C1_2"] == true && <Answer nonumber name="C1_2A" other="C1_2AO"/>}
          {s["C1_2"] == true && s["C1_2A"] != 4 && <Answer nonumber name="C1_2B" other="C1_2BO"/>}
        </Layout>
        <Layout width={3}>
          <Answer nonumber name="C1_3"/>
          {s["C1_3"] == true && <Answer nonumber name="C1_3A" other="C1_3AO"/>}
          {s["C1_3"] == true && s["C1_3A"] != 4 && <Answer nonumber name="C1_3B" other="C1_3BO"/>}
        </Layout>        
        <Layout width={3}>
          <Answer nonumber name="C1_4"/>
          {s["C1_4"] == true && <Answer nonumber name="C1_4A" other="C1_4AO"/>}
          {s["C1_4"] == true && s["C1_4A"] != 4 && <Answer nonumber name="C1_4B" other="C1_4BO"/>}
        </Layout>        
        <Layout width={3}>
          <Answer nonumber name="C1_5"/>
          {s["C1_5"] == true && <Answer nonumber name="C1_5A" other="C1_5AO"/>}
          {s["C1_5"] == true && s["C1_5A"] != 4 && <Answer nonumber name="C1_5B" other="C1_5BO"/>}
        </Layout>        
        <Layout width={3}>
          <Answer nonumber name="C1_6"/>
          {s["C1_6"] == true && <Answer nonumber name="C1_6A" other="C1_6AO"/>}
          {s["C1_6"] == true && s["C1_6A"] != 4 && <Answer nonumber name="C1_6B" other="C1_6BO"/>}
        </Layout>        
        <Layout width={3}>
          <Answer nonumber name="C1_7"/>
          {s["C1_7"] == true && <Answer nonumber name="C1_7A" other="C1_7AO"/>}
          {s["C1_7"] == true && s["C1_7A"] != 4 && <Answer nonumber name="C1_7B" other="C1_7BO"/>}
        </Layout>        
        {s["C1_8"] == true && <>
        <Layout width={3}>
          <Answer nonumber name="C1_8O"/>
          <Answer nonumber name="C1_8A" other="C1_8AO"/>
          {s["C1_8A"] != 4 && <Answer nonumber name="C1_8B" other="C1_8BO"/>}
        </Layout>       
        </>} 
        {s["C1_9"] == true && <>
          <Layout width={3}>
            <Answer nonumber name="C1_9O"/>
            <Answer nonumber name="C1_9A" other="C1_9AO"/>
            {s["C1_9A"] != 4 && <Answer nonumber name="C1_9B" other="C1_9BO"/>}
          </Layout>
        </>}
      </Block>
      <Block title="C4. Doenças crónicas">
        <Answer nonumber name="C4A"/>
        <Answer nonumber name="C4B"/>
        <Answer nonumber name="C4C"/>
        <Answer nonumber name="C4D"/>
        <Answer nonumber name="C4E"/>
        <Answer nonumber name="C4F"/>
        <Answer nonumber name="C4G"/>
        {s['C4H'] == true && <Answer nonumber name="C4H" other="C4H1"/>}
        {s['C4I'] == true && <Answer nonumber name="C4I" other="C4I1"/>}
      </Block>
      <Block title="C8. Acesso a serviços de saúde">
        <Answer nonumber name="C8"/>
      </Block>
    </>
    )
  }

  renderIncomeTab = () => {
    let s = this.state.response as any;
    return (
      <>
        <Divider hidden/>
        <Block title="D1. Principais fontes de rendimento">
          <IntroText>Quais são as principais fontes de rendimento do agregado familiar?</IntroText>
          <Layout width={2}>
            <>
              <Answer nonumber name='D1A'/>
              <Answer nonumber name='D1B'/>
              <Answer nonumber name='D1C'/>
              <Answer nonumber name='D1D'/>
              <Answer nonumber name='D1E'/>
            </>
            <>
              <Answer nonumber name='D1F'/>
              <Answer nonumber name='D1G'/>
              <Answer nonumber name='D1H'/>
              <Answer nonumber name='D1I'/>
              <Answer nonumber name='D1J' other='D1JO'/>
            </>
          </Layout>
        </Block>
        <Block title="D2. Rendimento médio mensal (em meticais)">
          <IntroText>Qual é o rendimento médio mensal do agregado familiar nas seguintes categorias?</IntroText>
          <Layout width={2}>
            <>
              <Answer nonumber name='D2A'/>
              <Answer nonumber name='D2B'/>
              <Answer nonumber name='D2C'/>
              <Answer nonumber name='D2D'/>
              <Answer nonumber name='D2E'/>
              <Answer nonumber name='D2F'/>
            </>
            <>
              <Answer nonumber name='D2G'/>
              <Answer nonumber name='D2H'/>
              <Answer nonumber name='D2I'/>
              <Answer nonumber name='D2J'/>
              {s['D2_OUTRO'] == 1 && <>
                <Answer nonumber name='D2K' other='D2KO'/>
              </>}
            </>
          </Layout>
        </Block>
        <Block title="D4. Despesa médio mensal (em meticais)">
          <IntroText>Aproximadamente, quanto gasta mensalmente o seu agregado familiar nas seguintes categorias?</IntroText>
          <Layout width={2}>
            <>
              <Answer nonumber name='D4A'/>
              <Answer nonumber name='D4B'/>
              <Answer nonumber name='D4C'/>
              <Answer nonumber name='D4D'/>
            </>
            <>
              <Answer nonumber name='D4E'/>
              <Answer nonumber name='D4F'/>
              {s['D4_OUTRO'] == 1 && <>
                <Answer nonumber name='D4G' other="D4GA"/>
              </>}
            </>
          </Layout>
        </Block>       
        <Block title="D6. Conta bancária">
          <Answer name="D6"/>
        </Block>
        <Block title="D8. Investimentos com dinheiro recebido do reassentamento">
          <Layout width={2}>
            <Answer nonumber name="D8A" other="D8AO"/>
          </Layout>
          {s["D8B"] && <>
            <Layout width={2}>
              <Answer nonumber name="D8B" other="D8BO"/>
            </Layout>
          </>}
          {s["D8C"] && <>
            <Layout width={2}>
              <Answer nonumber name="D8C" other="D8CO"/>
            </Layout>
          </>}
        </Block>           
      </>
    )
  }

  renderNetworkTab = () => {
    let s = this.state.response as any;
    return (<>
      <Divider hidden/>
      <Block title="E1. Recorrer em caso de dificuldade">
        <IntroText>Em caso de dificuldade ou emergência (por exemplo, problema de saúde, falta de alimento, falta de dinheiro, etc) a quem recorre?</IntroText>
        <Answer name='E1A' nonumber other='E1AO'/>
        {s["E1B"] && <Answer name='E1B' nonumber other='E1BO'/>}
        {s["E1C"] && <Answer name='E1C' nonumber other='E1CO'/>}
      </Block>
    </>)
  }  

  renderGoodsTab = () => {
    return (<>
      <Divider hidden/>
      <Block title="F1. Bens">
        <IntroText>Algum membro do agregado familiar possui alguns dos bens adiante listados? Por favor considere apenas aqueles que estão em uso e funcionam.</IntroText>
        <Table striped transparent>
          <tbody>
            <tr><td style={{width:'200px'}}>Rádio</td><td><SimpleAnswer name='F1A'/></td></tr>
            <tr><td>Aparelhagem</td><td><SimpleAnswer name='F1B'/></td></tr>
            <tr><td>Telefone/telemóvel</td><td><SimpleAnswer name='F1C'/></td></tr>
            <tr><td>Geleira/congelador</td><td><SimpleAnswer name='F1D'/></td></tr>
            <tr><td>Tractor</td><td><SimpleAnswer name='F1E'/></td></tr>
            <tr><td>Bicicleta</td><td><SimpleAnswer name='F1F'/></td></tr>
            <tr><td>Motorizada</td><td><SimpleAnswer name='F1G'/></td></tr>
            <tr><td>Veículo motorizado</td><td><SimpleAnswer name='F1H'/></td></tr>
            <tr><td>Bomba de água</td><td><SimpleAnswer name='F1I'/></td></tr>
          </tbody>
        </Table>
      </Block>
    </>)
  }

  renderPropertyTab = () => {
    let s = this.state.response as any;
    return (
      <>
        <Divider hidden/>
        <Block title="Infra-estrutura">
          <Answer name="G1_3"/>
          {(s['G1_3'] && (s['G1_3'] == 4 || s['G1_3'] == 5)) && 
          <div style={{paddingLeft: '30px'}}>
            <Answer name="G1_3A" nonumber other="G1_3AO"/>
            {s['G1_3B'] && <Answer name="G1_3B" nonumber other="G1_3BO"/>}
            {s['G1_3C'] && <Answer name="G1_3C" nonumber other="G1_3CO"/>}
          </div>}
          <Answer name="G1_2"/>
        </Block>
        {(s['AFPOLIGAMO'] == 1) && 
        <Block title="Compensaçào/poligamia">
          <Answer name="G1_4_A" other="G1_4_AO"/>
          <Answer name="G1_4_B" other="G1_4_BO"/>
          <IntroText>Se as segundas esposas não tiverem recebido toda a compensação de habitação acordada, porquê?</IntroText>
          <Answer nonumber name="G1_4_C1"/>
          <Answer nonumber name="G1_4_C2"/>
          <Answer nonumber name="G1_4_C3"/>
          <Answer nonumber name="G1_4_C4"/>
          <Answer nonumber name="G1_4_C5"/>
          <Answer nonumber name="G1_4_C98"/>
          <Answer nonumber name="G1_4_C999"/>
          {s["G1_4_C98"] == 1 && <Answer name="G1_4_CO"/>}
        </Block>}
        <Block title="Título">
          <Layout width={2}>
            <Answer name='G1_1_A'/>
            <Answer name='G1_1_B'/>
          </Layout>
        </Block>

        <Block title="Fonte de água">
          <Layout width={2}>
            <Answer name='G2' other='G2A'/>
            <Answer name='G3'/>
            <Answer name="G2_1"/>
          </Layout>
          <IntroText>Quais são os principais problemas que você enfrenta com o abastecimento de água em termos de qualidade e quantidade?</IntroText>
          <Layout width={2}>
            <Answer nonumber name="G2_2N"/>
            <Answer nonumber name="G2_2A"/>
            <Answer nonumber name="G2_2B"/>
            <Answer nonumber name="G2_2C"/>
            <Answer nonumber name="G2_2D"/>
            <Answer nonumber name="G2_2E"/>
            <Answer nonumber name="G2_2F"/>
            <Answer nonumber name="G2_2G"/>
            <Answer nonumber name="G2_2H"/>
          </Layout>
          {s["G2_2H"] == true && <Answer nonumber name="G2_2HO"/>}
          <Answer name="G3_1"/>
        </Block>

        <Block title="Fontes de energia">
          <IntroText>Qual a principal fonte de energia que o agregado familiar usa para...</IntroText>
          <Answer name='G4A' other='G4AO'/>
          <Answer name='G4B' other='G4BO'/>
          <Answer name='G4C' other='G4CO'/>
          <Answer name="G4_1"/>
        </Block>

        <Block title="Instalação sanitária">
          <Answer name='G5' other='G5A'/>
        </Block>

        <Block title="Lixo">
          <Answer name='G6' other='G6A'/>
        </Block>
      </>      
    )
  }

  renderAgricultureTab = () => {
    let obj = this.state.response as any;
    return (
      <>
        <Divider hidden/>
        <Block title="H1. Número de parcelas">
          <Answer name='H1'/>
        </Block>
        {obj.fields.length == 0 && <Message type="info">Não há parcelas registadas.</Message>}
        {obj.fields.length > 0 && 
        <>
          <Segment attached="top">
            Parcelas <Label size="tiny">{obj.fields.length}</Label>
          </Segment>
          <Accordion styled attached="bottom">
            {obj.fields.map((f: any, index: number) => 
              <Accordion.Tab key={index}
                title={<QuestionContext.Provider value={{data: f, group: 'fields', index: index}}>
                  Parcela {index+1}
                </QuestionContext.Provider>}>
                <QuestionContext.Provider value={{data: f, group: 'fields', index: index}}>
                  <Layout width={2}>
                    <Answer name="H2"/>
                    <Answer name="H3" other="H3O"/>
                  </Layout>
                  <Block title="Cultura 1">
                    <Layout width={2}>
                      <Answer name="H4A" other="H4AO"/>
                    </Layout>
                    <Layout width={3}>
                      <Answer name="H5A"/>
                      <Answer name="H6A" other="H6AO"/>
                    </Layout>
                  </Block>                    
                  <Block title="Cultura 2">
                    <Layout width={2}>
                      <Answer name="H4B" other="H4BO"/>
                    </Layout>
                    <Layout width={3}>
                      <Answer name="H5B"/>
                      <Answer name="H6B" other="H6BO"/>
                    </Layout>
                  </Block>                    
                  <Block title="Cultura 3">
                    <Layout width={2}>
                      <Answer name="H4C" other="H4CO"/>
                    </Layout>
                    <Layout width={3}>
                      <Answer name="H5C"/>
                      <Answer name="H6C" other="H6CO"/>
                    </Layout>
                  </Block>                    
                  <Layout width={2}>
                    <Answer name="H7" other="H7O"/>
                    <Answer name="H22" other="H22O"/>
                    <Answer name="H19"/>
                  </Layout>
                </QuestionContext.Provider>
              </Accordion.Tab>
            )}
          </Accordion>
        </>}

        <Divider hidden/>

        <Block title="H10. Parcelas em pousio/repouso">
          <IntroText>No caso das parcelas que não estão em pousio/repouso e que não cultivou nos últimos 12 meses, quais foram as razões que não permitiram o cultivo?</IntroText>
          <Layout width={2}>
            <div>
              <Answer nonumber name='H10A'/>
              <Answer nonumber name='H10B'/>
              <Answer nonumber name='H10C'/>
              <Answer nonumber name='H10D'/>
              <Answer nonumber name='H10E'/>
            </div>
            <div>
              <Answer nonumber name='H10F'/>
              <Answer nonumber name='H10G'/>
              <Answer nonumber name='H10H'/>
              <Answer nonumber name='H10I' other='H10IO'/>
            </div>
          </Layout>
        </Block>

        <Block title="H20. Satisfação parcelas">
          <Answer name="H20"/>
          {obj["H20"] && (obj["H20"] == 4 || obj["H20"] == 5) && <>
            <Answer name="H20A_1" other="H20A_1O"/>
            {obj["H20A_2"] && <Answer name="H20A_2" other="H20A_2O"/>}
            {obj["H20A_3"] && <Answer name="H20A_3" other="H20A_3O"/>}
          </>}
        </Block>

        <Block title="H21. Satisfação irrigação">
          <Answer name="H21"/>
          {obj["H21"] && (obj["H21"] == 4 || obj["H21"] == 5) && <>
            <Answer name="H21A_1" other="H21A_1O"/>
            {obj["H21A_2"] && <Answer name="H21A_2" other="H21A_2O"/>}
            {obj["H21A_3"] && <Answer name="H21A_3" other="H21A_3O"/>}
          </>}
        </Block>        

        <Block title="H11. Culturas de alimentação">
          <IntroText>No geral, quais são as 3 principais culturas que asseguram a alimentação do Agregado Familiar?</IntroText>
          <Answer nonumber name='H11A' other='H11AO'/>
          {obj["H11B"] && <Answer nonumber name='H11B' other='H11BO'/>}
          {obj["H11C"] && <Answer nonumber name='H11C' other='H11CO'/>}
        </Block>

        <Block title="H12. Refeições">
          <Answer name="H12"/>
        </Block>

        <Block title="H13. Reservas">
          <Answer name='H13'/>
          <Answer name='H13A'/>
          {obj["H13A"] == 1 && <>
            <Answer name="H13B" other="H13BO"/>
            <Answer name='H13C'/>
          </>}
        </Block>

        <Block title="H14. Culturas de rendimento">
          <IntroText>Quais são as culturas mais importantes para a geração de rendimento?</IntroText>
          <Answer nonumber name="H14A" other="H14AO"/>
          {obj["H14B"] && <Answer nonumber name="H14B" other="H14BO"/>}
          {obj["H14C"] && <Answer nonumber name="H14C" other="H14CO"/>}
        </Block>

        <Block title="H16/H17. Venda de culturas">
          <Answer name="H16"/>
          <Answer name="H17"/>
          <div style={{paddingLeft: "30px"}}>
          {obj["H17"] == 4 && <Answer name="H17A"/>}
          {obj["H17"] == 98 && <Answer name="H17B"/>}
          </div>
        </Block>

        <Block title="H18. Sementes">
          <Answer name="H18" other="H18O"/>
          <Answer name="H18A"/>
        </Block>

        <Block title="H23. Comparação machambas">
          <Answer name="H23"/>
        </Block>
      </>
    )
  }

  renderTreesTab = () => {
    let obj = this.state.response as any;
    return (
      <>
        <Divider hidden/>
        <Block title="I. Árvores">
          <Layout width={3}><Answer name="I1"/><Answer name="I1P" other="I1PO"/></Layout>
          <Layout width={3}><Answer name="I2"/><Answer name="I2P" other="I2PO"/></Layout>
          <Layout width={3}><Answer name="I3"/><Answer name="I3P" other="I3PO"/></Layout>
          <Layout width={3}><Answer name="I4"/><Answer name="I4P" other="I4PO"/></Layout>
          <Layout width={3}><Answer name="I5"/><Answer name="I5P" other="I5PO"/></Layout>
          <Layout width={3}><Answer name="I6"/><Answer name="I6P" other="I6PO"/></Layout>
          <Layout width={3}><Answer name="I7"/><Answer name="I7P" other="I7PO"/></Layout>
          <Layout width={3}><Answer name="I8"/><Answer name="I8P" other="I8PO"/></Layout>
          <Layout width={3}><Answer name="I9"/><Answer name="I9P" other="I9PO"/></Layout>
          <Layout width={3}><Answer name="I10"/><Answer name="I10P" other="I10PO"/></Layout>
          <Layout width={3}><Answer name="I11"/><Answer name="I11P" other="I11PO"/></Layout>
          <Layout width={3}><Answer name="I12"/><Answer name="I12P" other="I12PO"/></Layout>
          <Layout width={3}><Answer name="I13"/><Answer name="I13P" other="I13PO"/></Layout>
          <Layout width={3}><Answer name="I14"/><Answer name="I14P" other="I14PO"/></Layout>
          {obj["I15"] && <Layout width={3}><Answer name="I15"/><Answer name="I15O"/><Answer name="I15P" other="I15PO"/></Layout>}
          {obj["I16"] && <Layout width={3}><Answer name="I16"/><Answer name="I16O"/><Answer name="I16P" other="I16PO"/></Layout>}
        </Block>
      </>
    )
  }

  renderAnimalsTab = () => {
    let obj = this.state.response as any;
    return (
      <>
        <Divider hidden/>
        <Block title="J1. Animais">
          {obj["J3A"] && <Layout width={3}><Answer name="J3A"/><Answer name="J4A" other="J4AO"/></Layout>}
          {obj["J3B"] && <Layout width={3}><Answer name="J3B"/><Answer name="J4B" other="J4BO"/></Layout>}
          {obj["J3C"] && <Layout width={3}><Answer name="J3C"/><Answer name="J4C" other="J4CO"/></Layout>}
          {obj["J3D"] && <Layout width={3}><Answer name="J3D"/><Answer name="J4D" other="J4DO"/></Layout>}
          {obj["J3E"] && <Layout width={3}><Answer name="J3E"/><Answer name="J4E" other="J4EO"/></Layout>}
          {obj["J3F"] && <Layout width={3}><Answer name="J3F"/><Answer name="J4F" other="J4FO"/></Layout>}
          {obj["J3G"] && <Layout width={3}><Answer name="J3G"/><Answer name="J4G" other="J4GO"/></Layout>}
          {obj["J3H"] && <Layout width={3}><Answer name="J3H"/><Answer name="J4H" other="J4HO"/></Layout>}
          {obj["J3I"] && <Layout width={3}><Answer name="J3I"/><Answer name="J4I" other="J4IO"/></Layout>}
          {obj["J3J"] && <Layout width={3}><Answer name="J3J"/><Answer name="J4J" other="J4JO"/></Layout>}
          {obj["J3K"] && <Layout width={3}><Answer name="J3K"/><Answer name="J3KO"/><Answer name="J4K" other="J4KO"/></Layout>}
        </Block>

        <Block title="J7. Assistência sanitária">
          <Answer name='J7'/>
        </Block>

        <Block title="J13. Satisfação condições de pasto">
          <Answer name="J13"/>
          {obj["J13"] && (obj["J13"] == 4 || obj["J13"] == 5) &&
            <>
              <Answer name="J13_A" other="J13_AO"/>
              {obj['J13_B'] && <Answer name="J13_B" other="J13_BO"/>}
              {obj['J13_C'] && <Answer name="J13_C" other="J13_CO"/>}
          </>}
        </Block>

        <Block title="J14. Comparação acesso a pasto">
          <Answer name="J14"/>
        </Block>

        <Block title= "J9. Conflito homem/fauna bravia">
          <Answer name='J9'/>
          {obj["J9"] == 1 && <Answer name="J9_1"/>}
        </Block>

        {obj['J9'] == 1 && <>
          <Block title="J10. Tipo de danos causados">
            <Answer nonumber name='J100'/>
            <Answer nonumber name='J10A'/>
            <Answer nonumber name='J10B'/>
            <Answer nonumber name='J10C'/>
            <Answer nonumber name='J10D'/>
            <Answer nonumber name='J10E'/>
            <Answer nonumber name='J10F'/>
            <Answer nonumber name='J10G'/>
            <Answer nonumber name='J10H' other="J10HO"/>
          </Block>
          <Block title="J11. Animais selvagens mais frequentes">
            <IntroText>Por ordem de prioridade, enumere quais animais têm causado danos mais frequentemente?</IntroText>
            <Layout width={2}>
              <Answer name='J11A' other='J11AO'/>
              {obj['J11B'] && <Answer name='J11B' other='J11BO'/>}
              {obj['J11C'] && <Answer name='J11C' other='J11CO'/>}
            </Layout>
          </Block>   
          <Block title="J12. Comparação conflito homem-fauna bravia">
            <Answer name="J12"/>
          </Block>          
        </>}
      </>
    )
  }

  renderMobilityTab = () => {
    let obj = this.state.response as any;
    return (<>
      <Divider hidden/>
      <Block title="Serviços/instalações/recursos">
        <Flex stackable>
          {[4,6,7,8,12,13,11,22,9,19,20,21,23].map(k => 
            <Flex.Row key={k}>
              <Flex.Column width={1}>
                <Answer name={"L1_"+ k}/>
              </Flex.Column>
              <Flex.Column width={3}>
                {obj["L1_"+k] && obj["L1_"+k] == 1 && <Layout width={3}>
                  <Answer name={"L2_"+k}/>
                  <Answer name={"L3_"+k} other={"L3_"+k+"O"}/>
                </Layout>}
              </Flex.Column>
            </Flex.Row>
          )}
        </Flex>
      </Block>
    </>)
  }

  renderPerceptionTab = () => {
    let obj = this.state.response as any;
    return (
      <>
        <Divider hidden/>

        <Block title="N1.4. Satisfação e bem-estar">
          <Answer name="N1_4S"/>
        </Block>

        <Block title="N1. Preocupações">
          <IntroText>Quais são as suas maiores preocupações em relação à situação da sua comunidade hoje?</IntroText>
          <Layout width={2}>
            <>
              <Answer nonumber name="N1_0"/>
              <Answer nonumber name="N1_1"/>
              <Answer nonumber name="N1_2"/>
              <Answer nonumber name="N1_3"/>
              <Answer nonumber name="N1_4"/>
              <Answer nonumber name="N1_5"/>
              <Answer nonumber name="N1_6"/>
              <Answer nonumber name="N1_7"/>
              <Answer nonumber name="N1_8"/>
              <Answer nonumber name="N1_9"/>
            </>
            <>
              <Answer nonumber name="N1_10"/>
              <Answer nonumber name="N1_11"/>
              <Answer nonumber name="N1_12"/>
              <Answer nonumber name="N1_13"/>
              <Answer nonumber name="N1_14"/>
              <Answer nonumber name="N1_15"/>
              <Answer nonumber name="N1_17"/>
              <Answer nonumber name="N1_18"/>
              <Answer nonumber name="N1_19"/>
              <Answer nonumber name="N1_20"/>
              {obj['N1_20'] && <Answer nonumber name="N1_20O"/>}
            </>
          </Layout>
        </Block>

        <Block title="N1.2. Factores de melhoria">
          <IntroText>Quais dos seguintes factores considera mais importante para poder, nesta fase, melhorar as suas condições de vida?</IntroText>
          <Answer nonumber name="N1_2A" other="N1_2AO"/>
          {obj['N1_2B'] && <Answer nonumber name="N1_2B" other="N1_2BO"/>}
          {obj['N1_2C'] && <Answer nonumber name="N1_2C" other="N1_3BO"/>}
        </Block>

        <Block title="N1.3. Actores de melhoria">
          <p>Que actores ou instituições podem ter influência sobre os assuntos mais importantes para melhorar as suas condições de vida?</p>
          <Answer nonumber name="N1_3A" other="N1_3AO"/>
          {obj['N1_3B'] && <Answer nonumber name="N1_3B" other="N1_3BO"/>}
          {obj['N1_3C'] && <Answer nonumber name="N1_3C" other="N1_3BO"/>}
        </Block>

        <Block title="Reclamações">
          <Answer name="N3"/>
          {obj["N3"] == 1 && <>
            <Divider/>
            <Answer name="N3_1_1"/>
            <Answer name="N3_1_2"/>
            <Answer name="N3_1_3"/>
            <Answer name="N3_1_4"/>
            <Answer name="N3_1_5"/>
            <Answer name="N3_1_6"/>
            <Answer name="N3_1_98"/>
            {obj["N3_1_98"] == 1 && <Answer name="N3_1_98O"/>}
          </>}
        </Block>

        <Block title="Situação inconveniente durante reassentamento">
          <Answer name="N4"/>
          {obj["N4"] == 1 && <>
            <Answer name="N4_1"/>
            {obj["N4_1"] == 1 && <Answer name="N4_2"/>}
          </>}
        </Block>

        <Block title="N5. Satisfação reassentamento">
          <Layout width={2}>
            <Answer name="N5"/>
          </Layout>
          {obj["N5"] && (obj["N5"] == 4 || obj["N5"] == 5) &&
            <>
              <Answer nonumber name="N5_1" other="N5_1O"/>
              {obj['N5_2'] && <Answer nonumber name="N5_2" other="N5_2O"/>}
              {obj['N5_3'] && <Answer nonumber name="N5_3" other="N5_3O"/>}
          </>}
        </Block>

        <Block title="N6. Reassentamento conclúido?">
          <Answer name="N6"/>
          {obj["N6"] && obj["N6"] == 2 &&
            <>
              <Answer nonumber name="N6_1" other="N6_1O"/>
              {obj['N6_2'] && <Answer nonumber name="N6_2" other="N6_2O"/>}
              {obj['N6_3'] && <Answer nonumber name="N6_3" other="N6_3O"/>}
            </>}
        </Block>

        <Block title="N7. Comparação pós-reassentamento">
          <Answer name="N7"/>
          {obj["N7"] && obj["N7"] == 2 &&
            <>
              <Answer nonumber name="N7_1" other="N7_1O"/>
              {obj['N7_2'] && <Answer nonumber name="N7_2" other="N7_2O"/>}
              {obj['N7_3'] && <Answer nonumber name="N7_3" other="N7_3O"/>}
          </>}
        </Block>        

      </>
    )
  }  

  render() {
    let p = this.props;
    let obj = this.state.response as any;
    return (
      <Container>
        {(this.state.step == 'loading' || this.state.step == 'loadError') && 
        <Content>
          <Loader/>
        </Content>}
        {this.state.step == 'ready' && 
        <QuestionContext.Provider value={{data: obj}}>
          <Content>
            <Section padded>
              <Header size="h3">{obj.GEN1}</Header>
              <Tabs underlined active={ValueStore.get("view_response", 0)} onTabChange={this.handleTabChange}>

                <Tabs.Pane label="Geral">
                  {this.renderGeneralTab()}
                </Tabs.Pane>

                <Tabs.Pane label="Educação">
                  {this.renderEducationTab()}
                </Tabs.Pane>

                <Tabs.Pane label="Saúde">
                  {this.renderHealthTab()}
                </Tabs.Pane>                

                <Tabs.Pane label="Economia">
                  {this.renderIncomeTab()}
                </Tabs.Pane>                

                <Tabs.Pane label="Redes Sociais">
                  {this.renderNetworkTab()}
                </Tabs.Pane>                

                <Tabs.Pane label="Bens">
                  {this.renderGoodsTab()}
                </Tabs.Pane>                

                <Tabs.Pane label="Propriedade">
                  {this.renderPropertyTab()}
                </Tabs.Pane>                

                <Tabs.Pane label="Agricultura">
                  {this.renderAgricultureTab()}
                </Tabs.Pane>                

                <Tabs.Pane label="Árvores">
                  {this.renderTreesTab()}
                </Tabs.Pane>                

                <Tabs.Pane label="Animais">
                  {this.renderAnimalsTab()}
                </Tabs.Pane>                

                <Tabs.Pane label="Mobilidade">
                  {this.renderMobilityTab()}
                </Tabs.Pane>                                

                <Tabs.Pane label="Percepção">
                  {this.renderPerceptionTab()}
                </Tabs.Pane>

              </Tabs>
            </Section>
          </Content>
          <BottomBar>
            {this.props.auth.hasRight('can_edit_responses') && <Button primary onClick={this.handleEdit}><Icon name="edit"/> <Loc msg="btn_edit"/></Button>}
            <Timestamp model={ this.state.response }/>
          </BottomBar>
        </QuestionContext.Provider>}
        {this.state.step == 'error' && 
        <Content>
          <Section padded>
            <Message type="error"><Loc msg="msg_data_not_found"/></Message>
          </Section>
        </Content>}
        <Dialog.Xhr open={this.state.step == 'loadError'} error={this.state.error} onClose={this.handleCancelLoad} onRetry={this.handleRetry}/>          
      </Container>
    );
  }
}

const IntroText = styled('div')`
  font-style: italic;
  margin-bottom: 10px;
`

export { ViewResponse };
