import * as React from 'react';
import axios from 'axios';
import styled from '@independent-software/typeui/styles/Theme'

import { RouteComponentProps } from 'react-router';
import { LayoutType } from 'recharts';
import { CurrentPng } from "recharts-to-png";

import { Header } from '@independent-software/typeui/controls/Header';
import { Message } from '@independent-software/typeui/controls/Message';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Segment } from '@independent-software/typeui/controls/Segment';

import { App } from '../../App';
import { IAuthProps } from '../../services/Auth';
import { Query } from '../../services';
import { Container, Content, Section, Loc, LanguageContext } from '../../modules';
import { Community, CommunityFactory, Survey, SurveyFactory } from '../../resource';

import { IIndicator, TCalculation, TChartType, INDICATORS } from './definitions';
import { IndicatorTree } from './IndicatorTree';
import { IndicatorBarChart, IndicatorPieChart, IndicatorTable } from './charts';
import { ChartControls } from './ChartControls';
import { ChartData } from './ChartData';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { Loader } from '@independent-software/typeui/controls/Loader';

interface IState {
  surveys: Survey[];
  communities: Community[];
  survey: Survey;
  community: Community;
  monoparental: boolean;
  poligamo: boolean;
  indicator: IIndicator;
  data: ChartData;
  calc: TCalculation;
  layout: LayoutType;
  stacked: boolean;
  grid: boolean;
  donut: boolean;
  type: TChartType;
  loading: boolean;
  exporter: () => void;
}

class SurveyAnalysis extends React.Component<IAuthProps & RouteComponentProps<any>, IState> {
  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
    const currentSurvey: Survey = JSON.parse(localStorage.getItem("analysis_survey")) as Survey;
    const currentCommunity: Community = JSON.parse(localStorage.getItem("analysis_community")) as Community;
    this.state = {
      surveys: [],
      communities: [],
      survey: currentSurvey,
      community: currentCommunity,
      monoparental: false,
      poligamo: false,
      indicator: null,
      data: null,
      calc: 'absolute',
      layout: 'horizontal',
      stacked: false,
      grid: true,
      donut: false,
      type: 'bar',
      loading: false,
      exporter: null
    };
  }

  componentDidMount = () => {
    this.loadSurveys();
    this.loadCommunities();
  }

  loadSurveys = () => {
    let query = new Query('name', 'asc');
    SurveyFactory.getSome(this.props.auth, 0, 999, query)
      .then((res) => this.setState({ surveys: res.items }));    
  }

  loadCommunities = () => {
    let query = new Query('name', 'asc');
    CommunityFactory.getSome(this.props.auth, 0, 999, query)
      .then((res) => this.setState({ communities: res.items }));    
  }

  handleSelectSurvey = (survey: Survey) => {
    localStorage.setItem("analysis_survey", JSON.stringify(survey));
    this.setState({
      survey: survey
    }, this.loadData);
  }

  handleSelectCommunity = (community: Community) => {
    localStorage.setItem("analysis_community", JSON.stringify(community));
    this.setState({
      community: community
    }, this.loadData);
  }

  handleChangeMonoparental = (value: boolean) => {
    this.setState({
      monoparental: value
    }, this.loadData);
  }

  handleChangePoligamo = (value: boolean) => {
    this.setState({
      poligamo: value
    }, this.loadData);
  }

  // When an indicator is clicked, set it as the current indicator and
  // set calc, layout, and stacked to the indicator's defaults. Then load
  // data from the server.
  handleClickIndicator = (indicator: IIndicator) => {
    this.setState({ 
      indicator: indicator,
      data: null,
      calc: indicator.calc,
      layout: indicator.layout,
      stacked: indicator.stacked,
      type: indicator.type
    }, this.loadData);
  }

  // Load chart data from server.
  loadData = () => {
    // Do not load anything if survey not selected or indicator not selected,
    // or if no community selected.
    if(this.state.survey == null || this.state.indicator == null || this.state.community == null) return;

    this.setState({ loading: true });
    const array = this.state.indicator.array ? `array=${this.state.indicator.array}&` : '';
    const allow97 = this.state.indicator.allow_97 ? `allow97=1&` : '';
    const ignore = this.state.indicator.ignore ? `ignore=${this.state.indicator.ignore}&` : '';
    const communityID = this.state.community != null ? `community=${this.state.community.id}&` : '';
    const monoparental = this.state.monoparental == true ? `monoparental=1&` : '';
    const poligamo = this.state.poligamo == true ? `poligamo=1&` : '';

    axios.get(`${App.apiURL}surveys/chart/${this.state.survey.id}/${this.state.indicator.src}/${this.state.indicator.mode}?${communityID}${monoparental}${poligamo}${array}${allow97}${ignore}api_token=${this.props.auth.token}`) 
    .then(response => {
      this.setState({ loading: false });
      this.setState({ data: new ChartData(this.state.indicator, response.data) }); 
    })
    .catch(error => {
      this.setState({ loading: false });
    }); 
  }

  handleChangeChart = (type: TChartType, calc: TCalculation, layout: LayoutType, stacked: boolean, grid: boolean, donut: boolean) => {
    this.setState({
      type: type,
      calc: calc,
      layout: layout,
      stacked: stacked,
      grid: grid,
      donut: donut
    });
  }

  private handleExport = () => {
    this.state.exporter();
  }  

  private handleRegisterExporter = (exporter: () => void) => {
    this.setState({
      exporter: exporter
    });
  }
  
  render() {
    return (
      <Container>
        <React.Fragment>
          <Content>
            <IndicatorBar>
              <TreePadding>
                <Segment>
                  <Dropdown value={this.state.survey} fluid data={this.state.surveys} label={(item:Survey) => item.name} placeholder="Select survey" onChange={this.handleSelectSurvey}>
                    <Dropdown.Column>{(item:Survey) => item.name}</Dropdown.Column>
                  </Dropdown>
                  <div style={{height:"8px"}}></div>
                  <Dropdown value={this.state.community} fluid data={this.state.communities} label={(item:Community) => item.name} placeholder="Select community" onChange={this.handleSelectCommunity}>
                    <Dropdown.Column>{(item:Community) => item.name}</Dropdown.Column>
                  </Dropdown>    
                  <Checkbox type="toggle" checked={this.state.monoparental} label="Somente AF monoparentais" onChange={this.handleChangeMonoparental}/>
                  <Checkbox type="toggle" checked={this.state.poligamo} label="Somente AF polígamos" onChange={this.handleChangePoligamo}/>
                </Segment>
                <Segment>
                  <IndicatorTree
                    indicators={INDICATORS}
                    indicator={this.state.indicator}
                    onClickIndicator={this.handleClickIndicator}/>
                </Segment>
              </TreePadding>
            </IndicatorBar>
            <IndicatorArea>
              {this.state.loading === true && <Loader/>}
              <Section padded>
                {this.state.data == null && 
                  <Message type="info"><Loc msg="analysis_surveys_instructions"/></Message>
                }
                {this.state.data && <>
                  <Header size="h1">
                    {<Loc msg={("indicator_" + this.state.indicator.name) as any}/>}
                  </Header>
                  <Header size="h3">
                    {<Loc msg={("caption_" + this.state.indicator.name) as any}/>}
                  </Header>

                  <ChartControls
                    type={this.state.type}
                    calc={this.state.calc}
                    layout={this.state.layout}
                    stacked={this.state.stacked}
                    grid={this.state.grid}
                    donut={this.state.donut}
                    onChange={this.handleChangeChart}
                    onExport={this.handleExport}
                  />

                  {this.state.data.length() == 0 && 
                  <Message type="warning"><Loc msg="analysis_surveys_nodata"/></Message>}

                  {this.state.data.length() > 0 && 
                    <LanguageContext.Consumer>
                      {({data}) => <>
                        {this.state.type == 'table' &&
                        <IndicatorTable
                          name={this.state.indicator.name}
                          unit={this.state.indicator.unit}
                          decimals={this.state.indicator.decimals}
                          chartdata={this.state.data}
                          calc={this.state.calc}
                          langdata={data}
                          onRegister={this.handleRegisterExporter} />}

                        {this.state.type == 'bar' &&
                        <CurrentPng>{(props) => 
                        <IndicatorBarChart
                          {...props}
                          name={this.state.indicator.name}
                          unit={this.state.indicator.unit}
                          chartdata={this.state.data}
                          calc={this.state.calc}
                          layout={this.state.layout}
                          stacked={this.state.stacked}
                          grid={this.state.grid}
                          langdata={data}
                          onRegister={this.handleRegisterExporter} />}
                        </CurrentPng>}

                        {this.state.type == 'pie' &&
                        <CurrentPng>{(props) => 
                        <IndicatorPieChart
                          {...props}
                          name={this.state.indicator.name}
                          unit={this.state.indicator.unit}
                          chartdata={this.state.data}
                          calc={this.state.calc}
                          donut={this.state.donut}
                          langdata={data}
                          onRegister={this.handleRegisterExporter} />}
                        </CurrentPng>}
 
                        {/* Respondent count is shown if available, and only in table mode: */}
                        {this.state.data.getRespondents() != null && this.state.type == 'table' &&
                        <Message type='info'>
                          N = {this.state.data.getRespondents()}
                        </Message>}
                    </>}
                  </LanguageContext.Consumer>}
                    
                </>}
              </Section>
            </IndicatorArea>
          </Content>
        </React.Fragment>
      </Container>
    );
  }
}

const IndicatorBar = styled('div')`
  position: absolute;
  border-collapse: border-box;
  left: 0;
  top: 0;
  bottom: 0;
  width: 350px;
  overflow-x: hidden;
  overflow-y: auto;
  border-right: solid 1px #ccc;
  background: #efefef;
`;

const TreePadding = styled('div')`
  padding: 10px;
`;

const IndicatorArea = styled('div')`
  position: absolute;
  border-collapse: border-box;
  left: 350px;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
`

export { SurveyAnalysis };
