import { StampedModel, ResourceFactory } from '../services/';

class Profile extends StampedModel {
  public name: string = '';
  public users_count: number = 0;

  // Rights:
  public can_view_users = false;
  public can_edit_users = false;
  public can_view_profiles = false;
  public can_edit_profiles = false;
  public can_view_logs = false;
  public can_view_responses = false;
  public can_edit_responses = false;
  public can_view_communities = false;
  public can_edit_communities = false;
  public can_view_surveys = false;
  public can_edit_surveys = false;
  public can_view_attachments = false;
  public can_edit_attachments = false;
  public can_view_actions = false;
  public can_edit_actions = false;
  public can_flow_actions = false;
  public can_view_grievances = false;
  public can_edit_grievances = false;
  public can_view_issuetypes = false;
  public can_edit_issuetypes = false;
  public can_validate_grievances = false;
  public can_validate_actions = false;
  public can_edit_workflow = false;
  public can_view_actiontypes = false;
  public can_edit_actiontypes = false;
  public can_debug = false;
}

const ProfileFactory = ResourceFactory.create<Profile>(Profile, 'profile');

export { Profile, ProfileFactory };