import * as React from 'react';
import { User } from '../../resource/';
import { ProfileFactory, Profile } from '../../resource';
import { IAuthProps, Query } from '../../services/';

import { Input } from '@independent-software/typeui/controls/Input';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Form } from '@independent-software/typeui/controls/Form';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Loc } from '../../modules';


interface IProps {
  /** Initial form data. */
  data: User;
  /** Called whenever form changes. */
  onChange: (data: User, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */  
  dirty?: boolean;
}

interface IState {
  /** Current form data. */
  data: User;
  /** Data for profiles dropdown */
  profiles: Profile[];  
}

class UserForm extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);

    // Intialize state.
    this.state = {
      data: props.data,
      profiles: []
    };    

    // Retrieve a list of profiles:
    ProfileFactory.getSome(this.props.auth, 0, 50, new Query('name', 'asc'))
      .then((res) => this.setState({ profiles: res.items }));    
  }

  handleProfileSearch = (q:string) => {
    // Retrieve a list of profiles:
    let query = new Query('name', 'asc');
    query.setFilter('q', 'like', q);
    ProfileFactory.getSome(this.props.auth, 0, 50, query)
      .then((res) => this.setState({ profiles: res.items }));
  }  

  render() {
    let p = this.props;
    return (
      <Form
        data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
        <Flex.Columns count={2}>
          <Form.Field 
            required={{message: <Loc msg="users_fields_name_required"/>}}
            minLength={{length: 3, message: <Loc msg="users_fields_name_minlength"/>}}
            maxLength={{length: 30, message: <Loc msg="users_fields_name_maxlength"/>}}
            width={1} label={<Loc msg="users_fields_name_label"/>}
            name="name" 
            value={this.state.data.name}
            control={(<Input type="text" placeholder="Name" fluid/>)}
            hint={(<React.Fragment><Loc msg="users_fields_name_hint"/> <code><Loc msg="users_fields_name_hint_ex"/></code></React.Fragment>)}/>
          <Form.Field 
            required={{message: <Loc msg="users_fields_email_required"/>}}
            email={{message: <Loc msg="users_fields_email_email"/>}}
            width={1} label={<Loc msg="users_fields_email_label"/>}
            name="email" 
            value={this.state.data.email}
            control={(<Input type="text" placeholder="Email" fluid/>)}
            hint={(<React.Fragment><Loc msg="users_fields_email_hint"/> <code><Loc msg="users_fields_email_hint_ex"/></code></React.Fragment>)}/>
          <Form.Field
            required={{message: <Loc msg="users_fields_profile_required"/>}}
            width={1} label={<Loc msg="users_fields_profile_label"/>}
            name="profile" 
            value={this.state.data.profile}
            control={(<Dropdown onSearch={this.handleProfileSearch} fluid data={this.state.profiles} placeholder="Profile" label={(item:Profile) => item.name}>
              <Dropdown.Column weight={3}>{(item:Profile) => item.name}</Dropdown.Column>
              <Dropdown.Column align='right'>{(item:Profile) => item.users_count}</Dropdown.Column>
            </Dropdown>)}
            hint={<Loc msg="users_fields_profile_hint"/>}/>
        </Flex.Columns>
      </Form>
    )
  }
}

export { UserForm };
