import * as React from 'react';
import { css } from 'styled-components';
import styled from '@independent-software/typeui/styles/Theme';

/*
 * The Window is everything but the Sidebar.
 */

interface IWindowProps {
  className?: string;
  children?: React.ReactNode;
  /** Is the sidebar currently forced open? Window will be offset to the right. */
  open?: boolean;
}

class WindowBase extends React.Component<IWindowProps, {}> {
  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        {p.children}
      </div>
    );
  }
}

const Window = styled(WindowBase)`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  position: absolute;
  left:   250px; /* Width of the Sidebar */
  top:    0;
  right:  0;
  bottom: 0;

  /* Window leaves space for (open) Sidebar, except on small screens. */
  transition: left ${p => p.theme.transition.duration}s ease;
  ${p => !p.open && css`
    @media (max-width: ${p => p.theme.smallScreen}px) {
      left: 0;
    }    
  `}

  ${p => p.open && css`
    right: -250px;
  `}
`

export { Window };