import * as React from 'react';
import { Grievance, Attachment, Stakeholder, StakeholderFactory } from '../../resource/';
import { IAuthProps, Query } from '../../services/';

import { Input } from '@independent-software/typeui/controls/Input';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Form } from '@independent-software/typeui/controls/Form';
import { Section, AttachmentManager, Loc } from '../../modules';
import { TLocalization } from '../../modules/Loc/TLocalization';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Label } from '@independent-software/typeui/controls/Label';
import { MarkdownTextarea } from '@independent-software/typeui/modules/MarkdownTextarea';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';

interface IProps {
  /** Initial form data. */
  data: Grievance;
  /** Called whenever form changes. */
  onChange: (data: Grievance, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */  
  dirty?: boolean;
}

interface IState {
  /** Current form data. */
  data: Grievance;
  /** Data for Stakeholders dropdown */
  stakeholders: Stakeholder[];    
}

class GrievanceForm extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);

    // Intialize state.
    this.state = {
      data: props.data,
      stakeholders: []
    };    

    // Retrieve a list of Stakeholders:
    StakeholderFactory.getSome(this.props.auth, 0, 16, new Query('name', 'asc'))
      .then((res) => this.setState({ stakeholders: res.items }));    
  }

  handleStakeholderSearch = (q:string) => {
    // Retrieve a list of Stakeholders:
    let query = new Query('name', 'asc');
    query.setFilter('q', 'like', q);
    StakeholderFactory.getSome(this.props.auth, 0, 16, query)
      .then((res) => this.setState({ stakeholders: res.items }));
  }  

  handleChangeAttachments = (attachments: Attachment[]) => {
    this.setState((prevState) => {
      prevState.data.attachments = attachments;
      return prevState;
    });
  }  

  render() {
    let p = this.props;
    return (
      <Form
        data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
        <Tabs underlined>
          <Tabs.Pane label={<Loc msg="grievances_form_tab_data"/>}>
            <Section padded>
              <Flex.Columns count={2}>
                <React.Fragment>
                  <Form.Field 
                    required={{message: <Loc msg="grievances_fields_title_required"/>}}
                    minLength={{length: 3, message: <Loc msg="grievances_fields_title_minlength"/>}}
                    maxLength={{length: 1000, message: <Loc msg="grievances_fields_title_maxlength"/>}}
                    width={1} label={<Loc msg="grievances_fields_title_label"/>}
                    name="title" 
                    value={this.state.data.title}
                    control={(<Input type="text" placeholder="Title" fluid/>)}
                    hint={<Loc msg="grievances_fields_title_hint"/>}/>
                  <Form.Field 
                    required={{message: <Loc msg="grievances_fields_date_required"/>}}
                    width={1} label={<Loc msg="grievances_fields_date_label"/>}
                    name="date" 
                    value={this.state.data.date}
                    control={(<Input type="date" nofuture format="eee, d MMMM yyyy" placeholder="Date"/>)}
                    hint={<Loc msg="grievances_fields_date_hint"/>}/>
                  <Form.Field
                    forceupdate
                    required={{message: <Loc msg="grievances_fields_submission_required"/>}}
                    label={<Loc msg="grievances_fields_submission_label"/>}
                    name="sex"
                    value={this.state.data.submission}
                    control={<React.Fragment>
                      <div><Checkbox radio type="circle" name="submission" value="pnl" label={<Loc msg="grievances_fields_submission_pnl"/>} checked={this.state.data.submission=='pnl'} onChange={() => this.props.onChange({...this.state.data, submission: 'pnl'}, true)}/></div>
                      <div><Checkbox radio type="circle" name="submission" value="box" label={<Loc msg="grievances_fields_submission_box"/>} checked={this.state.data.submission=='box'} onChange={() => this.props.onChange({...this.state.data, submission: 'box'}, true)}/></div>
                      <div><Checkbox radio type="circle" name="submission" value="community" label={<Loc msg="grievances_fields_submission_community"/>} checked={this.state.data.submission=='community'} onChange={() => this.props.onChange({...this.state.data, submission: 'community'}, true)}/></div>
                      <div><Checkbox radio type="circle" name="submission" value="greenline" label={<Loc msg="grievances_fields_submission_greenline"/>} checked={this.state.data.submission=='greenline'} onChange={() => this.props.onChange({...this.state.data, submission: 'greenline'}, true)}/></div>
                      <div><Checkbox radio type="circle" name="submission" value="cso-ctv" label={<Loc msg="grievances_fields_submission_cso-ctv"/>} checked={this.state.data.submission=='cso-ctv'} onChange={() => this.props.onChange({...this.state.data, submission: 'cso-ctv'}, true)}/></div>
                      <div><Checkbox radio type="circle" name="submission" value="cso-cedes" label={<Loc msg="grievances_fields_submission_cso-cedes"/>} checked={this.state.data.submission=='cso-cedes'} onChange={() => this.props.onChange({...this.state.data, submission: 'cso-cedes'}, true)}/></div>
                      <div><Checkbox radio type="circle" name="submission" value="cso-other" label={<Loc msg="grievances_fields_submission_cso-other"/>} checked={this.state.data.submission=='cso-other'} onChange={() => this.props.onChange({...this.state.data, submission: 'cso-other'}, true)}/></div>
                      <div><Checkbox radio type="circle" name="submission" value="meeting" label={<Loc msg="grievances_fields_submission_meeting"/>} checked={this.state.data.submission=='meeting'} onChange={() => this.props.onChange({...this.state.data, submission: 'meeting'}, true)}/></div>
                      <div><Checkbox radio type="circle" name="submission" value="field" label={<Loc msg="grievances_fields_submission_field"/>} checked={this.state.data.submission=='field'} onChange={() => this.props.onChange({...this.state.data, submission: 'field'}, true)}/></div>
                    </React.Fragment>}
                    hint={<Loc msg="grievances_fields_submission_hint"/>}
                  />                 
                  {this.state.data.submission == 'cso-other' && <Form.Field 
                    required={{message: <Loc msg="grievances_fields_cso_required"/>}}
                    minLength={{length: 3, message: <Loc msg="grievances_fields_cso_minlength"/>}}
                    maxLength={{length: 255, message: <Loc msg="grievances_fields_cso_maxlength"/>}}
                    width={1} label={<Loc msg="grievances_fields_cso_label"/>}
                    name="cso" 
                    value={this.state.data.cso}
                    control={(<Input type="text" placeholder="CSO" fluid/>)}
                    hint={<Loc msg="grievances_fields_cso_hint"/>}/>}

                  <Form.Field 
                    forceupdate
                    name="anonymous"
                    value={this.state.data.anonymous}
                    control={(<Checkbox checked={this.state.data.anonymous} label={<Loc msg="grievances_fields_anonymous_label"/>}/>)}/>

                  {this.state.data.anonymous != true && <Form.Field
                    forceupdate
                    required={{message: <Loc msg="grievances_fields_stakeholder_required"/>}}
                    width={1} label={<Loc msg="grievances_fields_stakeholder_label"/>}
                    name="stakeholder" 
                    value={this.state.data.stakeholder}
                    control={(<Dropdown onSearch={this.handleStakeholderSearch} fluid data={this.state.stakeholders} placeholder="Grievant" label={(item:Stakeholder) => item.name}>
                      <Dropdown.Column weight={2}>{(item:Stakeholder) => item.name}</Dropdown.Column>
                      <Dropdown.Column weight={1}>{(item:Stakeholder) => item.type ? <Loc msg={('stakeholders_type_' + item.type) as TLocalization}/> : '-'}</Dropdown.Column>
                    </Dropdown>)}
                    hint={<Loc msg="grievances_fields_stakeholder_hint"/>}/>}

                  {this.state.data.anonymous != true && this.state.data.stakeholder != null && <Form.Field 
                    name="datasharing"
                    value={this.state.data.datasharing}
                    control={(<Checkbox checked={this.state.data.datasharing} label={<Loc msg="grievances_fields_datasharing_label"/>}/>)}/>}

                </React.Fragment>
                <React.Fragment>
                  <Form.Field 
                    required={{message: <Loc msg="grievances_fields_description_required"/>}}
                    label={<Loc msg="grievances_fields_description_label"/>}
                    name="description" value={this.state.data.description}
                    control={(<MarkdownTextarea rows={20} fluid label_text={<Loc msg="markdown_text"/>} label_preview={<Loc msg="markdown_preview"/>} label_markdown={<Loc msg="markdown_is_supported"/>}/>)}
                    hint={<Loc msg="grievances_fields_description_hint"/>}/>
                </React.Fragment>
              </Flex.Columns>
            </Section>
          </Tabs.Pane>
          {/* <Tabs.Pane label={<React.Fragment>{<Loc msg="grievances_form_tab_attachments"/>} <Label size="small">{this.state.data.attachments.length}</Label></React.Fragment>}>
            <Section padded>
              <AttachmentManager auth={p.auth} attachments={this.state.data.attachments} onChange={this.handleChangeAttachments}/>
            </Section>
          </Tabs.Pane> */}
        </Tabs>

      </Form>
    )
  }
}

export { GrievanceForm };
