import * as React from 'react';
import { css } from 'styled-components';
import styled from '@independent-software/typeui/styles/Theme'

class StatsSumBase extends React.Component<{ className?: string, value: number }, {}> {
  render() {
    let p = this.props;

    // For null value, render nothing.
    if(p.value == null) return null;
    
    return <div className={p.className}>
       {p.value > 0 && '+'}{p.value}%
      </div>
  }
}

const StatsSum = styled(StatsSumBase)`
  float: right;
  font-size: 12px;
  font-weight: 500;
  ${p => p.value != null && p.value > 0 && css`color: green`}
  ${p => p.value != null && p.value < 0 && css`color: red`}
`

export { StatsSum }