import * as React from 'react';
import { Flex } from '@independent-software/typeui/controls/Flex';

interface ILayoutProps {
  children?: any;
  width: number;
}

class Layout extends React.Component<ILayoutProps, {}> {

  getColumns = (offset: number): React.ReactNode[] => {
    let p = this.props;
    let children = React.Children.toArray(p.children);
    let items = [];
    for(let i = offset; i < Math.min(children.length, offset + p.width); i++) {
      items.push(<Flex.Column key={i} width={1}>{children[i]}</Flex.Column>);
    }
    while(items.length < p.width) items.push(<Flex.Column key={items.length - p.width} width={1}/>);
    return items;
  }

  getRows = (): React.ReactNode[] => {
    let p = this.props;
    let rows = [];
    let children = React.Children.toArray(p.children);
    for(let i = 0; i < children.length; i += p.width) {
      rows.push(<Flex.Row key={i}>
        {this.getColumns(i)}
      </Flex.Row>);
    }
    return rows;
  }

  render = () => {
    return (
      <Flex stackable>
        {this.getRows()}
      </Flex>
    )
  }
}

export { Layout }

