import * as React from 'react';
import axios from 'axios';
import styled from '@independent-software/typeui/styles/Theme'

import { IAuthProps } from '../../services/Auth';
import { App } from '../../App';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { Form } from '@independent-software/typeui/controls/Form';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Table } from '@independent-software/typeui/controls/Table';
import { ListOptions } from './ListOptions';
import { Export, Loc } from '../../modules';
import { TLocalization } from '../../modules/Loc/TLocalization';
import { Number } from '@independent-software/typeui/formatters/Number';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { CircularProgress } from '@independent-software/typeui/controls/CircularProgress/CircularProgress';
import { Divider } from '@independent-software/typeui/controls/Divider';

interface IProps {
}

interface ICommunity {
  name: string;
  households: number;
  hh_created: number;
  com_created: number;
  created: number;
  concluded: number;
  perc: number;
  has_actions: number;
  all_concluded: number;
  perc_hh_concluded: number;
}

interface IState {
  days: number;
  responsibility: string;
  delivery: string;
  polygamous: boolean;
  inas: boolean;
  vulnerability_singleheaded: boolean,
  vulnerability_femaleheaded: boolean,
  vulnerability_orphan: boolean,
  vulnerability_elderly: boolean,
  vulnerability_mental: boolean,
  vulnerability_physical: boolean,
  vulnerability_illness: boolean,
  vulnerability_income: boolean,
  data: ICommunity[];
}

class ListActionsImplementationByCommunity extends React.Component<IAuthProps & IProps, IState> {
   constructor(props: IAuthProps & IProps) {
     super(props);
     this.state = {
       days: -1,
       responsibility: null,
       delivery: null,
       polygamous: null,
       inas: null,
       vulnerability_singleheaded: null,
       vulnerability_femaleheaded: null,
       vulnerability_orphan: null,
       vulnerability_elderly: null,
       vulnerability_mental: null,
       vulnerability_physical: null,
       vulnerability_illness: null,
       vulnerability_income: null,       
       data: []
     }
   } 

   componentDidMount() {
     this.loadData();
   }

   componentDidUpdate(newProps: IProps, newState: IState) {
    if(this.state.days != newState.days ||
      this.state.responsibility != newState.responsibility ||
      this.state.delivery != newState.delivery ||
      this.state.polygamous != newState.polygamous ||
      this.state.inas != newState.inas ||
      this.state.vulnerability_singleheaded != newState.vulnerability_singleheaded ||
      this.state.vulnerability_femaleheaded != newState.vulnerability_femaleheaded ||
      this.state.vulnerability_orphan != newState.vulnerability_orphan ||
      this.state.vulnerability_elderly != newState.vulnerability_elderly ||
      this.state.vulnerability_mental != newState.vulnerability_mental ||
      this.state.vulnerability_physical != newState.vulnerability_physical ||
      this.state.vulnerability_illness != newState.vulnerability_illness ||
      this.state.vulnerability_income != newState.vulnerability_income) {
      this.loadData();
    }
  }

  getPath = () => {
    let path = `${App.apiURL}actions/list-implementation-communities?api_token=${this.props.auth.token}`;
    if(this.state.days) path = path + `&days=${this.state.days}`;
    if(this.state.responsibility) path = path + `&responsibility=${this.state.responsibility}`;
    if(this.state.delivery) path = path + `&delivery=${this.state.delivery}`;
    if(this.state.polygamous) path = path + `&polygamous=1`;
    if(this.state.inas) path = path + `&inas=1`;
    if(this.state.vulnerability_singleheaded) path = path + `&vulnerability_singleheaded=1`;
    if(this.state.vulnerability_femaleheaded) path = path + `&vulnerability_femaleheaded=1`;
    if(this.state.vulnerability_orphan) path = path + `&vulnerability_orphan=1`;
    if(this.state.vulnerability_elderly) path = path + `&vulnerability_elderly=1`;
    if(this.state.vulnerability_mental) path = path + `&vulnerability_mental=1`;
    if(this.state.vulnerability_physical) path = path + `&vulnerability_physical=1`;
    if(this.state.vulnerability_illness) path = path + `&vulnerability_illness=1`;
    if(this.state.vulnerability_income) path = path + `&vulnerability_income=1`;    
    return path;
  }

  loadData = () => {
    axios.get(this.getPath())
    .then(response => {
      let data = response.data;
      this.setState({
        data: data
      })
    })
    .catch(error => {
    });   
  }

  handleExport = (format: string) => {
    axios.get(this.getPath() + `&format=${format}`, { responseType: 'blob'}) 
    .then(response => {
      // Find the content-disposition header.
      let disposition:string = response.headers['content-disposition'];
      // Using a regexp, retrieve the filename from it.
      let regexp = new RegExp('\"(.*)\"');
      let res:RegExpExecArray = regexp.exec(disposition);
      let filename = res[1];
      // Download the file.
      saveAs(response.data, filename);
    })
    .catch(error => {
    });
  }

  handleChangeDays = (value: number) => {
    this.setState({days: value});
  }    

  handleChangeResponsibility = (value: string) => {
    this.setState({responsibility: value});
  }

  handleChangeDelivery = (value: string) => {
    this.setState({delivery: value});
  }  

  handlePolygamous = (value: boolean) => {
    this.setState({polygamous: value});
  }

  handleINAS = (value: boolean) => {
    this.setState({inas: value});
  }  
  
  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Flex.Columns count={2}>
          <React.Fragment>
            {/* <Form.Uncontrolled hint={<Loc msg="analysis_grievances_hint_period"/>}>
              <Dropdown name="days" fluid value={this.state.days} data={[30,90,180,365,-1]} placeholder="Period" label={(item:number) => item == -1 ? <Loc msg="analysis_grievances_hint_period_all_time"/> : <span>{item} {<Loc msg="analysis_grievances_hint_period_days"/>}</span>} onChange={this.handleChangeDays}>
                <Dropdown.Column>{(item:number) => item == -1 ? <Loc msg="analysis_grievances_hint_period_all_time"/> : <span>{item} {<Loc msg="analysis_grievances_hint_period_days"/>}</span>}</Dropdown.Column>
              </Dropdown>
            </Form.Uncontrolled> */}
            <Form.Uncontrolled hint={<Loc msg="actions_filter_responsibility_hint"/>}>
              <Dropdown name="responsibility" fluid clearable value={this.state.responsibility} data={['pnl','government','provider']} placeholder="Responsibility" label={(item:string) => <Loc msg={("actions_responsibility_" + item) as TLocalization}/>} onChange={this.handleChangeResponsibility}>
                <Dropdown.Column>{(item:string) => <Loc msg={("actions_responsibility_" + item) as TLocalization}/>}</Dropdown.Column>
              </Dropdown>
            </Form.Uncontrolled>        
            <Form.Uncontrolled hint={<Loc msg="actions_filter_delivery_hint"/>}>
              <Dropdown fluid value={this.state.delivery} clearable data={['cash','kind','specific','benefits','livelihood','unplanned']} placeholder="Delivery" label={(item:string) => <Loc msg={("actiontypes_delivery_" + item) as TLocalization}/>} onChange={this.handleChangeDelivery}>
                <Dropdown.Column>{(item:string) => <Loc msg={("actiontypes_delivery_" + item) as TLocalization}/>}</Dropdown.Column>
              </Dropdown>
            </Form.Uncontrolled>
            <Form.Uncontrolled>
              <Checkbox type="slider" checked={this.state.polygamous} label={<Loc msg="households_fields_polygamous_label"/>} onChange={this.handlePolygamous}/>
            </Form.Uncontrolled>
            <Form.Uncontrolled>
              <Checkbox type="slider" checked={this.state.inas} label={<Loc msg="households_fields_inas_label"/>} onChange={this.handleINAS}/>
            </Form.Uncontrolled>                
          </React.Fragment>
          <React.Fragment>
            <Form.Uncontrolled><Checkbox type="slider" checked={this.state.vulnerability_singleheaded} onChange={(v: boolean) => this.setState({vulnerability_singleheaded: v})} label={<Loc msg="households_vulnerabilities_singleheaded"/>}/></Form.Uncontrolled>
            <Form.Uncontrolled><Checkbox type="slider" checked={this.state.vulnerability_femaleheaded} onChange={(v: boolean) => this.setState({vulnerability_femaleheaded: v})} label={<Loc msg="households_vulnerabilities_femaleheaded"/>}/></Form.Uncontrolled>
            <Form.Uncontrolled><Checkbox type="slider" checked={this.state.vulnerability_orphan} onChange={(v: boolean) => this.setState({vulnerability_orphan: v})} label={<Loc msg="households_vulnerabilities_orphan"/>}/></Form.Uncontrolled>
            <Form.Uncontrolled><Checkbox type="slider" checked={this.state.vulnerability_elderly} onChange={(v: boolean) => this.setState({vulnerability_elderly: v})} label={<Loc msg="households_vulnerabilities_elderly"/>}/></Form.Uncontrolled>
            <Form.Uncontrolled><Checkbox type="slider" checked={this.state.vulnerability_mental} onChange={(v: boolean) => this.setState({vulnerability_mental: v})} label={<Loc msg="households_vulnerabilities_mental"/>}/></Form.Uncontrolled>
            <Form.Uncontrolled><Checkbox type="slider" checked={this.state.vulnerability_physical} onChange={(v: boolean) => this.setState({vulnerability_physical: v})} label={<Loc msg="households_vulnerabilities_physical"/>}/></Form.Uncontrolled>
            <Form.Uncontrolled><Checkbox type="slider" checked={this.state.vulnerability_illness} onChange={(v: boolean) => this.setState({vulnerability_illness: v})} label={<Loc msg="households_vulnerabilities_illness"/>}/></Form.Uncontrolled>
            <Form.Uncontrolled><Checkbox type="slider" checked={this.state.vulnerability_income} onChange={(v: boolean) => this.setState({vulnerability_income: v})} label={<Loc msg="households_vulnerabilities_income"/>}/></Form.Uncontrolled>        
          </React.Fragment>
        </Flex.Columns>
      </Panel.Content>
      <Panel.Footer>
        <Export onExport={this.handleExport}/>
      </Panel.Footer>      
    </React.Fragment>    

    return (
      this.state.data.length > 0 ? 
      <React.Fragment>
        <Flex.Columns count={2}>
          <div style={{textAlign: "center"}}>
            <CircularProgress background raised animated value={this.state.data[this.state.data.length-1]["perc"]}/><br/>
            <Loc msg="analysis_actions_implementation_perc"/>
          </div>
          <div style={{textAlign: "center"}}>
            <CircularProgress background raised animated value={this.state.data[this.state.data.length-1]["perc_hh_concluded"]}/><br/>
            <Loc msg="analysis_actions_implementation_perc_hh_concluded"/>
          </div>
        </Flex.Columns>
        <Divider hidden/>
        <ListOptions>
          <Panel.Icon icon="tools" width={600}>
            {filter}
          </Panel.Icon>
        </ListOptions>
        <Table transparent striped>
          <thead>
            <tr>
              <th style={{width: '200px'}}>&nbsp;</th>
              <th style={{textAlign: "center"}}><Loc msg="analysis_actions_implementation_households"/></th>
              <th style={{textAlign: "center"}}><Loc msg="analysis_actions_implementation_hh_created"/></th>
              <th style={{textAlign: "center"}}><Loc msg="analysis_actions_implementation_com_created"/></th>
              <th style={{textAlign: "center"}}><Loc msg="analysis_actions_implementation_created"/></th>
              <th style={{textAlign: "center"}}><Loc msg="analysis_actions_implementation_concluded"/></th>
              <th style={{textAlign: "center"}}><Loc msg="analysis_actions_implementation_perc"/></th>
              <th style={{textAlign: "center"}}><Loc msg="analysis_actions_implementation_has_actions"/></th>
              <th style={{textAlign: "center"}}><Loc msg="analysis_actions_implementation_all_concluded"/></th>
              <th style={{textAlign: "center"}}><Loc msg="analysis_actions_implementation_perc_hh_concluded"/></th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((item: ICommunity, idx:number) => 
              <tr key={idx} style={{ fontWeight: !item.name ? 500 : 'normal', background: !item.name ? "#eee" : null }}>
                <td>{item.name ? item.name : "Total"}</td>
                <td style={{textAlign: "center"}}>{<Number value={item.households} decimals={0}/>}</td>
                <td style={{textAlign: "center"}}>{<Number value={item.hh_created} decimals={0}/>}</td>
                <td style={{textAlign: "center"}}>{<Number value={item.com_created} decimals={0}/>}</td>
                <td style={{textAlign: "center"}}>{<Number value={item.created} decimals={0}/>}</td>
                <td style={{textAlign: "center"}}>{<Number value={item.concluded} decimals={0}/>}</td>
                <td style={{textAlign: "center"}}>{<span><Number value={item.perc} decimals={0}/>%</span>}</td>
                <td style={{textAlign: "center"}}>{<Number value={item.has_actions} decimals={0}/>}</td>
                <td style={{textAlign: "center"}}>{<Number value={item.all_concluded} decimals={0}/>}</td>
                <td style={{textAlign: "center"}}>{<span><Number value={item.perc_hh_concluded} decimals={0}/>%</span>}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </React.Fragment> : null
    );
  }
}

const ListItem = styled('tr')`
  cursor: pointer;
  &:hover {
    background: #E7E7e7;
  }
`

export { ListActionsImplementationByCommunity }
