import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import axios from 'axios';
import { RouteComponentProps } from 'react-router';
import { IAuthProps, Query } from '../../services';
import { Community, CommunityFactory, Survey, SurveyFactory } from '../../resource';
import { Container, Content, IconBar, LanguageContext, Loc, Section } from '../../modules';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { Button } from '@independent-software/typeui/controls/Button';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { Message } from '@independent-software/typeui/controls/Message';
import { Divider } from '@independent-software/typeui/controls/Divider';
import { App } from '../../App';
import { Table } from '@independent-software/typeui/controls/Table';
import { INDICATORS } from './definitions';
import { Loader } from '@independent-software/typeui/controls/Loader';
import * as Excel from 'exceljs';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';

interface IState {
  surveys: Survey[];
  communities: Community[];
  community: Community;
  monoparental: boolean;
  poligamo: boolean;
  selection: number[];
  seriesdata: IResult[];
  loading: boolean;
}

interface IResult {
  name: string;
  value: string;
  dimension: string;
  issue: string; 
}

class SurveySeries extends React.Component<IAuthProps & RouteComponentProps<any>, IState> {
  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
    this.state = {
      surveys: [],
      communities: [],
      community: null,
      monoparental: false,
      poligamo: false,
      selection: [],
      seriesdata: null,
      loading: false
    };
  }

  componentDidMount = () => {
    this.loadSurveys();
    this.loadCommunities();
  }

  loadSurveys = () => {
    let query = new Query('name', 'asc');
    SurveyFactory.getSome(this.props.auth, 0, 999, query)
      .then((res) => this.setState({ surveys: res.items }));    
  }  

  loadCommunities = () => {
    let query = new Query('name', 'asc');
    CommunityFactory.getSome(this.props.auth, 0, 999, query)
      .then((res) => this.setState({ communities: res.items }));    
  }  

  loadData = () => {
    // Do not load anything if no surveys selected or no community selected.
    if(this.state.selection.length == 0 || this.state.community === null) {
      this.setState({loading: false});
      return;
    }

    const communityID = this.state.community != null ? `community=${this.state.community.id}&` : '';
    const monoparental = this.state.monoparental == true ? `monoparental=1&` : '';
    const poligamo = this.state.poligamo == true ? `poligamo=1&` : '';

    axios.get(`${App.apiURL}surveys/series/${this.state.selection.join(',')}?${communityID}${monoparental}${poligamo}api_token=${this.props.auth.token}`) 
    .then(response => {
      let data: IResult[] = Object.keys(response.data).map((key) => {
        return {
          name: key,
          value: response.data[key],
          dimension: INDICATORS.find((i) => i.name == key).dimension,
          issue: INDICATORS.find((i) => i.name == key).issue,
        }
      });

      this.setState({ seriesdata: data, loading: false }); 
    })
    .catch(error => {
    }); 
  }

  handleToggleSurvey = (id: number) => {
    let idx = this.state.selection.indexOf(id);
    if(idx != -1) {
      this.state.selection.splice(idx, 1);
    } else {
      this.state.selection.push(id);
    }
    this.setState({ selection: this.state.selection, loading: true }, this.loadData);
  }

  handleSelectCommunity = (community: Community) => {
    this.setState({
      community: community
    }, this.loadData);
  }

  handleChangeMonoparental = (value: boolean) => {
    this.setState({
      monoparental: value
    }, this.loadData);
  }

  handleChangePoligamo = (value: boolean) => {
    this.setState({
      poligamo: value
    }, this.loadData);
  }  
  
  private setCell = (ws: Excel.Worksheet, r: number, c: number, value: any, font: any) => {
    const cell = ws.getCell(r, c);
    cell.value = value;
    cell.font = font;
  }

  private setCellNormal = (ws: Excel.Worksheet, r: number, c: number, value: any) => {
    this.setCell(ws, r, c, value, {});
  }

  private setCellHeader = (ws: Excel.Worksheet, r: number, c: number, value: any) => {
    this.setCell(ws, r, c, value, { size: 20, bold: true });
  }  

  private setCellSubheader = (ws: Excel.Worksheet, r: number, c: number, value: any) => {
    this.setCell(ws, r, c, value, { size: 16, bold: true });
  }  

  handleExport = (langdata: any) => {
    const workbook = new Excel.Workbook();
    const ws = workbook.addWorksheet('Series');

    // Headers
    this.setCellNormal(ws, 1, 1, Loc.getText(langdata, "analysis_series_hdr_indicator"));
    ws.getColumn(1).width = 50;
    this.state.selection.forEach((id, idx) => {
      this.setCellNormal(ws, 1, idx + 2, this.state.surveys.find((s) => s.id == id).name);
      ws.getColumn(idx + 2).width = 50;
    });

    let y = 2;
    let dimension:string = null;
    let issue:string = null;
    this.state.seriesdata//.sort(this.curriedCompareResult(langdata))
    .forEach((result: IResult, index:number) => {
      if(result.dimension != dimension) {
        dimension = result.dimension;
        this.setCellHeader(ws, y, 1, Loc.getText(langdata, dimension as any));
        y++;
      }
      if(result.issue != issue) {
        issue = result.issue;
        this.setCellSubheader(ws, y, 1, Loc.getText(langdata, issue as any));        
        y++;
      }
      this.setCellNormal(ws, y, 1, Loc.getText(langdata, ("indicator_" + result.name) as any));
      this.state.selection.forEach((id, idx) => {
        const value = result.value[idx];
        let cell = "";
        if(value == null) {
          cell = "-";
        } else if(value.startsWith('@')) {
          cell = Loc.getText(langdata, (`cat_${result.name}_${value.substr(1)}`) as any);
        } else {
          cell = value;
        }
        this.setCellNormal(ws, y, idx + 2, cell);
      });
      y++;
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
      saveAs(blob, "Series.xlsx");
    });    
  }


  //
  // This function is curried. Calling it with a langdata returns another 
  // function which already has langdata in its lexical scope.
  //
  private curriedCompareResult = (langdata: any) => {
    return function(a: IResult, b: IResult): number {
      const dimA = Loc.getTextNoDiacritics(langdata, a.dimension as any);
      const dimB = Loc.getTextNoDiacritics(langdata, b.dimension as any)
      if(dimA < dimB) return -1;
      if(dimA > dimB) return 1;
      const issueA = Loc.getTextNoDiacritics(langdata, a.issue as any);
      const issueB = Loc.getTextNoDiacritics(langdata, b.issue as any);
      if(issueA < issueB) return -1;
      if(issueA > issueB) return 1;
      const nameA = Loc.getTextNoDiacritics(langdata, ("indicator_" + a.name) as any);
      const nameB = Loc.getTextNoDiacritics(langdata, ("indicator_" + b.name) as any)
      if(nameA < nameB) return -1;
      if(nameA > nameB) return 1;
      return 0;
    }
  }

  renderTableData = (langdata:any) => {
    let dimension:string = null;
    let issue:string = null;
    let output: React.ReactNode[] = [];
    this.state.seriesdata//.sort(this.curriedCompareResult(langdata))
    .forEach((result: IResult, index:number) => {
      if(result.dimension != dimension) {
        dimension = result.dimension;
        output.push(<tr style={{background:'#333'}} key={result.dimension}><td colSpan={this.state.selection.length+1}><Dimension>{<Loc msg={dimension as any}/>}</Dimension></td></tr>);
      }
      if(result.issue != issue) {
        issue = result.issue;
        output.push(<tr style={{background:'#666'}} key={result.issue}><td colSpan={this.state.selection.length+1}><Issue><Loc msg={issue as any}/></Issue></td></tr>)
      }
      output.push(
        <tr key={result.name} style={{background: index % 2 == 0 ? '#fff' : '#eee'}}>
          <td>
            <Indicator title={Loc.getText(langdata, ("caption_" + result.name) as any)}><Loc msg={("indicator_" + result.name) as any}/></Indicator>
            {/* <Caption><Loc msg={("caption_" + result.name) as any}/></Caption> */}
          </td>
          {this.state.selection.map((id, index) => 
          <td key={id}>
            <Result langdata={langdata} name={result.name} value={result.value[index]}/>
          </td>)}
        </tr>
      );
    });
    return output;
  }

  render = () => {
    return (
      <LanguageContext.Consumer>
      {({data}) =>
      <Container>
        <>
          <Content>
            <IconBar>
              <Panel.Icon icon="tools" width={300}>
                <Panel.Content>
                  {this.state.surveys.map((survey) => <div key={survey.id}>
                    <Checkbox checked={this.state.selection.includes(survey.id)} onChange={() => this.handleToggleSurvey(survey.id)} /> {survey.name}
                  </div>)}
                  <Divider hidden/>
                  <Dropdown clearable value={this.state.community} fluid data={this.state.communities} label={(item:Community) => item.name} placeholder="Select community" onChange={this.handleSelectCommunity}>
                    <Dropdown.Column>{(item:Community) => item.name}</Dropdown.Column>
                  </Dropdown>
                  <Divider hidden/>
                  <Checkbox type="toggle" checked={this.state.monoparental} label="Somente AF monoparentais" onChange={this.handleChangeMonoparental}/>
                  <Checkbox type="toggle" checked={this.state.poligamo} label="Somente AF polígamos" onChange={this.handleChangePoligamo}/>                  
                </Panel.Content>
                <Panel.Footer>
                  <Button disabled={this.state.selection.length == 0 || this.state.seriesdata == null} secondary size="small" onClick={() => this.handleExport(data)}><Loc msg="analysis_surveys_export"/></Button>
                </Panel.Footer>
              </Panel.Icon>
            </IconBar>             
            <Divider hidden/>
            {this.state.loading == true && <Loader/>}
            <Section padded>

              {(this.state.selection.length == 0 || this.state.seriesdata == null) && 
              <Message type="info">
                <Loc msg="analysis_series_instructions"/>
              </Message>}

              {this.state.selection.length > 0 && this.state.seriesdata != null &&
                <Table>
                  <thead>
                    <tr>
                      <th><Loc msg="analysis_series_hdr_indicator"/></th>
                      {this.state.selection.map((id) => <th key={id}>
                        {this.state.surveys.find((s) => s.id == id).name}
                      </th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderTableData(data)}
                  </tbody>
                </Table>}
           </Section>
           <Divider hidden/>
          </Content>  
        </>
      </Container>
      }
      </LanguageContext.Consumer>
    );
  }
}

class Result extends React.Component<{ langdata: any, name: string, value: string }> {
  render = () => {
    let p = this.props;
    // If no result, display nothing:
    if(p.value == null) return <>-</>;
    // Make sure value is a string:
    const s = p.value.toString();
    // If result starts with @, display category:
    if(s.startsWith('@')) {
      let cat = s.substr(1);
      let translation = Loc.getText(p.langdata, (`cat_${p.name}_${cat}`) as any);
      if(translation == '?' && cat == "98") translation = Loc.getText(p.langdata, "cat_98" as any);
      if(translation == '?' && (cat == "99" || cat == "999")) translation = Loc.getText(p.langdata, "cat_99" as any);
      if(translation == '?') translation = cat.toString();
      return translation;
    }
    // Display simple result:
    return s;
  }
}

const Dimension = styled('div')`
  font-weight: 500;
  font-size: 125%;
  color: #fff;
`

const Issue = styled('div')`
  font-weight: 500;
  padding-left: 15px;
  color: #fff;
`

const Indicator = styled('div')`
  padding-left: 30px;
`

const Caption = styled('div')`
  padding-left: 30px;
  font-size: 60%;
  line-height: 1.2em;
  padding-bottom: 4px;
`

export { SurveySeries }
