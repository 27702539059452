import * as React from 'react';

interface IEuiProps {
  className?: string;
  /** Value to format. */
  value: string;
}

class Eui extends React.Component<IEuiProps, {}> {
  render() {
    let p = this.props;

    if(p.value == null) return null;

    return p.value.substr(0,4) 
           + '.' + p.value.substr(4,4)
           + '.' + p.value.substr(8,4)
           + '.' + p.value.substr(12,4);
  }
}

export { Eui };
