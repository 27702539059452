import { ResolutionSwitch } from "./ResolutionSwitch";
import { TypeSwitch } from "./TypeSwitch";

type TChartResolution = 'day' | 'week' | 'month' | 'year';
type TChartType = 'line' | 'bar' | 'area';

class Chart { 
  public static TypeSwitch = TypeSwitch;
  public static ResolutionSwitch = ResolutionSwitch;
}

export { Chart, TChartResolution, TChartType };