import * as React from 'react';
import { Container, Content, BottomBar, MultiUpload, Loc } from '../../modules';
import { Attachment } from '../../resource/';
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';
import { ListAttachments } from './ListAttachments';
import { Button } from '@independent-software/typeui/controls/Button';
import { App } from '../../App';
import { Icon } from '@independent-software/typeui/controls/Icon';

interface IState {
  showUpload: boolean;
  timestamp: number;
}

class AttachmentList extends React.Component<IAuthProps & RouteComponentProps<any>, IState> {
  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
    this.state = {
      showUpload: false,
      timestamp: 0
    };
  }

  private handleClickAttachment = (attachment: Attachment) => {
    this.props.history.push(`/attachments/${attachment.id}`);
  }  

  private handleOpenUpload = () => {
    this.setState({ showUpload: true });
  }

  private handleCloseUpload = () => {
    this.setState({ showUpload: false });
  }

  private handleUploadAttachment = (attachment: Attachment) => {
    // Set a new timestamp for ListAttachments so that
    // the list refetches.
    this.setState({timestamp: new Date().getTime() });
  }

  render() {
    let p = this.props;
    return (
      <Container>
        <Content>
          <ListAttachments timestamp={this.state.timestamp} auth={p.auth} name="attachments" onClick={this.handleClickAttachment}/>
        </Content>
        <BottomBar>
          {p.auth.hasRight('can_edit_attachments') &&
            <Button primary onClick={this.handleOpenUpload}><Icon name="plus"/><Loc msg="attachments_btn_upload"/></Button>}
        </BottomBar>        
        <MultiUpload 
          auth={p.auth}
          open={this.state.showUpload}
          url={`${App.apiURL}attachment`}
          title={<Loc msg="attachments_title_upload"/>}
          onClose={this.handleCloseUpload}
          onSuccess={this.handleUploadAttachment}
        />
      </Container>
    );
  }
}

export { AttachmentList };
