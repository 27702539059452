import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { css } from 'styled-components';

const Container = styled('div')`
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;  
`

class SectionBase extends React.Component<{ className?: string; padded?: boolean, stretched?: boolean, dark?: boolean}, {}> {
  render() {
    let p = this.props;
    return (
      <div className={p.className}>{p.children}</div>
    );
  }
}

const Section = styled(SectionBase)`
  position: relative;
  ${p => p.padded && css`padding: 10px 25px 5px 25px;`}
  ${p => p.stretched && css`flex:1;`}
  ${p => p.dark && css`background: #eee;`}
  margin-bottom: 5px;
`

const BottomBar = styled('div')`
  display: flex;
  z-index: 1;
  justify-content: space-between;
  box-shadow: rgba(34, 36, 38, 0.15) 0px 0px 4px 0px;
  padding: 16px 20px 16px 20px;  
  background: #fdfdfd;
`

export { Container, Content, BottomBar, Section };