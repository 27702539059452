import * as React from 'react';
import { Section, Viewer, Block, Loc } from '../../modules';
import { Actiontype, ActiontypeFactory, Grievance } from '../../resource/'; 
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';

import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Header } from '@independent-software/typeui/controls/Header';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Label } from '@independent-software/typeui/controls/Label';
import { Table } from '@independent-software/typeui/controls/Table';
import { ListGrievances } from '../Grievance';
import { TLocalization } from '../../modules/Loc/TLocalization';

class ViewActiontype extends React.Component<IAuthProps & RouteComponentProps<{id:string}>, {}> {
  private canEdit = (actiontype: Actiontype): boolean => {
    return this.props.auth && this.props.auth.hasRight('can_edit_actiontypes');
  }  

  private handleEdit = () => {
    this.props.history.push(`/actiontypes/edit/${parseInt(this.props.match.params.id)}`);
  }

  private handleDelete = () => {
    ToastService.toast(<Loc msg="actiontypes_msg_deleted"/>);
    this.props.history.push('/actiontypes');        
  }    

  private handleClickGrievance = (grievance: Grievance) => {
    this.props.history.push(`/grievances/${grievance.id}`);
  }

  render() {
    let p = this.props;
    return (
      <Viewer<Actiontype> 
        auth={p.auth}
        id={parseInt(p.match.params.id)}
        factory={ActiontypeFactory}
        canEdit={this.canEdit}
        onEdit={this.handleEdit}
        onDelete={this.handleDelete}
        content={(actiontype: Actiontype) => 
          <React.Fragment>
            <Section dark padded>
              <Flex>
                <Flex.Row>
                  <Flex.Column>
                    <Header size="h2">{actiontype.name}</Header>
                  </Flex.Column>
                </Flex.Row>
              </Flex>
            </Section>
            <Tabs underlined nohiddenrender>
              <Tabs.Pane label={<Loc msg="actiontypes_form_tab_data"/>}>
                <Section padded>
                  <Flex.Columns count={2}>
                    <React.Fragment>
                      <Block title={<Loc msg="actiontypes_block_identification"/>}>
                        <Table striped transparent>
                          <tbody>
                            <tr><td><Loc msg="actiontypes_fields_name_label"/></td><td>{actiontype.name}</td></tr>
                            <tr><td><Loc msg="actiontypes_fields_level_label"/></td><td>{actiontype.level ? <Loc msg={("actiontypes_level_" + actiontype.level) as TLocalization}/>  : '-'}</td></tr>
                            <tr><td><Loc msg="actiontypes_fields_delivery_label"/></td><td>{actiontype.delivery ? <Loc msg={("actiontypes_delivery_" + actiontype.delivery) as TLocalization}/>  : '-'}</td></tr>
                          </tbody>
                        </Table>
                      </Block>
                    </React.Fragment>
                  </Flex.Columns>
                </Section>
              </Tabs.Pane>
            </Tabs>
          </React.Fragment>}>
      </Viewer>
    );
  }
}

export { ViewActiontype };
