import * as React from 'react';
import axios from 'axios';

import { Container, Content, BottomBar, Section, Stats, Loc } from '../../modules';
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';
import { App } from '../../App';
import { Loader } from '@independent-software/typeui/controls/Loader';
import { Number } from '@independent-software/typeui/formatters/Number';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Action, Grievance } from '../../resource';
import { ListGrievances } from '../Grievance';
import { ListActions } from '../Action';

interface IState {
  loading: boolean;
  communities: number;
  actions: number;
  grievances: number;
  surveys: number;
  responses: number;
}

class Dashboard extends React.Component<IAuthProps & RouteComponentProps<any>, IState> {
  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
    this.state = {
      loading: true,
      communities: null,
      actions: null,
      grievances: null,
      surveys: null,
      responses: null
    }
  }

  componentDidMount() {
    // Load data from the server.
    this.loadData();
  } 

  private loadData = () => {
    this.setState({loading: true});
    axios.get(`${App.apiURL}dashboard?api_token=${this.props.auth.token}`)
    .then(response => {
      let data = response.data;
      this.setState({
        loading: false,
        communities: data.communities,
        actions: data.actions,
        grievances: data.grievances,
        surveys: data.surveys,
        responses: data.responses
      })
    })
    .catch(error => {
    });    
  }

  handleClickCommunities = () => {
    this.props.history.push('/communities');
  }

  handleClickActions = () => {
    this.props.history.push('/actions');
  }

  handleClickGrievances = () => {
    this.props.history.push('/grievances');
  }

  handleClickSurveys = () => {
    this.props.history.push('/surveys');
  }

  handleClickResponses = () => {
    this.props.history.push('/responses');
  }

  handleClickGrievance = (grievance: Grievance) => {
    if(!this.props.auth.hasRight('can_view_grievances')) return;
    this.props.history.push(`/grievances/${grievance.id}`);
  }

  handleClickAction = (action: Action) => {
    if(!this.props.auth.hasRight('can_view_actions')) return;
    this.props.history.push(`/actions/${action.id}`);
  }

  renderStats = () => {
    let p = this.props;
    return (
      <Tabs.Pane label={<Loc msg="dashboard_tab_summary"/>}>
        <Section padded>
          <Stats onClick={p.auth.hasRight('can_view_communities') ? this.handleClickCommunities : null}>
            <Stats.Title><Loc msg="dashboard_block_communities"/></Stats.Title>
            <Stats.Center>
              <Stats.BigNumber><Number decimals={0} value={this.state.communities}/></Stats.BigNumber>
            </Stats.Center>
            <Stats.Bottom><Loc msg="dashboard_block_communities_hint"/></Stats.Bottom>
          </Stats>
          <Stats onClick={p.auth.hasRight('can_view_actions') ? this.handleClickActions : null}>
            <Stats.Title><Loc msg="dashboard_block_actions"/></Stats.Title>
            <Stats.Center>
              <Stats.BigNumber><Number decimals={0} value={this.state.actions}/></Stats.BigNumber>
            </Stats.Center>
            <Stats.Bottom><Loc msg="dashboard_block_actions_hint"/></Stats.Bottom>
          </Stats>            
          <Stats onClick={p.auth.hasRight('can_view_grievances') ? this.handleClickGrievances : null}>
            <Stats.Title><Loc msg="dashboard_block_grievances"/></Stats.Title>
            <Stats.Center>
              <Stats.BigNumber><Number decimals={0} value={this.state.grievances}/></Stats.BigNumber>
            </Stats.Center>
            <Stats.Bottom><Loc msg="dashboard_block_grievances_hint"/></Stats.Bottom>
          </Stats>
          <Stats onClick={p.auth.hasRight('can_view_surveys') ? this.handleClickSurveys : null}>
            <Stats.Title><Loc msg="dashboard_block_surveys"/></Stats.Title>
            <Stats.Center>
              <Stats.BigNumber><Number decimals={0} value={this.state.surveys}/></Stats.BigNumber>
            </Stats.Center>
            <Stats.Bottom><Loc msg="dashboard_block_surveys_hint"/></Stats.Bottom>
          </Stats>   
          <Stats onClick={p.auth.hasRight('can_view_responses') ? this.handleClickResponses : null}>
            <Stats.Title><Loc msg="dashboard_block_responses"/></Stats.Title>
            <Stats.Center>
              <Stats.BigNumber><Number decimals={0} value={this.state.responses}/></Stats.BigNumber>
            </Stats.Center>
            <Stats.Bottom><Loc msg="dashboard_block_responses_hint"/></Stats.Bottom>
          </Stats>                                
        </Section>
      </Tabs.Pane>
    );
  }

  renderUnresolvedGrievances = () => {
    let p = this.props;
    return (
      <Tabs.Pane label={<Loc msg="dashboard_tab_unresolved_grievances"/>}>
        <ListGrievances auth={p.auth} unresolved name="unresolved_grievances" onClick={this.handleClickGrievance}/>
      </Tabs.Pane>    
    );
  }

  renderPendingGrievances = () => {
    let p = this.props;
    return (
      <Tabs.Pane label={<Loc msg="dashboard_tab_pending_grievances"/>}>
        <ListGrievances auth={p.auth} pending name="pending_grievances" onClick={this.handleClickGrievance}/>
      </Tabs.Pane>
    );    
  }

  renderOverdueGrievances = () => {
    let p = this.props;
    return (
      <Tabs.Pane label={<Loc msg="dashboard_tab_overdue_grievances"/>}>
        <ListGrievances auth={p.auth} overdue name="overdue_grievances" onClick={this.handleClickGrievance}/>
      </Tabs.Pane>
    );
  }

  renderPendingActions = () => {
    let p = this.props;
    return (
      <Tabs.Pane label={<Loc msg="dashboard_tab_pending_actions"/>}>
        <ListActions auth={p.auth} pending name="pending_actions" onClick={this.handleClickAction}/>
      </Tabs.Pane>
    );    
  }  

  renderUnresolvedActions = () => {
    let p = this.props;
    return (
      <Tabs.Pane label={<Loc msg="dashboard_tab_unresolved_actions"/>}>
        <ListActions auth={p.auth} unresolved name="unresolved_actions" onClick={this.handleClickAction}/>
      </Tabs.Pane>
    );    
  }  
  
  render() {
    let p = this.props;
    return (
      <Container>
        <Content>
          {this.state.loading && <Loader/>}
          {!this.state.loading && 
            <React.Fragment>
              <Tabs underlined>
                {this.renderStats()}
                {p.auth && p.auth.hasRight('can_view_grievances') && this.renderUnresolvedGrievances()}
                {p.auth && p.auth.hasRight('can_validate_grievances') && this.renderPendingGrievances()}
                {p.auth && p.auth.hasRight('can_view_grievances') && this.renderOverdueGrievances()}
                {p.auth && p.auth.hasRight('can_view_actions') && this.renderUnresolvedActions()}
                {p.auth && p.auth.hasRight('can_validate_actions') && this.renderPendingActions()}
              </Tabs>
            </React.Fragment>}
        </Content>
        <BottomBar>
          <div>
          </div>
        </BottomBar>
      </Container>
    );
  }
}

export { Dashboard };
