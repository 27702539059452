import * as React from 'react';
import { Section, Viewer, Block, Loc } from '../../modules';
import { Issuetype, IssuetypeFactory, Grievance } from '../../resource/'; 
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';

import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Header } from '@independent-software/typeui/controls/Header';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Label } from '@independent-software/typeui/controls/Label';
import { Table } from '@independent-software/typeui/controls/Table';
import { ListGrievances } from '../Grievance';

class ViewIssuetype extends React.Component<IAuthProps & RouteComponentProps<{id:string}>, {}> {
  private canEdit = (issuetype: Issuetype): boolean => {
    return this.props.auth && this.props.auth.hasRight('can_edit_issuetypes');
  }  

  private handleEdit = () => {
    this.props.history.push(`/issuetypes/edit/${parseInt(this.props.match.params.id)}`);
  }

  private handleDelete = () => {
    ToastService.toast(<Loc msg="issuetypes_msg_deleted"/>);
    this.props.history.push('/issuetypes');        
  }    

  private handleClickGrievance = (grievance: Grievance) => {
    this.props.history.push(`/grievances/${grievance.id}`);
  }

  render() {
    let p = this.props;
    return (
      <Viewer<Issuetype> 
        auth={p.auth}
        id={parseInt(p.match.params.id)}
        factory={IssuetypeFactory}
        canEdit={this.canEdit}
        onEdit={this.handleEdit}
        onDelete={this.handleDelete}
        content={(issuetype: Issuetype) => 
          <React.Fragment>
            <Section dark padded>
              <Flex>
                <Flex.Row>
                  <Flex.Column>
                    <Header size="h2">{issuetype.name}</Header>
                  </Flex.Column>
                </Flex.Row>
              </Flex>
            </Section>
            <Tabs underlined nohiddenrender>
              <Tabs.Pane label={<Loc msg="issuetypes_form_tab_data"/>}>
                <Section padded>
                  <Flex.Columns count={2}>
                    <React.Fragment>
                      <Block title={<Loc msg="issuetypes_block_identification"/>}>
                        <Table striped transparent>
                          <tbody>
                            <tr><td><Loc msg="issuetypes_fields_name_label"/></td><td>{issuetype.name}</td></tr>
                            <tr><td><Loc msg="issuetypes_fields_category_label"/></td><td>{issuetype.issuecategory ? issuetype.issuecategory.name : '-'}</td></tr>
                          </tbody>
                        </Table>
                      </Block>
                    </React.Fragment>
                  </Flex.Columns>
                </Section>
              </Tabs.Pane>
              <Tabs.Pane label={<span><Loc msg="issuetypes_form_tab_grievances"/> <Label size="small">{issuetype.grievances_count}</Label></span>}>
                <ListGrievances auth={p.auth} issuetypeId={issuetype.id} name="issuetypes_grievances" onClick={this.handleClickGrievance}/>
              </Tabs.Pane>      
            </Tabs>
          </React.Fragment>}>
      </Viewer>
    );
  }
}

export { ViewIssuetype };
