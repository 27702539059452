import * as React from 'react';
import { Container, Content, BottomBar, Section, Loc } from '../../modules';
import { CommunityForm } from './CommunityForm';
import { Community, CommunityFactory } from '../../resource/'; 
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';

import { Button } from '@independent-software/typeui/controls/Button';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Loader } from '@independent-software/typeui/controls/Loader';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Message } from '@independent-software/typeui/controls/Message';

type TStep = 'loading' | 'loadError' | 'ready' | 'saving' | 'saveError' | 'error';

interface IState {
  community: Community;
  isValid: boolean;
  step: TStep;
  error: any;
  dirty?: boolean;
}

interface MatchParams {
  id: string;
}

class EditCommunity extends React.Component<IAuthProps & RouteComponentProps<MatchParams>, IState> {
  private community: Community;

  constructor(props: IAuthProps & RouteComponentProps<MatchParams>) {
    super(props);

    this.state = {
      step: 'loading',
      isValid: false,
      error: null,
      community: null
    };
    
    this.loadItem();
  }

  loadItem() {
    CommunityFactory.get(this.props.auth, parseInt(this.props.match.params.id)).then((community) => {
      this.community = community;
      this.setState({
        step: 'ready',
        community: this.community
      });
    })
    .catch(error => {
      this.setState({
        step: 'loadError',
        error: error
      })
    });
  }

  handleChange = (community: Community, forceupdate: boolean) => {
    this.community = Object.assign(this.community, community);
    if(forceupdate) { 
      this.setState({ community: this.community });
    }       
  }

  handleValidate = (valid: boolean) => {
    this.setState({
      isValid: valid
    })
  }  

  handleSubmit = () => {
    this.setState({ community: this.community, dirty: true });
    if(!this.state.isValid) return;    

    this.setState({ step: 'saving' });
    this.community.$update(this.props.auth)
      .then(res => {
        ToastService.toast(<Loc msg="communities_msg_updated"/>);
        this.props.history.goBack();
      })
      .catch(error => {
        this.setState({
          step: 'saveError',
          error: error
        })
      });
  }

  private handleCancelLoad = () => {
    this.setState({ step: 'error' });
  }

  private handleRetryLoad = () => {
    this.setState({ step: 'loading', error: null });
    this.loadItem();
  }
  
  private handleCancelSave = () => {
    this.setState({ step: 'ready' });
  }

  render() {
    let p = this.props;
    return (
      <Container>
        {(this.state.step == 'loading' || this.state.step == 'saving') && <Loader/>}
        {this.state.step != 'loading' && this.state.step != 'loadError' && this.state.step != 'error' && 
        <React.Fragment>
          <Content>
            <CommunityForm auth={p.auth} dirty={this.state.dirty} data={this.state.community} onChange={this.handleChange} onValidate={this.handleValidate}/>
          </Content>
          <BottomBar>
            <Button primary onClick={this.handleSubmit}><Icon name="save"/> <Loc msg="btn_save"/></Button>
            <Button secondary onClick={() => p.history.goBack()}><Loc msg="btn_cancel"/></Button>
          </BottomBar>
        </React.Fragment>}
        {this.state.step == 'error' && <Content>
          <Section padded>
            <Message type="error"><Loc msg="msg_data_not_found"/></Message>
          </Section>
        </Content>}
        <Dialog.Xhr open={this.state.step == 'loadError'} error={this.state.error} onClose={this.handleCancelLoad} onRetry={this.handleRetryLoad}/>
        <Dialog.Xhr open={this.state.step == 'saveError'} error={this.state.error} onClose={this.handleCancelSave} onRetry={this.handleSubmit}/>
      </Container>
    );
  }
}

export { EditCommunity };
