import * as React from 'react';
import { Community, CommunityFactory, Household, HouseholdFactory } from '../../resource/'; 
import { IAuthProps, List, IListState, IListProps, SearchFilter, Query } from '../../services/';
import { IconBar, Export, Loc } from '../../modules';

import { Form } from '@independent-software/typeui/controls/Form';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { DataTable } from '@independent-software/typeui/controls/DataTable';
import { Number } from '@independent-software/typeui/formatters/Number';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { TLocalization } from '../../modules/Loc/TLocalization';
import { Label } from '@independent-software/typeui/controls/Label';

interface IProps extends IListProps {
  /**
   * Event is fired when an issue type is clicked.
   */
  onClick?: (household: Household) => void;
  /**
   * If set, list is prefiltered by a community and
   * community column and filter are hidden.
   */
  communityId?: number;  
}

interface IState extends IListState<Household> {
  /**
   * Data for communities dropdown
   */
  communities: Community[];  
}

class ListHouseholds extends List<Household, IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props, HouseholdFactory, 'name', 'asc');

    // Initialize state (list initializes its own part of the state):
    this.state = {
      ...this.state,
      communities: []
    };

    // The community stored in the Query is an ID, not a Community.
    // Is the list prefiltered by a community? Then set a filter.
    if(this.props.communityId) {
      this.setFilter('community', 'eq', { id: this.props.communityId }, true);
    }
  }

  componentDidMount() {
    this.handleCommunitySearch();    
  }

  handleSearch = (value:string) => {
    this.setFilter('q', 'like', value);
  }  

  handleChangeCommunity = (value: Community) => {
    this.setFilter('community', 'eq', value);
  }

  handleCommunitySearch = (q?:string) => {
    // Retrieve a list of communities:
    let query = new Query('name', 'asc');
    if (q) query.setFilter('q', 'like', q);
    CommunityFactory.getSome(this.props.auth, 0, 999, query)
      .then((res) => this.setState({ communities: res.items }));
  }    

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Form.Uncontrolled hint={<Loc msg="households_filter_search_hint"/>}>
          <SearchFilter value={this.getFilter('q', 'like')} onSearch={this.handleSearch}/>
        </Form.Uncontrolled>
        {!p.communityId && 
          <Form.Uncontrolled hint={<Loc msg="households_filter_community_hint"/>}>
            <Dropdown onSearch={this.handleCommunitySearch} name="community" fluid clearable value={this.getFilter('community', 'eq')} data={this.state.communities} placeholder="Community" label={(item:Community) => item.name} onChange={this.handleChangeCommunity}>
              <Dropdown.Column>{(item:Community) => item.name}</Dropdown.Column>
            </Dropdown>
          </Form.Uncontrolled>}        
      </Panel.Content>
      <Panel.Footer>
        <Export onExport={this.handleExport}/>
      </Panel.Footer>      
    </React.Fragment>

    return (
      <React.Fragment>
        <IconBar>
          <Panel.Icon icon="tools" width={300}>
            {filter}
          </Panel.Icon>
        </IconBar> 
        <DataTable error={this.state.error} loading={this.state.loading} scrollTop={this.state.scrollTop} onScroll={this.handleScroll} data={this.state.items} onFetch={this.handleFetch} onClick={p.onClick} onOrder={this.handleOrder} order={this.getOrder()} dir={this.getDir()}>
          <DataTable.Column weight={1} label={<Loc msg="households_list_hdr_code"/>} order="code" dir="asc">{(item:Household) => <Label>{item.code}</Label>}</DataTable.Column>
          <DataTable.Column weight={5} label={<Loc msg="households_list_hdr_name"/>} order="name" dir="asc">{(item:Household) => <>{item.name} ({item.hhh_name})</>}</DataTable.Column>
          <DataTable.Column weight={1} label={<Loc msg="households_list_hdr_state"/>} order="state" dir="asc">{(item:Household) => <Loc msg={("households_states_" + item.state) as TLocalization}/>}</DataTable.Column>
          {!p.communityId && <DataTable.Column weight={2} label={<Loc msg="households_list_hdr_community"/>} order="community" dir="asc">{(item:Household) => item.community ? item.community.name : 'None'}</DataTable.Column>}
          <DataTable.Column weight={1} align="right" label={<Loc msg="households_list_hdr_actions"/>} order="actions_count" dir="asc">{(item:Household) => <Number value={item.actions_count} decimals={0}/>}</DataTable.Column>
        </DataTable>
        <Dialog.Xhr open={this.state.exportError != null} error={this.state.exportError} onClose={this.handleCloseDialog}/>
      </React.Fragment>
    );
  }
}

export { ListHouseholds };
