import { BaseRoute, SecureRoute, PublicRoute } from './Route';

import { Dashboard } from '../components/Dashboard';
import { Account } from '../components/Account';
import { LogList } from '../components/Log';
import { SignIn } from '../components/Signin/Signin';
import { ResetPassword } from '../components/ResetPassword/ResetPassword';
import { UserList, ViewUser, AddUser, EditUser } from '../components/User';
import { ProfileList, ViewProfile, AddProfile, EditProfile  } from '../components/Profile';
import { ResponseList, ViewResponse, AddResponse, EditResponse } from '../components/Response';
import { CommunityList, ViewCommunity, AddCommunity, EditCommunity } from '../components/Community';
import { ViewSurvey, EditSurvey, AddSurvey, SurveyList } from '../components/Survey';
import { AttachmentList, ViewAttachment } from '../components/Attachment';
import { ActionList, ViewAction, AddAction, EditAction } from '../components/Action';
import { GrievanceList, ViewGrievance, AddGrievance, EditGrievance } from '../components/Grievance';
import { RequestList, ViewRequest, AddRequest, EditRequest } from '../components/Request'
import { DistrictList, ViewDistrict, AddDistrict, EditDistrict} from '../components/District';
import { StakeholderList, AddStakeholder, EditStakeholder, ViewStakeholder } from '../components/Stakeholder';
import { PostList } from '../components/Post';
import { IssuetypeList, AddIssuetype, EditIssuetype, ViewIssuetype } from '../components/Issuetype';
import { GrievanceAnalysis } from '../components/Analysis/GrievanceAnalysis';
import { AddIssuecategory, EditIssuecategory, IssuecategoryList, ViewIssuecategory } from '../components/Issuecategory';
import { ActiontypeList, AddActiontype, EditActiontype, ViewActiontype } from '../components/Actiontype';
import { AddHousehold, EditHousehold, HouseholdList, ViewHousehold } from '../components/Household';
import { ActionAnalysis } from '../components/Analysis/ActionAnalysis';
import { SurveyAnalysis, SurveySeries } from '../components/SurveyAnalysis';

// 
// Routes are named so that they can be referred to in a Menu.
// 
interface IRoutes {
  [key:string]: BaseRoute
} 

const ROUTES: IRoutes = {
  users_list:        new SecureRoute('can_view_users', '/users', 'users_title_list', UserList),
  users_add:         new SecureRoute('can_edit_users', "/users/add", "users_title_add", AddUser),
  users_edit:        new SecureRoute('can_edit_users', "/users/edit/:id", "users_title_edit", EditUser),
  users_view:        new SecureRoute('can_view_users', "/users/:id", "users_title_view", ViewUser),
  
  profiles_list:     new SecureRoute('can_view_profiles', "/profiles",  "profiles_title_list",  ProfileList),
  profiles_add:      new SecureRoute('can_edit_profiles', "/profiles/add", "profiles_title_add", AddProfile),
  profiles_edit:     new SecureRoute('can_edit_profiles', "/profiles/edit/:id", "profiles_title_edit", EditProfile),
  profiles_view:     new SecureRoute('can_view_profiles', "/profiles/:id", "profiles_title_view", ViewProfile),

  logs_list:         new SecureRoute('can_view_logs', "/logs", "logs_title_list", LogList),

  responses_list:    new SecureRoute('can_view_responses', "/responses", "responses_title_list", ResponseList),
  responses_add:     new SecureRoute('can_edit_responses', "/responses/add", "responses_title_add", AddResponse),
  responses_edit:    new SecureRoute('can_edit_responses', "/responses/edit/:id", "responses_title_edit", EditResponse),
  responses_view:    new SecureRoute('can_view_responses', "/responses/:id", "responses_title_view", ViewResponse),

  communities_list:  new SecureRoute('can_view_communities', "/communities", "communities_title_list", CommunityList),
  communities_add:   new SecureRoute('can_edit_communities', "/communities/add", "communities_title_add", AddCommunity),
  communities_edit:  new SecureRoute('can_edit_communities', "/communities/edit/:id", "communities_title_edit", EditCommunity),
  communities_view:  new SecureRoute('can_view_communities', "/communities/:id", "communities_title_view", ViewCommunity),

  surveys_list:      new SecureRoute('can_view_surveys', "/surveys", "surveys_title_list", SurveyList),
  surveys_add:       new SecureRoute('can_edit_surveys', "/surveys/add", "surveys_title_add", AddSurvey),
  surveys_edit:      new SecureRoute('can_edit_surveys', "/surveys/edit/:id", "surveys_title_edit", EditSurvey),
  surveys_view:      new SecureRoute('can_view_surveys', "/surveys/:id", "surveys_title_view", ViewSurvey),  

  attachments_list:  new SecureRoute('can_view_attachments', "/attachments", "attachments_title_list", AttachmentList),
  attachments_view:  new SecureRoute('can_view_attachments', "/attachments/:id", "attachments_title_view", ViewAttachment),

  actions_list:      new SecureRoute('can_view_actions', "/actions", "actions_title_list", ActionList),
  actions_add:       new SecureRoute('can_edit_actions', "/actions/add", "actions_title_add", AddAction),
  actions_edit:      new SecureRoute('can_edit_actions', "/actions/edit/:id", "actions_title_edit", EditAction),
  actions_view:      new SecureRoute('can_view_actions', "/actions/:id", "actions_title_view", ViewAction),

  grievances_list:   new SecureRoute('can_view_grievances', "/grievances", "grievances_title_list", GrievanceList),
  grievances_add:    new SecureRoute('can_edit_grievances', "/grievances/add", "grievances_title_add", AddGrievance),
  grievances_edit:   new SecureRoute('can_edit_grievances', "/grievances/edit/:id", "grievances_title_edit", EditGrievance),
  grievances_view:   new SecureRoute('can_view_grievances', "/grievances/:id", "grievances_title_view", ViewGrievance),

  requests_list:     new SecureRoute('can_view_requests', "/requests", "requests_title_list", RequestList),
  requests_add:      new SecureRoute('can_edit_requests', "/requests/add", "requests_title_add", AddRequest),
  requests_edit:     new SecureRoute('can_edit_requests', "/requests/edit/:id", "requests_title_edit", EditRequest),
  requests_view:     new SecureRoute('can_view_requests', "/requests/:id", "requests_title_view", ViewRequest),
  
  districts_list:    new SecureRoute('can_view_districts', "/districts", "districts_title_list", DistrictList),
  districts_add:     new SecureRoute('can_edit_districts', "/districts/add", "districts_title_add", AddDistrict),
  districts_edit:    new SecureRoute('can_edit_districts', "/districts/edit/:id", "districts_title_edit", EditDistrict),
  districts_view:    new SecureRoute('can_view_districts', "/districts/:id", "districts_title_view", ViewDistrict),

  stakeholders_list: new SecureRoute('can_view_stakeholders', "/stakeholders", "stakeholders_title_list", StakeholderList),
  stakeholders_add:  new SecureRoute('can_edit_stakeholders', "/stakeholders/add", "stakeholders_title_add", AddStakeholder),
  stakeholders_edit: new SecureRoute('can_edit_stakeholders', "/stakeholders/edit/:id", "stakeholders_title_edit", EditStakeholder),
  stakeholders_view: new SecureRoute('can_view_stakeholders', "/stakeholders/:id", "stakeholders_title_view", ViewStakeholder),

  issuetypes_list:   new SecureRoute('can_view_issuetypes', "/issuetypes", "issuetypes_title_list", IssuetypeList),
  issuetypes_add:    new SecureRoute('can_edit_issuetypes', "/issuetypes/add", "issuetypes_title_add", AddIssuetype),
  issuetypes_edit:   new SecureRoute('can_edit_issuetypes', "/issuetypes/edit/:id", "issuetypes_title_edit", EditIssuetype),
  issuetypes_view:   new SecureRoute('can_view_issuetypes', "/issuetypes/:id", "issuetypes_title_view", ViewIssuetype),  

  issuecategories_list: new SecureRoute('can_view_issuetypes', "/issuecategories", "issuecategories_title_list", IssuecategoryList),
  issuecategories_add:  new SecureRoute('can_edit_issuetypes', "/issuecategories/add", "issuecategories_title_add", AddIssuecategory),
  issuecategories_edit: new SecureRoute('can_edit_issuetypes', "/issuecategories/edit/:id", "issuecategories_title_edit", EditIssuecategory),
  issuecategories_view: new SecureRoute('can_view_issuetypes', "/issuecategories/:id", "issuecategories_title_view", ViewIssuecategory),  

  // posts_list:        new SecureRoute('can_view_logs', "/posts", "posts_title_list", PostList),

  analysis_grievances: new SecureRoute('can_view_grievances', "/analysis-grievances", "analysis_grievances_title", GrievanceAnalysis),
  analysis_actions:    new SecureRoute('can_view_actions', "/analysis-actions", "analysis_actions_title", ActionAnalysis), 

  analysis_surveys:    new SecureRoute('can_view_surveys', "/analysis-surveys", "analysis_surveys_title", SurveyAnalysis),
  analysis_series:     new SecureRoute('can_view_surveys', "/analysis-series", "analysis_series_title", SurveySeries),

  actiontypes_list:  new SecureRoute('can_view_actiontypes', "/actiontypes", "actiontypes_title_list", ActiontypeList),
  actiontypes_add:   new SecureRoute('can_edit_actiontypes', "/actiontypes/add", "actiontypes_title_add", AddActiontype),
  actiontypes_edit:  new SecureRoute('can_edit_actiontypes', "/actiontypes/edit/:id", "actiontypes_title_edit", EditActiontype),
  actiontypes_view:  new SecureRoute('can_view_actiontypes', "/actiontypes/:id", "actiontypes_title_view", ViewActiontype),    

  households_list:   new SecureRoute('can_view_households', "/households", "households_title_list", HouseholdList),
  households_add:    new SecureRoute('can_edit_households', "/households/add", "households_title_add", AddHousehold),
  households_edit:   new SecureRoute('can_edit_households', "/households/edit/:id", "households_title_edit", EditHousehold),
  households_view:   new SecureRoute('can_view_households', "/households/:id", "households_title_view", ViewHousehold),      

  reset:             new PublicRoute("/reset", "reset_password_title", ResetPassword),
  account:           new SecureRoute(null, "/account", "account_title", Account),
  dashboard:         new SecureRoute(null, "/dashboard", "dashboard_title", Dashboard),
  signin:            new PublicRoute("", "signin_title", SignIn),
};

export { ROUTES };
