import * as React from 'react';
import { Action, ActionFactory, Actiontype, ActiontypeFactory, Community, CommunityFactory, Household, HouseholdFactory } from '../../resource/'; 
import { IAuthProps, List, IListState, IListProps, SearchFilter, Query } from '../../services/';
import { IconBar, Export, Loc } from '../../modules';

import { Form } from '@independent-software/typeui/controls/Form';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { DataTable } from '@independent-software/typeui/controls/DataTable';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { TLocalization } from '../../modules/Loc/TLocalization';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Header } from '@independent-software/typeui/controls/Header';
import { Number } from '@independent-software/typeui/formatters/Number';
import { Label } from '@independent-software/typeui/controls/Label';
import { LocalizedDatum } from '../../formatters';
import { Divider } from '@independent-software/typeui/controls/Divider';
import { Button } from '@independent-software/typeui/controls/Button';

type TColumn = 'title' | 'enddate' | 'responsibility' | 'actiontype' | 'attachments' | 'duration';

interface IListActionsProps extends IListProps {
  /**
   * If set, list is prefiltered by a community and
   * community column and filter are hidden.
   */
  communityId?: number;
  householdId?: number;
  delivery?: string;
  actiontypeId?: number;
  /*
   * If set, list is prefiltered with pending actions only.
   * Filter will be hidden.
   */
  pending?: boolean;
  /*
   * If set, list is prefiltered with unresolved actions only.
   * Filter will be hidden.
   */
  unresolved?: boolean;  
  /**
   * Event is fired when an action is clicked.
   */
  onClick?: (action: Action) => void;
}

interface IListActionsState extends IListState<Action> {
  /**
   * Data for communities dropdown
   */
  communities: Community[];  
  /**
   * Data for households dropdown
   */
  households: Household[];  
  /**
   * Data for actiontypes dropdown
   */
  actiontypes: Actiontype[];
  /**
   * Columns currently shown.
   */
  columns: string[];
}

class ListActions extends List<Action, IListActionsProps, IListActionsState> {
  constructor(props: IAuthProps & IListActionsProps) {
    super(props, ActionFactory, 'title', 'asc');

    // Get activated table columns from local storage.
    let columns: string[] = 
      JSON.parse(localStorage.getItem("listactions_columns")) as string[];
    if(!columns) columns = [];

    // Initialize state (list initializes its own part of the state):
    this.state = {
      ...this.state,
      communities: [],
      households: [],
      actiontypes: [],
      columns: columns
    };

    // The community stored in the Query is an ID, not a Community.
    // Is the list prefiltered by a community? Then set a filter.
    if(this.props.communityId) {
      this.setFilter('community', 'eq', { id: this.props.communityId }, true);
    }

    if(this.props.householdId) {
      this.setFilter('household', 'eq', { id: this.props.householdId }, true);
    }    

    if(this.props.delivery) {
      this.setFilter('delivery', 'eq', { id: this.props.delivery }, true);
    }

    if(this.props.actiontypeId) {
      this.setFilter('actiontype', 'eq', { id: this.props.actiontypeId }, true);
    }

    if(this.props.pending) {
      this.setFilter('newstate', 'neq', 0, true);
    }

    if(this.props.unresolved) {
      this.setFilter('state', 'eq', 'open', true);
    }
  }

  componentDidMount() {
    this.handleCommunitySearch();    
    this.handleHouseholdSearch();    
    this.handleActiontypeSearch();
  }

  handleSearch = (value:string) => {
    this.setFilter('q', 'like', value);
  }  

  handleChangeCommunity = (value: Community) => {
    this.setFilter('community', 'eq', value);
  }

  handleCommunitySearch = (q?:string) => {
    // Retrieve a list of communities:
    let query = new Query('name', 'asc');
    if (q) query.setFilter('q', 'like', q);
    CommunityFactory.getSome(this.props.auth, 0, 999, query)
      .then((res) => this.setState({ communities: res.items }));
  }  

  handleChangeHousehold = (value: Household) => {
    this.setFilter('household', 'eq', value);
  }

  handleHouseholdSearch = (q?:string) => {
    // Retrieve a list of households:
    let query = new Query('hhh_name', 'asc');
    if (q) query.setFilter('q', 'like', q);
    HouseholdFactory.getSome(this.props.auth, 0, 12, query)
      .then((res) => this.setState({ households: res.items }));
  }  

  handleChangeDelivery = (value: string) => {
    this.setFilter('delivery', 'eq', value);
  }

  handleChangeState = (value: string) => {
    this.setFilter('state', 'eq', value);
  }

  handleChangeActiontype = (value: Actiontype) => {
    this.setFilter('actiontype', 'eq', value);
  }

  handleActiontypeSearch = (q?:string) => {
    // Retrieve a list of actiontypes:
    let query = new Query('name', 'asc');
    if (q) query.setFilter('q', 'like', q);
    ActiontypeFactory.getSome(this.props.auth, 0, 999, query)
      .then((res) => this.setState({ actiontypes: res.items }));
  }  

  handleChangeResponsibility = (value: string) => {
    this.setFilter('responsibility', 'eq', value);
  }

  handleChangePhase = (value: string) => {
    this.setFilter('phase', 'eq', value);
  }

  handleChangePolygamous = (value: boolean) => {
    this.setFilter("polygamous", 'eq', value ? 1 : null);
  }

  handleChangeMonoparental = (value: boolean) => {
    this.setFilter("monoparental", 'eq', value ? 1 : null);
  }

  handleChangeINAS = (value: boolean) => {
    this.setFilter("inas", 'eq', value ? 1 : null);
  }

  handleChangeFilterPending = (value: boolean) => {
    this.setFilter('newstate', 'neq', value ? 0 : null);
  }

  handleChangeFilterState = (value: string) => {
    if(value == 'closed') this.setFilter('state', 'eq', 'closed');
    else if(value == 'open') this.setFilter('state', 'eq', 'open');
    else this.setFilter('state', 'eq', null);
  }

  hasColumn = (column: TColumn) => {
    return this.state.columns.includes(column);
  }

  handleToggleColumn = (column: TColumn, value: boolean) => {
    let idx = this.state.columns.indexOf(column);
    if(idx != -1) this.state.columns.splice(idx, 1);
    if(value) this.state.columns.push(column);
    localStorage.setItem("listactions_columns", JSON.stringify(this.state.columns));
    this.setState({
      columns: this.state.columns,
      ...this.state
    });
  }

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Flex>
          <Flex.Row>
            <Flex.Column width={2}>
              <Header size='h3' dividing>Columns</Header>
              <Flex.Columns count={2}>
                <>
                  <Form.Uncontrolled><Checkbox label={<Loc msg="actions_column_title"/>} type="slider" checked={this.hasColumn("title")} onChange={(value: boolean) => this.handleToggleColumn("title", value)}/></Form.Uncontrolled>
                  <Form.Uncontrolled><Checkbox label={<span style={{whiteSpace: 'nowrap'}}><Loc msg="actions_column_enddate"/></span>} type="slider" checked={this.hasColumn("enddate")} onChange={(value: boolean) => this.handleToggleColumn("enddate", value)}/></Form.Uncontrolled>
                  <Form.Uncontrolled><Checkbox label={<Loc msg="actions_column_duration"/>} type="slider" checked={this.hasColumn("duration")} onChange={(value: boolean) => this.handleToggleColumn("duration", value)}/></Form.Uncontrolled>
                </>
                <>
                  <Form.Uncontrolled><Checkbox label={<Loc msg="actions_column_responsibility"/>} type="slider" checked={this.hasColumn("responsibility")} onChange={(value: boolean) => this.handleToggleColumn("responsibility", value)}/></Form.Uncontrolled>
                  <Form.Uncontrolled><Checkbox label={<Loc msg="actions_column_actiontype"/>} type="slider" checked={this.hasColumn("actiontype")} onChange={(value: boolean) => this.handleToggleColumn("actiontype", value)}/></Form.Uncontrolled>
                  <Form.Uncontrolled><Checkbox label={<Loc msg="actions_column_attachments"/>} type="slider" checked={this.hasColumn("attachments")} onChange={(value: boolean) => this.handleToggleColumn("attachments", value)}/></Form.Uncontrolled>
                </>
              </Flex.Columns>
              {!p.householdId && !p.communityId && <React.Fragment>
                <Header size='h3' dividing>Vulnerabilities</Header>
                <Form.Uncontrolled><Checkbox type="slider" checked={this.getFilter('vulnerability_singleheaded', 'eq')} onChange={(v: boolean) => this.setFilter("vulnerability_singleheaded", 'eq', v ? 1 : null)} label={<Loc msg="households_vulnerabilities_singleheaded"/>}/></Form.Uncontrolled>
                <Form.Uncontrolled><Checkbox type="slider" checked={this.getFilter('vulnerability_femaleheaded', 'eq')} onChange={(v: boolean) => this.setFilter("vulnerability_femaleheaded", 'eq', v ? 1 : null)} label={<Loc msg="households_vulnerabilities_femaleheaded"/>}/></Form.Uncontrolled>
                <Form.Uncontrolled><Checkbox type="slider" checked={this.getFilter('vulnerability_orphan', 'eq')} onChange={(v: boolean) => this.setFilter("vulnerability_orphan", 'eq', v ? 1 : null)} label={<Loc msg="households_vulnerabilities_orphan"/>}/></Form.Uncontrolled>
                <Form.Uncontrolled><Checkbox type="slider" checked={this.getFilter('vulnerability_elderly', 'eq')} onChange={(v: boolean) => this.setFilter("vulnerability_elderly", 'eq', v ? 1 : null)} label={<Loc msg="households_vulnerabilities_elderly"/>}/></Form.Uncontrolled>
                <Form.Uncontrolled><Checkbox type="slider" checked={this.getFilter('vulnerability_mental', 'eq')} onChange={(v: boolean) => this.setFilter("vulnerability_mental", 'eq', v ? 1 : null)} label={<Loc msg="households_vulnerabilities_mental"/>}/></Form.Uncontrolled>
                <Form.Uncontrolled><Checkbox type="slider" checked={this.getFilter('vulnerability_physical', 'eq')} onChange={(v: boolean) => this.setFilter("vulnerability_physical", 'eq', v ? 1 : null)} label={<Loc msg="households_vulnerabilities_physical"/>}/></Form.Uncontrolled>
                <Form.Uncontrolled><Checkbox type="slider" checked={this.getFilter('vulnerability_illness', 'eq')} onChange={(v: boolean) => this.setFilter("vulnerability_illness", 'eq', v ? 1 : null)} label={<Loc msg="households_vulnerabilities_illness"/>}/></Form.Uncontrolled>
                <Form.Uncontrolled><Checkbox type="slider" checked={this.getFilter('vulnerability_income', 'eq')} onChange={(v: boolean) => this.setFilter("vulnerability_income", 'eq', v ? 1 : null)} label={<Loc msg="households_vulnerabilities_income"/>}/></Form.Uncontrolled>
              </React.Fragment>}
              <Divider hidden/>
              {!p.householdId && !p.communityId && <React.Fragment>
                <Checkbox type="slider" checked={this.getFilter('polygamous', 'eq')} onChange={this.handleChangePolygamous} label={<Loc msg="actions_filter_polygamous"/>}/>
                <Checkbox type="slider" checked={this.getFilter('inas', 'eq')} onChange={this.handleChangeINAS} label={<Loc msg="actions_filter_inas"/>}/>
              </React.Fragment>}
            </Flex.Column>
            <Flex.Column width={3}>
              <Header size='h3' dividing>Filters</Header>
              <Form.Uncontrolled hint={<Loc msg="actions_filter_search_hint"/>}>
                <SearchFilter value={this.getFilter('q', 'like')} onSearch={this.handleSearch}/>
              </Form.Uncontrolled>
              {!p.communityId && !p.communityId && 
                <Form.Uncontrolled hint={<Loc msg="actions_filter_community_hint"/>}>
                  <Dropdown onSearch={this.handleCommunitySearch} name="community" fluid clearable value={this.getFilter('community', 'eq')} data={this.state.communities} placeholder="Community" label={(item:Community) => item.name} onChange={this.handleChangeCommunity}>
                    <Dropdown.Column>{(item:Community) => item.name}</Dropdown.Column>
                  </Dropdown>
                </Form.Uncontrolled>}
              {!p.householdId && !p.communityId && 
                <Form.Uncontrolled hint={<Loc msg="actions_filter_household_hint"/>}>
                  <Dropdown onSearch={this.handleHouseholdSearch} name="household" fluid clearable value={this.getFilter('household', 'eq')} data={this.state.households} placeholder="Household" label={(item:Household) => item.hhh_name} onChange={this.handleChangeHousehold}>
                    <Dropdown.Column weight={1}>{(item:Household) => item.code}</Dropdown.Column>
                    <Dropdown.Column weight={5}>{(item:Household) => item.hhh_name}</Dropdown.Column>
                  </Dropdown>
                </Form.Uncontrolled>}          
              {!p.delivery && 
                <Form.Uncontrolled hint={<Loc msg="actions_filter_delivery_hint"/>}>
                  <Dropdown name="delivery" fluid clearable value={this.getFilter('delivery', 'eq')} data={['cash','kind','specific','benefits','livelihood','unplanned']} placeholder="Delivery" label={(item:string) => <Loc msg={("actiontypes_delivery_" + item) as TLocalization}/>} onChange={this.handleChangeDelivery}>
                    <Dropdown.Column>{(item:string) => <Loc msg={("actiontypes_delivery_" + item) as TLocalization}/>}</Dropdown.Column>
                  </Dropdown>
                </Form.Uncontrolled>}          
              {!p.actiontypeId && 
                <Form.Uncontrolled hint={<Loc msg="actions_filter_actiontype_hint"/>}>
                  <Dropdown onSearch={this.handleActiontypeSearch} name="actiontype" fluid clearable value={this.getFilter('actiontype', 'eq')} data={this.state.actiontypes} placeholder="Action type" label={(item:Actiontype) => item.name} onChange={this.handleChangeActiontype}>
                    <Dropdown.Column weight={1}>{(item:Actiontype) => <Loc msg={("actiontypes_delivery_" + item.delivery) as TLocalization}/>}</Dropdown.Column>
                    <Dropdown.Column weight={4}>{(item:Actiontype) => item.name}</Dropdown.Column>
                  </Dropdown>
                </Form.Uncontrolled>}
              <Form.Uncontrolled hint={<Loc msg="actions_filter_responsibility_hint"/>}>
                <Dropdown name="responsibility" fluid clearable value={this.getFilter('responsibility', 'eq')} data={['pnl','government','provider']} placeholder="Responsibility" label={(item:string) => <Loc msg={("actions_responsibility_" + item) as TLocalization}/>} onChange={this.handleChangeResponsibility}>
                  <Dropdown.Column>{(item:string) => <Loc msg={("actions_responsibility_" + item) as TLocalization}/>}</Dropdown.Column>
                </Dropdown>
              </Form.Uncontrolled>
              <Form.Uncontrolled hint={<Loc msg="actions_filter_phase_hint"/>}>
                <Dropdown name="phase" fluid clearable value={this.getFilter('phase', 'eq')} data={['prerelocation','relocation','postrelocation']} placeholder="Phase" label={(item:string) => <Loc msg={("actions_phases_" + item) as TLocalization}/>} onChange={this.handleChangePhase}>
                  <Dropdown.Column>{(item:string) => <Loc msg={("actions_phases_" + item) as TLocalization}/>}</Dropdown.Column>
                </Dropdown>
              </Form.Uncontrolled>              

              {!p.pending && <Form.Uncontrolled hint="">
                <Checkbox label={<Loc msg="actions_filter_only_pending_hint"/>} name='empty' type="toggle" checked={this.getFilter('newstate', 'neq') != null} onChange={this.handleChangeFilterPending}/>
              </Form.Uncontrolled>}
              {!p.unresolved && <Form.Uncontrolled hint="">
                <Button.Group size="small">
                  <Button noripple secondary={this.getFilter('state', 'eq') == null} onClick={() => this.handleChangeFilterState(null)}><Loc msg="actions_filter_btn_all"/></Button>
                  <Button noripple secondary={this.getFilter('state', 'eq') == 'open'} onClick={() => this.handleChangeFilterState('open')}><Loc msg="actions_filter_btn_open"/></Button>
                  <Button noripple secondary={this.getFilter('state', 'eq') == 'closed'} onClick={() => this.handleChangeFilterState('closed')}><Loc msg="actions_filter_btn_closed"/></Button>
                </Button.Group>
              </Form.Uncontrolled>}


            </Flex.Column>
          </Flex.Row>
        </Flex>
      </Panel.Content>
      <Panel.Footer>
        <Export onExport={this.handleExport}/>
      </Panel.Footer>      
    </React.Fragment>

    return (
      <React.Fragment>
        <IconBar>
          <Panel.Icon icon="tools" width={800}>
            {filter}
          </Panel.Icon>
        </IconBar> 
        <DataTable error={this.state.error} loading={this.state.loading} scrollTop={this.state.scrollTop} onScroll={this.handleScroll} data={this.state.items} onFetch={this.handleFetch} onClick={p.onClick} onOrder={this.handleOrder} order={this.getOrder()} dir={this.getDir()}>
          {this.hasColumn("title") && <DataTable.Column weight={5} label={<Loc msg="actions_list_hdr_title"/>} order="title" dir="asc">{(item:Action) => <span>{item.title}</span>}</DataTable.Column>}
          <DataTable.Column weight={1} label={<Loc msg="actions_list_hdr_startdate"/>} order="startdate" dir="asc">{(item:Action) => <LocalizedDatum.MediumDate value={item.startdate}/>}</DataTable.Column>
          {this.hasColumn("enddate") && <DataTable.Column weight={1} label={<Loc msg="actions_list_hdr_enddate"/>} order="enddate" dir="asc">{(item:Action) => item.enddate ? <LocalizedDatum.MediumDate value={item.enddate}/> : "-"}</DataTable.Column>}
          {this.hasColumn("duration") && <DataTable.Column weight={1} label={<Loc msg="actions_list_hdr_duration"/>} order="duration" dir="asc">{(item:Action) => item.duration ? <React.Fragment><Number value={item.duration} decimals={0}/>d</React.Fragment> : "-"}</DataTable.Column>}
          {!p.householdId && !p.communityId && <DataTable.Column weight={3} label={<Loc msg="actions_list_hdr_household"/>} order="household" dir="asc">{(item:Action) => item.household ? <React.Fragment><Label size="small">{item.household.code}</Label> {item.household.name} ({item.household.hhh_name})</React.Fragment> : '-'}</DataTable.Column>}
          {!p.communityId && !p.householdId && <DataTable.Column weight={3} label={<Loc msg="actions_list_hdr_community"/>} order="community" dir="asc">{(item:Action) => item.community ? item.community.name : '-'}</DataTable.Column>}
          {!p.delivery && <DataTable.Column weight={2} label={<Loc msg="actions_list_hdr_delivery"/>} order="delivery" dir="asc">{(item:Action) => item.delivery ? <Loc msg={("actiontypes_delivery_" + item.delivery) as TLocalization}/> : '-'}</DataTable.Column>}
          {!p.actiontypeId && this.hasColumn("actiontype") && <DataTable.Column weight={5} label={<Loc msg="actions_list_hdr_actiontype"/>} order="actiontype" dir="asc">{(item:Action) => item.actiontype ? item.actiontype.name : '-'}</DataTable.Column>}
          <DataTable.Column weight={1} label={<Loc msg="actions_list_hdr_resolved"/>}>{(item:Action) => <div style={{textAlign: 'center'}}>{item.state == 'closed' ? <Icon name="check" color="green" circular inverted/> : <Icon name="times" color="darkred" circular inverted/>}</div>}</DataTable.Column>
          {this.hasColumn("responsibility") && <DataTable.Column weight={1} label={<Loc msg="actions_list_hdr_responsibility"/>}>{(item:Action) => item.responsibility ? <Loc msg={("actions_responsibility_" + item.responsibility) as TLocalization}/> : '-'}</DataTable.Column>}
        </DataTable>
        <Dialog.Xhr open={this.state.exportError != null} error={this.state.exportError} onClose={this.handleCloseDialog}/>
      </React.Fragment>
    );
  }
}

export { ListActions };
