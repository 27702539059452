import { StampedModel, ResourceFactory } from '../services/';
import { Attachment } from './Attachment';
import { Post } from './Post';

type TActionStates = 
  "created"
| "open"
| "closed";

interface IActionStep {
  state: TActionStates;
  type: "move" | "validation" | "rollback";
  created_at: string;
  created_by: {
    id: number;
    name: string;
    email: string;
  }
}

interface IActionFile {
  id: number;
  filename: string;
  ext: string;
  is_image: boolean;
  size: number;
}

class Action extends StampedModel {
  public title: string = '';
  public state: TActionStates;
  public newstate: TActionStates;
  public level: string = '';
  public delivery: string = '';
  public responsibility: string = '';
  public serviceprovider: string = '';
  public phase: string = '';
  public startdate: string = '';
  public enddate: string = '';
  public description: string = '';
  public attachments_count: number = 0;
  public duration: number = 0;
  public actiontype: {
    id: number;
    name: string;
  } = null;
  public community: { 
    id: number;
    name: string;
  } = null;
  public household: { 
    id: number;
    name: string;
    hhh_name: string;
    code: string;
  } = null;  
  public steps: IActionStep[];
  public files: IActionFile[] = [];
  public attachments: Attachment[] = [];
  public posts: Post[] = [];
}

const ActionFactory = ResourceFactory.create<Action>(Action, 'action');

export { Action, ActionFactory, IActionFile, TActionStates, IActionStep };