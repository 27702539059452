import * as React from 'react';
import { css } from 'styled-components';
import styled from '@independent-software/typeui/styles/Theme'
import { Button } from '@independent-software/typeui/controls/Button'
import { Icon } from '@independent-software/typeui/controls/Icon'
import { TChartType } from './Chart';

interface IProps {
  className?: string;
  type: TChartType;
  onChange: (type: TChartType) => void;
}

class TypeSwitchBase extends React.Component<IProps, {}> {
  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        <Button.Group size="small">
          <Button icon noripple secondary={p.type == 'line'} onClick={() => p.onChange('line')}><Icon name='line-chart'/></Button>
          <Button icon noripple secondary={p.type == 'bar'}  onClick={() =>  p.onChange('bar')}><Icon name='bar-chart'/></Button>
          <Button icon noripple secondary={p.type == 'area'} onClick={() => p.onChange('area')}><Icon name='area-chart'/></Button>
        </Button.Group>      
      </div>
    )
  }
}

const TypeSwitch = styled(TypeSwitchBase)`
  padding-bottom: 6px;
`

export { TypeSwitch };