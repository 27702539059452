import * as React from 'react';
import { Loc } from '../../modules';
import { Post, PostFactory } from '../../resource/';
import { IAuthProps } from '../../services/Auth';

import { Button } from '@independent-software/typeui/controls/Button';
import { Form } from '@independent-software/typeui/controls/Form';
import { MarkdownTextarea } from '@independent-software/typeui/modules/MarkdownTextarea';
import { Segment } from '@independent-software/typeui/controls/Segment';
import { Input } from '@independent-software/typeui/controls/Input';

interface IProps {
  type: string;
  type_id: number;
  onSave: (post: Post) => void;
}

interface IState {
  active: boolean;
  post: Post;
  isValid: boolean;
  dirty?: boolean;
}

class AddPost extends React.Component<IAuthProps & IProps, IState> {

  private post: Post;

  constructor(props: IAuthProps & IProps) {
    super(props);
    this.post = PostFactory.create();
    this.post.type = props.type;
    this.post.type_id = props.type_id;
    this.state = {
      active: false,
      isValid: false,
      post: this.post
    };
  }

  clear = () => {
    this.post = PostFactory.create();
    this.post.type = this.props.type;
    this.post.type_id = this.props.type_id;
    this.setState({ active: false, post: this.post, dirty: false, isValid: true });
  }

  handleSubmit = () => {
    this.setState({ post: this.post, dirty: true });
    if(!this.state.isValid) return;    

    this.post.$save(this.props.auth)
      .then(res => {
        PostFactory.get(this.props.auth, res.data).then((post) => {
          this.post = post;
          this.setState({
            post: this.post
          });
          this.props.onSave(this.post);
          this.clear();
        })
      })
  }

  handleActivate = () => {
    // Use tine timeout to see button animation.
    setTimeout(() => { this.setState({ active: true }) }, 250);
  }

  handleCancel = () => {
    // Use tine timeout to see button animation.
    setTimeout(() => { 
      this.clear();
    }, 250);
  }

  handleChange = (post: Post, forceupdate: boolean) => {
    this.post = Object.assign(this.post, post);
    this.setState({ post: this.post });
  }

  handleValidate = (valid: boolean) => {
    this.setState({
      isValid: valid
    })
  }    

  render() {
    let p = this.props;
    return (
      <div>
        {this.state.active == false && 
        <div style={{textAlign:'right'}}>
          <Button primary onClick={this.handleActivate}>Add post</Button>
        </div>}
        {this.state.active == true && 
        <Segment>
          <Form
            data={this.state.post} 
            dirty={this.state.dirty} 
            onChange={this.handleChange} 
            onValidate={this.handleValidate}>

            <Form.Field 
              required={{message: <Loc msg="posts_fields_title_required"/>}}
              minLength={{length: 3, message: <Loc msg="posts_fields_title_minlength"/>}}
              maxLength={{length: 1000, message: <Loc msg="posts_fields_title_maxlength"/>}}
              width={1} name="title" value={this.state.post.title}
              control={(<Input type="text" placeholder="Title" fluid/>)}
              />

            <Form.Field 
              name="text" value={this.state.post.text}
              control={(<MarkdownTextarea rows={5} fluid label_text={<Loc msg="markdown_text"/>} label_preview={<Loc msg="markdown_preview"/>} label_markdown={<Loc msg="markdown_is_supported"/>}/>)}
              />
          </Form>
          <div style={{textAlign:'right'}}>
            <Button size="small" secondary onClick={this.handleCancel}><Loc msg="btn_cancel"/></Button>
            <Button size="small" primary disabled={!this.state.isValid} onClick={this.handleSubmit}><Loc msg="btn_save"/></Button>
          </div>
        </Segment>}
      </div>
    );
  }
}

export { AddPost };
