import * as React from 'react';
import { Input } from '@independent-software/typeui/controls/Input';
import { Form } from '@independent-software/typeui/controls/Form';
import { IAccount } from './IAccount';
import { Password } from '@independent-software/typeui/modules/Password';
import { Loc } from '../../modules';

interface IProps {
  /** Initial form data. */
  data: IAccount;
  /** Called whenever form changes. */
  onChange: (data: IAccount, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */
  dirty?: boolean;
}

interface IState {
  /** Current form data. */
  data: IAccount;
}

class AccountForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    // Intialize state.
    this.state = {
      data: props.data
    };    
  }

  render() {
    let p = this.props;
    return (
      <Form data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
        <Form.Group equal>
          <Form.Field 
            required={{message: <Loc msg="account_fields_username_required"/>}}
            minLength={{length: 3, message: <Loc msg="account_fields_username_minlength"/>}}
            maxLength={{length: 30, message: <Loc msg="account_fields_username_maxlength"/>}}            
            width={1} label={<Loc msg="account_fields_username_label"/>}
            name="name" 
            value={this.state.data.name}
            control={(<Input type="text" placeholder="Name" fluid/>)}
            hint={(<React.Fragment><Loc msg="account_fields_username_hint"/> <code><Loc msg="account_fields_username_hint_ex"/></code></React.Fragment>)}/>
          <Form.Field 
            required={{message: <Loc msg="account_fields_email_required"/>}}
            email={{message: <Loc msg="account_fields_email_email"/>}}
            width={1} label={<Loc msg="account_fields_email_label"/>}
            name="email" 
            value={this.state.data.email}
            control={(<Input type="text" placeholder="Email" fluid/>)}
            hint={(<React.Fragment><Loc msg="account_fields_email_hint"/> <code><Loc msg="account_fields_email_hint_ex"/></code></React.Fragment>)}/>
        </Form.Group>
        <Form.Group>
          <Form.Field
            required={{message: <Loc msg="account_fields_password_required"/>}}
            width={1} label={<Loc msg="account_fields_password_label"/>}
            name="password" 
            value={this.state.data.password}
            control={(<Password fluid placeholder="Password"/>)}
            hint={<Loc msg="account_fields_password_hint"/>}/>
          <Form.Field
            minLength={{length: 8, message: <Loc msg="account_fields_newpassword_minlength"/>}}
            maxLength={{length: 30, message: <Loc msg="account_fields_newpassword_maxlength"/>}}
            noPattern={{pattern: '^(.{0,7}|.{31,}|[^0-9]*|[^A-Za-z]*|[a-zA-Z0-9]*)$', message: <Loc msg="account_fields_newpassword_nopattern"/>}}            
            width={1} label={<Loc msg="account_fields_newpassword_label"/>}
            name="newpassword" 
            value={this.state.data.newpassword}
            control={(<Password fluid placeholder="New password"/>)}
            hint={<Loc msg="account_fields_newpassword_hint"/>}/>
        </Form.Group>            
      </Form>
    )
  }
}

export { AccountForm };
