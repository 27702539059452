import * as React from 'react';
import { Section, Viewer, Block, Loc } from '../../modules';
import { Issuecategory, IssuecategoryFactory, Issuetype } from '../../resource/'; 
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';

import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Header } from '@independent-software/typeui/controls/Header';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Label } from '@independent-software/typeui/controls/Label';
import { Table } from '@independent-software/typeui/controls/Table';
import { ListIssuetypes } from '../Issuetype';

class ViewIssuecategory extends React.Component<IAuthProps & RouteComponentProps<{id:string}>, {}> {
  private canEdit = (issuecategory: Issuecategory): boolean => {
    return this.props.auth && this.props.auth.hasRight('can_edit_issuetypes');
  }  

  private handleEdit = () => {
    this.props.history.push(`/issuecategories/edit/${parseInt(this.props.match.params.id)}`);
  }

  private handleDelete = () => {
    ToastService.toast(<Loc msg="issuecategories_msg_deleted"/>);
    this.props.history.push('/issuecategories');        
  }    

  private handleClickIssuetype = (issuetype: Issuetype) => {
    this.props.history.push(`/issuetypes/${issuetype.id}`);
  }

  render() {
    let p = this.props;
    return (
      <Viewer<Issuecategory> 
        auth={p.auth}
        id={parseInt(p.match.params.id)}
        factory={IssuecategoryFactory}
        canEdit={this.canEdit}
        onEdit={this.handleEdit}
        onDelete={this.handleDelete}
        content={(issuecategory: Issuecategory) => 
          <React.Fragment>
            <Section dark padded>
              <Flex>
                <Flex.Row>
                  <Flex.Column>
                    <Header size="h2">{issuecategory.name}</Header>
                  </Flex.Column>
                </Flex.Row>
              </Flex>
            </Section>
            <Tabs underlined nohiddenrender>
              <Tabs.Pane label={<Loc msg="issuecategories_form_tab_data"/>}>
                <Section padded>
                  <Flex.Columns count={2}>
                    <React.Fragment>
                      <Block title={<Loc msg="issuecategories_block_identification"/>}>
                        <Table striped transparent>
                          <tbody>
                            <tr><td><Loc msg="issuecategories_fields_name_label"/></td><td>{issuecategory.name}</td></tr>
                          </tbody>
                        </Table>
                      </Block>
                    </React.Fragment>
                  </Flex.Columns>
                </Section>
              </Tabs.Pane>
              <Tabs.Pane label={<span><Loc msg="issuecategories_form_tab_issuetypes"/> <Label size="small">{issuecategory.issuetypes_count}</Label></span>}>
                <ListIssuetypes auth={p.auth} issuecategoryId={issuecategory.id} name="issuecategories_issuetypes" onClick={this.handleClickIssuetype}/>
              </Tabs.Pane>      
            </Tabs>
          </React.Fragment>}>
      </Viewer>
    );
  }
}

export { ViewIssuecategory };
