import * as React from 'react';
import axios from 'axios';
import { RouteComponentProps } from 'react-router';
import { ResetForm } from './ResetForm';
import { IReset } from './IReset';

import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { App } from '../../App';
import { Loc } from '../../modules';

interface IProps {
  className?: string;
}

interface IState {
  reset: IReset;
  isValid?: boolean
  /** Reset request in progress? Will show a loader. */
  resetLoading: boolean;  
}

class ResetPassword extends React.Component<IProps & RouteComponentProps, IState> {
  private messageTimer: number = null;
  private token: string;

  constructor(props: IProps & RouteComponentProps) {
    super(props);
    this.token = new URLSearchParams(props.location.search).get('token');
    this.state = {
      reset: {
        password: ''
      },
      resetLoading: false
    }
  }

  handleChange = (reset: IReset, forceupdate: boolean) => {
    this.setState({
      reset: reset
    });
  }  

  handleValidate = (valid: boolean) => {
    this.setState({
      isValid: valid
    })
  }  

  // If there is a message timeout still running,
  // clear it before unmounting.
  componentWillUnmount() {
    if(this.messageTimer) {
      clearTimeout(this.messageTimer);
    }
  }


  // Adds a 500ms timeout to make sure that login button
  // animation actually gets seen.
  handleSubmit = () => {
    this.setState({
      resetLoading: true
    });
    setTimeout(() => {
      axios.post(`${App.apiURL}/reset`, {
        token: this.token,
        password: this.state.reset.password
      })
      .then(response => {
        this.props.history.replace('/login');
      })
      .catch(error => {
        let text = '';
        if(error.response) {
          switch(error.response.status) {
            case 400: 
              text = "You did not provide a valid new password.";
              break;
            case 401:
              text = "Your password reset token was not valid. Please request a new token.";
              break;
            default:
              text = "There was a problem communicating with the server.";
              break;
          }          
        } else if(error.request) {
          text = "No server response was received.";
        } else {
          text = "There was a problem sending your request.";
        }
        ToastService.toast(text);
      })
      .then(() => {
        this.setState({
          resetLoading: false,
        });
      });
    }, 500);
  }  

  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        <ResetForm disabled={this.state.resetLoading} data={this.state.reset} onChange={this.handleChange} onValidate={this.handleValidate}/>
        <div style={{textAlign:'right'}}>
          <Button primary disabled={!this.state.isValid} onClick={this.handleSubmit}>
            {this.state.resetLoading && <Icon loading name="circle-notch"/>}
            {!this.state.resetLoading && <Loc msg="reset_password_btn_save"/>}
            {this.state.resetLoading && <Loc msg="reset_password_btn_saving"/>}
          </Button>        
        </div>
      </div>
    )
  }
}

export { ResetPassword };