import * as React from 'react';
import { Stakeholder, Attachment, Community, CommunityFactory } from '../../resource/';
import { IAuthProps, Query } from '../../services/';

import { Input } from '@independent-software/typeui/controls/Input';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Form } from '@independent-software/typeui/controls/Form';
import { Section, AttachmentManager, Loc, Block } from '../../modules';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Label } from '@independent-software/typeui/controls/Label';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';

interface IProps {
  /** Initial form data. */
  data: Stakeholder;
  /** Called whenever form changes. */
  onChange: (data: Stakeholder, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */  
  dirty?: boolean;
}

interface IState {
  /** Current form data. */
  data: Stakeholder;
  /** Data for communities dropdown */
  communities: Community[];    
}

class StakeholderForm extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);

    // Intialize state.
    this.state = {
      data: props.data,
      communities: []
    };    

    // Retrieve a list of communities:
    CommunityFactory.getSome(this.props.auth, 0, 999, new Query('name', 'asc'))
      .then((res) => this.setState({ communities: res.items }));    
  }

  handleCommunitySearch = (q:string) => {
    // Retrieve a list of communities:
    let query = new Query('name', 'asc');
    query.setFilter('q', 'like', q);
    CommunityFactory.getSome(this.props.auth, 0, 999, query)
      .then((res) => this.setState({ communities: res.items }));
  }  

  handleChangeAttachments = (attachments: Attachment[]) => {
    this.setState((prevState) => {
      prevState.data.attachments = attachments;
      return prevState;
    });
  }  

  render() {
    let p = this.props;
    return (
      <Form
        data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
        <Tabs underlined>
          <Tabs.Pane label={<Loc msg="stakeholders_form_tab_data"/>}>
            <Section padded>
              <Flex.Columns count={2}>
                <React.Fragment>
                  <Block title={<Loc msg="stakeholders_block_identification"/>}>
                    <Form.Field 
                      required={{message: <Loc msg="stakeholders_fields_name_required"/>}}
                      minLength={{length: 3, message: <Loc msg="stakeholders_fields_name_minlength"/>}}
                      maxLength={{length: 30, message: <Loc msg="stakeholders_fields_name_maxlength"/>}}
                      width={1} label={<Loc msg="stakeholders_fields_name_label"/>}
                      name="name" 
                      value={this.state.data.name}
                      control={(<Input type="text" placeholder="Name" fluid/>)}
                      hint={<Loc msg="stakeholders_fields_name_hint"/>}/>
                    <Form.Field
                      forceupdate
                      required={{message: <Loc msg="stakeholders_fields_type_required"/>}}
                      label={<Loc msg="stakeholders_fields_type_label"/>}
                      name="type"
                      value={this.state.data.type}
                      control={<React.Fragment>
                        <div><Checkbox radio type="circle" name="type" value="pap" label={<Loc msg="stakeholders_type_pap"/>} checked={this.state.data.type=='pap'} onChange={() => this.props.onChange({...this.state.data, type: 'pap'}, true)}/></div>
                        <div><Checkbox radio type="circle" name="type" value="civil" label={<Loc msg="stakeholders_type_civil"/>} checked={this.state.data.type=='civil'} onChange={() => this.props.onChange({...this.state.data, type: 'civil'}, true)}/></div>
                        <div><Checkbox radio type="circle" name="type" value="other" label={<Loc msg="stakeholders_type_other"/>} checked={this.state.data.type=='other'} onChange={() => this.props.onChange({...this.state.data, type: 'other'}, true)}/></div>
                      </React.Fragment>}
                      hint={<Loc msg="stakeholders_fields_type_hint"/>}/> 
                    {this.state.data.type == 'other' && <Form.Field 
                      required={{message: <Loc msg="stakeholders_fields_type_other_required"/>}}
                      minLength={{length: 3, message: <Loc msg="stakeholders_fields_type_other_minlength"/>}}
                      maxLength={{length: 30, message: <Loc msg="stakeholders_fields_type_other_maxlength"/>}}
                      width={1} label={<Loc msg="stakeholders_fields_type_other_label"/>}
                      name="type_other" 
                      value={this.state.data.type_other}
                      control={(<Input type="text" placeholder="Other" fluid/>)}
                      hint={<Loc msg="stakeholders_fields_type_other_hint"/>}/>}
                  </Block>

                  <Block title={<Loc msg="stakeholders_block_contact"/>}>
                    <Form.Field 
                      required={{message: <Loc msg="stakeholders_fields_phone_required"/>}}
                      maxLength={{length: 20, message: <Loc msg="stakeholders_fields_phone_maxlength"/>}}
                      width={1} label={<Loc msg="stakeholders_fields_phone_label"/>}
                      name="phone" 
                      value={this.state.data.phone}
                      control={(<Input type="text" placeholder="Phone" fluid/>)}
                      hint={<Loc msg="stakeholders_fields_phone_hint"/>}/>                    
                    <Form.Field 
                      required={{message: <Loc msg="stakeholders_fields_contact_required"/>}}
                      maxLength={{length: 255, message: <Loc msg="stakeholders_fields_contact_maxlength"/>}}
                      width={1} label={<Loc msg="stakeholders_fields_contact_label"/>}
                      name="contact" 
                      value={this.state.data.contact}
                      control={(<Input type="text" placeholder="Contact" fluid/>)}
                      hint={<Loc msg="stakeholders_fields_contact_hint"/>}/>                                      
                  </Block>
                </React.Fragment>


                {this.state.data.type == 'pap' &&
                <Block title={<Loc msg="stakeholders_block_data"/>}>

                  <Form.Field 
                    required={{message: <Loc msg="stakeholders_fields_age_required"/>}}
                    isInt={{message: <Loc msg="stakeholders_fields_age_isinteger"/>}}
                    min={{value: 0, message: <Loc msg="stakeholders_fields_age_min"/>}}
                    width={1} label={<Loc msg="stakeholders_fields_age_label"/>}
                    name="age" 
                    value={this.state.data.age}
                    control={(<Input type="text" placeholder="Age" fluid/>)}
                    hint={<Loc msg="stakeholders_fields_age_hint"/>}/>
                  <Form.Field
                    required={{message: <Loc msg="stakeholders_fields_sex_required"/>}}
                    label={<Loc msg="stakeholders_fields_sex_label"/>}
                    name="sex"
                    value={this.state.data.sex}
                    control={<React.Fragment>
                      <div><Checkbox radio type="circle" name="sex" value="m" label={<Loc msg="stakeholders_sex_male"/>} checked={this.state.data.sex=='m'} onChange={() => this.props.onChange({...this.state.data, sex: 'm'}, true)}/></div>
                      <div><Checkbox radio type="circle" name="sex" value="f" label={<Loc msg="stakeholders_sex_female"/>} checked={this.state.data.sex=='f'} onChange={() => this.props.onChange({...this.state.data, sex: 'f'}, true)}/></div>
                      <div><Checkbox radio type="circle" name="sex" value="?" label={<Loc msg="stakeholders_sex_unknown"/>} checked={this.state.data.sex=='?'} onChange={() => this.props.onChange({...this.state.data, sex: '?'}, true)}/></div>
                    </React.Fragment>}
                    hint={<Loc msg="stakeholders_fields_sex_hint"/>}/>                    
                  <Form.Field
                    required={{message: <Loc msg="stakeholders_fields_community_required"/>}}
                    width={1} label={<Loc msg="stakeholders_fields_community_label"/>}
                    name="community" 
                    value={this.state.data.community}
                    control={(<Dropdown onSearch={this.handleCommunitySearch} fluid data={this.state.communities} placeholder="Community" label={(item:Community) => item.name}>
                      <Dropdown.Column weight={3}>{(item:Community) => item.name}</Dropdown.Column>
                    </Dropdown>)}
                    hint={<Loc msg="stakeholders_fields_community_hint"/>}/>
                  <Form.Field
                    required={{message: <Loc msg="stakeholders_fields_state_required"/>}}
                    label={<Loc msg="stakeholders_fields_state_label"/>}
                    name="state"
                    value={this.state.data.state}
                    control={<React.Fragment>
                      <div><Checkbox radio type="circle" name="state" value="to-resettle" label={<Loc msg="stakeholders_state_to-resettle"/>} checked={this.state.data.state=='to-resettle'} onChange={() => this.props.onChange({...this.state.data, state: 'to-resettle'}, true)}/></div>
                      <div><Checkbox radio type="circle" name="state" value="resettled" label={<Loc msg="stakeholders_state_resettled"/>} checked={this.state.data.state=='resettled'} onChange={() => this.props.onChange({...this.state.data, state: 'resettled'}, true)}/></div>
                      <div><Checkbox radio type="circle" name="state" value="host" label={<Loc msg="stakeholders_state_host"/>} checked={this.state.data.state=='host'} onChange={() => this.props.onChange({...this.state.data, state: 'host'}, true)}/></div>
                    </React.Fragment>}
                    hint={<Loc msg="stakeholders_fields_state_hint"/>}/>
                  <Form.Field
                    required={{message: <Loc msg="stakeholders_fields_community_leader_required"/>}}
                    label={<Loc msg="stakeholders_fields_community_leader_label"/>}
                    name="community_leader"
                    value={this.state.data.community_leader}
                    control={<React.Fragment>
                      <div><Checkbox radio type="circle" name="community_leader" value="none" label={<Loc msg="stakeholders_community_leader_none"/>} checked={this.state.data.community_leader=='none'} onChange={() => this.props.onChange({...this.state.data, community_leader: 'none'}, true)}/></div>
                      <div><Checkbox radio type="circle" name="community_leader" value="current" label={<Loc msg="stakeholders_community_leader_current"/>} checked={this.state.data.community_leader=='current'} onChange={() => this.props.onChange({...this.state.data, community_leader: 'current'}, true)}/></div>
                      <div><Checkbox radio type="circle" name="community_leader" value="moved" label={<Loc msg="stakeholders_community_leader_moved"/>} checked={this.state.data.community_leader=='moved'} onChange={() => this.props.onChange({...this.state.data, community_leader: 'moved'}, true)}/></div>
                      <div><Checkbox radio type="circle" name="community_leader" value="passed" label={<Loc msg="stakeholders_community_leader_passed"/>} checked={this.state.data.community_leader=='passed'} onChange={() => this.props.onChange({...this.state.data, community_leader: 'passed'}, true)}/></div>
                    </React.Fragment>}
                    hint={<Loc msg="stakeholders_fields_community_leader_hint"/>}/>
                  <Form.Field
                    required={{message: <Loc msg="stakeholders_fields_head_of_household_required"/>}}
                    label={<Loc msg="stakeholders_fields_head_of_household_label"/>}
                    name="head_of_household"
                    value={this.state.data.head_of_household}
                    control={<React.Fragment>
                      <div><Checkbox radio type="circle" name="head_of_household" value="none" label={<Loc msg="stakeholders_head_of_household_none"/>} checked={this.state.data.head_of_household=='none'} onChange={() => this.props.onChange({...this.state.data, head_of_household: 'none'}, true)}/></div>
                      <div><Checkbox radio type="circle" name="head_of_household" value="current" label={<Loc msg="stakeholders_head_of_household_current"/>} checked={this.state.data.head_of_household=='current'} onChange={() => this.props.onChange({...this.state.data, head_of_household: 'current'}, true)}/></div>
                      <div><Checkbox radio type="circle" name="head_of_household" value="moved" label={<Loc msg="stakeholders_head_of_household_moved"/>} checked={this.state.data.head_of_household=='moved'} onChange={() => this.props.onChange({...this.state.data, head_of_household: 'moved'}, true)}/></div>
                      <div><Checkbox radio type="circle" name="head_of_household" value="passed" label={<Loc msg="stakeholders_head_of_household_passed"/>} checked={this.state.data.head_of_household=='passed'} onChange={() => this.props.onChange({...this.state.data, head_of_household: 'passed'}, true)}/></div>
                    </React.Fragment>}
                    hint={<Loc msg="stakeholders_fields_head_of_household_hint"/>}/>
                </Block>}

              </Flex.Columns>
            </Section>
          </Tabs.Pane>
          <Tabs.Pane label={<React.Fragment>{<Loc msg="stakeholders_form_tab_attachments"/>} <Label size="small">{this.state.data.attachments.length}</Label></React.Fragment>}>
            <Section padded>
              <AttachmentManager auth={p.auth} attachments={this.state.data.attachments} onChange={this.handleChangeAttachments}/>
            </Section>
          </Tabs.Pane>
        </Tabs>

      </Form>
    )
  }
}

export { StakeholderForm };
