import * as React from 'react';
import { Loc } from '../../modules';
import { Post, PostFactory } from '../../resource/';
import { IAuthProps } from '../../services/Auth';
import axios from 'axios';

import { Button } from '@independent-software/typeui/controls/Button';
import { Form } from '@independent-software/typeui/controls/Form';
import { MarkdownTextarea } from '@independent-software/typeui/modules/MarkdownTextarea';
import { Segment } from '@independent-software/typeui/controls/Segment';
import { Input } from '@independent-software/typeui/controls/Input';
import { App } from '../../App';

interface IProps {
  post: Post;
  onSave: (post: Post) => void;
  onCancel: () => void;
}

interface IState {
  post: Post;
  isValid: boolean;
  dirty?: boolean;
}

class EditPost extends React.Component<IAuthProps & IProps, IState> {

  private post: Post;

  constructor(props: IAuthProps & IProps) {
    super(props);
    this.post = props.post;
    this.state = {
      isValid: false,
      post: this.post
    };
  }

  handleSubmit = () => {
    this.setState({ post: this.post, dirty: true });
    if(!this.state.isValid) return;    

    axios.put(`${App.apiURL}post/${this.state.post.id}`, {
      api_token: this.props.auth.token,
      title: this.state.post.title,
      text: this.state.post.text
    })
    .then(response => {
      // Read post back to get updated timestamps.
      PostFactory.get(this.props.auth, this.state.post.id).then((post) => {
        this.props.onSave(post);
      })
    })    
  }

  handleChange = (post: Post, forceupdate: boolean) => {
    this.post = Object.assign(this.post, post);
    this.setState({ post: this.post });
  }

  handleValidate = (valid: boolean) => {
    this.setState({
      isValid: valid
    })
  }    

  handleCancel = () => {
    // Use tiny timeout to see button animation.
    setTimeout(() => {
      this.props.onCancel();
    }, 250);
  }

  render() {
    let p = this.props;
    return (
      <div>
        <Segment>
          <Form
            data={this.state.post} 
            dirty={this.state.dirty} 
            onChange={this.handleChange} 
            onValidate={this.handleValidate}>

            <Form.Field 
              required={{message: <Loc msg="posts_fields_title_required"/>}}
              minLength={{length: 3, message: <Loc msg="posts_fields_title_minlength"/>}}
              maxLength={{length: 1000, message: <Loc msg="posts_fields_title_maxlength"/>}}
              width={1} name="title" value={this.state.post.title}
              control={(<Input type="text" placeholder="Title" fluid/>)}
              />

            <Form.Field 
              name="text" value={this.state.post.text}
              control={(<MarkdownTextarea rows={5} fluid label_text={<Loc msg="markdown_text"/>} label_preview={<Loc msg="markdown_preview"/>} label_markdown={<Loc msg="markdown_is_supported"/>}/>)}
              />
          </Form>
          <div style={{textAlign:'right'}}>
            <Button secondary size="small" onClick={this.handleCancel}><Loc msg="btn_cancel"/></Button>
            <Button primary size="small" disabled={!this.state.isValid} onClick={this.handleSubmit}><Loc msg="btn_save"/></Button>
          </div>
        </Segment>
        
      </div>
    );
  }
}

export { EditPost };
