import { Model } from './Resource';

interface ICreator {
  id: number;
  name: string;
  email: string;
}

class StampedModel extends Model {
  public created_at: string;
  public updated_at: string;
  public created_by: ICreator;
  public updated_by: ICreator;  
}

export { StampedModel };