import * as React from 'react';
import { Container, Content, BottomBar, Loc } from '../../modules';
import { User } from '../../resource/';
import { RouteComponentProps } from 'react-router';
import { ListUsers } from './ListUsers';
import { IAuthProps } from '../../services/Auth';

import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';

class UserList extends React.Component<IAuthProps & RouteComponentProps<any>, {}> {
  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
    this.handleAdd = this.handleAdd.bind(this);    
    this.handleClick = this.handleClick.bind(this);
  }

  // Go to add user view.
  handleAdd() {
    this.props.history.push('/users/add');
  }  

  // Go to view user view.
  handleClick(item: User) {
    this.props.history.push(`/users/${item.id}`);
  }  

  render() {
    let p = this.props;
    return (
      <Container>
        <Content>
          <ListUsers auth={p.auth} name="users" onClick={this.handleClick}/>
        </Content>
        <BottomBar>
          {p.auth && p.auth.hasRight('can_edit_users') && <Button primary onClick={this.handleAdd}><Icon name="plus"/> <Loc msg="users_btn_add"/></Button>}
        </BottomBar>
      </Container>
    );
  }
}

export { UserList };
