import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { Flex } from '@independent-software/typeui/controls/Flex';
import { Segment } from '@independent-software/typeui/controls/Segment';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Button } from '@independent-software/typeui/controls/Button';
import { Divider } from '@independent-software/typeui/controls/Divider';
import { Form } from '@independent-software/typeui/controls/Form';
import { Message } from '@independent-software/typeui/controls/Message';
import { Table } from '@independent-software/typeui/controls/Table';
import { IAuthProps, Query } from '../../services';

import { Response, ResponseMember, ResponseField, Community, CommunityFactory, SurveyFactory, Survey } from '../../resource';

import { Question } from './Question';
import { QuestionContext } from './QuestionContext';
import { Block, Layout } from '../../controls';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';

interface IProps {
  /** Initial form data. */
  data: Response;
  /** Called whenever form changes. */
  onChange: (data: Response, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */  
  dirty?: boolean;
}

interface IState {
  /** Current form data. */
  data: Response;
  surveys: Survey[];
  communities: Community[];
}

class ResponseForm extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);
    this.state = {
      data: this.props.data,
      surveys: [],
      communities: []
    }
  }

  componentDidMount = () => {
    // Load surveys:
    let query = new Query('name', 'asc');
    SurveyFactory.getSome(this.props.auth, 0, 999, query)
      .then((res) => this.setState({ surveys: res.items }));

    // Load communities.
    query = new Query('name', 'asc');
    CommunityFactory.getSome(this.props.auth, 0, 999, query)
      .then((res) => this.setState({ communities: res.items }));
  }
  
  handleAddMember = () => {
    this.setState((prevState) => {
      prevState.data.members.push(new ResponseMember());
      return prevState;
    });
  }

  handleDeleteMember = (index:number) => {
    this.setState((prevState) => {
      prevState.data.members.splice(index, 1);
      return prevState;
    });
  }

  handleAddField = () => {
    this.setState((prevState) => {
      prevState.data.fields.push(new ResponseField());
      return prevState;
    });
  }

  handleDeleteField = (index:number) => {
    this.setState((prevState) => {
      prevState.data.fields.splice(index, 1);
      return prevState;
    });
  }  

  renderGeneralTab = () => {
    return <>
      <Divider hidden/>
      <Block title="Inquérito">
        <Form.Field 
          required={{message: "Este campo é mandatório"}}
          width={1} label="Inquérito" name="survey" 
          value={this.state.data.survey}
          control={<Dropdown fluid clearable data={this.state.surveys} label={(item:Survey) => item.name} placeholder="Inquérito">
            <Dropdown.Column>{(item:Survey) => item.name}</Dropdown.Column>
          </Dropdown>}
          hint="Por favor escolha um inquérito."/>        
      </Block>
      <Block title="Questionário">
        <Layout width={2}>
          <Question nonumber name='GEN_SURVEY_NUM'/>
          <Question nonumber name='GEN_WEIGHT'/>
        </Layout>
        <Layout width={2}>
          <Question nonumber name='GEN_COORDENATOR_CODE'/>
          <Question nonumber name='GEN_INTERVIEWER_CODE'/>
        </Layout>
        <Layout width={3}>
          <Question nonumber name='GEN_DATE'/>
          <Question nonumber name='GEN_TIME_START'/>
          <Question nonumber name='GEN_TIME_END'/>
        </Layout>
      </Block>
      <Block title="CAF">
        <Layout width={2}>
          <Question nonumber name='GEN_CAF_NAME'/>
          <Question nonumber name='GEN_CAF_CONTACT'/>
          <Question nonumber name='GEN_CAF_AGE'/>
          <Question nonumber name='GEN_CAF_GENDER'/>
        </Layout>
        <Layout width={2}>
          <Question nonumber name='AFMONOPARENTAL' update/>
          <Question nonumber name='AFPOLIGAMO' update/>
        </Layout>
      </Block>
      <Block title="Entrevistado">
        <Layout width={2}>
          <Question nonumber name='GEN_NAME'/>
          <Question nonumber name='GEN_CONTACT'/>
          <Question nonumber name='GEN_RELATION'/>
          <Question nonumber name='GEN_CONTACT_CONSENT'/>
        </Layout>
      </Block>
      <Block title="Local">
        <Layout width={2}>
          <Question nonumber name='GEN_COORD_LAT'/>
          <Question nonumber name='GEN_COORD_LON'/>
          <Form.Field 
            required={{message: "Este campo é mandatório"}}
            width={1} label="Comunidade" name="community" 
            value={this.state.data.community}
            control={<Dropdown fluid clearable data={this.state.communities} label={(item:Community) => item.name} placeholder="Comunidade">
              <Dropdown.Column>{(item:Community) => item.name}</Dropdown.Column>
            </Dropdown>}
            hint="Por favor escolha uma comunidade."/>    
          <Question nonumber name='GEN_RESIDENCE'/>
          <Question nonumber name='GEN_BAIRRO'/>
        </Layout>
      </Block>
      <Block title="Comentários">
        <Question nonumber name="GEN_COMMENTS"/>
      </Block>
    </>
  }

  renderMembersTab = () => {
    return <>
      <Divider hidden/>
      <Block title="A1. Tamanho do agregado familiar">
        <Question name="A1"/>
      </Block>    
      <Divider hidden/>
      <Block title="Membros com idade superior ou igual a 5 anos">
        {this.state.data.members.length == 0 && 
          <Message type="info">
            <Message.Header>Acrescentar membros</Message.Header>
            <Message.Content>Não há membros do agregado familiar com idade superior ou igual a 5 anos registados.</Message.Content>
          </Message>}
        <Message type="warning">
          Registe somente membros do agredago familiar com idade superior ou igual a 5 anos.
        </Message>
        {this.state.data.members.map((m:any, index) => 
          <QuestionContext.Provider key={index} value={{ data: m, group: 'members', index: index }} >
            <Segment tertiary attached="top">
              Membro {index + 1} de {this.state.data.members.length}
              <RemoveIcon><Icon color="darkred" inverted cornered name="trash" onClick={() => this.handleDeleteMember(index)}/></RemoveIcon>
            </Segment>
            <Segment attached="bottom">
              <Layout width={3}>
                <Question name='A2'/>
                <Question name='A3'/>
                <Question name='A5'/>
              </Layout>
              <Layout width={2}>
                <Question name='B2' update/>
              </Layout>
              {(m['B2'] && m['B2'] != 0) && 
                <Layout width={2}>
                  <Question name='B3'/>
                </Layout>}
              {(m['B2'] && m['B2'] != 0) && 
                <Layout width={2}>
                  <Question name='B4' update/>
                  {(m['B4'] && m['B4'] == 98) && <Question name='B4A'/>}
                </Layout>}
              {(m['B2'] && m['B2'] == 0) && 
                <Layout width={2}>
                  <Question name='B5' update/>
                  {(m['B5'] && m['B5'] == 98) && <Question name='B5A'/>}
                </Layout>}
            </Segment>
          </QuestionContext.Provider>
        )}
        <div style={{textAlign: 'right'}}>
          <Button onClick={this.handleAddMember}><Icon name="plus"/> Acrescentar membro</Button>
        </div>
      </Block>
      <Block title="B6. Acesso a serviços de educação">
        <Question name="B6"/>
      </Block>
    </>
  }

  renderHealthTab = () => {
    let s = this.state.data as any;
    return (
    <>
      <Divider hidden/>
      <Block title="C1. Doenças nos últimos 30 dias">
        <Question name="C1_1" update/>
        {s["C1_1"] == 1 && <>
          <Layout width={2}>
            <Question name="C1_1A" update/>
            {s["C1_1A"] && s["C1_1A"] == 98 && <Question name="C1_1AO"/>}
          </Layout>
          <Layout width={2}>
            <Question name="C1_1B" update/>
            {s["C1_1B"] && s["C1_1B"] == 98 && <Question name="C1_1BO"/>}
          </Layout>
        </>}
        <Question name="C1_2" update/>
        {s["C1_2"] == 1 && <>
          <Layout width={2}>
            <Question name="C1_2A" update/>
            {s["C1_2A"] && s["C1_2A"] == 98 && <Question name="C1_2AO"/>}
          </Layout>
          <Layout width={2}>
            <Question name="C1_2B" update/>
            {s["C1_2B"] && s["C1_2B"] == 98 && <Question name="C1_2BO"/>}
          </Layout>
        </>}        
        <Question name="C1_3" update/>
        {s["C1_3"] == 1 && <>
          <Layout width={2}>
            <Question name="C1_3A" update/>
            {s["C1_3A"] && s["C1_3A"] == 98 && <Question name="C1_3AO"/>}
          </Layout>
          <Layout width={2}>
            <Question name="C1_3B" update/>
            {s["C1_3B"] && s["C1_3B"] == 98 && <Question name="C1_3BO"/>}
          </Layout>
        </>}        
        <Question name="C1_4" update/>
        {s["C1_4"] == 1 && <>
          <Layout width={2}>
            <Question name="C1_4A" update/>
            {s["C1_4A"] && s["C1_4A"] == 98 && <Question name="C1_4AO"/>}
          </Layout>
          <Layout width={2}>
            <Question name="C1_4B" update/>
            {s["C1_4B"] && s["C1_4B"] == 98 && <Question name="C1_4BO"/>}
          </Layout>
        </>}        
        <Question name="C1_5" update/>
        {s["C1_5"] == 1 && <>
          <Layout width={2}>
            <Question name="C1_5A" update/>
            {s["C1_5A"] && s["C1_5A"] == 98 && <Question name="C1_5AO"/>}
          </Layout>
          <Layout width={2}>
            <Question name="C1_5B" update/>
            {s["C1_5B"] && s["C1_5B"] == 98 && <Question name="C1_5BO"/>}
          </Layout>
        </>}        
        <Question name="C1_6" update/>
        {s["C1_6"] == 1 && <>
          <Layout width={2}>
            <Question name="C1_6A" update/>
            {s["C1_6A"] && s["C1_6A"] == 98 && <Question name="C1_6AO"/>}
          </Layout>
          <Layout width={2}>
            <Question name="C1_6B" update/>
            {s["C1_6B"] && s["C1_6B"] == 98 && <Question name="C1_6BO"/>}
          </Layout>
        </>}        
        <Question name="C1_7" update/>
        {s["C1_7"] == 1 && <>
          <Layout width={2}>
            <Question name="C1_7A" update/>
            {s["C1_7A"] && s["C1_7A"] == 98 && <Question name="C1_7AO"/>}
          </Layout>
          <Layout width={2}>
            <Question name="C1_7B" update/>
            {s["C1_7B"] && s["C1_7B"] == 98 && <Question name="C1_7BO"/>}
          </Layout>
        </>}        
        <Question name="C1_8" update/>
        {s["C1_8"] == 1 && <>
          <Layout width={2}><Question name="C1_8O"/></Layout>
          <Layout width={2}>
            <Question name="C1_8A" update/>
            {s["C1_8A"] && s["C1_8A"] == 98 && <Question name="C1_8AO"/>}
          </Layout>
          <Layout width={2}>
            <Question name="C1_8B" update/>
            {s["C1_8B"] && s["C1_8B"] == 98 && <Question name="C1_8BO"/>}
          </Layout>
        </>}        
        {s["C1_8"] == 1 && <>
          <Question name="C1_9" update/>
          {s["C1_9"] == 1 && <>
            <Layout width={2}><Question name="C1_9O"/></Layout>
            <Layout width={2}>
              <Question name="C1_9A" update/>
              {s["C1_9A"] && s["C1_9A"] == 98 && <Question name="C1_9AO"/>}
            </Layout>
            <Layout width={2}>
              <Question name="C1_9B" update/>
              {s["C1_9B"] && s["C1_9B"] == 98 && <Question name="C1_9BO"/>}
            </Layout>
          </>}
        </>}
      </Block>
      <Block title="C4. Doenças crónicas">
        <Question name="C4A"/>
        <Question name="C4B"/>
        <Question name="C4C"/>
        <Question name="C4D"/>
        <Question name="C4E"/>
        <Question name="C4F"/>
        <Question name="C4G"/>
        <Question name="C4H" update/>
        <>{s['C4H'] == 1 && <Question name='C4H1'/>}</>
        {s['C4H'] == 1 && <>
          <Question name="C4I" update/>
          <>{(s['C4I'] == 1) && <Question name='C4I1'/>}</>
        </>}
      </Block>
      <Block title="C8. Acesso a serviços de saúde">
        <Question name="C8"/>
      </Block>
    </>)
  }

  renderIncomeTab = () => {
    let s = this.state.data as any;
    return (
      <>
        <Divider hidden/>
        <Block title="D1. Principais fontes de rendimento">
          <IntroText>Quais são as principais fontes de rendimento do agregado familiar?</IntroText>
          <Question name='D1A'/>
          <Question name='D1B'/>
          <Question name='D1C'/>
          <Question name='D1D'/>
          <Question name='D1E'/>
          <Question name='D1F'/>
          <Question name='D1G'/>
          <Question name='D1H'/>
          <Question name='D1I'/>
          <Question name='D1J' update/>
          {s['D1J'] == 1 && <Question name='D1JO'/>}
        </Block>
        <Block title="D2. Rendimento médio mensal (em meticais)">
          <IntroText>Qual é o rendimento médio mensal do agregado familiar nas seguintes categorias?</IntroText>
          <Layout width={2}>
            <>
              <Question name='D2A'/>
              <Question name='D2B'/>
              <Question name='D2C'/>
              <Question name='D2D'/>
              <Question name='D2E'/>
              <Question name='D2F'/>
            </>
            <>
              <Question name='D2G'/>
              <Question name='D2H'/>
              <Question name='D2I'/>
              <Question name='D2J'/>
              <Question name="D2_OUTRO" update/>
              {s["D2_OUTRO"] == 1 && <>
                <Question name='D2KO'/>
                <Question name='D2K'/>
              </>}
            </>
          </Layout>
        </Block>
        <Block title="D4. Despesa médio mensal (em meticais)">
          <IntroText>Aproximadamente, quanto gasta mensalmente o seu agregado familiar nas seguintes categorias?</IntroText>
          <Layout width={2}>
            <>
              <Question name='D4A'/>
              <Question name='D4B'/>
              <Question name='D4C'/>
              <Question name='D4D'/>
            </>
            <>
              <Question name='D4E'/>
              <Question name='D4F'/>
              <Question name="D4_OUTRO" update/>
              {s["D4_OUTRO"] == 1 && <>
                <Question name='D4GA'/>
                <Question name='D4G'/>
              </>}
            </>
          </Layout>
        </Block>        
        <Block title="D6. Conta bancária">
          <Question name="D6"/>
        </Block>
        <Block title="D8. Investimentos com dinheiro recebido do reassentamento">
          <Layout width={2}>
            <Question name="D8A" update/>
            {s["D8A"] && s["D8A"]==98 && <Question name="D8AO"/>}
          </Layout>
          <Layout width={2}>
            <Question name="D8B" update/>
            {s["D8B"] && s["D8B"]==98 && <Question name="D8BO"/>}
          </Layout>
          <Layout width={2}>
            <Question name="D8C" update/>
            {s["D8C"] && s["D8C"]==98 && <Question name="D8CO"/>}
          </Layout>
        </Block>
      </>
    )
  }

  renderNetworkTab = () => {
    let s = this.state.data as any;
    return (
      <>
        <Divider hidden/>
        <Block title="E1. Recorrer em caso de dificuldade">
          <IntroText>Em caso de dificuldade ou emergência (por exemplo, problema de saúde, falta de alimento, falta de dinheiro, etc) a quem recorre?</IntroText>
          <Layout width={2}>
            <Question name='E1A' update/>
            <div>{(s['E1A'] && s['E1A'] == 98) && <Question name='E1AO'/>}</div>
            <Question name='E1B' update/>
            <div>{(s['E1B'] && s['E1B'] == 98) && <Question name='E1BO'/>}</div>
            <Question name='E1C' update/>
            <div>{(s['E1C'] && s['E1C'] == 98) && <Question name='E1CO'/>}</div>
          </Layout>
        </Block>
      </>
    )
  }

  renderGoodsTab = () => {
    return (
      <>
        <Divider hidden/>
        <Block title="F1. Bens">
          <IntroText>Algum membro do agregado familiar possui alguns dos bens adiante listados? Por favor considere apenas aqueles que estão em uso e funcionam.</IntroText>
          <Table striped transparent>
            <tbody>
              <tr><td style={{width:'200px'}}>Rádio</td><td><Question name='F1A' nolabel/></td></tr>
              <tr><td>Aparelhagem</td><td><Question name='F1B' nolabel/></td></tr>
              <tr><td>Telefone/telemóvel</td><td><Question name='F1C' nolabel/></td></tr>
              <tr><td>Geleira/congelador</td><td><Question name='F1D' nolabel/></td></tr>
              <tr><td>Tractor</td><td><Question name='F1E' nolabel/></td></tr>
              <tr><td>Bicicleta</td><td><Question name='F1F' nolabel/></td></tr>
              <tr><td>Motorizada</td><td><Question name='F1G' nolabel/></td></tr>
              <tr><td>Veículo motorizado</td><td><Question name='F1H' nolabel/></td></tr>
              <tr><td>Bomba de água</td><td><Question name='F1I' nolabel/></td></tr>
            </tbody>
          </Table>
        </Block>
      </>
    )
  }

  renderPropertyTab = () => {
    let s = this.state.data as any;
    return (
      <>
        <Divider hidden/>
        <Block title="Infra-estrutura">
          <Question name="G1_3" update/>
          {(s['G1_3'] && (s['G1_3'] == 4 || s['G1_3'] == 5)) && 
          <>
            <Layout width={2}>
              <Question name="G1_3A" update/>
              {s["G1_3A"] && s["G1_3A"] == 98 && <Question name="G1_3AO"/>}
            </Layout>
            <Layout width={2}>
              <Question name="G1_3B" update/>
              {s["G1_3B"] && s["G1_3B"] == 98 && <Question name="G1_3BO"/>}
            </Layout>
            <Layout width={2}>
              <Question name="G1_3C" update/>
              {s["G1_3C"] && s["G1_3C"] == 98 && <Question name="G1_3CO"/>}
            </Layout>
          </>}
          <Question name="G1_2"/>
        </Block>
        {(s['AFPOLIGAMO'] == 1) && 
        <Block title="Compensaçào/poligamia">
          <Question name="G1_4_A" update/>
          {s["G1_4_A"] && s["G1_4_A"] == 98 && <Question name="G1_4_AO"/>}
          <Question name="G1_4_B" update/>
          {s["G1_4_B"] && s["G1_4_B"] == 98 && <Question name="G1_4_BO"/>}
          <IntroText>Se as segundas esposas não tiverem recebido toda a compensação de habitação acordada, porquê?</IntroText>
          <Question name="G1_4_C1"/>
          <Question name="G1_4_C2"/>
          <Question name="G1_4_C3"/>
          <Question name="G1_4_C4"/>
          <Question name="G1_4_C5"/>
          <Question name="G1_4_C98" update/>
          <Question name="G1_4_C999"/>
          {s["G1_4_C98"] == true && <Question name="G1_4_CO"/>}
        </Block>}
        <Block title="Título">
          <Layout width={2}>
            <Question name='G1_1_A'/>
            <Question name='G1_1_B'/>
          </Layout>
        </Block>

        <Block title="Fonte de água">
          <Layout width={2}>
            <Question name='G2' update/>
            <>{(s['G2'] && s['G2'] == 98) && <Question name='G2A'/>}</>
            <Question name='G3'/>
            <Question name="G2_1"/>
          </Layout>
          <IntroText>Quais são os principais problemas que você enfrenta com o abastecimento de água em termos de qualidade e quantidade?</IntroText>
          <Question name="G2_2N"/>
          <Question name="G2_2A"/>
          <Question name="G2_2B"/>
          <Question name="G2_2C"/>
          <Question name="G2_2D"/>
          <Question name="G2_2E"/>
          <Question name="G2_2F"/>
          <Question name="G2_2G"/>
          <Question name="G2_2H" update/>
          {s["G2_2H"] == true && <Question name="G2_2HO"/>}
          <Question name="G3_1"/>
        </Block>

        <Block title="Fontes de energia">
          <IntroText>Qual a principal fonte de energia que o agregado familiar usa para...</IntroText>
          <Layout width={2}>
            <Question name='G4A' update/>
            <>{(s['G4A'] && s['G4A'] == 98) && <Question name='G4AO'/>}</>
            <Question name='G4B' update/>
            <>{(s['G4B'] && s['G4B'] == 98) && <Question name='G4BO'/>}</>
            <Question name='G4C' update/>
            <>{(s['G4C'] && s['G4C'] == 98) && <Question name='G4CO'/>}</>
          </Layout>
          <Question name="G4_1"/>
        </Block>

        <Block title="Instalação sanitária">
          <Layout width={2}>
            <Question name='G5' update/>
            {(s['G5'] && s['G5'] == 98) && <Question name='G5A'/>}
          </Layout>
        </Block>

        <Block title="Lixo">
          <Layout width={2}>
            <Question name='G6' update/>
            {(s['G6'] && s['G6'] == 98) && <Question name='G6A'/>}
          </Layout>
        </Block>
      </>
    )
  }

  renderAgricultureTab = () => {
    let s = this.state.data as any;
    return (
      <>
        <Divider hidden/>
        <Block title="H1. Número de parcelas">
          <Question name="H1"/>
        </Block>    
        <Divider hidden/>
        {this.state.data.fields.length == 0 && 
          <Message type="info">
            <Message.Header>Acrescentar parcelas</Message.Header>
            <Message.Content>Não há parcelas registadas. Prima em <b>Acrescentar parcela</b> para criar uma nova parcela.</Message.Content>
          </Message>}
        {this.state.data.fields.map((f:any, index) => 
          <QuestionContext.Provider key={index} value={{ data: f, group: 'fields', index: index }} >
            <Segment piled attached="top">
              Parcela {index + 1} of {this.state.data.fields.length}
              <RemoveIcon><Icon color="darkred" inverted cornered name="trash" onClick={() => this.handleDeleteField(index)}/></RemoveIcon>
            </Segment>
            <Segment piled attached="bottom">
              <Layout width={4}>
                <Question name='H2'/>
                <Question name='H3' update/>
                <>{(f['H3'] && f['H3'] == 98) && <Question name='H3O'/>}</>
              </Layout>
              <Block title="Cultura 1">
                <Layout width={2}>
                  <Question update name="H4A"/>
                  {(f['H4A'] && f['H4A'] == 98) && <Question name="H4AO"/>}
                </Layout>
                <Layout width={3}>
                  <Question name="H5A"/>
                  <Question update name="H6A"/>
                  {(f['H6A'] && f['H6A'] == 98) && <Question name="H6AO"/>}
                </Layout>
              </Block>
              <Block title="Cultura 2">
                <Layout width={2}>
                  <Question update name="H4B"/>
                  {(f['H4B'] && f['H4B'] == 98) && <Question name="H4BO"/>}
                </Layout>
                <Layout width={3}>
                  <Question name="H5B"/>
                  <Question update name="H6B"/>
                  {(f['H6B'] && f['H6B'] == 98) && <Question name="H6BO"/>}
                </Layout>
              </Block>
              <Block title="Cultura 3">
                <Layout width={2}>
                  <Question update name="H4C"/>
                  {(f['H4C'] && f['H4C'] == 98) && <Question name="H4CO"/>}
                </Layout>
                <Layout width={3}>
                  <Question name="H5C"/>
                  <Question update name="H6C"/>
                  {(f['H6C'] && f['H6C'] == 98) && <Question name="H6CO"/>}
                </Layout>
              </Block>                            
              <Layout width={2}>
                <Question name='H7' update/>
                <>{(f['H7'] && f['H7'] == 98) && <Question name='H7O'/>}</>
                <Question name='H22' update/>
                <>{(f['H22'] && f['H22'] == 98) && <Question name='H22O'/>}</>
                <Question name='H19'/>
              </Layout>
              <Divider section/>

            </Segment>
          </QuestionContext.Provider>
        )}
        <div style={{textAlign: 'right'}}>
          <Button onClick={this.handleAddField}><Icon name="plus"/> Acrescentar parcela</Button>
        </div>

        <Divider section/>

        <Block title="H10. Parcelas em pousio/repouso">
          <IntroText>No caso das parcelas que não estão em pousio/repouso e que não cultivou nos últimos 12 meses, quais foram as razões que não permitiram o cultivo?</IntroText>
          <Layout width={2}>
            <>
              <Question name='H10A'/>
              <Question name='H10B'/>
              <Question name='H10C'/>
              <Question name='H10D'/>
              <Question name='H10E'/>
            </>
            <>
              <Question name='H10F'/>
              <Question name='H10G'/>
              <Question name='H10H'/>
              <Question name='H10I' update/>
              {s['H10I'] == true && <Question name='H10IO'/>}
            </>
          </Layout>
        </Block>

        <Block title="H20. Satisfação parcelas">
          <Question name="H20" update/>
          {s["H20"] && (s["H20"] == 4 || s["H20"] == 5) && <>
            <Layout width={2}>
              <Question name="H20A_1" update/>
              {s["H20A_1"] && s["H20A_1"] == 98 && <Question name="H20A_1O"/>}
            </Layout>
            <Layout width={2}>
              <Question name="H20A_2" update/>
              {s["H20A_2"] && s["H20A_2"] == 98 && <Question name="H20A_2O"/>}
            </Layout>
            <Layout width={2}>
              <Question name="H20A_3" update/>
              {s["H20A_3"] && s["H20A_3"] == 98 && <Question name="H20A_3O"/>}
            </Layout>
          </>}
        </Block>
        
        <Block title="H21. Satisfação irrigação">
          <Question name="H21" update/>
          {s["H21"] && (s["H21"] == 4 || s["H21"] == 5) && <>
            <Layout width={2}>
              <Question name="H21A_1" update/>
              {s["H21A_1"] && s["H21A_1"] == 98 && <Question name="H21A_1O"/>}
            </Layout>
            <Layout width={2}>
              <Question name="H21A_2" update/>
              {s["H21A_2"] && s["H21A_2"] == 98 && <Question name="H21A_2O"/>}
            </Layout>
            <Layout width={2}>
              <Question name="H21A_3" update/>
              {s["H21A_3"] && s["H21A_3"] == 98 && <Question name="H21A_3O"/>}
            </Layout>
          </>}
        </Block>

        <Block title="H11. Culturas de alimentação">
          <IntroText>No geral, quais são as 3 principais culturas que asseguram a alimentação do agregado familiar?</IntroText>
          <Layout width={2}>
            <Question name='H11A' update/>
            <>{(s['H11A'] && s['H11A'] == 98) && <Question name='H11AO'/>}</>
            <Question name='H11B' update/>
            <>{(s['H11B'] && s['H11B'] == 98) && <Question name='H11BO'/>}</>
            <Question name='H11C' update/>
            <>{(s['H11C'] && s['H11C'] == 98) && <Question name='H11CO'/>}</>
          </Layout>
        </Block>

        <Block title="H12. Refeições">
          <Layout width={2}>
            <Question name='H12'/>
          </Layout>
        </Block>

        <Block title="H13. Reservas">
          <Question name='H13'/>
          <Question name='H13A' update/>
          {s["H13A"] && <>
            <Layout width={2}>
              <Question name="H13B" update/>
              {s["H13B"] && s["H13B"] == 98 && <Question name="H13BO"/>}
            </Layout>
            <Layout width={2}>
              <Question name='H13C'/>
            </Layout>
          </>}
        </Block>

        <Block title="H14. Culturas de rendimento">
          <IntroText>Quais são as culturas mais importantes para a geração de rendimento?</IntroText>
          <Layout width={2}>
            <Question name='H14A' update/>
            <>{(s['H14A'] && s['H14A'] == 98) && <Question name='H14AO'/>}</>
            <Question name='H14B' update/>
            <>{(s['H14B'] && s['H14B'] == 98) && <Question name='H14BO'/>}</>
            <Question name='H14C' update/>
            <>{(s['H14C'] && s['H14C'] == 98) && <Question name='H14CO'/>}</>
          </Layout>
        </Block>

        <Block title="H16/H17. Venda de culturas">
          <Layout width={2}>
            <Question name='H16'/>
            <React.Fragment/>
            <Question name='H17' update/>
            {(s['H17'] && s['H17'] == 4) && <Question name='H17A'/>}
            {(s['H17'] && s['H17'] == 98) && <Question name='H17B'/>}
          </Layout>
        </Block>

        <Block title="H18. Sementes">
          <Layout width={2}>
            <Question name='H18' update/>
            <>{(s['H18'] && s['H18'] == 98) && <Question name="H18O"/>}</>
            <Question name='H18A'/>
          </Layout>
        </Block>

        <Block title="H23. Comparação machambas">
          <Question name="H23"/>
        </Block>
      </>
    )
  }

  renderTreesTab = () => {
    let s = this.state.data as any;
    return (
      <>
        <Divider hidden/>
        <Block title="I. Árvores">
          <Layout width={4}><Question name="I1"/><Question update name="I1P"/>{s['I1P'] == 98 && <Question name="I1PO"/>}</Layout>
          <Layout width={4}><Question name="I2"/><Question update name="I2P"/>{s['I2P'] == 98 && <Question name="I2PO"/>}</Layout>
          <Layout width={4}><Question name="I3"/><Question update name="I3P"/>{s['I3P'] == 98 && <Question name="I3PO"/>}</Layout>
          <Layout width={4}><Question name="I4"/><Question update name="I4P"/>{s['I4P'] == 98 && <Question name="I4PO"/>}</Layout>
          <Layout width={4}><Question name="I5"/><Question update name="I5P"/>{s['I5P'] == 98 && <Question name="I5PO"/>}</Layout>
          <Layout width={4}><Question name="I6"/><Question update name="I6P"/>{s['I6P'] == 98 && <Question name="I6PO"/>}</Layout>
          <Layout width={4}><Question name="I7"/><Question update name="I7P"/>{s['I7P'] == 98 && <Question name="I7PO"/>}</Layout>
          <Layout width={4}><Question name="I8"/><Question update name="I8P"/>{s['I8P'] == 98 && <Question name="I8PO"/>}</Layout>
          <Layout width={4}><Question name="I9"/><Question update name="I9P"/>{s['I9P'] == 98 && <Question name="I9PO"/>}</Layout>
          <Layout width={4}><Question name="I10"/><Question update name="I10P"/>{s['I10P'] == 98 && <Question name="I10PO"/>}</Layout>
          <Layout width={4}><Question name="I11"/><Question update name="I11P"/>{s['I11P'] == 98 && <Question name="I11PO"/>}</Layout>
          <Layout width={4}><Question name="I12"/><Question update name="I12P"/>{s['I12P'] == 98 && <Question name="I12PO"/>}</Layout>
          <Layout width={4}><Question name="I13"/><Question update name="I13P"/>{s['I13P'] == 98 && <Question name="I13PO"/>}</Layout>
          <Layout width={4}><Question name="I14"/><Question update name="I14P"/>{s['I14P'] == 98 && <Question name="I14PO"/>}</Layout>
          <Layout width={4}><Question name="I15"/><Question name="I15O"/><Question update name="I15P"/>{s['I15P'] == 98 && <Question name="I15PO"/>}</Layout>
          <Layout width={4}><Question name="I16"/><Question name="I16O"/><Question update name="I16P"/>{s['I16P'] == 98 && <Question name="I16PO"/>}</Layout>
        </Block>
      </>  
    )
  }

  renderAnimalsTab = () => {
    let s = this.state.data as any;
    return (
      <>
        <Divider hidden/>

        <Block title="J1. Animais">
          <Layout width={4}><Question name="J3A"/><Question update name="J4A"/>{s['J4A'] == 98 && <Question name="J4AO"/>}</Layout>
          <Layout width={4}><Question name="J3B"/><Question update name="J4B"/>{s['J4B'] == 98 && <Question name="J4BO"/>}</Layout>
          <Layout width={4}><Question name="J3C"/><Question update name="J4C"/>{s['J4C'] == 98 && <Question name="J4CO"/>}</Layout>
          <Layout width={4}><Question name="J3D"/><Question update name="J4D"/>{s['J4D'] == 98 && <Question name="J4DO"/>}</Layout>
          <Layout width={4}><Question name="J3E"/><Question update name="J4E"/>{s['J4E'] == 98 && <Question name="J4EO"/>}</Layout>
          <Layout width={4}><Question name="J3F"/><Question update name="J4F"/>{s['J4F'] == 98 && <Question name="J4FO"/>}</Layout>
          <Layout width={4}><Question name="J3G"/><Question update name="J4G"/>{s['J4G'] == 98 && <Question name="J4GO"/>}</Layout>
          <Layout width={4}><Question name="J3J"/><Question update name="J4J"/>{s['J4J'] == 98 && <Question name="J4JO"/>}</Layout>
          <Layout width={4}><Question name="J3H"/><Question update name="J4H"/>{s['J4H'] == 98 && <Question name="J4HO"/>}</Layout>
          <Layout width={4}><Question name="J3I"/><Question update name="J4I"/>{s['J4I'] == 98 && <Question name="J4IO"/>}</Layout>
          <Layout width={4}><Question name="J3K"/><Question name="J3KO"/><Question update name="J4K"/>{s['J4K'] == 98 && <Question name="I4KO"/>}</Layout>
        </Block>

        <Block title="J7. Assistência sanitária">
          <Question name='J7'/>
        </Block>

        <Block title="J13. Satisfação condições de pasto">
          <Question name="J13" update/>
          {s["J13"] && (s["J13"] == 4 || s["J13"] == 5) &&
            <>
            <Layout width={2}>
              <Question name="J13_A" update/>
              {s["J13_A"] && s["J13_A"] == 98 && <Question name="J13_AO"/>}
            </Layout>
            <Layout width={2}>
              <Question name="J13_B" update/>
              {s["J13_B"] && s["J13_B"] == 98 && <Question name="J13_BO"/>}
            </Layout>
            <Layout width={2}>
              <Question name="J13_C" update/>
              {s["J13_C"] && s["J13_C"] == 98 && <Question name="J13_CO"/>}
            </Layout>
          </>}
        </Block>

        <Block title="J14. Comparação acesso a pasto">
          <Question name="J14"/>
        </Block>

        <Block title= "J9. Conflito homem/fauna bravia">
          <Question name='J9' update/>
          {s["J9"] == true && <Question name="J9_1"/>}
        </Block>
        
        {s['J9'] == true && <>
          <Block title="J10. Tipo de danos causados">
            <Question name='J100'/>
            <Question name='J10A'/>
            <Question name='J10B'/>
            <Question name='J10C'/>
            <Question name='J10D'/>
            <Question name='J10E'/>
            <Question name='J10F'/>
            <Question name='J10G'/>
            <Question name='J10H' update/>
            {s["J10H"] == true && <Question name='J10HO'/>}
          </Block>
          <Block title="J11. Animais selvagens mais frequentes">
            <IntroText>Por ordem de prioridade, enumere quais animais têm causado danos mais frequentemente?</IntroText>
            <Layout width={2}>
              <Question name='J11A' update/>
              <>{(s['J11A'] && s['J11A'] == 98) && <Question name='J11AO'/>}</>
              <Question name='J11B' update/>
              <>{(s['J11B'] && s['J11B'] == 98) && <Question name='J11BO'/>}</>
              <Question name='J11C' update/>
              <>{(s['J11C'] && s['J11C'] == 98) && <Question name='J11CO'/>}</>
            </Layout>
          </Block>
          <Block title="J12. Comparação conflito homem-fauna bravia">
            <Question name="J12"/>
          </Block>
        </>}
      </>  
    )
  }

  renderMobilityTab = () => {
    let s = this.state.data as any;
    return (<>
      <Divider hidden/>
      <Block title="Serviços/instalações/recursos">
        <Flex stackable>
          {[4,6,7,8,12,13,11,22,9,19,20,21,23].map(k => 
            <Flex.Row key={k}>
              <Flex.Column width={1}>
                <Question name={"L1_"+ k} update/>
              </Flex.Column>
              <Flex.Column width={3}>
                {s["L1_"+k] && s["L1_"+k] == 1 && <Layout width={3}>
                  <Question name={"L2_"+k}/>
                  <Question name={"L3_"+k} update/>
                  {s["L3_"+k] && s["L3_"+k] == 98 && <Question name={"L3_"+k+"O"}/>}
                </Layout>}
              </Flex.Column>
            </Flex.Row>
          )}
        </Flex>
      </Block>
    </>)
  }

  renderPerceptionTab = () => {
    let s = this.state.data as any;
    return (
      <>
        <Divider hidden/>
        <Block title="N1.4. Satisfação e bem-estar">
          <Layout width={2}>
            <Question name="N1_4S"/>
          </Layout>
        </Block>

        <Block title="N1. Preocupações">
          <IntroText>Quais são as suas maiores preocupações em relação à situação da sua comunidade hoje?</IntroText>
          <Layout width={2}>
            <>
              <Question name="N1_0"/>
              <Question name="N1_1"/>
              <Question name="N1_2"/>
              <Question name="N1_3"/>
              <Question name="N1_4"/>
              <Question name="N1_5"/>
              <Question name="N1_6"/>
              <Question name="N1_7"/>
              <Question name="N1_8"/>
              <Question name="N1_9"/>
            </>
            <>
              <Question name="N1_10"/>
              <Question name="N1_11"/>
              <Question name="N1_12"/>
              <Question name="N1_13"/>
              <Question name="N1_14"/>
              <Question name="N1_15"/>
              <Question name="N1_17"/>
              <Question name="N1_18"/>
              <Question name="N1_19"/>
              <Question name="N1_20" update/>
              {s['N1_20'] == 1 && <Question name="N1_20O"/>}
            </>
          </Layout>
        </Block>

        <Block title="N1.2. Factores de melhoria">
          <IntroText>Quais dos seguintes factores considera mais importante para poder, nesta fase, melhorar as suas condições de vida?</IntroText>
          <Layout width={2}>
            <Question name="N1_2A" update/>
            <>{(s['N1_2A'] && s['N1_2A'] == 98) && <Question name="N1_2AO"/>}</>
            <Question name="N1_2B" update/>
            <>{(s['N1_2B'] && s['N1_2B'] == 98) && <Question name="N1_2BO"/>}</>
            <Question name="N1_2C" update/>
            <>{(s['N1_2C'] && s['N1_2C'] == 98) && <Question name="N1_2CO"/>}</>
          </Layout>
        </Block>

        <Block title="N1.3. Actores de melhoria">
          <IntroText>Que actores ou instituições podem ter influência sobre os assuntos mais importantes para melhorar as suas condições de vida?</IntroText>
          <Layout width={2}>
            <Question name="N1_3A" update/>
            <>{(s['N1_3A'] && s['N1_3A'] == 98) && <Question name="N1_3AO"/>}</>
            <Question name="N1_3B" update/>
            <>{(s['N1_3B'] && s['N1_3B'] == 98) && <Question name="N1_3BO"/>}</>
            <Question name="N1_3C" update/>
            <>{(s['N1_3C'] && s['N1_3C'] == 98) && <Question name="N1_3CO"/>}</>
          </Layout>
        </Block>

        <Block title="Reclamações">
          <Question name="N3" update/>
          {s["N3"] && <>
            <Divider/>
            <Question name="N3_1_1"/>
            <Question name="N3_1_2"/>
            <Question name="N3_1_3"/>
            <Question name="N3_1_4"/>
            <Question name="N3_1_5"/>
            <Question name="N3_1_6"/>
            <Question name="N3_1_98" update/>
            {s["N3_1_98"] == 1 && <Question name="N3_1_98O"/>}
          </>}
        </Block>

        <Block title="Situação inconveniente durante reassentamento">
          <Question name="N4" update/>
          {s["N4"] == 1 && <>
            <Question name="N4_1" update/>
            {s["N4_1"] == 1 && <Question name="N4_2"/>}
          </>}
        </Block>

        <Block title="N5. Satisfação reassentamento">
          <Layout width={2}>
            <Question name="N5" update/>
          </Layout>
          {s["N5"] && (s["N5"] == 4 || s["N5"] == 5) &&
            <>
              <Question name="N5_1" update/>
              {s["N5_1"] && s["N5_1"] == 98 && <Question name="N5_1O"/>}
              <Question name="N5_2" update/>
              {s["N5_2"] && s["N5_2"] == 98 && <Question name="N5_2O"/>}
              <Question name="N5_3" update/>
              {s["N5_3"] && s["N5_3"] == 98 && <Question name="N5_3O"/>}
          </>}
        </Block>

        <Block title="N6. Reassentamento conclúido?">
          <Layout width={2}>
            <Question name="N6" update/>
          </Layout>
          {s["N6"] && s["N6"] == 2 &&
            <>
              <Question name="N6_1" update/>
              {s["N6_1"] && s["N6_1"] == 98 && <Question name="N6_1O"/>}
              <Question name="N6_2" update/>
              {s["N6_2"] && s["N6_2"] == 98 && <Question name="N6_2O"/>}
              <Question name="N6_3" update/>
              {s["N6_3"] && s["N6_3"] == 98 && <Question name="N6_3O"/>}
            </>}
        </Block>

        <Block title="N7. Comparação pós-reassentamento">
          <Layout width={2}>
            <Question name="N7" update/>
          </Layout>
          {s["N7"] && s["N7"] == 2 &&
            <>
              <Question name="N7_1" update/>
              {s["N7_1"] && s["N7_1"] == 98 && <Question name="N7_1O"/>}
              <Question name="N7_2" update/>
              {s["N7_2"] && s["N7_2"] == 98 && <Question name="N7_2O"/>}
              <Question name="N7_3" update/>
              {s["N7_3"] && s["N7_3"] == 98 && <Question name="N7_3O"/>}
          </>}
        </Block>        

      </>
    )
  }


  render() {
    let p = this.props;
    let s = this.state.data as any;
    return (
      <Form data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
        <QuestionContext.Provider value={{ data: s }} >
          <Tabs nohiddenrender underlined>
            <Tabs.Pane label="Geral">
              {this.renderGeneralTab()}
            </Tabs.Pane>
            <Tabs.Pane label="Educação">
              {this.renderMembersTab()}
            </Tabs.Pane>
            <Tabs.Pane label="Saúde">
              {this.renderHealthTab()}
            </Tabs.Pane>
            <Tabs.Pane label="Economia">
              {this.renderIncomeTab()}
            </Tabs.Pane>
            <Tabs.Pane label="Redes Sociais">
              {this.renderNetworkTab()}
            </Tabs.Pane>
            <Tabs.Pane label="Bens">
              {this.renderGoodsTab()}
            </Tabs.Pane>
            <Tabs.Pane label="Propriedade">
              {this.renderPropertyTab()}
            </Tabs.Pane>
            <Tabs.Pane label="Agricultura">
              {this.renderAgricultureTab()}
            </Tabs.Pane>
            <Tabs.Pane label="Árvores">
              {this.renderTreesTab()}
            </Tabs.Pane>
            <Tabs.Pane label="Animais">
              {this.renderAnimalsTab()}
            </Tabs.Pane>
            <Tabs.Pane label="Mobilidade">
              {this.renderMobilityTab()}
            </Tabs.Pane>
            <Tabs.Pane label="Percepção">
              {this.renderPerceptionTab()}
            </Tabs.Pane>            
          </Tabs>
        </QuestionContext.Provider>
      </Form>
    )
  }
}

const IntroText = styled('div')`
  font-style: italic;
  margin-bottom: 10px;
`

const RemoveIcon = styled('div')` 
  position: absolute;
  right: 13px;
  top: 13px;
`



export { ResponseForm };
