import { StampedModel, ResourceFactory } from '../services/';
import { Attachment } from './Attachment';
import { Post } from './Post';

class Request extends StampedModel {
  public title: string = '';
  public date: string = "";
  public description: string = '';
  public attachments_count: number = 0;
  public posts_count: number = 0;

  public stakeholder: { 
    id: number;
    name: string;
  } = null;

  public attachments: Attachment[] = [];
  public posts: Post[] = [];
}

const RequestFactory = ResourceFactory.create<Request>(Request, 'request');

export { Request, RequestFactory };