import * as React from 'react';
import { District, DistrictFactory } from '../../resource/'; 
import { IAuthProps, List, IListState, IListProps, SearchFilter } from '../../services/';
import { IconBar, Export, Loc } from '../../modules';

import { Form } from '@independent-software/typeui/controls/Form';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { DataTable } from '@independent-software/typeui/controls/DataTable';
import { Number } from '@independent-software/typeui/formatters/Number';

interface IProps extends IListProps {
  /**
   * Event is fired when a district is clicked.
   */
  onClick?: (district: District) => void;
}

interface IState extends IListState<District> {
}

class ListDistricts extends List<District, IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props, DistrictFactory, 'name', 'asc');

    // Initialize state (list initializes its own part of the state):
    this.state = {
      ...this.state
    };
  }

  handleSearch = (value:string) => {
    this.setFilter('q', 'like', value);
  }  

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Form.Uncontrolled hint={<Loc msg="districts_filter_search_hint"/>}>
          <SearchFilter value={this.getFilter('q', 'like')} onSearch={this.handleSearch}/>
        </Form.Uncontrolled>
      </Panel.Content>
      <Panel.Footer>
        <Export onExport={this.handleExport}/>
      </Panel.Footer>      
    </React.Fragment>

    return (
      <React.Fragment>
        <IconBar>
          <Panel.Icon icon="tools" width={300}>
            {filter}
          </Panel.Icon>
        </IconBar> 
        <DataTable error={this.state.error} loading={this.state.loading} scrollTop={this.state.scrollTop} onScroll={this.handleScroll} data={this.state.items} onFetch={this.handleFetch} onClick={p.onClick} onOrder={this.handleOrder} order={this.getOrder()} dir={this.getDir()}>
          <DataTable.Column weight={5} label={<Loc msg="districts_list_hdr_name"/>} order="name" dir="asc">{(item:District) => <span>{item.name}</span>}</DataTable.Column>
          <DataTable.Column weight={1} align="right" label={<Loc msg="districts_list_hdr_communities"/>} order="communities_count" dir="asc">{(item:District) => <Number value={item.communities_count} decimals={0}/>}</DataTable.Column>
        </DataTable>
        <Dialog.Xhr open={this.state.exportError != null} error={this.state.exportError} onClose={this.handleCloseDialog}/>
      </React.Fragment>
    );
  }
}

export { ListDistricts };
