import * as React from 'react'
import styled from '@independent-software/typeui/styles/Theme'
import { RouteComponentProps } from 'react-router'
import { LanguageSwitch } from '../modules';

interface IScreenProps {
  className?: string;
  children?: React.ReactNode;
}

class PublicBase extends React.Component<IScreenProps & RouteComponentProps, {}> {

  constructor(props: IScreenProps & RouteComponentProps) {
    super(props);
  }

  render() {
    let p = this.props;

    return (
      <div className={p.className}>
        <LangHolder>
          <LanguageSwitch/>
        </LangHolder>
        <Content>
          <Box>
            <Logo><use xlinkHref={"sprites.svg#limpopo"}></use></Logo>
            {p.children}
          </Box>
        </Content>
      </div>
    );
  }
}

const Content = styled('div')`
  position: absolute;
  width: 400px;
  left: 50%;
  margin-left: -200px;
  padding-top: 20vh;
`

const Logo = styled('svg')`
  width: 350px;
  height: 100px;
  margin-bottom: 40px;
`

const Box = styled('div')`
  background: #333;
  padding: 25px 25px 25px 25px;
  border-radius: 4px;
`

const LangHolder = styled('div')`
  position: absolute;
  right: 20px;
  top: 20px;
`

const Public = styled(PublicBase)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(rgb(21, 71, 121), rgb(16, 108, 200));
`

export { Public };
