import * as React from 'react';
import { Section, Viewer, AttachmentManager, Block, Loc } from '../../modules';
import { Stakeholder, StakeholderFactory, Grievance } from '../../resource/'; 
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';

import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Header } from '@independent-software/typeui/controls/Header';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Label } from '@independent-software/typeui/controls/Label';
import { Button } from '@independent-software/typeui/controls/Button';
import { Table } from '@independent-software/typeui/controls/Table';
import { ListGrievances } from '../Grievance';
import { TLocalization } from '../../modules/Loc/TLocalization';

class ViewStakeholder extends React.Component<IAuthProps & RouteComponentProps<{id:string}>, {}> {
  private canEdit = (stakeholder: Stakeholder): boolean => {
    return this.props.auth && this.props.auth.hasRight('can_edit_stakeholders');
  }  

  private handleEdit = () => {
    this.props.history.push(`/stakeholders/edit/${parseInt(this.props.match.params.id)}`);
  }

  private handleDelete = () => {
    ToastService.toast(<Loc msg="stakeholders_msg_deleted"/>);
    this.props.history.push('/stakeholders');        
  }    

  private handleClickCommunity = (id: number) => {
    this.props.history.push(`/communities/${id}`);
  }

  private handleClickGrievance = (grievance: Grievance) => {
    this.props.history.push(`/grievances/${grievance.id}`);
  }

  render() {
    let p = this.props;
    return (
      <Viewer<Stakeholder> 
        auth={p.auth}
        id={parseInt(p.match.params.id)}
        factory={StakeholderFactory}
        canEdit={this.canEdit}
        onEdit={this.handleEdit}
        onDelete={this.handleDelete}
        content={(stakeholder: Stakeholder) => 
          <React.Fragment>
            <Section dark padded>
              <Flex>
                <Flex.Row>
                  <Flex.Column>
                    <Header size="h2">{stakeholder.name}</Header>
                  </Flex.Column>
                </Flex.Row>
              </Flex>
            </Section>
            <Tabs underlined nohiddenrender>
              <Tabs.Pane label={<Loc msg="stakeholders_form_tab_data"/>}>
                <Section padded>
                  <Flex.Columns count={2}>
                    <React.Fragment>
                      {stakeholder.community &&
                      <Block title={<Loc msg="stakeholders_fields_community_label"/>}>
                        {stakeholder.community.name}
                        {p.auth.hasRight('can_view_communities') && 
                          <div style={{float:'right'}}>
                            <Button compact basic size="mini" onClick={() => this.handleClickCommunity(stakeholder.community.id)}><Loc msg="btn_view"/></Button>
                          </div>
                        }                        
                      </Block>}
                      <Block title={<Loc msg="stakeholders_block_identification"/>}>
                        <Table striped transparent>
                          <tbody>
                            <tr><td><Loc msg="stakeholders_fields_name_label"/></td><td>{stakeholder.name}</td></tr>
                            <tr><td><Loc msg="stakeholders_fields_type_label"/></td><td>
                              <Loc msg={("stakeholders_type_" + stakeholder.type) as TLocalization}/>
                              {stakeholder.type == 'other' && <React.Fragment> ({stakeholder.type_other})</React.Fragment>}
                            </td></tr>
                          </tbody>
                        </Table>
                      </Block>
                      <Block title={<Loc msg="stakeholders_block_contact"/>}>
                      <Table striped transparent>
                          <tbody>
                            <tr><td><Loc msg="stakeholders_fields_phone_label"/></td><td>{stakeholder.phone}</td></tr>
                            <tr><td><Loc msg="stakeholders_fields_contact_label"/></td><td>{stakeholder.contact}</td></tr>
                          </tbody>
                        </Table>
                      </Block>
                    </React.Fragment>
                    {stakeholder.type == 'pap' && 
                    <Block title={<Loc msg="stakeholders_block_data"/>}>
                      <Table striped transparent>
                        <tbody>
                          <tr><td><Loc msg="stakeholders_fields_age_label"/></td><td>{stakeholder.age}</td></tr>
                          <tr><td><Loc msg="stakeholders_fields_sex_label"/></td><td>
                            {stakeholder.sex == 'm' && <Loc msg="stakeholders_sex_male"/>}
                            {stakeholder.sex == 'f' && <Loc msg="stakeholders_sex_female"/>}
                            {stakeholder.sex == '?' && <Loc msg="stakeholders_sex_unknown"/>}
                          </td></tr>
                          <tr><td><Loc msg="stakeholders_fields_state_label"/></td><td><Loc msg={("stakeholders_state_" + stakeholder.state) as TLocalization}/></td></tr>
                          <tr><td><Loc msg="stakeholders_fields_community_leader_label"/></td><td><Loc msg={("stakeholders_community_leader_" + stakeholder.community_leader) as TLocalization}/></td></tr>
                          <tr><td><Loc msg="stakeholders_fields_head_of_household_label"/></td><td><Loc msg={("stakeholders_head_of_household_" + stakeholder.head_of_household) as TLocalization}/></td></tr>
                        </tbody>
                      </Table>
                    </Block>}
                  </Flex.Columns>
                </Section>
              </Tabs.Pane>
              <Tabs.Pane label={<span><Loc msg="stakeholders_form_tab_grievances"/> <Label size="small">{stakeholder.grievances_count}</Label></span>}>
                <ListGrievances auth={p.auth} name="stakeholder_grievances" stakeholderId={stakeholder.id} onClick={this.handleClickGrievance}/>
              </Tabs.Pane>      
              <Tabs.Pane label={<span><Loc msg="stakeholders_form_tab_attachments"/> <Label size="small">{stakeholder.attachments.length}</Label></span>}>
                <Section padded>
                  <AttachmentManager auth={p.auth} attachments={stakeholder.attachments}/>
                </Section>
              </Tabs.Pane>                
            </Tabs>
          </React.Fragment>}>
      </Viewer>
    );
  }
}

export { ViewStakeholder };
