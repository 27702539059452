import * as React from 'react';

import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Form } from '@independent-software/typeui/controls/Form';
import { DataTable } from '@independent-software/typeui/controls/DataTable';
import { Panel } from '@independent-software/typeui/controls/Panel';

import { Community, CommunityFactory, Response, ResponseFactory, Survey, SurveyFactory } from '../../resource/'; 
import { List, IListState, IListProps, SearchFilter, Query } from '../../services/';
import { IconBar, Export, Loc } from '../../modules/';
import { LocalizedDatum } from '../../formatters';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';

interface IProps extends IListProps {
  /**
   * If set, list is prefiltered by a survey and
   * survey column and filter are hidden.
   */
  surveyId?: number;    
  /**
   * Event is fired when a Response is clicked.
   */
  onClick?: (response: Response) => void;
}

interface IState extends IListState<Response> {
  surveys: Survey[];
  communities: Community[];
}

class ListResponses extends List<Response, IProps, IState> {
  constructor(props: IProps) {
    super(props, ResponseFactory, 'number', 'desc');

    // Initialize state (list initializes its own part of the state):
    this.state = {
      ...this.state,
      surveys: [],
      communities: []
    };

    if(this.props.surveyId) this.setFilter('survey', 'eq', { id: this.props.surveyId }, true);
  }

  componentDidMount() {
    this.handleSurveySearch();    
    this.handleCommunitySearch();
  }

  handleChangeSurvey = (value: Survey) => {
    this.setFilter('survey', 'eq', value);
  }

  handleSurveySearch = (q?:string) => {
    // Retrieve a list of surveys:
    let query = new Query('name', 'asc');
    if (q) query.setFilter('q', 'like', q);
    SurveyFactory.getSome(this.props.auth, 0, 999, query)
      .then((res) => this.setState({ surveys: res.items }));
  }

  handleChangeCommunity = (value: Community) => {
    this.setFilter('community', 'eq', value);
  }

  handleCommunitySearch = (q?:string) => {
    // Retrieve a list of communities:
    let query = new Query('name', 'asc');
    if (q) query.setFilter('q', 'like', q);
    CommunityFactory.getSome(this.props.auth, 0, 999, query)
      .then((res) => this.setState({ communities: res.items }));
  }

  handleChangeMonoparental = (value: string) => {
    this.setFilter('monoparental', 'eq', value);
  }

  handleChangePoligamo = (value: string) => {
    this.setFilter('poligamo', 'eq', value);
  }

  handleSearch = (value:string) => {
    this.setFilter('q', 'like', value);
  }

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Form.Uncontrolled hint={<Loc msg="responses_filter_search_hint"/>}>
          <SearchFilter value={this.getFilter('q', 'like')} onSearch={this.handleSearch}/>
        </Form.Uncontrolled>
        {!p.surveyId && 
          <Form.Uncontrolled hint={<Loc msg="responses_filter_survey_hint"/>}>
            <Dropdown onSearch={this.handleSurveySearch} name="survey" fluid clearable value={this.getFilter('survey', 'eq')} data={this.state.surveys} placeholder="Survey" label={(item:Survey) => item.name} onChange={this.handleChangeSurvey}>
              <Dropdown.Column>{(item:Survey) => item.name}</Dropdown.Column>
            </Dropdown>
          </Form.Uncontrolled>}        
        <Form.Uncontrolled hint={<Loc msg="responses_filter_community_hint"/>}>
          <Dropdown onSearch={this.handleCommunitySearch} name="community" fluid clearable value={this.getFilter('community', 'eq')} data={this.state.communities} placeholder="Community" label={(item:Community) => item.name} onChange={this.handleChangeCommunity}>
            <Dropdown.Column>{(item:Community) => item.name}</Dropdown.Column>
          </Dropdown>
        </Form.Uncontrolled>
        <Form.Uncontrolled>
          <Checkbox type="toggle" checked={this.getFilter('monoparental', 'eq')} label="Somente AF monoparentais" onChange={this.handleChangeMonoparental}/>
        </Form.Uncontrolled>
        <Form.Uncontrolled>
          <Checkbox type="toggle" checked={this.getFilter('poligamo', 'eq')} label="Somente AF polígamos" onChange={this.handleChangePoligamo}/>
        </Form.Uncontrolled>        
      </Panel.Content>
      <Panel.Footer>
        <Export onExport={this.handleExport}/>
      </Panel.Footer>        
    </React.Fragment>    
    
    return (
      <React.Fragment>
        <IconBar>
          <Panel.Icon icon="tools" width={300}>
            {filter}
          </Panel.Icon>
        </IconBar>       
        <DataTable error={this.state.error} loading={this.state.loading} scrollTop={this.state.scrollTop} onScroll={this.handleScroll} data={this.state.items} onFetch={this.handleFetch} onClick={p.onClick} onOrder={this.handleOrder} order={this.getOrder()} dir={this.getDir()}>
          {!p.surveyId && <DataTable.Column weight={1} label={<Loc msg="surveys_list_hdr_survey"/>} order="survey_name" dir="asc">{(item:Response) => <span>{item.survey_name}</span>}</DataTable.Column>}
          <DataTable.Column weight={1} label={<Loc msg="responses_hdr_number"/>} order="number" dir="asc">{(item:Response) => item.number}</DataTable.Column>
          <DataTable.Column weight={2} label={<Loc msg="responses_hdr_date"/>} order="date" dir="asc">{(item:Response) => <LocalizedDatum.LongDate value={item.date}/>}</DataTable.Column>
          <DataTable.Column weight={1} label={<Loc msg="responses_hdr_weight"/>} order="weight" dir="asc">{(item:Response) => item.weight}</DataTable.Column>
          <DataTable.Column weight={2} label={<Loc msg="responses_hdr_community"/>} order="community_name" dir="asc">{(item:Response) => item.community_name}</DataTable.Column>
          {/* (Hidden for sensitivity reasons) <DataTable.Column weight={3} label={<Loc msg="responses_hdr_name"/>} order="name" dir="asc">{(item:Response) => item.name}</DataTable.Column> */}
        </DataTable>
        <Dialog.Xhr open={this.state.exportError != null} error={this.state.exportError} onClose={this.handleCloseDialog}/>
      </React.Fragment>
    );
  }
}

export { ListResponses };
