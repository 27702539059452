import axios from 'axios';
import * as React from 'react';
import { Section, Viewer, Block, Loc } from '../../modules';
import { Attachment, AttachmentFactory } from '../../resource/'; 
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';
import { App } from '../../App';

import { Icon } from '@independent-software/typeui/controls/Icon';
import { Header } from '@independent-software/typeui/controls/Header';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Label } from '@independent-software/typeui/controls/Label';
import { Image } from '@independent-software/typeui/controls/Image';
import { Message } from '@independent-software/typeui/controls/Message';
import { Button } from '@independent-software/typeui/controls/Button';
import { Table } from '@independent-software/typeui/controls/Table';
import { Filesize } from '@independent-software/typeui/formatters/Filesize';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { List } from '@independent-software/typeui/controls/List';
import { LocalizedDatum } from '../../formatters';
import { Input } from '@independent-software/typeui/controls/Input';

interface IState {
  downloading: boolean;
  editing: boolean;
  filename: string;
}

class ViewAttachment extends React.Component<IAuthProps & RouteComponentProps<{id:string}>, IState> {
  
  constructor(props: IAuthProps & RouteComponentProps<{id:string}>) {
    super(props);
    this.state = { 
      downloading: false,
      editing: false,
      filename: ''
    };
  }

  private canDelete = (item: Attachment) => {
    return this.props.auth.hasRight('can_edit_attachments');
  }

  private handleDelete = () => {
    ToastService.toast(<Loc msg="attachments_msg_deleted"/>);
    this.props.history.push('/attachments');
  }

  private handleDownload = (attachment: Attachment) => {
    this.setState({ downloading: true });
    axios.get(`${App.apiURL}attachment/download/${attachment.id}?api_token=${this.props.auth.token}`, { responseType: 'blob'}) 
    .then(response => {
      this.setState({ downloading: false });
      // Find the content-disposition header.
      let disposition:string = response.headers['content-disposition'];
      // Using a regexp, retrieve the filename from it.
      let regexp = new RegExp('\"(.*)\"');
      let res:RegExpExecArray = regexp.exec(disposition);
      let filename = res[1];
      // Download the file.
      saveAs(response.data, filename);
    })
    .catch(error => {
      this.setState({ downloading: false });
    });    
  }

  private handleEdit = (attachment: Attachment) => {
    this.setState({ editing: true });
  }

  private saveEdit = (attachment: Attachment) => {
    // Update Attachment object with new filename.
    let oldFilename = attachment.filename;
    attachment.filename = this.state.filename;
    // Save Attachment object.
    attachment.$update(this.props.auth)
      .then(res => {
        ToastService.toast(<Loc msg="attachments_msg_updated"/>);
      })
      .catch(error => {
        ToastService.toast(<Loc msg="attachments_msg_update_error"/>);
        this.setState({
          filename: oldFilename
        });
        attachment.filename = oldFilename;
      })
      .finally(() => {
        this.setState({ editing: false });
      });
  }

  private handleClickCommunity = (id: number) => {
    this.props.history.push(`/communities/${id}`);
  }

  private handleClickAction = (id: number) => {
    this.props.history.push(`/actions/${id}`);
  }  

  private handleClickGrievance = (id: number) => {
    this.props.history.push(`/grievances/${id}`);
  }  

  private handleClickStakeholder = (id: number) => {
    this.props.history.push(`/stakeholders/${id}`);
  }    

  private isFilenameValid = () => {
    if (!/^[a-zA-Z0-9À-ž\-_, ]+\.[a-zA-Z0-9]+$/.test(this.state.filename)) return false;
    return this.state.filename.length >= 3;
  }

  render() {
    let p = this.props;
    return (
      <Viewer<Attachment> 
        auth={p.auth}
        id={parseInt(p.match.params.id)}
        factory={AttachmentFactory}
        onLoad={(attachment: Attachment) => this.setState({filename: attachment.filename})}
        canEdit={() => false}
        canDelete={this.canDelete}
        onEdit={null}
        onDelete={this.handleDelete}
        confirmMessage={<React.Fragment>Are you sure you wish to delete this attachment permanently?</React.Fragment>}
        controls={(attachment) => 
          <Button primary onClick={() => this.handleDownload(attachment)}>
            {!this.state.downloading && <Icon name="download"/>}
            {this.state.downloading && <Icon name="circle-notch" loading/>}
            Download <Label attached="right"><Filesize value={attachment.size}/></Label>
          </Button>}
        content={(attachment: Attachment) => 
          <React.Fragment>
            <Section dark padded>
              <Flex>
                <Flex.Row>
                  <Flex.Column>
                    <Header size="h2">{attachment.filename}</Header>
                  </Flex.Column>
                </Flex.Row>
              </Flex>
            </Section>
            <Tabs underlined>
              <Tabs.Pane label="File">
                <Section padded>
                  <Flex.Columns count={2}>
                    <React.Fragment>
                      <Table striped>
                        <tbody>
                          <tr>
                            <td style={{width:'120px'}}>File name</td>
                            <td>
                              {this.state.editing == false && this.state.filename}
                              {this.state.editing == true && 
                                <Input fluid placeholder="Filename" value={this.state.filename} error={this.isFilenameValid() ? null : true} onChange={(value: string) => this.setState({filename: value}) }/>
                              }
                            </td>
                            <td style={{textAlign:'right', width:'40px'}}>
                              {this.state.editing == false && this.props.auth.hasRight('can_edit_attachments') && 
                              <Button icon size="small" onClick={() => this.handleEdit(attachment)}><Icon title="Rename" name="edit"/></Button>}
                              {this.state.editing == true && this.props.auth.hasRight('can_edit_attachments') &&
                              <Button primary icon size="small" disabled={this.isFilenameValid() ? null : true} onClick={() => this.saveEdit(attachment)}><Icon title="Save" name="check"/></Button>}
                            </td>
                          </tr>
                          <tr>
                            <td>Size</td>
                            <td><Filesize value={attachment.size}/></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>MIME</td>
                            <td>{attachment.mime}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Created</td>
                            <td><LocalizedDatum.LongDateTime value={attachment.created_at}/></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </Table>
                    </React.Fragment>
                    {attachment.is_image == true &&
                      <Image alt="Preview" fluid size="big" rounded src={`${App.apiURL}attachment/download/${attachment.id}?api_token=${p.auth.token}&size=p`}>
                        <Label>
                          <Icon name="times" color="darkred" />
                          Image load error
                        </Label>                    
                      </Image>}
                    {!attachment.is_image &&
                      <Message>
                        No preview is available for this attachment type.
                      </Message>}
                  </Flex.Columns>
                </Section>

              </Tabs.Pane>
              <Tabs.Pane label="Links">
                <Section padded>
                  <Flex.Columns count={2}>
                    <React.Fragment>
                      {p.auth.hasRight('can_view_communities') &&
                        <Block title={<React.Fragment>Communities <Label size="tiny">{attachment.communities.length}</Label></React.Fragment>}>
                          <List selection animated>
                            {attachment.communities.map((c) => <List.Item key={c.id} onClick={() => this.handleClickCommunity(c.id)}>{c.name}</List.Item>)}
                          </List>
                        </Block>}
                      {p.auth.hasRight('can_view_actions') &&
                        <Block title={<React.Fragment>Actions <Label size="tiny">{attachment.actions.length}</Label></React.Fragment>}>
                          <List selection animated>
                            {attachment.actions.map((c) => <List.Item key={c.id} onClick={() => this.handleClickAction(c.id)}>{c.title}</List.Item>)}
                          </List>
                        </Block>}
                    </React.Fragment>

                    <React.Fragment>
                      {p.auth.hasRight('can_view_grievances') &&
                        <Block title={<React.Fragment>Grievances <Label size="tiny">{attachment.grievances.length}</Label></React.Fragment>}>
                          <List selection animated>
                            {attachment.grievances.map((c) => <List.Item key={c.id} onClick={() => this.handleClickGrievance(c.id)}>{c.title}</List.Item>)}
                          </List>
                        </Block>}
                      {p.auth.hasRight('can_view_stakeholders') &&
                        <Block title={<React.Fragment>Stakeholders <Label size="tiny">{attachment.stakeholders.length}</Label></React.Fragment>}>
                          <List selection animated>
                            {attachment.stakeholders.map((c) => <List.Item key={c.id} onClick={() => this.handleClickStakeholder(c.id)}>{c.name}</List.Item>)}
                          </List>
                        </Block>}
                    </React.Fragment>
                  </Flex.Columns>

                </Section>
              </Tabs.Pane>
            </Tabs>
          </React.Fragment>}>
      </Viewer>
    );
  }
}

export { ViewAttachment };
