import { StampedModel, ResourceFactory } from '../services/';

class Actiontype extends StampedModel {
  public name: string = '';
  public level: string = '';
  public delivery: string = '';
}

const ActiontypeFactory = ResourceFactory.create<Actiontype>(Actiontype, 'actiontype');

export { Actiontype, ActiontypeFactory };