import * as React from 'react';
import { Container, Content, BottomBar } from '../../modules';
import { RouteComponentProps } from 'react-router';
import { ListLogs } from './ListLogs';
import { IAuthProps } from '../../services/Auth';

class LogList extends React.Component<IAuthProps & RouteComponentProps<any>, {}> {
  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
  }

  render() {
    let p = this.props;
    return (
      <Container>
        <Content>
          <ListLogs auth={p.auth} name="logs"/>
        </Content>
        <BottomBar>
        </BottomBar>
      </Container>
    );
  }
}

export { LogList };
