import * as React from 'react';
import styled from 'styled-components';
import { Section, Viewer, Block, Loc } from '../../modules';
import { Action, Household, HouseholdFactory } from '../../resource/'; 
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';

import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Header } from '@independent-software/typeui/controls/Header';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Table } from '@independent-software/typeui/controls/Table';
import { TLocalization } from '../../modules/Loc/TLocalization';
import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Markdown } from '@independent-software/typeui/formatters/Markdown';
import { ListActions } from '../Action';
import { Label } from '@independent-software/typeui/controls/Label';


class ViewHousehold extends React.Component<IAuthProps & RouteComponentProps<{id:string}>, {}> {
  private canEdit = (household: Household): boolean => {
    return this.props.auth && this.props.auth.hasRight('can_edit_households');
  }  

  private handleEdit = () => {
    this.props.history.push(`/households/edit/${parseInt(this.props.match.params.id)}`);
  }

  private handleDelete = () => {
    ToastService.toast(<Loc msg="households_msg_deleted"/>);
    this.props.history.push('/households');        
  }    

  private handleClickCommunity = (id: number) => {
    this.props.history.push(`/communities/${id}`);
  }

  private handleActionClick = (item: Action) => {
    this.props.history.push(`/actions/${item.id}`);
  }  

  render() {
    let p = this.props;
    return (
      <Viewer<Household> 
        auth={p.auth}
        id={parseInt(p.match.params.id)}
        factory={HouseholdFactory}
        canEdit={this.canEdit}
        onEdit={this.handleEdit}
        onDelete={this.handleDelete}
        content={(household: Household) => 
          <React.Fragment>
            <Section dark padded>
              <Flex>
                <Flex.Row>
                  <Flex.Column>
                    <Header size="h2">{household.name} ({household.hhh_name})&nbsp;<Label size="tiny">{household.code}</Label></Header>
                  </Flex.Column>
                </Flex.Row>
              </Flex>
            </Section>
            <Tabs underlined nohiddenrender>
              <Tabs.Pane label={<Loc msg="households_form_tab_data"/>}>
                <Section padded>
                  <Flex.Columns count={2}>
                      <Block title={<Loc msg="households_block_general"/>}>
                        <Table striped transparent>
                          <tbody>
                            <tr><td><Loc msg="households_fields_code_label"/></td><td>{household.code}</td></tr>
                            <tr><td><Loc msg="households_fields_state_label"/></td><td><Loc msg={("households_states_" + household.state) as TLocalization}/></td></tr>
                            <tr><td><Loc msg="households_fields_after_resettlement_label"/></td><td><Icon name={household.after_resettlement ? 'check' : 'times'}/></td></tr>
                            {household.after_resettlement == true && <tr><td><Loc msg="households_fields_after_resettlement_code_label"/></td><td>{household.after_resettlement_code}</td></tr>}
                          </tbody>
                        </Table>
                      </Block>
                      <Block title={<Loc msg="households_block_community"/>}>
                        {household.community.name}
                        {p.auth.hasRight('can_view_communities') && 
                          <div style={{textAlign:'right'}}>
                            <Button secondary size="small" onClick={() => this.handleClickCommunity(household.community.id)}><Loc msg="btn_view"/></Button>
                          </div>
                        }
                      </Block>                      
                      <Block title={<Loc msg="households_block_head"/>}>
                        <Table striped transparent>
                          <tbody>
                            <tr><td><Loc msg="households_fields_hhh_name_label"/></td><td>{household.hhh_name}</td></tr>
                            <tr><td><Loc msg="households_fields_hhh_age_label"/></td><td>{household.hhh_age}</td></tr>
                            <tr><td><Loc msg="households_fields_hhh_gender_label"/></td><td><Loc msg={("households_genders_" + household.hhh_gender) as TLocalization}/></td></tr>
                            <tr><td><Loc msg="households_fields_hhh_phone_label"/></td><td>{household.hhh_phone}</td></tr>
                            <tr><td><Loc msg="households_fields_hhh_contact_label"/></td><td>{household.hhh_contact}</td></tr>
                            <tr><td><Loc msg="households_fields_hhh_civil_id_label"/></td><td>{household.hhh_civil_id}</td></tr>
                            <tr><td><Loc msg="households_fields_hhh_account_label"/></td><td>{household.hhh_account}</td></tr>
                            <tr><td><Loc msg="households_fields_hhh_leader_label"/></td><td><Icon name={household.hhh_leader ? 'check' : 'times'}/></td></tr>
                          </tbody>
                        </Table>
                      </Block>
                      <React.Fragment>
                        <Block title={<Loc msg="households_block_household"/>}>
                          <Table striped transparent>
                            <tbody>
                              <tr><td><Loc msg="households_fields_members_label"/></td><td>{household.members}</td></tr>
                              <tr><td><Loc msg="households_fields_graves_label"/></td><td>{household.graves}</td></tr>
                              <tr><td><Loc msg="households_fields_polygamous_label"/></td><td>
                                <Icon name={household.polygamous ? 'check' : 'times'}/>
                                {household.polygamous == true && <React.Fragment> ({household.polygamous_spouses} <Loc msg="households_txt_spouses"/>)</React.Fragment>}
                              </td></tr>
                              <tr><td><Loc msg="households_fields_inas_label"/></td><td><Icon name={household.inas ? 'check' : 'times'}/></td></tr>
                            </tbody>
                          </Table>
                        </Block>
                        <Block title={<Loc msg="households_block_vulnerabilities"/>}>
                          {household.vulnerability_singleheaded == true && <LabelSpacer><Label><Loc msg="households_vulnerabilities_singleheaded"/></Label></LabelSpacer>}
                          {household.vulnerability_femaleheaded == true && <LabelSpacer><Label><Loc msg="households_vulnerabilities_femaleheaded"/></Label></LabelSpacer>}
                          {household.vulnerability_orphan == true && <LabelSpacer><Label><Loc msg="households_vulnerabilities_orphan"/></Label></LabelSpacer>}
                          {household.vulnerability_elderly == true && <LabelSpacer><Label><Loc msg="households_vulnerabilities_elderly"/></Label></LabelSpacer>}
                          {household.vulnerability_mental == true && <LabelSpacer><Label><Loc msg="households_vulnerabilities_mental"/></Label></LabelSpacer>}
                          {household.vulnerability_physical == true && <LabelSpacer><Label><Loc msg="households_vulnerabilities_physical"/></Label></LabelSpacer>}
                          {household.vulnerability_illness == true && <LabelSpacer><Label><Loc msg="households_vulnerabilities_illness"/></Label></LabelSpacer>}
                          {household.vulnerability_income == true && <LabelSpacer><Label><Loc msg="households_vulnerabilities_income"/></Label></LabelSpacer>}
                          {household.vulnerability_other == true && <LabelSpacer><Label><Loc msg="households_vulnerabilities_other"/> ({household.vulnerability_other_description})</Label></LabelSpacer>}
                        </Block>
                      </React.Fragment>
                      <Block title={<Loc msg="households_block_programs"/>}>
                        {household.programs &&
                          <Markdown source={household.programs}/>
                        }
                        {!household.programs &&
                          <Loc msg="households_txt_no_programs"/>
                        }    
                      </Block>

                  </Flex.Columns>
                </Section>
              </Tabs.Pane>
              <Tabs.Pane label={<span><Loc msg="households_form_tab_actions"/> <Label size="small">{household.actions_count}</Label></span>}>
                <ListActions auth={p.auth} name={`household_actions${p.match.params.id}`} householdId={household.id} onClick={this.props.auth.hasRight('can_view_actions') ?  this.handleActionClick : null}/>
              </Tabs.Pane>
            </Tabs>
          </React.Fragment>}>
      </Viewer>
    );
  }
}

const LabelSpacer = styled('div')`
  margin: 2px;
  display: inline-block;
`

export { ViewHousehold };
