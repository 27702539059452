import * as React from 'react';
import axios from 'axios';
import { pt } from 'date-fns/locale'
import { enGB as en } from 'date-fns/locale'

interface ILanguageContext {
  language: string;
  locale: Locale;
  setLanguage: (language: string) => void;
  data: any;
}

const LanguageContext = React.createContext<ILanguageContext>({
  language: 'pt',
  locale: pt,
  setLanguage: null,
  data: null
});

class LanguageProvider extends React.Component<{}, ILanguageContext> {
  constructor(props: {}) {
    super(props);
    this.state = {
      language: 'pt',
      locale: pt,
      setLanguage: null,
      data: null
    }
  }

  componentDidMount() {
    this.setLanguage('pt');
  }

  private setLanguage = (language: string) => {
    axios.get(`locales/${language}/messages.json`)
    .then(response => {
      this.setState({ locale: null, language: language, data: response.data });
      if(language == 'en') this.setState({ locale: en });
      if(language == 'pt') this.setState({ locale: pt });      
    });    
  }    

  render() {
    return (
      <LanguageContext.Provider value={{ language: this.state.language, locale: this.state.locale, setLanguage: this.setLanguage, data: this.state.data }}>
        {this.props.children}
      </LanguageContext.Provider>
    );
  }
}

export { ILanguageContext, LanguageContext, LanguageProvider }