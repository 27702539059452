import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'

/**
 * A container adds padding to a group of components.
 */

interface IContainerProps {
  className?: string;
  children?: React.ReactNode;
}

class ContainerBase extends React.Component<IContainerProps, {}> {
  constructor(props: IContainerProps) {
    super(props);
  }

  render() {
    return (
      <div className={this.props.className}>{this.props.children}</div>
    )
  }
}

const Container = styled(ContainerBase)`
  padding: 20px;
`

export { Container };
