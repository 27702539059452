import * as React from 'react';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { Input } from '@independent-software/typeui/controls/Input'

interface IProps {
  value: string;
  onSearch: (value: string) => void;
}

interface IState {
  value: string;
}

class SearchFilter extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      value: props.value
    }
  }

  setFilterSearch = (value:string) => {
    this.props.onSearch(value);
  }
  handleChangeFilterSearchDebounced = AwesomeDebouncePromise(this.setFilterSearch, 350);
  handleChange = async(value: string) => { 
    this.setState({ value: value });
    this.handleChangeFilterSearchDebounced(value);
  }  

  render() {
    return(
      <Input clearable fluid name="search" icon="search" type="text" value={this.state.value} placeholder="Search" onChange={this.handleChange}/>
    );
  }
}

export { SearchFilter }
