import * as React from 'react';
import { District } from '../../resource/';
import { IAuthProps } from '../../services/';

import { Input } from '@independent-software/typeui/controls/Input';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Form } from '@independent-software/typeui/controls/Form';
import { Section, Loc } from '../../modules';
import { Divider } from '@independent-software/typeui/controls/Divider';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { MarkdownTextarea } from '@independent-software/typeui/modules/MarkdownTextarea';

interface IProps {
  /** Initial form data. */
  data: District;
  /** Called whenever form changes. */
  onChange: (data: District, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */  
  dirty?: boolean;
}

interface IState {
  /** Current form data. */
  data: District;
}

class DistrictForm extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);

    // Intialize state.
    this.state = {
      data: props.data
    };    
  }

  render() {
    let p = this.props;
    return (
      <Form
        data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
        <Tabs underlined>
          <Tabs.Pane label={<Loc msg="districts_form_tab_data"/>}>
            <Section padded>
              <Flex.Columns count={2}>
                <React.Fragment>

                  <Form.Field 
                    required={{message: <Loc msg="districts_fields_name_required"/>}}
                    minLength={{length: 3, message: <Loc msg="districts_fields_name_minlength"/>}}
                    maxLength={{length: 255, message: <Loc msg="districts_fields_name_maxlength"/>}}
                    width={1} label={<Loc msg="districts_fields_name_label"/>}
                    name="name" 
                    value={this.state.data.name}
                    control={(<Input type="text" placeholder="Name" fluid/>)}
                    hint={<Loc msg="districts_fields_name_hint"/>}/>

                  <Divider hidden/>

                  <Form.Field 
                    label={<Loc msg="districts_fields_notes_label"/>}
                    name="notes" value={this.state.data.notes}
                    control={(<MarkdownTextarea rows={10} fluid label_text={<Loc msg="markdown_text"/>} label_preview={<Loc msg="markdown_preview"/>} label_markdown={<Loc msg="markdown_is_supported"/>}/>)}
                    hint={<Loc msg="districts_fields_notes_hint"/>}/>

                </React.Fragment>
              </Flex.Columns>
            </Section>
          </Tabs.Pane>
        </Tabs>

      </Form>
    )
  }
}

export { DistrictForm };
