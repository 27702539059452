import * as React from 'react';
import { Issuecategory, IssuecategoryFactory, Issuetype, IssuetypeFactory } from '../../resource/'; 
import { IAuthProps, List, IListState, IListProps, SearchFilter, Query } from '../../services/';
import { IconBar, Export, Loc } from '../../modules';

import { Form } from '@independent-software/typeui/controls/Form';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { DataTable } from '@independent-software/typeui/controls/DataTable';
import { Number } from '@independent-software/typeui/formatters/Number';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';

interface IProps extends IListProps {
  /* If set, list is prefiltered by an issue category and issue category
   * column and filter are hidden.
   */
  issuecategoryId?: number;  
  /**
   * Event is fired when an issue type is clicked.
   */
  onClick?: (issuetype:Issuetype) => void;
}

interface IState extends IListState<Issuetype> {
  /* 
   * Data for issuecategory dropdown
   */
  issuecategories: Issuecategory[];  
}

class ListIssuetypes extends List<Issuetype, IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props, IssuetypeFactory, 'name', 'asc');

    // Initialize state (list initializes its own part of the state):
    this.state = {
      ...this.state,
      issuecategories: []
    };

    if(this.props.issuecategoryId) {
      this.setFilter('issuecategory', 'eq', { id: this.props.issuecategoryId }, true);
    }           
  }

  componentDidMount() {
    this.handleIssuecategorySearch();
  }  

  handleSearch = (value:string) => {
    this.setFilter('q', 'like', value);
  }  

  handleChangeIssuecategory = (value: Issuecategory) => {
    this.setFilter('issuecategory', 'eq', value);
  }

  handleIssuecategorySearch = (q?:string) => {
    // Retrieve a list of issue categories:
    let query = new Query('name', 'asc');
    if (q) query.setFilter('q', 'like', q);
    IssuecategoryFactory.getSome(this.props.auth, 0, 8, query)
      .then((res) => this.setState({ issuecategories: res.items }));
  }  


  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Form.Uncontrolled hint={<Loc msg="issuetypes_filter_search_hint"/>}>
          <SearchFilter value={this.getFilter('q', 'like')} onSearch={this.handleSearch}/>
        </Form.Uncontrolled>
        {!p.issuecategoryId && 
        <Form.Uncontrolled hint={<Loc msg="issuetypes_filter_issuecategory_hint"/>}>
          <Dropdown onSearch={this.handleIssuecategorySearch} name="issuecategory" fluid clearable value={this.getFilter('issuecategory', 'eq')} data={this.state.issuecategories} placeholder="Issue category" label={(item:Issuecategory) => item.name} onChange={this.handleChangeIssuecategory}>
            <Dropdown.Column>{(item:Issuecategory) => item.name}</Dropdown.Column>
          </Dropdown>
        </Form.Uncontrolled>}                  
      </Panel.Content>
      <Panel.Footer>
        <Export onExport={this.handleExport}/>
      </Panel.Footer>      
    </React.Fragment>

    return (
      <React.Fragment>
        <IconBar>
          <Panel.Icon icon="tools" width={300}>
            {filter}
          </Panel.Icon>
        </IconBar> 
        <DataTable error={this.state.error} loading={this.state.loading} scrollTop={this.state.scrollTop} onScroll={this.handleScroll} data={this.state.items} onFetch={this.handleFetch} onClick={p.onClick} onOrder={this.handleOrder} order={this.getOrder()} dir={this.getDir()}>
          <DataTable.Column weight={5} label={<Loc msg="issuetypes_list_hdr_name"/>} order="name" dir="asc">{(item:Issuetype) => <span>{item.name}</span>}</DataTable.Column>
          {!p.issuecategoryId && <DataTable.Column weight={3} label={<Loc msg="issuetypes_list_hdr_category"/>} dir="asc">{(item:Issuetype) => <span>{item.issuecategory ? item.issuecategory.name : '-'}</span>}</DataTable.Column>}
          <DataTable.Column weight={1} align="right" label={<Loc msg="issuetypes_list_hdr_grievances"/>} order="grievances_count" dir="desc">{(item:Issuetype) => <Number value={item.grievances_count} decimals={0}/>}</DataTable.Column>
        </DataTable>
        <Dialog.Xhr open={this.state.exportError != null} error={this.state.exportError} onClose={this.handleCloseDialog}/>
      </React.Fragment>
    );
  }
}

export { ListIssuetypes };
