import * as React from 'react';
import { css } from 'styled-components';
import styled from '@independent-software/typeui/styles/Theme'
import { Button } from '@independent-software/typeui/controls/Button'
import { TChartResolution } from './Chart';

interface IProps {
  className?: string;
  resolution: TChartResolution;
  onChange: (type: TChartResolution) => void;
}

class ResolutionSwitchBase extends React.Component<IProps, {}> {
  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        <Button.Group size="small">
          <Button noripple secondary={p.resolution == 'day'}   onClick={() => p.onChange('day')}>Day</Button>
          <Button noripple secondary={p.resolution == 'week'}  onClick={() => p.onChange('week')}>Week</Button>
          <Button noripple secondary={p.resolution == 'month'} onClick={() => p.onChange('month')}>Month</Button>
          <Button noripple secondary={p.resolution == 'year'}  onClick={() => p.onChange('year')}>Year</Button>
        </Button.Group> 
      </div>
    )
  }
}

const ResolutionSwitch = styled(ResolutionSwitchBase)`
  padding-bottom: 6px;
`

export { ResolutionSwitch };