import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import axios from 'axios';

import { IAuthProps } from '../../services/Auth';
import { App } from '../../App';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { Form } from '@independent-software/typeui/controls/Form';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { ListOptions } from './ListOptions';
import { Card } from '@independent-software/typeui/controls/Card';
import { Number } from '@independent-software/typeui/formatters/Number';
import { Block, Loc } from '../../modules';

interface IProps {
}

interface IState {
  days: number;
  total_cases: number;
  ongoing_cases: number;
  avg_notification_time: number;
  deleted_grievances: number;
}

class CardStats extends React.Component<IAuthProps & IProps, IState> {
   constructor(props: IAuthProps & IProps) {
     super(props);
     this.state = {
       days: -1,
       total_cases: null,
       ongoing_cases: null,
       avg_notification_time: null,
       deleted_grievances: null
     }
   } 

   componentDidMount() {
     this.loadData();
   }

   componentDidUpdate(newProps: IProps, newState: IState) {
    if(this.state.days != newState.days) {
      this.loadData();
    }
  }

   loadData = () => {
    let path = `${App.apiURL}grievances/stats?api_token=${this.props.auth.token}`;
    if(this.state.days) { path = path + `&days=${this.state.days}`; }
    axios.get(path)
    .then(response => {
      let data = response.data;
      this.setState({
        total_cases: data.total_cases,
        ongoing_cases: data.ongoing_cases,
        avg_notification_time: data.avg_notification_time,
        deleted_grievances: data.deleted_grievances
      })
    })
    .catch(error => {
    });   
  }

  handleChangeDays = (value: number) => {
    this.setState({days: value});
  }    

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Form.Uncontrolled hint={<Loc msg="analysis_grievances_hint_period"/>}>
          <Dropdown name="days" fluid value={this.state.days} data={[30,90,180,365,-1]} placeholder="Period" label={(item:number) => item == -1 ? <Loc msg="analysis_grievances_hint_period_all_time"/> : <span>{item} {<Loc msg="analysis_grievances_hint_period_days"/>}</span>} onChange={this.handleChangeDays}>
            <Dropdown.Column>{(item:number) => item == -1 ? <Loc msg="analysis_grievances_hint_period_all_time"/> : <span>{item} {<Loc msg="analysis_grievances_hint_period_days"/>}</span>}</Dropdown.Column>
          </Dropdown>
        </Form.Uncontrolled>        
      </Panel.Content>
    </React.Fragment>    


    return (
      <Block title={<Loc msg="analysis_grievances_title_statistics"/>}>
        <ListOptions>
          <Panel.Icon icon="tools" width={400}>
            {filter}
          </Panel.Icon>
        </ListOptions>
        <Card>
          <Card.Header align='center'>
            <Loc msg="analysis_grievances_title_court_cases"/>
          </Card.Header>
          <Card.Content>
            <Value>
              {this.state.ongoing_cases != null && <React.Fragment>
                <BigNumber>
                  <Number value={this.state.ongoing_cases} decimals={0}/>
                </BigNumber>  
                <BigNumber>/</BigNumber>
                <BigNumber>
                  <Number value={this.state.total_cases} decimals={0}/>
                </BigNumber>
              </React.Fragment>}
            </Value>
          </Card.Content>
          <Card.Content secondary>
            <Loc msg="analysis_grievances_text_ongoing_total"/>
          </Card.Content>
        </Card>
        <Card>
          <Card.Header align='center'>
            <Loc msg="analysis_grievances_title_avg_notification_time"/>
          </Card.Header>
          <Card.Content>
            <Value>
              {this.state.avg_notification_time != null && 
              <BigNumber>
                <Number value={this.state.avg_notification_time} decimals={0}/>
              </BigNumber>}
            </Value>
          </Card.Content>
          <Card.Content secondary>
            <Loc msg="analysis_grievances_text_days"/>
          </Card.Content>
        </Card>        
        <Card>
          <Card.Header align='center'>
            <Loc msg="analysis_grievances_title_deleted_grievances"/>
          </Card.Header>
          <Card.Content>
            <Value>
              {this.state.deleted_grievances != null && 
              <BigNumber>
                <Number value={this.state.deleted_grievances} decimals={0}/>
              </BigNumber>}
            </Value>
          </Card.Content>
          <Card.Content secondary>
            &nbsp;
          </Card.Content>
        </Card>        

      </Block>
    );
  }
}

const Value = styled('div')`
  padding: 10px 0;
  height: 35px;
  text-align: center;
`

const BigNumber = styled('span')`
  font-size: 50px;
  font-weight: 500;
  padding: 0 10px;
`

export { CardStats }


