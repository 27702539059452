const COLORS: string[] = [
  "#03738C",
  "#128C58",
  "#F2B441",
  "#D9430D",
  "#F26052",
  "#971115",
  "#D92024",
  "#FFB700",
  "#60C6C5",
  "#023559",
  "#927371",
  "#8FA69B",
  "#896EC1",
  "#A69A94",
  "#73645F",
  "#FA88CB",
  "#CF86E3",
  "#BFA0FA",
  "#8C94E6",
  "#94CAFF"
];

export { COLORS };