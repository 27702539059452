import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { css } from 'styled-components';
import { Label } from '@independent-software/typeui/controls/Label';
import { Icon } from '@independent-software/typeui/controls/Icon';

interface IProps {
  children?: React.ReactNode;
  value: boolean;
}

class Tag extends React.Component<IProps, {}> {
  render() {
    let p = this.props;
    return (
      <Label color={p.value == true ? 'gray' : null}>
        <Icon 
          name={p.value == true ? 'check' : 'times'}
          color={p.value == true ? 'lightgreen' : 'darkred'}/>
        {p.children}
      </Label>
    );
  }
}

export { Tag };
