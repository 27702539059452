import { StampedModel, ResourceFactory } from '../services/';
import { Attachment } from './Attachment';

class Stakeholder extends StampedModel {
  public type: string = '';
  public type_other: string = '';
  public state: string = '';
  public community_leader: string = '';
  public head_of_household: string = '';
  public name: string = '';
  public phone: string = '';
  public contact: string = '';
  public sex: string = '';
  public age: number = null;
  public grievances_count: number = 0;
  public attachments_count: number = 0;
  public community: { 
    id: number;
    name: string;
  } = null;

  public attachments: Attachment[] = [];
}

const StakeholderFactory = ResourceFactory.create<Stakeholder>(Stakeholder, 'stakeholder');

export { Stakeholder, StakeholderFactory };