import * as React from 'react'
import { IActionStep } from '../../resource/'
import { Table } from '@independent-software/typeui/controls/Table';
import { Label } from '@independent-software/typeui/controls/Label';
import { parseISO, formatDistance, differenceInDays } from 'date-fns';
import { Theme } from '@independent-software/typeui/styles';
import styled from '@independent-software/typeui/styles/Theme';
import { Loc } from '../../modules';
import { TLocalization } from '../../modules/Loc/TLocalization';
import { Gravatar } from '@independent-software/typeui/controls/Gravatar';
import { IAuthProps } from '../../services';
import * as H from 'history';
import { LocalizedDatum } from '../../formatters';

interface IProps {
  steps: IActionStep[];
  history: H.History<{}>;
}

class StepTable extends React.Component<IAuthProps & IProps, {}> {

  private handleClickUser = (userId: number) => {
    this.props.history.push(`/users/${userId}`);
  }

  render() {
    let p = this.props;

    return (
      <Table striped>
        <thead>
          <tr>
            <th><Loc msg={"grievances_steps_hdr_date"}/></th>
            <th><Loc msg={"grievances_steps_hdr_user"}/></th>
            <th><Loc msg={"grievances_steps_hdr_action"}/></th>
            <th><Loc msg={"grievances_steps_hdr_state"}/></th>
          </tr>
        </thead>
        <tbody>
          {p.steps.map((step, idx) => 
            <tr key={idx}>
              <td style={{position: 'relative'}}>
                <LocalizedDatum.ShortDateTime value={step.created_at}/>
                <Bullet/>
                {idx >0 && <StickDown/>}
                {idx < p.steps.length - 1 && <StickUp/>}
                {/* label */}
                {idx > 0 && <DiffLabel date={step.created_at} prevDate={p.steps[idx-1].created_at}/>}  
              </td>
              <td>
                <Label onClick={p.auth.hasRight('can_view_users') ? () => this.handleClickUser(step.created_by.id) : null}>
                  <Gravatar email={step.created_by.email}/>
                  {step.created_by.name}
                </Label>
              </td>
              <td>
                {idx == 0 
                ? <Loc msg="grievances_steps_action_creation"/>
                : <Loc msg={("grievances_steps_action_" + step.type) as TLocalization}/>}
              </td>
              <td>
                <Loc msg={('actions_workflow_' + step.state) as TLocalization}/>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }
}

const Bullet = styled('div')`
  position: absolute;
  right: 22px;
  top: calc(-4px + 50%);
  border: solid 4px #2185D0;
  border-radius: 4px;
`

const StickDown = styled('div')`
  position: absolute;
  right: 25px;
  top: 0;
  height: 50%;
  border-right: solid 2px #2185D0;
`

const StickUp = styled('div')`
  position: absolute;
  right: 25px;
  bottom: 0;
  height: 50%;
  border-right: solid 2px #2185D0;
`

class DiffLabel extends React.Component<{ date: string; prevDate: string }> {
  render() {
    let parsed_date = parseISO(this.props.date);
    let parsed_prev_date = parseISO(this.props.prevDate);
    let difference = differenceInDays(parsed_date, parsed_prev_date);
    let distance = formatDistance(parsed_date, parsed_prev_date);
    return (
      <div style={{position: 'absolute', right: '15px', top: '-14px'}}>
        <Label size="mini" basic={difference <= 1} color={difference > 1 ? Theme.errorColor.color : null}>
          {distance}
        </Label>
      </div>
    );
  }
}

export { StepTable }