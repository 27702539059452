import { ResourceFactory, StampedModel } from '../services/';
import { Grievance } from './Grievance';

class Post extends StampedModel {
  public id: number;
  public title: string;
  public text: string;
  public createdBy: {
    id: number;
    name: string;
    email: string;
  }

  type?: string;
  type_id?: number;

  public grievances: Grievance[];
}

const PostFactory = ResourceFactory.create<Post>(Post, `post`);

export { Post, PostFactory }
