import * as React from 'react';
import { Actiontype } from '../../resource/';
import { IAuthProps, Query } from '../../services/';

import { Input } from '@independent-software/typeui/controls/Input';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Form } from '@independent-software/typeui/controls/Form';
import { Section, Loc } from '../../modules';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { TLocalization } from '../../modules/Loc/TLocalization';

interface IProps {
  /** Initial form data. */
  data: Actiontype;
  /** Called whenever form changes. */
  onChange: (data: Actiontype, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */  
  dirty?: boolean;
}

interface IState {
  /** Current form data. */
  data: Actiontype;
}

class ActiontypeForm extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);

    // Intialize state.
    this.state = {
      data: props.data
    };    
  }

  render() {
    let p = this.props;
    return (
      <Form
        data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
        <Section padded>
            <Form.Field 
              required={{message: <Loc msg="actiontypes_fields_name_required"/>}}
              minLength={{length: 3, message: <Loc msg="actiontypes_fields_name_minlength"/>}}
              maxLength={{length: 255, message: <Loc msg="actiontypes_fields_name_maxlength"/>}}
              width={1} label={<Loc msg="actiontypes_fields_name_label"/>}
              name="name" 
              value={this.state.data.name}
              control={(<Input type="text" placeholder="Name" fluid/>)}
              hint={<Loc msg="actiontypes_fields_name_hint"/>}/>

            <Flex.Columns count={2}>
              <Form.Field
                required={{message: <Loc msg="actiontypes_fields_level_required"/>}}
                width={1} label={<Loc msg="actiontypes_fields_level_label"/>}
                name="level" 
                value={this.state.data.level}
                control={(<Dropdown fluid data={['individual','community','both']} placeholder="Level" label={(item:string) => <Loc msg={("actiontypes_level_" + item) as TLocalization}/>}>
                    <Dropdown.Column>{(item:string) => <Loc msg={("actiontypes_level_" + item) as TLocalization}/>}</Dropdown.Column>
                </Dropdown>)}
                hint={<Loc msg="actiontypes_fields_level_hint"/>}/>
              <Form.Field
                required={{message: <Loc msg="actiontypes_fields_delivery_required"/>}}
                width={1} label={<Loc msg="actiontypes_fields_delivery_label"/>}
                name="delivery" 
                value={this.state.data.delivery}
                control={(<Dropdown fluid data={['cash','kind','specific','benefits','livelihood','unplanned']} placeholder="Delivery" label={(item:string) => <Loc msg={("actiontypes_delivery_" + item) as TLocalization}/>}>
                    <Dropdown.Column>{(item:string) => <Loc msg={("actiontypes_delivery_" + item) as TLocalization}/>}</Dropdown.Column>
                </Dropdown>)}
                hint={<Loc msg="actiontypes_fields_delivery_hint"/>}/>
            </Flex.Columns>
        </Section>
      </Form>
    )
  }
}

export { ActiontypeForm };
