import * as React from 'react'
import axios from 'axios';
import { Action } from '../../../resource';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Button } from '@independent-software/typeui/controls/Button';
import { IAuthProps } from '../../../services';
import { App } from '../../../App';
import { Loc } from '../../../modules';
import { Icon } from '@independent-software/typeui/controls/Icon';

interface IProps {
  action: Action;
  open: boolean;
  onClose: () => void;
  onExec: () => void;
  onFail: (error: any) => void;
}

interface IState {
  dirty: boolean;
  isValid: boolean;
  saving: boolean;
}

class ReopenDialog extends React.Component<IAuthProps & IProps, IState> {

  constructor(props: IAuthProps & IProps) {
    super(props);
    this.state = { 
      dirty: false,
      isValid: true,
      saving: false
    }
  }

  private exec = () => {
    let action = this.props.action;
    this.setState({ saving: true });

    let formData = new FormData();
    // Attach the api_token.
    formData.append('api_token', this.props.auth.token);
    // Attach fields
    // (no data)

    axios.post(`${App.apiURL}action/move/${action.id}/reopen`, formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    .then(response => {
      action.newstate = 'open';
      this.props.onExec();
    })
    .catch(error => {
      this.props.onFail(error);
    })
    .finally(() => {
      this.setState({ saving: false });
    });
  }

  render() {
    return (
      <Dialog width={800} open={this.props.open} onClose={this.props.onClose} canClose={!this.state.saving}>
        <Dialog.Header><Loc msg="actions_workflow_reopen_title"/></Dialog.Header>
        <Dialog.Content>
          <p><Loc msg="actions_workflow_reopen_text"/></p>
        </Dialog.Content>
        <Dialog.Footer>
          <Button disabled={this.state.saving} primary onClick={() => this.exec()}>
            {this.state.saving && <Icon name="circle-notch" loading/>}
            <Loc msg="btn_ok"/>
          </Button>
          <Button secondary disabled={this.state.saving} onClick={this.props.onClose}><Loc msg="btn_cancel"/></Button>          
        </Dialog.Footer>
      </Dialog>
    )
  }
}

export { ReopenDialog }
