import * as React from 'react';
import { Label } from "@independent-software/typeui/controls/Label";
import { Segment } from "@independent-software/typeui/controls/Segment";
import { Table } from "@independent-software/typeui/controls/Table";
import { TooltipPayload, TooltipProps } from "recharts";

const TooltipFormatter = (p: TooltipProps) => {
  return (
    <div style={{opacity: 0.7}}>
      <Segment>
        {p.label && <Label>{p.label.toString().replace('.', ' Q')}</Label>}
        <div style={{width:'200px'}}>
          {p.payload && 
            <Table transparent striped>
              <tbody>
                {p.payload.map((load:TooltipPayload, idx: number) =>
                <tr key={idx}>
                  <td style={{color: load.color, whiteSpace:'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{load.name}</td>
                  <td style={{width: '45px', textAlign:'right'}}>{load.value}</td>
                </tr>)}
              </tbody>
            </Table>}
        </div>
      </Segment>
    </div>
  );
}

export { TooltipFormatter }