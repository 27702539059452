import * as React from 'react';
import { IReset } from './IReset';

import { Form } from '@independent-software/typeui/controls/Form';
import { Input } from '@independent-software/typeui/controls/Input';
import { Loc } from '../../modules';

interface IProps {
  /** Initial form data. */
  data: IReset;
  /** Called whenever form changes. */
  onChange: (data: IReset, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** Form controls disabled? */
  disabled?: boolean;  
  dirty?: boolean;
}

interface IState {
  /** Current form data. */
  data: IReset;
}

class ResetForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      data: props.data
    }
  }

  render() {
    let p = this.props;
    return (
      <Form data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
        <Form.Field 
          required={{message: <Loc msg="reset_password_fields_newpassword_required"/>}}
          minLength={{length: 8, message: <Loc msg="reset_password_fields_newpassword_minlength"/>}}
          maxLength={{length: 30, message: <Loc msg="reset_password_fields_newpassword_maxlength"/>}}
          noPattern={{pattern: '^(.{0,7}|.{31,}|[^0-9]*|[^A-Za-z]*|[a-zA-Z0-9]*)$', message: <Loc msg="reset_password_fields_newpassword_nopattern"/>}}
          contrast
          name="password" 
          value={this.state.data.password}
          control={(<Input disabled={p.disabled} type="password" fluid 
            placeholder="Password"/>)}
          hint={<Loc msg="reset_password_fields_newpassword_hint"/>}/>             
      </Form>
    )
  }
}

export { ResetForm };