import * as React from 'react';
import { Survey, SurveyFactory } from '../../resource/'; 
import { IAuthProps, List, IListState, IListProps, SearchFilter } from '../../services/';
import { IconBar, Export, Loc } from '../../modules';

import { Form } from '@independent-software/typeui/controls/Form';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { DataTable } from '@independent-software/typeui/controls/DataTable';
import { Input } from '@independent-software/typeui/controls/Input';
import { Number } from '@independent-software/typeui/formatters/Number';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { LocalizedDatum } from '../../formatters';

interface IListSurveysProps extends IListProps {
  /**
   * Event is fired when a survey is clicked.
   */
  onClick?: (survey:Survey) => void;
}

interface IListSurveysState extends IListState<Survey> {
}

class ListSurveys extends List<Survey, IListSurveysProps, IListSurveysState> {
  constructor(props: IAuthProps & IListSurveysProps) {
    super(props, SurveyFactory, 'name', 'asc');

    // Initialize state (list initializes its own part of the state):
    this.state = {
      ...this.state
    };
  }

  handleSearch = (value:string) => {
    this.setFilter('q', 'like', value);
  }  
  
  handleChangeFilterEmpty = (value: boolean) => {
    this.setFilter('responses', 'eq', value ? 0 : null);
  }  

  handleChangeFromDate = (value:string) => {
    this.setFilter('date', 'gte', value);
  }  

  handleChangeToDate = (value:string) => {
    this.setFilter('date', 'lte', value);
  }     

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Form.Uncontrolled hint={<Loc msg="surveys_filter_search_hint"/>}>
          <SearchFilter value={this.getFilter('q', 'like')} onSearch={this.handleSearch}/>
        </Form.Uncontrolled>
        <Form.Uncontrolled hint="">
          <Checkbox label={<Loc msg="surveys_filter_only_empty_hint"/>} name='empty' type="toggle" checked={this.getFilter('responses', 'eq') != null} onChange={this.handleChangeFilterEmpty}/>
        </Form.Uncontrolled>        
        <Form.Uncontrolled hint={<Loc msg="surveys_filter_from_date_hint"/>}>
          <Input name="from" type="date" fluid clearable value={this.getFilter('date', 'gte')} placeholder="From date" onChange={this.handleChangeFromDate}/>
        </Form.Uncontrolled>
        <Form.Uncontrolled hint={<Loc msg="surveys_filter_to_date_hint"/>}>
          <Input name="to" type="date" fluid clearable value={this.getFilter('date', 'lte')} placeholder="To date" onChange={this.handleChangeToDate}/>
        </Form.Uncontrolled>              
      </Panel.Content>
      <Panel.Footer>
        <Export onExport={this.handleExport}/>
      </Panel.Footer>      
    </React.Fragment>

    return (
      <React.Fragment>
        <IconBar>
          <Panel.Icon icon="tools" width={300}>
            {filter}
          </Panel.Icon>
        </IconBar> 
        <DataTable error={this.state.error} loading={this.state.loading} scrollTop={this.state.scrollTop} onScroll={this.handleScroll} data={this.state.items} onFetch={this.handleFetch} onClick={p.onClick} onOrder={this.handleOrder} order={this.getOrder()} dir={this.getDir()}>
          <DataTable.Column weight={2} label={<Loc msg="surveys_list_hdr_name"/>} order="name" dir="asc">{(item:Survey) => <span>{item.name}</span>}</DataTable.Column>
          <DataTable.Column weight={1} label={<Loc msg="surveys_list_hdr_date"/>} order="date" dir="desc">{(item:Survey) => <LocalizedDatum.LongDate value={item.date}/>}</DataTable.Column>
          <DataTable.Column weight={1} align="right" label={<Loc msg="surveys_list_hdr_communities"/>} order="communities_count" dir="asc">{(item:Survey) => <Number value={item.communities_count} decimals={0}/>}</DataTable.Column>
          <DataTable.Column weight={1} align="right" label={<Loc msg="surveys_list_hdr_responses"/>} order="responses_count" dir="asc">{(item:Survey) => <Number value={item.responses_count} decimals={0}/>}</DataTable.Column>
        </DataTable>
        <Dialog.Xhr open={this.state.exportError != null} error={this.state.exportError} onClose={this.handleCloseDialog}/>
      </React.Fragment>
    );
  }
}

export { ListSurveys };
