import * as React from 'react';
import { css } from 'styled-components';
import styled from '@independent-software/typeui/styles/Theme'

interface IProps {
  className?: string;
  children?: React.ReactNode;
}

class ChartOptionsBase extends React.Component<IProps, {}> {
  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        {p.children}
      </div>
    )
  }
}

const ChartOptions = styled(ChartOptionsBase)`
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 10px;
`

export { ChartOptions };