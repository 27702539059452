import * as React from 'react'
import axios from 'axios';
import { Grievance } from '../../../resource';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Button } from '@independent-software/typeui/controls/Button';
import { IAuthProps } from '../../../services';
import { Dropzone } from '@independent-software/typeui/controls/Dropzone';
import { App } from '../../../App';
import { Filesize } from '@independent-software/typeui/formatters/Filesize';
import { Loc } from '../../../modules';
import { Icon } from '@independent-software/typeui/controls/Icon';

interface IProps {
  grievance: Grievance;
  open: boolean;
  onClose: () => void;
  onExec: () => void;
  onFail: (error: any) => void;
}

interface IState {
  file: File;
  saving: boolean;
}

class LitigationConclusionDialog extends React.Component<IAuthProps & IProps, IState> {

  constructor(props: IAuthProps & IProps) {
    super(props);
    this.state = { 
      file: null,
      saving: false
    }
  }

  private exec = () => {
    let grievance = this.props.grievance;
    this.setState({ saving: true });

    // Sending a file requires the use of FormData. This is
    // a dictionary of string to string. We can therefore not 
    // send the entire grievance along (it's several layers deep,
    // and cannot be converted to a list of string), and we
    // don't need to, since we only need to send the file and 
    // the api_token to the endpoint.
    let formData = new FormData();
    // Attach the file:
    formData.append('file', this.state.file);
    // Attach the api_token.
    formData.append('api_token', this.props.auth.token);

    axios.post(`${App.apiURL}grievance/move/${grievance.id}/litigation_conclusion`, formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    .then(response => {
      grievance.newstate = 'litigation_conclusion';
      this.props.onExec();
    })
    .catch(error => {
      this.props.onFail(error);
    })
    .finally(() => {
      this.setState({ saving: false });
    });
  }

  render() {
    return (
      <Dialog width={800} open={this.props.open} onClose={this.props.onClose} canClose={!this.state.saving}>
        <Dialog.Header><Loc msg="grievance_workflow_litigation_conclusion_title"/></Dialog.Header>
        <Dialog.Content>
          <Dropzone 
            message={this.state.file ? <React.Fragment>{this.state.file.name} (<Filesize value={this.state.file.size}/>)</React.Fragment> : <Loc msg="grievance_workflow_litigation_conclusion_file"/>} 
            onAddFiles={(files: File[]) => this.setState({ file: files[0] })}/>
        </Dialog.Content>
        <Dialog.Footer>
          <Button disabled={this.state.file == null || this.state.saving} primary onClick={() => this.exec()}>
            {this.state.saving && <Icon name="circle-notch" loading/>}
            <Loc msg="btn_ok"/>
          </Button>
          <Button secondary disabled={this.state.saving} onClick={this.props.onClose}><Loc msg="btn_cancel"/></Button>          
        </Dialog.Footer>
      </Dialog>
    )
  }
}

export { LitigationConclusionDialog }