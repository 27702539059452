import * as React from 'react';
import { Profile, ProfileFactory, User, UserFactory } from '../../resource/'; 
import { IAuthProps, List, IListState, Query, IListProps, SearchFilter } from '../../services/';
import { IconBar, Export, Loc } from '../../modules';

import { Form } from '@independent-software/typeui/controls/Form';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { DataTable } from '@independent-software/typeui/controls/DataTable';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Gravatar } from '@independent-software/typeui/controls/Gravatar';
import { Input } from '@independent-software/typeui/controls/Input';
import { LocalizedDatum } from '../../formatters';

interface IListUsersProps extends IListProps {
  /**
   * If set, list is prefiltered by a profile and
   * profile column and filter are hidden.
   */
  profileId?: number;
  /**
   * Event is fired when a user is clicked.
   */
  onClick?: (user:User) => void;
}

interface IListUsersState extends IListState<User> {
  /**
   * Data for profiles dropdown
   */
  profiles: Profile[];
}

class ListUsers extends List<User, IListUsersProps, IListUsersState> {
  constructor(props: IAuthProps & IListUsersProps) {
    super(props, UserFactory, 'name', 'asc');

    // Initialize state (list initializes its own part of the state):
    this.state = {
      ...this.state,
      profiles: []
    };

    // The profile stored in the Query is an ID, not a Profile.
    // Is the list prefiltered by a profile? Then set a filter.
    if(this.props.profileId) {
      this.setFilter('profile', 'eq', { id: this.props.profileId }, true);
    }    
  }

  componentDidMount() {
    this.handleProfileSearch();    
  }

  handleSearch = (value:string) => {
    this.setFilter('q', 'like', value);
  }  

  handleChangeProfile = (value: Profile) => {
    this.setFilter('profile', 'eq', value);
  }

  handleProfileSearch = (q?:string) => {
    // Retrieve a list of profiles:
    let query = new Query('name', 'asc');
    if (q) query.setFilter('q', 'like', q);
    ProfileFactory.getSome(this.props.auth, 0, 8, query)
      .then((res) => this.setState({ profiles: res.items }));
  }

  handleChangeFromDate = (value:string) => {
    this.setFilter('date', 'gte', value);
  }  

  handleChangeToDate = (value:string) => {
    this.setFilter('date', 'lte', value);
  }   

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Form.Uncontrolled hint={<Loc msg="users_filter_search_hint"/>}>
          <SearchFilter value={this.getFilter('q', 'like')} onSearch={this.handleSearch}/>
        </Form.Uncontrolled>
        {!p.profileId && 
          <Form.Uncontrolled hint={<Loc msg="users_filter_profile_hint"/>}>
            <Dropdown onSearch={this.handleProfileSearch} name="profile" fluid clearable value={this.getFilter('profile', 'eq')} data={this.state.profiles} placeholder="Profile" label={(item:Profile) => item.name} onChange={this.handleChangeProfile}>
              <Dropdown.Column weight={3}>{(item:Profile) => item.name}</Dropdown.Column>
              <Dropdown.Column align='right'>{(item:Profile) => item.users_count}</Dropdown.Column>
            </Dropdown>
          </Form.Uncontrolled>}
        <Form.Uncontrolled hint={<Loc msg="users_filter_from_date_hint"/>}>
          <Input name="from" type="date" fluid clearable value={this.getFilter('date', 'gte')} placeholder="From date" onChange={this.handleChangeFromDate}/>
        </Form.Uncontrolled>
        <Form.Uncontrolled hint={<Loc msg="users_filter_to_date_hint"/>}>
          <Input name="to" type="date" fluid clearable value={this.getFilter('date', 'lte')} placeholder="To date" onChange={this.handleChangeToDate}/>
        </Form.Uncontrolled>              
      </Panel.Content>
      <Panel.Footer>
        <Export onExport={this.handleExport}/>
      </Panel.Footer>      
    </React.Fragment>

    return (
      <React.Fragment>
        <IconBar>
          <Panel.Icon icon="tools" width={300}>
            {filter}
          </Panel.Icon>
        </IconBar> 
        <DataTable error={this.state.error} loading={this.state.loading} scrollTop={this.state.scrollTop} onScroll={this.handleScroll} data={this.state.items} onFetch={this.handleFetch} onClick={p.onClick} onOrder={this.handleOrder} order={this.getOrder()} dir={this.getDir()}>
          <DataTable.Column weight={2} label={<Loc msg="users_list_hdr_name"/>} order="name" dir="asc">{(item:User) => <span><Gravatar email={item.email}/> {item.name}</span>}</DataTable.Column>
          <DataTable.Column weight={2} label={<Loc msg="users_list_hdr_email"/>} order="email" dir="asc">{(item:User) => item.email}</DataTable.Column>
          {!p.profileId && <DataTable.Column label={<Loc msg="users_list_hdr_profile"/>} order="profile" dir="asc">{(item:User) => item.profile ? item.profile.name : 'None'}</DataTable.Column>}
          <DataTable.Column label={<Loc msg="users_list_hdr_last_seen"/>} order="login_at" dir="desc">{(item:User) => item.login_at ? <LocalizedDatum.Distance value={item.login_at}/> : <Loc msg="timestamp_unknown_time"/>}</DataTable.Column>
        </DataTable>
        <Dialog.Xhr open={this.state.exportError != null} error={this.state.exportError} onClose={this.handleCloseDialog}/>
      </React.Fragment>
    );
  }
}

export { ListUsers };
