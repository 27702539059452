import * as React from 'react'
import axios from 'axios';
import { Grievance, Issuetype, IssuetypeFactory } from '../../../resource';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Button } from '@independent-software/typeui/controls/Button';
import { IAuthProps, Query } from '../../../services';
import { App } from '../../../App';
import { Form } from '@independent-software/typeui/controls/Form';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Loc, Section } from '../../../modules';
import { Input } from '@independent-software/typeui/controls/Input';
import { Icon } from '@independent-software/typeui/controls/Icon';

interface IProps {
  grievance: Grievance;
  open: boolean;
  onClose: () => void;
  onExec: () => void;
  onFail: (error: any) => void;
}

interface IData {
  issuetype: Issuetype;
  reason: string;
  contact_type: string;
  contact_date: string;
}

interface IState {
  issuetypes: Issuetype[];
  data: IData;
  dirty: boolean;
  isValid: boolean;
  saving: boolean;
}

class TriageConclusionDialog extends React.Component<IAuthProps & IProps, IState> {

  constructor(props: IAuthProps & IProps) {
    super(props);
    this.state = { 
      issuetypes: [],
      data: {
        issuetype: null,
        reason: null,
        contact_type: null,
        contact_date: null
      },
      dirty: false,
      isValid: false,
      saving: false
    }
  }

  componentDidMount() {
    this.loadIssuetypes();
  }

  private loadIssuetypes = () => {
    let query = new Query('name', 'asc');
    IssuetypeFactory.getSome(this.props.auth, 0, 999, query)
      .then((res) => this.setState({ issuetypes: res.items }));    
  }

  private exec = () => {
    let grievance = this.props.grievance;
    this.setState({ saving: true });

    axios.post(`${App.apiURL}grievance/move/${grievance.id}/triage_conclusion`, { api_token: this.props.auth.token, ... this.state.data })
    .then(response => {
      grievance.newstate = 'triage_conclusion';
      this.props.onExec();
    })
    .catch(error => {
      this.props.onFail(error);
    })
    .finally(() => {
      this.setState({ saving: false });
    });
  }

  handleChange = (data: IData, forceupdate: boolean) => {
    this.setState({ data: data });
  }

  handleValidate = (valid: boolean) => {
    this.setState({
      isValid: valid
    })
  }  


  render() {
    return (
      <Dialog width={800} open={this.props.open} onClose={this.props.onClose} canClose={!this.state.saving}>
        <Dialog.Header><Loc msg="grievance_workflow_triage_conclusion_title"/></Dialog.Header>
        
        <Section padded>
          <Form data={this.state.data} dirty={this.state.dirty} onChange={this.handleChange} onValidate={this.handleValidate}>
            
            <Form.Field
              required={{message: <Loc msg="grievance_workflow_triage_conclusion_issuetype_required"/>}}
              width={1} label={<Loc msg="grievance_workflow_triage_conclusion_issuetype_label"/>}
              name="issuetype" 
              value={this.state.data.issuetype}
              control={(<Dropdown fluid data={this.state.issuetypes} placeholder="Issue type" label={(item:Issuetype) => item.name}>
                <Dropdown.Column>{(item:Issuetype) => item.name}</Dropdown.Column>
              </Dropdown>)}
              hint={<Loc msg="grievance_workflow_triage_conclusion_issuetype_hint"/>}/>

            <Form.Field 
              required={{message: <Loc msg="grievance_workflow_triage_conclusion_reason_required"/>}}
              minLength={{length: 3, message: <Loc msg="grievance_workflow_triage_conclusion_reason_minlength"/>}}
              maxLength={{length: 255, message: <Loc msg="grievance_workflow_triage_conclusion_reason_maxlength"/>}}
              width={1} label={<Loc msg="grievance_workflow_triage_conclusion_reason_label"/>}
              name="reason" 
              value={this.state.data.reason}
              control={(<Input type="text" placeholder="Reason" fluid/>)}
              hint={(<React.Fragment><Loc msg="grievance_workflow_triage_conclusion_reason_hint"/></React.Fragment>)}/>

          </Form>

        </Section>
        
        <Dialog.Footer>
          <Button disabled={!this.state.isValid || this.state.saving} primary onClick={() => this.exec()}>
            {this.state.saving && <Icon name="circle-notch" loading/>}
            <Loc msg="btn_ok"/>
          </Button>
          <Button disabled={this.state.saving} secondary onClick={this.props.onClose}><Loc msg="btn_cancel"/></Button>          
        </Dialog.Footer>
      </Dialog>
    )
  }
}

export { TriageConclusionDialog }