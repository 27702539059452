import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { TCalculation, TChartType } from './definitions/Types';
import { LayoutType } from 'recharts';
import { IconBar, Loc } from '../../modules';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { Button } from '@independent-software/typeui/controls/Button';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { Divider } from '@independent-software/typeui/controls/Divider';

interface IProps {
  type: TChartType;
  calc: TCalculation;
  layout: LayoutType;
  stacked: boolean;
  grid: boolean;
  donut: boolean;
  onChange: (type: TChartType, calc: TCalculation, layout: LayoutType, stacked: boolean, grid: boolean, donut: boolean) => void;
  onExport: () => void;
}

class ChartControls extends React.Component<IProps> {
  render = () => {
    let p = this.props;
    return (
      <IconBar>
        <Panel.Icon icon="tools" width={400}>
          <Panel.Content>
            <ControlContainer>
              <Loc msg="analysis_surveys_visualization"/>
              <Button.Group size="small">
                <Button noripple secondary={p.type == 'table'} onClick={() => p.onChange('table', p.calc, p.layout, p.stacked, p.grid, p.donut)}><Loc msg="analysis_surveys_table"/></Button>
                <Button noripple secondary={p.type == 'bar'} onClick={() => p.onChange('bar', p.calc, p.layout, p.stacked, p.grid, p.donut)}><Loc msg="analysis_surveys_bar"/></Button>
                <Button noripple secondary={p.type == 'pie'} onClick={() => p.onChange('pie', p.calc, p.layout, p.stacked, p.grid, p.donut)}><Loc msg="analysis_surveys_pie"/></Button>
              </Button.Group>   
            </ControlContainer>
            <Divider/>                
            <ControlContainer>
              <Loc msg="analysis_surveys_calculation"/>
              <Button.Group size="small">
                <Button noripple secondary={p.calc == 'absolute'} onClick={() => p.onChange(p.type, 'absolute', p.layout, p.stacked, p.grid, p.donut)}><Loc msg="analysis_surveys_abs"/></Button>
                <Button noripple secondary={p.calc == 'relative'} onClick={() => p.onChange(p.type, 'relative', p.layout, p.stacked, p.grid, p.donut)}><Loc msg="analysis_surveys_rel"/></Button>
              </Button.Group>
            </ControlContainer>
            {p.type == 'bar' && <>
            <Divider/>
            <ControlContainer>
              <Loc msg="analysis_surveys_layout"/>
              <Button.Group size="small">
                <Button noripple secondary={p.layout == 'horizontal'} onClick={() => p.onChange(p.type, p.calc, 'horizontal', p.stacked, p.grid, p.donut)}><Loc msg="analysis_surveys_hor"/></Button>
                <Button noripple secondary={p.layout == 'vertical'} onClick={() => p.onChange(p.type, p.calc, 'vertical', p.stacked, p.grid, p.donut)}><Loc msg="analysis_surveys_vert"/></Button>
              </Button.Group>   
            </ControlContainer>
            <Divider/>
            <ControlContainer>
              <Loc msg="analysis_surveys_stacked"/>
              <Checkbox type='toggle' checked={p.stacked} onChange={(value) => p.onChange(p.type, p.calc, p.layout, value, p.grid, p.donut)}/>
            </ControlContainer>
            <ControlContainer>
              <Loc msg="analysis_surveys_grid"/>
              <Checkbox type='toggle' checked={p.grid} onChange={(value) => p.onChange(p.type, p.calc, p.layout, p.stacked, value, p.donut)}/>
            </ControlContainer>
            </>}
            {p.type == 'pie' && <>
              <Divider/>
              <ControlContainer>
                <Loc msg="analysis_surveys_donut"/>
                <Checkbox type='toggle' checked={p.donut} onChange={(value) => p.onChange(p.type, p.calc, p.layout, p.stacked, p.grid, value)}/>
              </ControlContainer>
            </>}
          </Panel.Content>
          <Panel.Footer>
            <Button secondary size="small" onClick={p.onExport}><Loc msg="analysis_surveys_export"/></Button>
          </Panel.Footer>
        </Panel.Icon>
      </IconBar>       
    );
  }
}

const ControlContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export { ChartControls }
