import * as React from 'react';

class CustomizedAxisTick extends React.PureComponent<{ x?:number, y?: number, payload?: any }> {
  render() {
    let value = this.props.payload.value;
    return (
      <g transform={`translate(${this.props.x},${this.props.y})`}>
        <text x={0} y={0} dy={12} fontSize={12} textAnchor="middle" fill="#666">{'Q' + (value ? value.substr(2) : '')}</text>
      </g>
    );
  }
}

export { CustomizedAxisTick }