import * as React from 'react';
import { Container, Content, BottomBar, Loc } from '../../modules';
import { Household } from '../../resource/';
import { RouteComponentProps } from 'react-router';
import { ListHouseholds } from './ListHouseholds';
import { IAuthProps } from '../../services/Auth';

import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';

class HouseholdList extends React.Component<IAuthProps & RouteComponentProps<any>, {}> {

  // Go to add action type.
  handleAdd = () => {
    this.props.history.push('/households/add');
  }  

  // Go to view action type view.
  handleClick = (item: Household) => {
    this.props.history.push(`/households/${item.id}`);
  }  

  render() {
    let p = this.props;
    return (
      <Container>
        <Content>
          <ListHouseholds auth={p.auth} name="households" onClick={this.handleClick}/>
        </Content>
        <BottomBar>
          {p.auth && p.auth.hasRight('can_edit_households') && <Button primary onClick={this.handleAdd}><Icon name="plus"/> <Loc msg="households_btn_add"/></Button>}
        </BottomBar>
      </Container>
    );
  }
}

export { HouseholdList };
