import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { css } from 'styled-components';

import { Icon } from '@independent-software/typeui/controls/Icon';
import { List } from '@independent-software/typeui/controls/List';
import { IIndicator } from './definitions';
import { LanguageContext, Loc } from '../../modules';
import { Flex } from '@independent-software/typeui/controls/Flex';

interface IProps {
  indicators: IIndicator[],
  indicator: IIndicator;
  onClickIndicator: (indicator: IIndicator) => void;
}

interface IState {
  dimension: string;
  issue: string;
}

class IndicatorTree extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      dimension: null,
      issue: null
    }
  }

  handleClickIndicator = (indicator: IIndicator) => {
    this.props.onClickIndicator(indicator);
  }

  renderIndicators = (dimension: string, issue: string) => {
    return (
      <List>
        <LanguageContext.Consumer>
        {({language, data}) => <>        
        {this.props.indicators.filter(i => i.dimension == dimension && i.issue == issue)
          //.sort((a,b) => Loc.getTextNoDiacritics(data, ("indicator_" + a.name) as any) > Loc.getTextNoDiacritics(data, ("indicator_" + b.name) as any) ? 1 : 0)
          .map(i => 
          <List.Item key={i.name}>
            <Selector active={this.props.indicator != null && this.props.indicator.name==i.name}>
              <Flex.Quick>
                <Icon name="line-chart" color={(this.props.indicator != null && this.props.indicator.name==i.name) ? '#fff' : '#333'}/>
                <List.Content onClick={() => this.handleClickIndicator(i)}><Loc msg={("indicator_" + i.name) as any}/></List.Content>
              </Flex.Quick>
            </Selector>
          </List.Item>
        )}</>
        }</LanguageContext.Consumer>
      </List>
    )
  }

  handleClickIssue = (issue: string) => {
    this.setState({ issue: issue });
  }

  renderIssues = (dimension: string) => {
    return (
      <List>
        <LanguageContext.Consumer>
        {({language, data}) => <>
        {this.props.indicators.filter(i => i.dimension == dimension).map(i => i.issue)
        //.sort((a,b) => Loc.getTextNoDiacritics(data, a as any) > Loc.getTextNoDiacritics(data, b as any) ? 1 : 0)
        .filter((item, pos, ary) => !pos || item != ary[pos - 1]).map(issue =>
          <List.Item key={issue}>
            <Icon 
              name="caret-down" 
              onClick={() => this.handleClickIssue(issue)} 
              rotated={dimension == this.state.dimension && issue == this.state.issue ? 0 : 270}/>
            <List.Content onClick={() => this.handleClickIssue(issue)}><Loc msg={issue as any}/></List.Content>
            {(dimension == this.state.dimension && issue == this.state.issue) && this.renderIndicators(dimension, issue)}
          </List.Item>
        )}</>
      }</LanguageContext.Consumer>
      </List>
    )
  }

  handleClickDimension = (dimension: string) => {
    this.setState({ dimension: dimension, issue: null });
  }

  render = () => {
    return (
      <LanguageContext.Consumer>
      {({language, data}) =>         
      <List>
        {this.props.indicators.map(i => i.dimension)
          //.sort((a,b) => Loc.getTextNoDiacritics(data, a as any) > Loc.getTextNoDiacritics(data, b as any) ? 1 : 0)
          .filter((item, pos, ary) => !pos || item != ary[pos - 1]).map(dim =>
          <List.Item key={dim}>
            <Icon 
              name="caret-down" 
              onClick={() => this.handleClickDimension(dim)} 
              rotated={dim == this.state.dimension ? 0 : 270}/>
            <List.Content 
              onClick={() => this.handleClickDimension(dim)}><Loc msg={dim as any}/></List.Content>
            {dim == this.state.dimension && this.renderIssues(dim)}
          </List.Item>
        )}
      </List>
      }</LanguageContext.Consumer>
    );
  }
} 

class SelectorBase extends React.Component<{className?: string, active: boolean}> {
  render = () => {
    return (
      <div className={this.props.className}>{this.props.children}</div>
    );
  }
}

const Selector = styled(SelectorBase)`
  padding: 1px 8px 1px 8px;
  width: 100%;
  ${p => p.active && css`
    background: #333;
    color: white;
  `}
`


export { IndicatorTree };
