import * as React from 'react';
import { StakeholderFactory, Stakeholder, Request, RequestFactory } from '../../resource/'; 
import { IAuthProps, List, IListState, IListProps, SearchFilter, Query } from '../../services/';
import { IconBar, Export, Loc } from '../../modules';

import { Form } from '@independent-software/typeui/controls/Form';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { DataTable } from '@independent-software/typeui/controls/DataTable';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { LocalizedDatum } from '../../formatters/LocalizedDatum';
import { TLocalization } from '../../modules/Loc/TLocalization';
import { Label } from '@independent-software/typeui/controls/Label';
import { Theme } from '@independent-software/typeui/styles';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';

interface IListRequestsProps extends IListProps {
  /**
   * If set, list is prefiltered by a Stakeholder and
   * Stakeholder column and filter are hidden.
   */
  stakeholderId?: number;
  /**
   * Event is fired when a request is clicked.
   */
  onClick?: (request:Request) => void;
}

interface IListRequestsState extends IListState<Request> {
  /**
   * Data for Stakeholders dropdown
   */
  stakeholders: Stakeholder[];
}

class ListRequests extends List<Request, IListRequestsProps, IListRequestsState> {
  constructor(props: IAuthProps & IListRequestsProps) {
    super(props, RequestFactory, 'title', 'asc');

    // Initialize state (list initializes its own part of the state):
    this.state = {
      ...this.state,
      stakeholders: []
    };

    // The stakeholder stored in the Query is an ID, not a stakeholder.
    // Is the list prefiltered by a stakeholder? Then set a filter.
    if(this.props.stakeholderId) {
      this.setFilter('stakeholder', 'eq', { id: this.props.stakeholderId }, true);
    }        
  }

  componentDidMount() {
    this.handleStakeholderSearch();
  }

  handleSearch = (value:string) => {
    this.setFilter('q', 'like', value);
  }  

  handleChangeStakeholder = (value: Stakeholder) => {
    this.setFilter('stakeholder', 'eq', value);
  }

  handleStakeholderSearch = (q?:string) => {
    // Retrieve a list of stakeholders:
    let query = new Query('name', 'asc');
    if (q) query.setFilter('q', 'like', q);
    StakeholderFactory.getSome(this.props.auth, 0, 8, query)
      .then((res) => this.setState({ stakeholders: res.items }));
  }  

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Form.Uncontrolled hint={<Loc msg="requests_filter_search_hint"/>}>
          <SearchFilter value={this.getFilter('q', 'like')} onSearch={this.handleSearch}/>
        </Form.Uncontrolled>
        {!p.stakeholderId && 
          <Form.Uncontrolled hint={<Loc msg="requests_filter_stakeholder_hint"/>}>
            <Dropdown onSearch={this.handleStakeholderSearch} name="stakeholder" fluid clearable value={this.getFilter('stakeholder', 'eq')} data={this.state.stakeholders} placeholder="stakeholder" label={(item:Stakeholder) => item.name} onChange={this.handleChangeStakeholder}>
              <Dropdown.Column>{(item:Stakeholder) => item.name}</Dropdown.Column>
            </Dropdown>
          </Form.Uncontrolled>}        
      </Panel.Content>
      <Panel.Footer>
        <Export onExport={this.handleExport}/>
      </Panel.Footer>      
    </React.Fragment>

    return (
      <React.Fragment>
        <IconBar>
          <Panel.Icon icon="tools" width={300}>
            {filter}
          </Panel.Icon>
        </IconBar> 
        <DataTable error={this.state.error} loading={this.state.loading} scrollTop={this.state.scrollTop} onScroll={this.handleScroll} data={this.state.items} onFetch={this.handleFetch} onClick={p.onClick} onOrder={this.handleOrder} order={this.getOrder()} dir={this.getDir()}>
          <DataTable.Column weight={2} label="ID" order="id" dir="asc">{(item:Request) => item.id}</DataTable.Column>
          <DataTable.Column weight={8} label={<Loc msg="requests_list_hdr_title"/>} order="title" dir="asc">{(item:Request) => <span>{item.title}</span>}</DataTable.Column>
          {/* <DataTable.Column weight={5} label={<Loc msg="requests_list_hdr_date"/>} order="date" dir="desc">{(item:Request) => <LocalizedDatum.ShortDate value={item.date}/>}</DataTable.Column> */}
          {!p.stakeholderId && <DataTable.Column weight={8} label={<Loc msg="requests_list_hdr_stakeholder"/>} order="stakeholder" dir="asc">{(item:Request) => item.stakeholder ? item.stakeholder.name : 'None'}</DataTable.Column>}
          {/* <DataTable.Column weight={2} align="right" label={<Loc msg="requests_list_hdr_attachments"/>} order="attachments_count" dir="asc">{(item:Request) => <Number value={item.attachments_count} decimals={0}/>}</DataTable.Column> */}
        </DataTable>
        <Dialog.Xhr open={this.state.exportError != null} error={this.state.exportError} onClose={this.handleCloseDialog}/>
      </React.Fragment>
    );
  }
}

export { ListRequests };
