import * as React from 'react';
import axios from 'axios';
import { Section, Loc, Container, Content, BottomBar, Timestamp } from '../../modules';
import { Survey, SurveyFactory, SurveyCommunity, Response } from '../../resource/'; 
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';

import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Header } from '@independent-software/typeui/controls/Header';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { ListSurveyCommunities } from './ListSurveyCommunities';
import { LocalizedDatum } from '../../formatters';
import { ListResponses } from '../Response';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Dropzone } from '@independent-software/typeui/controls/Dropzone';
import { Button } from '@independent-software/typeui/controls/Button';
import { App } from '../../App';
import { Loader } from '@independent-software/typeui/controls/Loader';
import { Message } from '@independent-software/typeui/controls/Message';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Label } from '@independent-software/typeui/controls/Label';
import { QuestionTree } from '../Response/Definitions';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Form } from '@independent-software/typeui/controls/Form';
import { Flex } from '@independent-software/typeui/controls/Flex';

type TStep = 'loading' | 'loadError' | 'ready' | 'confirm' | 'deleting' | 'deleteError' | 'error' | 'saveError';

interface IState {
  importOpen: boolean;
  step: TStep;
  survey: Survey;
  error: any;  
  deleteAll: boolean;
  loading: boolean;
  exportAll: boolean;
  exportLabels: string;
  exportFormat: string;
}

class ViewSurvey extends React.Component<IAuthProps & RouteComponentProps<{id:string}>, IState> {
  
  constructor(props: IAuthProps & RouteComponentProps<{id:string}>) {
    super(props);
    this.state = {
      step: 'loading',
      survey: null,
      error: null,
      importOpen: false,
      deleteAll: false,
      loading: false,
      exportAll: false,
      exportLabels: "labels",
      exportFormat: "excel"
    };
  }

  componentDidMount() {
    this.loadItem();
  }

  private loadItem = () => {
    this.setState({ step: 'loading' });
    SurveyFactory.get(this.props.auth, parseInt(this.props.match.params.id))
    .then((item) => {
      this.setState({
        step: 'ready',
        survey: item
      });
    })
    .catch(error => {
      this.setState({
        step: 'loadError',
        error: error
      })
    });
  }

  private goto = (step:TStep) => {
    this.setState({ step: step});
  }  

  private handleCancelLoad = () => {
    this.setState({ step: 'error' });
  }

  private handleCancelSave = () => {
    this.setState({ step: 'ready' });
  }

  private handleRetry = () => {
    this.setState({error: null, step: 'loading'});
    this.loadItem();
  }  

  private handleEdit = () => {
    this.props.history.push(`/surveys/edit/${parseInt(this.props.match.params.id)}`);
  }

  private handleDelete = () => {
    this.setState({ step: 'deleting', error: null});
    this.state.survey.$delete(this.props.auth)
      .then(res => {
        ToastService.toast(<Loc msg="surveys_msg_deleted"/>);
        this.props.history.push('/surveys');        
      })
      .catch(error => {
        this.setState({
          step: 'deleteError',
          error: error
        })
      })
  }   

  private handleBeginDeleteAll = () => {
    this.setState({ deleteAll: true });
  }

  private handleCancelDeleteAll = () => {
    this.setState({ deleteAll: false });
  }

  private handleDoDeleteAll = () => {
    this.setState({ deleteAll: false });

    axios.post(`${App.apiURL}responses/deleteall/${this.state.survey.id}?api_token=${this.props.auth.token}`)
    .then(response => {
      location.reload();
    })
    .catch(error => {
    })
    .finally(() => {
    });    
  }

  private handleSurveyCommunityClick = (item: SurveyCommunity) => {
    this.props.history.push(`/communities/${item.community_id}`);
  }

  private handleResponseClick = (item: Response) => {
    this.props.history.push(`/responses/${item.id}`);
  }

  handleImport = () => {
    this.setState({
      importOpen: true
    });
  }

  handleBeginExportAll = () => {
    this.setState({ exportAll: true });
  }

  handleCancelExportAll = () => {
    this.setState({ exportAll: false });
  }

  handleExportAllChangeLabels = (value: string) => {
    this.setState({ exportLabels: value });
  }

  handleExportAllChangeFormat = (value: string) => {
    this.setState({ exportFormat: value});
  }

  handleDoExportAll = () => {
    this.setState({ exportAll: false, loading: true });
    axios.post(`${App.apiURL}responses/exportall/${this.state.survey.id}/${this.state.exportLabels == 'labels' ? 1 : 0}/${this.state.exportFormat}`, { 
      api_token: this.props.auth.token,
      tree: QuestionTree
    }, { responseType: 'blob' })
    .then(response => {
      // Find the content-disposition header.
      let disposition:string = response.headers['content-disposition'];
      // Using a regexp, retrieve the filename from it.
      let regexp = new RegExp('\"(.*)\"');
      let res:RegExpExecArray = regexp.exec(disposition);
      let filename = res[1];
      // Download the file.
      saveAs(response.data, filename);    
    })
    .finally(() => {
      this.setState({ loading: false });
    });
  }

  handleCloseImport = () => {
    this.setState({
      importOpen: false
    });
  }

  handleDoImport = () => {
    this.setState({
      importOpen: false
    });    
  }

  handleUpload = (files: File[]) => {
    this.setState({ importOpen: false, loading: true });    
    let formData = new FormData();
    formData.append('file', files[0]);
    formData.append('api_token', this.props.auth.token);
    axios.post(`${App.apiURL}responses/import/${this.state.survey.id}`, formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    .then(response => {      
      location.reload();
    })
    .catch(error => {
    })
    .finally(() => {
      this.setState({ loading: false });
    });
  }  

  render() {
    let p = this.props;
    return (
      <React.Fragment>
        {this.state.loading && <Loader/>}
        <Container>
          {(this.state.step == 'loading' || this.state.step == 'loadError' || this.state.step == 'deleting') && 
            <Loader/>}
          {this.state.step != 'loading' && this.state.step != 'loadError' && this.state.step != 'error' && 
          <React.Fragment>
            <Content>
              <Section dark padded>
                <Header size="h2">{this.state.survey.name}</Header>
                <Header size="h5"><LocalizedDatum.LongDate value={this.state.survey.date}/></Header>
                { /* New responses cannot be added manually - they are imported as part of a survey. */ }
              </Section>
              <Tabs underlined>
                <Tabs.Pane label={<span><Loc msg="surveys_form_tab_responses"/> <Label size="tiny">{this.state.survey.responses_count}</Label></span>}>
                  <ListResponses auth={p.auth} name="survey_reponses" surveyId={this.state.survey.id} onClick={this.handleResponseClick}/>
                </Tabs.Pane>
                <Tabs.Pane label={<span><Loc msg="surveys_form_tab_communities"/> <Label size="tiny">{this.state.survey.communities_count}</Label></span>}>
                  <ListSurveyCommunities auth={p.auth} name="survey_surveycommunities" surveyId={this.state.survey.id} onClick={this.handleSurveyCommunityClick}/>
                </Tabs.Pane>                
              </Tabs>
            </Content>
            <BottomBar>
              <div>
                {p.auth && p.auth.hasRight('can_edit_surveys') && <Button primary onClick={this.handleEdit}><Icon name="edit"/> <Loc msg="btn_edit"/></Button>}
              </div>
              <div>
                {p.auth && p.auth.hasRight('can_debug') && <Button onClick={this.handleBeginExportAll}><Icon name="file-excel"/> <Loc msg="surveys_btn_export"/></Button>}
                {p.auth && p.auth.hasRight('can_debug') && <Button onClick={this.handleImport}><Icon name="file-excel"/> <Loc msg="surveys_btn_import"/></Button>}
                {p.auth && p.auth.hasRight('can_edit_surveys') && <Button negative onClick={() => this.goto('confirm')}><Icon name="trash"/> <Loc msg="surveys_btn_delete"/></Button>}
                {p.auth && p.auth.hasRight('can_debug') && <Button negative onClick={this.handleBeginDeleteAll}><Icon name="trash"/> <Loc msg="surveys_btn_delete_all"/></Button>}
                <Timestamp model={this.state.survey}/>
              </div>
            </BottomBar>
          </React.Fragment>}
          {this.state.step == 'error' && 
          <Content>
            <Section padded>
              <Message type="error"><Loc msg="msg_data_not_found"/></Message>
            </Section>
          </Content>}        
        </Container>

        <Dialog.Xhr open={this.state.step == 'loadError'} error={this.state.error} onClose={this.handleCancelLoad} onRetry={this.handleRetry}/>    
        <Dialog.Xhr open={this.state.step == 'saveError'} error={this.state.error} onClose={this.handleCancelSave}/>    
        <Dialog.Xhr open={this.state.step == 'deleteError'} error={this.state.error} onClose={() => this.goto('ready')} onRetry={this.handleDelete}/>

        <Dialog.Confirm open={this.state.step == 'confirm'} onClose={() => this.goto('ready')} onConfirm={this.handleDelete}>
          <Loc msg="surveys_msg_confirm_deletion"/>
        </Dialog.Confirm>

        <Dialog width={800} open={this.state.importOpen} onClose={this.handleCloseImport}>
          <Dialog.Header>
            <Loc msg="surveys_hdr_import"/>
          </Dialog.Header>
          <Dialog.Content>
            <Message type='info'>
              <Loc msg="surveys_txt_import"/>
            </Message>
            <Dropzone onAddFiles={this.handleUpload} message={<Loc msg="surveys_msg_import"/>}/>
          </Dialog.Content>
          <Dialog.Footer>
            <Button negative onClick={this.handleCloseImport}><Loc msg="btn_close"/></Button>
          </Dialog.Footer>
        </Dialog>  

        <Dialog.Confirm open={this.state.deleteAll == true} onClose={this.handleCancelDeleteAll} onConfirm={this.handleDoDeleteAll}>
          <Loc msg="surveys_msg_confirm_clear"/>
        </Dialog.Confirm>

        <Dialog open={this.state.exportAll == true} onClose={this.handleCancelExportAll}>
          <Dialog.Header>
            <Loc msg="surveys_exportall_hdr"/>
          </Dialog.Header>
          <Section padded>
            <Message type='info'>
              <Loc msg="surveys_exportall_txt"/>
            </Message>
            <Flex.Columns count={2}>
              <Form.Uncontrolled hint={<Loc msg="surveys_exportall_labels_hint"/>}>
                <Dropdown fluid data={[ "labels", "numbers" ]} value={this.state.exportLabels} label={(item) => <Loc msg={("surveys_exportall_lbl_" + item) as any}/>} onChange={this.handleExportAllChangeLabels}>
                  <Dropdown.Column>{(item) => <Loc msg={("surveys_exportall_lbl_" + item) as any}/>}</Dropdown.Column>
                </Dropdown>
              </Form.Uncontrolled>
              <Form.Uncontrolled hint={<Loc msg="surveys_exportall_format_hint"/>}>
                <Dropdown fluid data={[ "excel", "ods" ]} value={this.state.exportFormat} label={(item) => <Loc msg={("surveys_exportall_lbl_" + item) as any}/>} onChange={this.handleExportAllChangeFormat}>
                  <Dropdown.Column>{(item) => <Loc msg={("surveys_exportall_lbl_" + item) as any}/>}</Dropdown.Column>
                </Dropdown>
              </Form.Uncontrolled>
            </Flex.Columns>
          </Section>
          <Dialog.Footer>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <Button negative onClick={this.handleCancelExportAll}><Loc msg="btn_close"/></Button>
              <Button onClick={this.handleDoExportAll}><Loc msg="btn_ok"/></Button>
            </div>
          </Dialog.Footer>
        </Dialog>   

      </React.Fragment>
    );
  }
}
 
export { ViewSurvey };
