import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { TooltipPayload, TooltipProps } from 'recharts';
import { TCalculation } from '../definitions';
import { Table } from '@independent-software/typeui/controls/Table';

/*
  The CustomTooltip can by passed to a Recharts chart. It prettifies tooltip
  display and makes it more compact.
*/

class CustomTooltip extends React.Component<TooltipProps & { calc: TCalculation }> {
  render = () => {
    let p = this.props;
    return (
    <TooltipWrapper>
      <Table transparent striped>
        <tbody>
          {p.payload != null && p.payload.map((payload:TooltipPayload, index) => <tr key={index}>
            <td style={{width: '80%'}}>{payload.name}</td>
            <td style={{width: '20%', textAlign: 'right'}}>{Math.round(payload.value as number)}{p.calc == 'relative' && '%'}</td>
          </tr>)}
        </tbody>
      </Table>
    </TooltipWrapper>);
  }
}

const TooltipWrapper = styled('div')`
  width: 220px;
  padding: 5px;
  background: #fff;
  font-size: 70%;
  line-height: 1.3em;
  border: solid 1px #aaa;
  box-shadow: rgba(34, 36, 38, 0.15) 0px 0px 4px 0px;
`

export { CustomTooltip }
