import * as React from 'react';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { ROUTES } from './config/Routes';
import { Auth } from './services/Auth';
import { Screen } from './environment/Screen'; 
import { Public } from './environment/Public';
import { AccessDenied } from './components/AccessDenied/AccessDenied';
import { ToastContainer } from '@independent-software/typeui/controls/Toast';
import { PublicRoute, SecureRoute } from './config/Route';

interface IAppContainerState {
  /**
   * Auth data (or null if not signed in)
   */
  auth: Auth;
}

class AppContainer extends React.Component<{}, IAppContainerState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      auth: Auth.restore()
    };
  }

  /**
   * On successful signin, store Auth in state.
   * @param auth Auth object
   */    
  private handleSignin = (auth: Auth) => {
    this.setState({ auth: auth });
  }

  /**
   * On successful signout, remove Auth from state.
   */
  private handleSignout = () => {
    sessionStorage.removeItem('auth');
    this.setState({ auth: null });
  }

  private getPublicRoutes = () => {
    // Go through list of routes (only routes that do NOT require authentication):
    return Object.keys(ROUTES).filter((key) => ROUTES[key] instanceof PublicRoute).map((key, index) => {
      const route = ROUTES[key]; 

      return <Route 
        key={index} 
        exact 
        path={route.path} 
        render={(props) => {
          // Instantiate component:
          let component = React.createElement(route.component, {...props, onSignin: this.handleSignin, auth: this.state.auth});
          // Wrap component in Public screen:
          return (
            <Public {...props}>{component}</Public>
          );
        }}
        />

    });
  }

  private getAuthRoutes = () => {
    // Go through list of routes (only routes that require authentication)
    return Object.keys(ROUTES).filter((key) => ROUTES[key] instanceof SecureRoute).map((key, index) => {
      const route = ROUTES[key]; 

      return <Route 
        key={index} 
        exact 
        path={route.path} 
        render={(props) => {
          
          // Instantiate component:
          let component = null;
          if(route.isAllowed(this.state.auth)) {
            component = React.createElement(route.component, {...props, auth: this.state.auth});              
          } else {
            component = <AccessDenied/>;
          }

          // Wrap component in <Screen>:
          return ( 
            <Screen {...props} 
              title={route.title}
              auth={this.state.auth} 
              onSignout={this.handleSignout}>
              {component}
            </Screen> 
          ) 
        }}
        />
    });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer maxToasts={5}/>
        <Router>
          <Switch>
            {this.state.auth ? this.getAuthRoutes() : this.getPublicRoutes()}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export { AppContainer };