type TOperator = 'eq' | 'neq' | 'gt' | 'gte' | 'lt' | 'lte' | 'like';
type TDir = 'asc' | 'desc';

/**
 * A query is a collection of filters and an order.
 */
class Query {
  public order: string;
  public dir: TDir;
  public timestamp: number;
  private filters: Map<string, any>;

  constructor(order: string, dir?: TDir) {
    this.order = order;
    this.dir = dir ? dir : 'asc';
    this.filters = new Map<string, any>();
  }

  // A null value removes the filter.
  public setFilter = (name: string, operator: TOperator, value: any): Query => {
    if(value == null) {
      this.filters.delete(`${name}-${operator}`);  
    } else {
      this.filters.set(`${name}-${operator}`, value);
    }
    return this;
  }

  public removeFilter = (name: string, operator: TOperator): Query => {
    this.filters.delete(`${name}-${operator}`);
    return this;
  }

  public hasFilter = (name: string, operator: TOperator): boolean => {
    return this.filters.has(`${name}-${operator}`);
  }

  public getFilter = (name: string, operator: TOperator): any => {
    if(!this.filters.has(`${name}-${operator}`)) return null;
    return this.filters.get(`${name}-${operator}`);
  }

  private buildFilterString = (filterString: string, field: string, operator: string, value:any) => {
    // Chain filters together with '&':
    if(filterString != '') filterString += '&';
    // If value is an object, use the object's id field
    if(typeof value === 'object' && value !== null) value = value.id;
    // Add value to filter string:
    return filterString + `${field}[]=${operator}-${value}`;
  }

  public toUrl = (): string => {
    let order = this.order ? `order=${this.order}&dir=${this.dir}` : '';
    let filters = '';
    this.filters.forEach((value: any, key: string) => {
      let [field, operator] = key.split('-', 2);

      // Is the value an array of values? Then look through it.
      if(Array.isArray(value)) {
        value.forEach((v) => {
          filters = this.buildFilterString(filters, field, operator, v);
        });
      } 
      // Is the value a single value?
      else {
        filters = this.buildFilterString(filters, field, operator, value);
      }
    });
    return order + (order && filters && '&') + filters;
  }
}

export { TOperator, TDir, Query };
