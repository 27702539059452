import * as React from 'react';
import { SurveyCommunity, SurveyCommunityFactory } from '../../resource/'; 
import { IAuthProps, List, IListState, IListProps, SearchFilter } from '../../services/';
import { IconBar, Export, Loc } from '../../modules';

import { Form } from '@independent-software/typeui/controls/Form';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { DataTable } from '@independent-software/typeui/controls/DataTable';
import { Number } from '@independent-software/typeui/formatters/Number';

interface IListSurveyCommunitiesProps extends IListProps {
  /**
   * If set, list is prefiltered by a survey and
   * survey column and filter are hidden.
   */
  surveyId?: number;  
  /**
   * If set, list is prefiltered by a community and
   * community column and filter are hidden.
   */
  communityId?: number;    
  /**
   * Event is fired when a survey is clicked.
   */
  onClick?: (surveyCommunity:SurveyCommunity) => void;
}

interface IListSurveysState extends IListState<SurveyCommunity> {
}

class ListSurveyCommunities extends List<SurveyCommunity, IListSurveyCommunitiesProps, IListSurveysState> {
  constructor(props: IAuthProps & IListSurveyCommunitiesProps) {
    super(props, SurveyCommunityFactory, 'id', 'asc');

    // Initialize state (list initializes its own part of the state):
    this.state = {
      ...this.state
    };

    if(this.props.surveyId) this.setFilter('survey', 'eq', { id: this.props.surveyId }, true);
    if(this.props.communityId) this.setFilter('community', 'eq', { id: this.props.communityId }, true);
  }

  handleSearch = (value:string) => {
    this.setFilter('q', 'like', value);
  }  

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Form.Uncontrolled hint={<Loc msg="surveys_filter_search_hint"/>}>
          <SearchFilter value={this.getFilter('q', 'like')} onSearch={this.handleSearch}/>
        </Form.Uncontrolled>
      </Panel.Content>
    </React.Fragment>

    return (
      <React.Fragment>
        <IconBar>
          <Panel.Icon icon="tools" width={300}>
            {filter}
          </Panel.Icon>
        </IconBar> 
        <DataTable error={this.state.error} loading={this.state.loading} scrollTop={this.state.scrollTop} onScroll={this.handleScroll} data={this.state.items} onFetch={this.handleFetch} onClick={p.onClick} onOrder={this.handleOrder} order={this.getOrder()} dir={this.getDir()}>
          {!p.surveyId && <DataTable.Column weight={2} label={<Loc msg="surveys_list_hdr_survey"/>} order="survey" dir="asc">{(item:SurveyCommunity) => <span>{item.survey}</span>}</DataTable.Column>}
          {!p.communityId && <DataTable.Column weight={2} label={<Loc msg="surveys_list_hdr_community"/>} order="community" dir="asc">{(item:SurveyCommunity) => <span>{item.community}</span>}</DataTable.Column>}
          {/* <DataTable.Column weight={2} label={<Loc msg="surveys_list_hdr_weight_factor"/>} order="factor" dir="asc" align="right">{(item:SurveyCommunity) => <Number value={item.factor} decimals={1}/>}</DataTable.Column> */}
        </DataTable>
        <Dialog.Xhr open={this.state.exportError != null} error={this.state.exportError} onClose={this.handleCloseDialog}/>
      </React.Fragment>
    );
  }
}

export { ListSurveyCommunities };
