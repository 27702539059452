import * as React from 'react';
import axios from 'axios';

import { IAuthProps } from '../../services';
import { FileProgress } from './FileProgress';
import { Attachment } from '../../resource';

import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Divider } from '@independent-software/typeui/controls/Divider';
import { Dropzone } from '@independent-software/typeui/controls/Dropzone';
import { Loc } from '../Loc';

interface IProps {
  className?: string;
  children?: React.ReactNode;
  open: boolean;
  url: string;
  title: React.ReactNode;
  /** This callback is called when the MultiUpload requests to close. */
  onClose: () => void;
  /** This callback is called whenever a file is uploaded successfully. */
  onSuccess: (attachment: Attachment) => void;
}

interface IFileInfo {
  /* Filename of file being uploaded. */
  filename: string;
  /* Upload progress (0-100) */
  progress: number;
  /* Upload failed flag */
  fail: boolean;
}

interface IState {
  files: IFileInfo[]
}

class MultiUpload extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);
    this.state = {
      files: []
    }
  }

  handleClose = () => {
    // Reinitialize state. This is given a small timeout
    // so that state update isn't seen by user while dialog
    // is closing.
    setTimeout(() => this.setState({
      files: []
    }), 300);
    this.props.onClose();
  }

  handleUploadProgress = (e: ProgressEvent, fileInfo: IFileInfo) => {
    this.setState((prevState) => {
      let pos = prevState.files.indexOf(fileInfo);
      prevState.files[pos].progress = Math.round(e.loaded * 100 / e.total);
      return { files: prevState.files };
    });
  }

  handleAddFiles = (files: File[]) => {
    // Go through files:
    files.forEach((file) => {
      // Create IFileInfo object.
      let fileInfo: IFileInfo = {
        filename: file.name,
        progress: 0,
        fail: false
      };

      // Add IFileInfo to state.
      this.setState((prevState) => { return {
        files: [ ...prevState.files, fileInfo]
      }});      

      // Start upload.
      // During the upload, onUploadProgress is called repeatedly, with a reference
      // to the FileInfo of the file being uploaded.
      let config = { onUploadProgress: (e: ProgressEvent) => this.handleUploadProgress(e, fileInfo) };
      let data = new FormData();
      data.append('api_token', this.props.auth.token);
      data.append('file', file);
      axios.post(this.props.url, data, config)
        .then((res) => {
          // Attachment controller returns ID of uploaded filed in
          // Attachments table.
          let attachment = new Attachment();
          attachment.id = res.data.id;
          attachment.size = res.data.size;
          attachment.is_image = res.data.is_image;
          attachment.filename = fileInfo.filename;
          this.props.onSuccess(attachment);
        })
        .catch((err) => {
          // If upload fails, then set FileInfo object
          // to failed.
          this.setState((prevState) => {
            let pos = prevState.files.indexOf(fileInfo);
            prevState.files[pos].fail = true;
            return { files: prevState.files };
          });
        });
    });
  }

  render() {
    let p = this.props;
    return (
      <Dialog open={p.open} onClose={this.handleClose}>
        <Dialog.Header>{p.title}</Dialog.Header>
        <Dialog.Content>
          <Dropzone onAddFiles={this.handleAddFiles} message={<Loc msg="upload_drop_file"/>}/>
          <Divider hidden/>
          {this.state.files.map((f: IFileInfo, index) => 
            <FileProgress key={index} name={f.filename} progress={f.progress} fail={f.fail}/>
          )}
        </Dialog.Content>
      </Dialog>
    )
  }
}

export { MultiUpload }