import * as React from 'react';
import { Section, AttachmentManager, Block, Loc, Container, Content, BottomBar, Timestamp } from '../../modules';
import { Post, Request, RequestFactory } from '../../resource/'; 
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';
import { PostView } from '../Post/PostView';

import { Markdown } from '@independent-software/typeui/formatters/Markdown';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Header } from '@independent-software/typeui/controls/Header';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Label } from '@independent-software/typeui/controls/Label';
import { Button } from '@independent-software/typeui/controls/Button';
import { LocalizedDatum } from '../../formatters';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Loader } from '@independent-software/typeui/controls/Loader';
import { Message } from '@independent-software/typeui/controls/Message';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { AddPost } from '../Post/AddPost';

type TStep = 'loading' | 'loadError' | 'ready' | 'confirm' | 'deleting' | 'deleteError' | 'error' | 'saveError';

interface IState {
  step: TStep;
  request: Request;
  error: any;
}

class ViewRequest extends React.Component<IAuthProps & RouteComponentProps<{id:string}>, IState> {

  constructor(props: IAuthProps & RouteComponentProps<{id:string}>) {
    super(props);
    this.state = {
      step: 'loading',
      request: null,
      error: null
    };
  }

  componentDidMount() {
    this.loadItem();
  }

  private loadItem = () => {
    this.setState({ step: 'loading' });
    RequestFactory.get(this.props.auth, parseInt(this.props.match.params.id))
    .then((item) => {
      this.setState({
        step: 'ready',
        request: item
      });
    })
    .catch(error => {
      this.setState({
        step: 'loadError',
        error: error
      })
    });
  }

  private goto = (step:TStep) => {
    this.setState({ step: step});
  }  

  private handleCancelLoad = () => {
    this.setState({ step: 'error' });
  }

  private handleCancelSave = () => {
    this.setState({ step: 'ready' });
  }

  private handleRetry = () => {
    this.setState({error: null, step: 'loading'});
    this.loadItem();
  }  

  private handleEdit = () => {
    this.props.history.push(`/requests/edit/${parseInt(this.props.match.params.id)}`);
  }

  private handleDelete = () => {
    this.setState({ step: 'deleting', error: null});
    this.state.request.$delete(this.props.auth)
      .then(res => {
        ToastService.toast(<Loc msg="requests_msg_deleted"/>);
        this.props.history.push('/requests');        
      })
      .catch(error => {
        this.setState({
          step: 'deleteError',
          error: error
        })
      })
  }      

  private handleClickStakeholder = (id: number) => {
    this.props.history.push(`/stakeholders/${id}`);
  }

  private handleAddPost = (post: Post) => {
    this.setState({
      request: {
        ...this.state.request,
        posts: [ ...this.state.request.posts, post ]
      }
    });
  }

  private handleUpdatePost = (post: Post) => {
    this.setState({
      request: {
        ...this.state.request,
        posts: [ ...this.state.request.posts ]
      }
    })
  }

  private handleDeletePost = (post: Post) => {
    let posts = this.state.request.posts;
    let idx = posts.indexOf(post);
    posts.splice(idx, 1);
    this.setState({ 
      request: {
        ...this.state.request,
        posts: posts
      }
    })
  }

  render() {
    let p = this.props;
    let request = this.state.request;
    return (
      <React.Fragment>
        <Container>
          {(this.state.step == 'loading' || this.state.step == 'loadError' || this.state.step == 'deleting') && 
            <Loader/>}
          {this.state.step != 'loading' && this.state.step != 'loadError' && this.state.step != 'error' && 
          <React.Fragment>
            <Content>
              <Section dark padded>
                <Flex>
                  <Flex.Row>
                    <Flex.Column>
                      <Header size="h2">
                        #{request.id}: {request.title} 
                      </Header>
                    </Flex.Column>
                  </Flex.Row>
                </Flex>
              </Section>
              <Tabs underlined nohiddenrender>
                <Tabs.Pane label={<Loc msg="requests_form_tab_data"/>}>
                  <Section padded>
                    <Flex.Columns count={2}>
                      <React.Fragment>
                        <Block title={<Loc msg="requests_fields_stakeholder_label"/>}>
                          {request.stakeholder.name}
                          {p.auth.hasRight('can_view_stakeholders') && 
                            <div style={{float:'right'}}>
                              <Button compact basic size="mini" onClick={() => this.handleClickStakeholder(request.stakeholder.id)}><Loc msg="btn_view"/></Button>
                            </div>
                          }
                        </Block>

                        <Block title={<Loc msg="requests_fields_date_label"/>}>
                          <LocalizedDatum.LongDate value={request.date}/>
                        </Block>
                      </React.Fragment>
                      <React.Fragment>
                        <Block title={<Loc msg="requests_fields_description_label"/>}>
                          {request.description &&
                            <Markdown source={request.description}/>
                          }
                          {!request.description &&
                            <Loc msg="requests_fields_description_none"/>
                          }
                        </Block>
                      </React.Fragment>
                    </Flex.Columns>
                  </Section>
                </Tabs.Pane>

                <Tabs.Pane label={<span><Loc msg="requests_form_tab_attachments"/> <Label size="small">{request.attachments.length}</Label></span>}>
                  <Section padded>
                    <AttachmentManager auth={p.auth} attachments={request.attachments}/>
                  </Section>
                </Tabs.Pane>                

                <Tabs.Pane label={<span>{<Loc msg="requests_form_tab_discussion"/>} <Label size="small">{request.posts.length}</Label></span>}>
                  <Section padded>
                    {request.posts.map((post, index) => 
                      <PostView key={index} auth={p.auth} post={post} 
                        onSave={this.handleUpdatePost}
                        onDelete={this.handleDeletePost}/>
                    )}
                    <AddPost 
                      auth={p.auth} onSave={this.handleAddPost}
                      type="request" type_id={this.state.request.id}/>
                  </Section>
                </Tabs.Pane>

              </Tabs>
            </Content>
            <BottomBar>
              <div>
                {p.auth && p.auth.hasRight('can_edit_requests') && <Button primary onClick={this.handleEdit}><Icon name="edit"/> <Loc msg="btn_edit"/></Button>}
              </div>
              <div>
                {p.auth && p.auth.hasRight('can_edit_requests') && <Button negative onClick={() => this.goto('confirm')}><Icon name="trash"/> <Loc msg="btn_delete"/></Button>}
                <Timestamp model={request}/>
              </div>
            </BottomBar>
          </React.Fragment>}
          {this.state.step == 'error' && 
          <Content>
            <Section padded>
              <Message type="error"><Loc msg="msg_data_not_found"/></Message>
            </Section>
          </Content>}        
        </Container>

        <Dialog.Xhr open={this.state.step == 'loadError'} error={this.state.error} onClose={this.handleCancelLoad} onRetry={this.handleRetry}/>    
        <Dialog.Xhr open={this.state.step == 'saveError'} error={this.state.error} onClose={this.handleCancelSave}/>    
        <Dialog.Xhr open={this.state.step == 'deleteError'} error={this.state.error} onClose={() => this.goto('ready')} onRetry={this.handleDelete}/>
        <Dialog.Confirm open={this.state.step == 'confirm'} onClose={() => this.goto('ready')} onConfirm={this.handleDelete}>
          <Loc msg="msg_confirm_deletion"/>
        </Dialog.Confirm>
      </React.Fragment>
    );
  }
}

export { ViewRequest };
