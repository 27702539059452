import { StampedModel, ResourceFactory } from '../services/';

class District extends StampedModel {
  public name: string = '';
  public notes: string = '';
  public communities_count: number = 0;
}

const DistrictFactory = ResourceFactory.create<District>(District, 'district');

export { District, DistrictFactory };