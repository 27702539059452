import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

interface ILinkProps {
  className?: string;
  children?: React.ReactNode;
  to: string;
}

class LinkBase extends React.Component<ILinkProps, {}> {
  render() {
    let p = this.props;
    return (
      <span className={p.className}>
        <RouterLink  to={p.to}>{p.children}</RouterLink>
      </span>
    );
  }
}

const Link = styled(LinkBase)`
  a {
    position: relative;
    color: steelblue;
    text-decoration: none;
    outline: none;
    transition: color ${p => p.theme.transition.duration}s;
    &:hover {
      color: #165284;
    }
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      right: 50%;
      height: 1px;
      background-color: LightSteelBlue;
      opacity: 0.3;
      transition: left ${p => p.theme.transition.duration*2}s, 
                  right ${p => p.theme.transition.duration*2}s,
                  opacity ${p => p.theme.transition.duration*2}s;
    }
    &:hover:before {
      left: -8px;
      right: -8px;
      opacity: 1;
    }
  }
`

export { Link };
