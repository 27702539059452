import * as React from 'react';
import axios from 'axios';
import styled from '@independent-software/typeui/styles/Theme';
import { Attachment } from '../../resource';
import { IAuthProps } from '../../services';
import { MultiUpload } from '../MultiUpload';

import { Button } from '@independent-software/typeui/controls/Button';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Divider } from '@independent-software/typeui/controls/Divider';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Image } from '@independent-software/typeui/controls/Image';
import { Label } from '@independent-software/typeui/controls/Label';
import { Message } from '@independent-software/typeui/controls/Message';
import { Table } from '@independent-software/typeui/controls/Table';
import { Filesize } from '@independent-software/typeui/formatters/Filesize';
import { App } from '../../App';
import { ListAttachments } from '../../components/Attachment/ListAttachments';
import { Loc } from '../Loc';
import { Input } from '@independent-software/typeui/controls/Input';
import { Form } from '@independent-software/typeui/controls/Form';
import { ToastService } from '@independent-software/typeui/controls/Toast';

interface IProps {
  className?: string;
  /**
   * List of attachments shown in AttachmentManager.
   */
  attachments: Attachment[];
  /**
   * This callback is called whenever the attachment list changes.
   * The AttachmentManager will offer upload, rename and removal options
   * only if this callback is present.
   */
  onChange?: (attachments: Attachment[]) => void;
}

interface IState {
  attachOpen: boolean;    // Is attach dialog open?
  editOpen: boolean;      // Is edit dialog open?
  uploadOpen: boolean;    // Is the upload dialog open?
  previewOpen: boolean;   // Is the preview dialog open?
  attachment: Attachment; // Attachment currently previewed or edited
  filename: string;       // Filename being edited
}

/**
 * The AttachmentManager provides a GUI listing, uploading, previewing, downloading and removing of Attachments.
 * Uploaded and removed attachments only take effect when the parent control save the object it manages.
 */
class AttachmentManagerBase extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);
    this.state = {
      attachOpen: false,
      editOpen: false,
      uploadOpen: false,
      previewOpen: false,
      attachment: null,
      filename: ''
    }
  }

  handleDownload = (attachment: Attachment) => {
    axios.get(`${App.apiURL}attachment/download/${attachment.id}?api_token=${this.props.auth.token}`, { responseType: 'blob'}) 
    .then(response => {
      // Find the content-disposition header.
      let disposition:string = response.headers['content-disposition'];
      // Using a regexp, retrieve the filename from it.
      let regexp = new RegExp('\"(.*)\"');
      let res:RegExpExecArray = regexp.exec(disposition);
      let filename = res[1];
      // Download the file.
      saveAs(response.data, filename);
    })
    .catch(error => {
    });    
  }

  handleOpenPreview = (attachment: Attachment) => {
    this.setState({
      previewOpen: true,
      attachment: attachment
    })
  }

  handleClosePreview = () => {
    this.setState({ previewOpen: false });
  }

  handleOpenAttach = () => {
    this.setState({
      attachOpen: true
    });
  }

  handleCloseAttach = () => {
    this.setState({
      attachOpen: false
    });
  }

  handleAttach = (attachment: Attachment) => {
    // If attachment not yet present in attachment list,
    // then add it.
    if (!this.props.attachments.find((a) => a.id == attachment.id)) {
      this.props.onChange([ ...this.props.attachments, attachment ]);  
    }
    // Close attachment window.
    this.setState({
      attachOpen: false
    });
  }

  handleRemove = (attachment: Attachment) => {
    this.handleClosePreview();
    let attachments = this.props.attachments.filter(a => a != attachment);
    this.props.onChange(attachments);
  }

  handleOpenUpload = () => {
    this.setState({
      attachOpen: false,
      uploadOpen: true
    })
  }  

  handleCloseUpload = () => {
    this.setState({
      uploadOpen: false
    });
  }

  handleEdit = (attachment: Attachment) => {
    this.setState({
      editOpen: true,
      attachment: attachment,
      filename: attachment.filename
    })
  }

  handleCloseEdit = () => {
    this.setState({
      editOpen: false
    });
  }

  private handleSaveEdit = () => {
    // Update Attachment object.
    axios.put(`${App.apiURL}attachment/${this.state.attachment.id}?api_token=${this.props.auth.token}&filename=${this.state.filename}`) 
      .then(res => {
        ToastService.toast(<Loc msg="attachments_msg_updated"/>);
        // Update the Attachment's filename:
        this.state.attachment.filename = this.state.filename;
        // Close the edit dialog:
        this.setState({ editOpen: false });
        // Let the parent component know that the attachments list 
        // has changed so that it will update:
        this.props.onChange(this.props.attachments);
      })
      .catch(error => {
        ToastService.toast(<Loc msg="attachments_msg_update_error"/>);
      });
  }


  private isFilenameValid = () => {
    if (!/^[a-zA-Z0-9À-ž\-_, ]+\.[a-zA-Z0-9]+$/.test(this.state.filename)) return false;
    return this.state.filename.length >= 3;
  }

  handleUpload = (attachment: Attachment) => {
    this.props.onChange([ ...this.props.attachments, attachment ]);
  }  

  render() {
    let p = this.props;

    return (
      <div className={p.className}>
        {p.attachments.length > 0 &&
        <Table striped>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>File</th>
              <th>Size</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {p.attachments.map((attachment: Attachment, index) => 
              <tr key={index}>
                <td>{attachment.is_image ? <Image rounded inline size="mini" src={`${App.apiURL}attachment/download/${attachment.id}?api_token=${p.auth.token}&size=t`} onClick={() => this.handleOpenPreview(attachment)}/> : null}</td>
                <td>{attachment.filename}</td>
                <td><Filesize value={attachment.size}/></td>
                <td>
                  <Button icon size="small" onClick={() => this.handleDownload(attachment)}><Icon title="Download" name="download"/></Button>
                  {p.onChange && <Button icon size="small" onClick={() => this.handleEdit(attachment)}><Icon title="Rename" name="edit"/></Button>}
                  {p.onChange && <Button icon negative size="small" onClick={() => this.handleRemove(attachment)}><Icon title="Remove" name="trash"/></Button>}
                </td>
              </tr>)}
          </tbody>
        </Table>}

        {p.attachments.length == 0 &&
        <Message type="info">
          <Loc msg="attachmentmanager_no_attachments"/>
        </Message>}

        {/*
          * Upload attachment
          */}
        {p.onChange && <React.Fragment>
          <Divider hidden/>
          <div style={{textAlign:'right'}}>
            <Button onClick={this.handleOpenAttach}><Icon name="plus"/> <Loc msg="attachmentmanager_add_attachment"/></Button>
          </div>
          <MultiUpload auth={p.auth} onSuccess={this.handleUpload} open={this.state.uploadOpen} title={<Loc msg="attachmentmanager_upload_attachments"/>} url={`${App.apiURL}attachment`} onClose={this.handleCloseUpload}/>
        </React.Fragment>}

        {/* 
          * Image preview dialog
          */}
        <Dialog width={800} open={this.state.previewOpen} onClose={this.handleClosePreview}>
          <Dialog.Header>
            {this.state.attachment && this.state.attachment.filename}
          </Dialog.Header>
          <Dialog.Content>
            {this.state.attachment && 
              <Image rounded src={`${App.apiURL}attachment/download/${this.state.attachment.id}?api_token=${p.auth.token}&size=p`}/>
            }
          </Dialog.Content>
          <Dialog.Footer>
            {this.state.attachment && 
              <div style={{float: 'left'}}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Button primary onClick={() => this.handleDownload(this.state.attachment)}><Icon name="download"/> <Loc msg="btn_download"/> <Label attached="right"><Filesize value={this.state.attachment.size}/></Label></Button>
                </div>
              </div>}
            <Button secondary onClick={this.handleClosePreview}><Loc msg="btn_close"/></Button>
          </Dialog.Footer>
        </Dialog>    

        {/* 
          * Add attachment dialog
          */}
        <Dialog width={800} open={this.state.attachOpen} onClose={this.handleCloseAttach}>
          <Dialog.Header><Loc msg="attachmentmanager_add_attachment"/></Dialog.Header>
          <div style={{position: 'relative', display: 'flex', height:'400px'}}>
            <ListAttachments name="attachment-selection" auth={p.auth} onClick={this.handleAttach}/>
          </div>
          <Dialog.Footer>
            <div style={{float:'left'}}>
              <Button onClick={this.handleCloseAttach}><Loc msg="btn_cancel"/></Button>
            </div>
            <Button secondary onClick={this.handleOpenUpload}><Icon name="plus"/> <Loc msg="btn_upload"/></Button>
          </Dialog.Footer>
        </Dialog>

        {/*
          * Rename file dialog
          */}
        <Dialog width={800} open={this.state.editOpen} onClose={this.handleCloseEdit}>
          <Dialog.Header><Loc msg="attachmentmanager_edit_attachment"/></Dialog.Header>
          <Dialog.Content>
            <Form.Uncontrolled 
              hint={this.isFilenameValid() ? <Loc msg="attachmentmanager_edit_attachment_hint" /> : <Loc msg="attachmentmanager_edit_attachment_error"/>}>
              <Input fluid placeholder="Filename" value={this.state.filename} error={this.isFilenameValid() ? null : true} onChange={(value: string) => this.setState({ filename: value })}/>
            </Form.Uncontrolled>
          </Dialog.Content>
          <Dialog.Footer>
            <div style={{float:'left'}}>
              <Button onClick={this.handleCloseEdit}><Loc msg="btn_cancel"/></Button>
            </div>
            <Button secondary disabled={this.isFilenameValid() ? null : true} onClick={this.handleSaveEdit}><Loc msg="btn_ok"/></Button>            
          </Dialog.Footer>
        </Dialog>
      </div>
    );
  }
}

const AttachmentManager = styled(AttachmentManagerBase)`
  th:nth-child(1), td:nth-child(1) {
    width: 40px;
  }

  th:nth-child(2), td:nth-child(2) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th:nth-child(3), td:nth-child(3) {
    width: 100px;
    text-align: right;
  }

  th:nth-child(4), td:nth-child(4) {
    width: 120px;
    text-align: right;
  }
`

export { AttachmentManager };
