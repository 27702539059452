/* 
 * Types 
 */

type TQuestionType = 'number' | 'text' | 'longtext' | 'check' | 'list' | 'date' | 'time' | 'community';

interface IQuestion {
  title: string;   // "Distance to school?"
  type: TQuestionType;
  required?: boolean;
  list?: { [key: number]: string };
  placeholder?: string;
  hint?: string;
  specialValues?: { [key: string]: string } 
}

/*
 * GENERAL 
 */

const GEN_SURVEY_NUM: IQuestion = {
  title: "Número do questionário",
  hint: "Número sequencial a preencher pelo coordenador de campo",
  type: 'number',
  required: true
}

const GEN_COORDENATOR_CODE: IQuestion = {
  title: "Código do coordenador",
  type: 'text'
}

const GEN_INTERVIEWER_CODE: IQuestion = {
  title: "Código do inquiridor",
  type: 'text',
  required: true
}

const GEN_WEIGHT: IQuestion = {
  title: "Factor de ponderação",
  type: 'number',
  required: true
}

const GEN_DATE: IQuestion = {
  title: "Data do questionário",
  type: 'date',
  required: true
}

const GEN_TIME_START: IQuestion = {
  title: "Hora do início",
  type: 'time',
}

const GEN_TIME_END: IQuestion = {
  title: "Hora do fim",
  type: 'time',
}

const GEN_CAF_NAME: IQuestion = {
  title: "Nome do CAF", type: 'text',
  required: true
}

const GEN_CAF_CONTACT: IQuestion = {
  title: "Contacto do agredado familiar", type: 'text',
  specialValues: {
    "999": "Não sabe"
  }
}

const GEN_CAF_AGE: IQuestion = {
  title: "Idade do CAF",
  type: 'number',
  hint: "999 se não sabe",
  required: true
}

const GEN_CAF_GENDER: IQuestion = {
  title: "Sexo do CAF",
  type: 'list',
  list: { 1: 'Masculino', 2: 'Femenino' },
  required: true
}

const AFMONOPARENTAL: IQuestion = {
  title: "Monoparental",
  type: 'check',
  hint: "O agregado familiar é monoparental?"
}

const AFPOLIGAMO: IQuestion = {
  title: "Polígamo",
  type: 'check',
  hint: "O agregado familiar é polígamo?"
}

const GEN_NAME: IQuestion = {
  title: "Nome do entrevistado",
  type: 'text'
}

const GEN_CONTACT: IQuestion = {
  title: "Contacto do entrevistado",
  type: 'text',
  specialValues: {
    "999": "Não sabe"
  }  
}

const GEN_RELATION: IQuestion = {
  title: "Grau de parentesco",
  hint: "Qual é o grau de parentesco do entrevistado com o CAF?",
  type: "list",
  list: { 
    1: 'CAF',
    2: 'Cônjuge do CAF',
    3: 'Filho/a do CAF',
    4: 'Genro/nora do CAF',
    5: 'Pai/mãe do CAF',
    6: 'Sogro/a do CAF',
    7: 'Irmão/irmã do CAF',
    8: 'Avô/avó do CAF',
    9: 'Neto/a do CAF',
    10: 'Filho adoptivo/enteado do CAF',
    11: 'Sobrinho/a do CAF',
    12: 'Cunhado/a do CAF',
    13: 'Outro parente do CAF',
    14: 'Não familiar do CAF'
  }
}

const GEN_CONTACT_CONSENT: IQuestion = {
  title: "Consentimento de contacto",
  hint: "O inquirido está disponível para ser contactado em caso de necessidade de confirmar alguns dados?",
  type: 'check'
}

const GEN_COORD_LAT: IQuestion = {
  title: "Latitude",
  hint: "Coordenadas da casa do Agregado Familiar",
  type: "number"
}

const GEN_COORD_LON: IQuestion = {
  title: "Longitude",
  hint: "Coordenadas da casa do Agregado Familiar",
  type: "number"
}

const community_id: IQuestion = {
  title: "Comunidade",
  type: 'community',
  required: true
}

const GEN_RESIDENCE: IQuestion = {
  title: "Residência actual",
  type: 'list',
  list: { 1: "Comunidade origem", 2: "Comunidade destino" },
  required: true
}

const GEN_BAIRRO: IQuestion = {
  title: "Bairro",
  hint: "Registo 999 se não se aplica",
  type: "text",
  required: true
}

const GEN_COMMENTS: IQuestion = {
  title: "Comentários",
  type: "longtext"
}

/*
 * B. EDUCAÇÃO
 */

const A1: IQuestion = {
  title: "Número de membros",
  type: 'number',
  hint: "Número total de membros do agregado familiar"
}

const A2: IQuestion = {
  title: "Nome", type: 'text',
  hint: "Nome do membro do agregado familiar"
}

const A3: IQuestion = {
  title: "Idade", type: 'number',
  hint: "Idade em anos (999 se não sabe)",
  specialValues: {
    "999": "Não sabe"
  }    
}

const A5: IQuestion = {
  title: "Sexo", type: 'list',
  list: { 1: 'Masculino', 2: 'Feminino' },
  hint: "Sexo do membro"
}

const B2: IQuestion = {
  title: "Nível matriculado", type: 'list',
  list: { 1: 'Escolinha/pré-primária', 2: 'Primária (1º-7º)', 3: 'Secundária (8º-10º)',
          4: 'Pré-universitária (ESG2)', 5: 'Formação profissional básica',
          6: 'Formação profissional médio', 7: 'Universidade', 0: "Não está matriculado na escola", 
          96: "s/i", 97: "n/a", 99: "Não sabe" },
  hint: "Em que nível está matriculado?"
}

const B3: IQuestion = {
  title: "Distância para escola", type: 'list',
  list: { 1: 'Menos de 5 minutos', 2: 'Entre 5 minutos e meia hora', 3: 'Mais de meia hora', 
          96: "s/i", 97: "n/a", 99: "Não sabe"  },
  hint: "A que distância fica a escola onde a pessoa está matriculada?"
}

const B4: IQuestion = {
  title: "Transporta para escola", type: 'list',
  list: { 1: 'A pé', 2: 'De bicicleta', 3: 'Em veículos motorizados pessoais', 
          4: 'Boleia em veículo motorizado privado', 5: 'Transporte pago em veículo motorizado privado', 
          6: 'Transporte público (machimbombo/chapa)', 96: "s/i", 97: "n/a", 98: 'Outro' },
  hint: "Como o membro do agregado familiar normalmente vai à escola?"        
}
const B4A: IQuestion = {
  title: "Transporte para escola (outro)", type: 'text', hint: "Especifique"
}

const B5: IQuestion = {
  title: "Razão não matriculado", type: 'list',
  list: { 1: 'Já não tem idade', 2: 'É demasiado novo', 3: 'Não tem condições financeiras',
          4: 'Escola é demasiado distante', 5: 'Casou-se', 6: 'Trabalha', 
          7: 'Não quer estudar', 8: 'Concluí nível máximo que pretendia', 96: "s/i", 97: "n/a", 98: 'Outro' },
  hint: "Porquê o membro do agregado não está matriculado?"
}
const B5A: IQuestion = {
  title: "Razão não matriculado", type: "text", hint: "Especifique"
}
const B6: IQuestion = {
  title: "Acesso a serviços de educação",
  type: 'list',
  hint: "Comparando com a situação antes do reassentamento, como avalia o acesso a serviços de educação?",
  list: {
    1: "Melhorou",
    2: "Piorou",
    3: "Igual",
    96: "s/i",
    97: "n/a",
    99: "Não sabe"
  }
}

/*
 * C. SAÚDE
 */

const Diseases = { 
  1: 'Malaria/febre', 
  2: 'Diarreia', 
  3: 'Tosse', 
  4: 'Acidente', 
  5: 'Dores de dentes', 
  6: 'Doença/irritação da pele', 
  7: 'Doença de ouvidos, nariz, garganta', 
  96: "s/i", 97: "n/a",
  98: 'Outra' 
};

const Medidas = { 
  1: 'Muita comida e água', 
  2: 'Médico tradicional', 
  3: 'Remédio caseiro',
  4: 'Unidade sanitária', 
  5: 'Farmácia', 
  6: 'Igreja/mesquita/reza', 
  7: 'Nada', 
  8: 'Agente polivalente',
  96: "s/i", 97: "n/a",
  98: 'Outro' } // Especificar

const RazaoNaoUS = { 
  1: 'Não existe US na zona', 
  2: 'US está longe', 
  3: 'Não há pessoal médico',
  4: 'É muito caro', 
  5: 'Não foi necessário tratamento',
  96: "s/i", 97: "n/a",
  98: 'Outro' } // Especificar

const C1_1: IQuestion = { title: "Malária", type: 'check' }
const C1_1A: IQuestion = { title: "Primeira medida", type: 'list', list: Medidas }
const C1_1AO: IQuestion = { title: "Especifique", type: 'text' }
const C1_1B: IQuestion = { title: "Razão não US", type: 'list', list: RazaoNaoUS }
const C1_1BO: IQuestion = { title: "Especifique", type: 'text' }

const C1_2: IQuestion = { title: "Diarreia", type: 'check' }
const C1_2A: IQuestion = { title: "Primeira medida", type: 'list', list: Medidas }
const C1_2AO: IQuestion = { title: "Especifique", type: 'text' }
const C1_2B: IQuestion = { title: "Razão não US", type: 'list', list: RazaoNaoUS }
const C1_2BO: IQuestion = { title: "Especifique", type: 'text' }

const C1_3: IQuestion = { title: "Tosse", type: 'check' }
const C1_3A: IQuestion = { title: "Primeira medida", type: 'list', list: Medidas }
const C1_3AO: IQuestion = { title: "Especifique", type: 'text' }
const C1_3B: IQuestion = { title: "Razão não US", type: 'list', list: RazaoNaoUS }
const C1_3BO: IQuestion = { title: "Especifique", type: 'text' }

const C1_4: IQuestion = { title: "Acidente", type: 'check' }
const C1_4A: IQuestion = { title: "Primeira medida", type: 'list', list: Medidas }
const C1_4AO: IQuestion = { title: "Especifique", type: 'text' }
const C1_4B: IQuestion = { title: "Razão não US", type: 'list', list: RazaoNaoUS }
const C1_4BO: IQuestion = { title: "Especifique", type: 'text' }

const C1_5: IQuestion = { title: "Dores de dentes", type: 'check' }
const C1_5A: IQuestion = { title: "Primeira medida", type: 'list', list: Medidas }
const C1_5AO: IQuestion = { title: "Especifique", type: 'text' }
const C1_5B: IQuestion = { title: "Razão não US", type: 'list', list: RazaoNaoUS }
const C1_5BO: IQuestion = { title: "Especifique", type: 'text' }

const C1_6: IQuestion = { title: "Doença/irritação da pele", type: 'check' }
const C1_6A: IQuestion = { title: "Primeira medida", type: 'list', list: Medidas }
const C1_6AO: IQuestion = { title: "Especifique", type: 'text' }
const C1_6B: IQuestion = { title: "Razão não US", type: 'list', list: RazaoNaoUS }
const C1_6BO: IQuestion = { title: "Especifique", type: 'text' }

const C1_7: IQuestion = { title: "Doença de ouvidos, nariz, garganta", type: 'check' }
const C1_7A: IQuestion = { title: "Primeira medida", type: 'list', list: Medidas }
const C1_7AO: IQuestion = { title: "Especifique", type: 'text' }
const C1_7B: IQuestion = { title: "Razão não US", type: 'list', list: RazaoNaoUS }
const C1_7BO: IQuestion = { title: "Especifique", type: 'text' }

const C1_8: IQuestion = { title: "Outra", type: 'check' }
const C1_8O: IQuestion = { title: "Especifique doença", type: 'text' }
const C1_8A: IQuestion = { title: "Primeira medida", type: 'list', list: Medidas }
const C1_8AO: IQuestion = { title: "Especifique", type: 'text' }
const C1_8B: IQuestion = { title: "Razão não US", type: 'list', list: RazaoNaoUS }
const C1_8BO: IQuestion = { title: "Especifique", type: 'text' }

const C1_9: IQuestion = { title: "Outra", type: 'check' }
const C1_9O: IQuestion = { title: "Especifique doença", type: 'text' }
const C1_9A: IQuestion = { title: "Primeira medida", type: 'list', list: Medidas }
const C1_9AO: IQuestion = { title: "Especifique", type: 'text' }
const C1_9B: IQuestion = { title: "Razão não US", type: 'list', list: RazaoNaoUS }
const C1_9BO: IQuestion = { title: "Especifique", type: 'text' }

const C4A: IQuestion = { title: 'Tosse persistente', type: 'check' }
const C4B: IQuestion = { title: 'Irritações/feridas na pele', type: 'check' }
const C4C: IQuestion = { title: 'Problemas sanguíneos (Anemia) ', type: 'check' }
const C4D: IQuestion = { title: 'Problemas de ossos', type: 'check' }
const C4E: IQuestion = { title: 'Ataques/Desmaios/Epilepsia', type: 'check' }
const C4F: IQuestion = { title: 'Doenças psiquiátricas', type: 'check' }
const C4G: IQuestion = { title: 'Invalidez', type: 'check' }
const C4H: IQuestion = { title: 'Outra doença crónica', type: 'check' }
const C4H1: IQuestion = { title: 'Outra doença crónica - especifique', type: 'text' }
const C4I: IQuestion = { title: 'Outra doença crónica', type: 'check' }
const C4I1: IQuestion = { title: 'Outra doença crónica - especifique', type: 'text' }

const C8: IQuestion = {
  title: "Acesso a serviços de saúde",
  type: 'list',
  hint: "Comparando com a situação antes do reassentamento, como avalia o acesso a serviços de saúde?",
  list: {
    1: "Melhorou",
    2: "Piorou",
    3: "Igual",
    97: "Não se aplica",
    99: "Não sabe"
  }
}

/*
 * D. RENDIMENTO
 */

const D1A:  IQuestion = { title: 'Emprego formal (sector público)', type: 'check' }
const D1B:  IQuestion = { title: 'Emprego formal (sector privado)', type: 'check' }
const D1C:  IQuestion = { title: 'Trabalhador fora da região ou fora do país', type: 'check' }
const D1D:  IQuestion = { title: 'Agricultura', type: 'check' }
const D1E:  IQuestion = { title: 'Pesca', type: 'check' }
const D1F:  IQuestion = { title: 'Comércio e serviços', type: 'check' }
const D1G:  IQuestion = { title: 'Pequenos serviços de construção', type: 'check' }
const D1H:  IQuestion = { title: 'Pastorícia/Pecuária', type: 'check' }
const D1I:  IQuestion = { title: 'Não quis responder', type: 'check' }
const D1J:  IQuestion = { title: 'Outra fonte de rendimento', type: 'check' }
const D1JO: IQuestion = { title: 'Outra fonte de rendimento (especifique)', type: 'text', hint: "Especifique" }

const D2A:  IQuestion = { title: 'Emprego formal (sector público) (mt)', type: 'number' }
const D2B:  IQuestion = { title: 'Emprego formal (sector privado) (mt)', type: 'number' }
const D2C:  IQuestion = { title: 'Pesca (mt)', type: 'number' }
const D2D:  IQuestion = { title: 'Venda de gado (mt)', type: 'number' }
const D2E:  IQuestion = { title: 'Venda de galinhas (mt)', type: 'number' }
const D2F:  IQuestion = { title: 'Produção agrícola (mt)', type: 'number' }
const D2G:  IQuestion = { title: 'Negócio informal/barraca/quiosque (mt)', type: 'number' }
const D2H:  IQuestion = { title: 'Artesanato (mt)', type: 'number' }
const D2I:  IQuestion = { title: 'Remessas (mt)', type: 'number' }
const D2J:  IQuestion = { title: 'Pensões (mt)', type: 'number' }
const D2_OUTRO: IQuestion = { title: 'Tem mais uma fonte de rendimento?', type: 'check' }
const D2K:  IQuestion = { title: 'Qual é o rendimento mensal desta fonte?', type: 'number' }
const D2KO: IQuestion = { title: 'Qual outra fonte?', type: 'text', hint: "Especifique (nome do rendimento)" }

const D4A:  IQuestion = { title: 'Escola/educação (mt)', type: 'number' }
const D4B:  IQuestion = { title: 'Centro de Saúde/Hospital/Farmácia (mt)', type: 'number' }
const D4C:  IQuestion = { title: 'Comida (mt)', type: 'number' }
const D4D:  IQuestion = { title: 'Transporte (mt)', type: 'number' }
const D4E:  IQuestion = { title: 'Roupa (mt)', type: 'number' }
const D4F:  IQuestion = { title: 'Calçado (mt)', type: 'number' }
const D4_OUTRO: IQuestion = { title: 'Tem alguma outra categoria de gastos?', type: 'check' }
const D4G:  IQuestion = { title: 'Quanto gasta mensalmente nesta categoria?', type: 'number' }
const D4GA: IQuestion = { title: 'Quais outros gastos', type: 'text', hint: "Especifique (nome do gasto)" }

const D6: IQuestion = {
  title: "Conta bancária",
  type: 'list',
  hint: "Algum membro do AF tem uma conta bancária?",
  list: {
    1: "Sim",
    2: "Não",
    96: "s/i", 97: "n/a",
    99: "Não sabe"
  }
}

const InvestmentList = {
  1:	"Comida",
  2:	"Roupa e calçado",
  3:	"Gado (bovino e caprino)",
  4:	"Casa (construção/infra-estruturas, electrodomésticos, mobiliário/utensílios/têxteis)",
  5:	"Agricultura (terra/machamba, maquinaria/carroça/utensílios, insumos/sementes/mudas, artigos de pesca)",
  6:	"Saúde, bem-estar e higiene pessoal",
  7:	"Educação",
  8:	"Transporte",
  9:	"Investimento para começar negócio",
  10: "Não fez nenhum investimento",
  0:	"Não teve compensação",
  95: "Não quis responder",
  96: "s/i",
  97:	"Não se aplica",
  98:	"Outro (especifique)",
  99: "Não sabe"
}

const D8A: IQuestion = { 
  title: 'Investimento (1o lugar)', type: 'list', list: InvestmentList,
  hint: "Investiu em 1º lugar"
}
const D8AO: IQuestion = { title: 'Outro', type: 'text', hint: "Especifique" }
const D8B: IQuestion = { 
  title: 'Investimento (2o lugar)', type: 'list', list: InvestmentList,
  hint: "Investiu em 2º lugar"
}
const D8BO: IQuestion = { title: 'Outro', type: 'text', hint: "Especifique" }
const D8C: IQuestion = { 
  title: 'Investimento (3o lugar)', type: 'list', list: InvestmentList,
  hint: "Investiu em 3º lugar"
}
const D8CO: IQuestion = { title: 'Outro', type: 'text', hint: "Especifique" }

/*
 * E.	REDES SOCIAIS
 */

const RecorrerList = { 
  1: 'Família nuclear', 
  2: 'Família alargada', 
  3: 'Amigos', 
  4: 'Vizinhos', 
  5: 'Líder', 
  6: 'Governo', 
  7: 'Comerciantes locais', 
  0: 'Não tem ninguém', 
  10: 'Não recorre a ninguém',
  98: 'Outra'
};

const E1A: IQuestion = { 
  title: 'Tipo de relação (1o lugar)', type: 'list', list: RecorrerList,
  hint: "Em caso de dificuldade (por exemplo, problema de saúde, falta de alimento, falta de dinheiro, etc) a quem recorre em primeiro lugar?"
}
const E1AO: IQuestion = { title: "Recorrer (1o lugar) - outra", type: 'text', hint: "Especifique" }
const E1B: IQuestion = { 
  title: 'Tipo de relação (2o lugar)', type: 'list', list: RecorrerList,
  hint: "Em caso de dificuldade (por exemplo, problema de saúde, falta de alimento, falta de dinheiro, etc) a quem recorre em segundo lugar?"
}
const E1BO: IQuestion = { title: "Recorrer (2o lugar) - outra", type: 'text', hint: "Especifique" }
const E1C: IQuestion = { 
  title: 'Tipo de relação (3o lugar)', type: 'list', list: RecorrerList,
  hint: "Em caso de dificuldade (por exemplo, problema de saúde, falta de alimento, falta de dinheiro, etc) a quem recorre em terceiro lugar?" 
}
const E1CO: IQuestion = { title: "Recorrer (3o lugar) - outra", type: 'text', hint: "Especifique" }

/*
 * F. BENS
 */

const F1A: IQuestion = { title: 'Rádio', type: 'number' }
const F1B: IQuestion = { title: 'Aparelhagem de som', type: 'number' }
const F1C: IQuestion = { title: 'Telefone/telemóvel', type: 'number' }
const F1D: IQuestion = { title: 'Geleira/congelador', type: 'number' }
const F1E: IQuestion = { title: 'Tractor', type: 'number' }
const F1F: IQuestion = { title: 'Bicicleta', type: 'number' }
const F1G: IQuestion = { title: 'Motorizada', type: 'number' }
const F1H: IQuestion = { title: 'Veículo motorizado', type: 'number' }
const F1I: IQuestion = { title: 'Bomba de água', type: 'number' }

/*
 * G. PROPRIEDADE
*/

const G1_3: IQuestion = {
  title: "Satisfação infra-estruturas", type: 'list', 
  hint: "Actualmente, qual é o seu nível de satisfação relativamente às infra-estruturas da sua propriedade (Casas e outras infra-estruturas construídas durante o reassentamento, SE FOR O CASO) e às condições da sua habitação?",
  list: { 1: 'Muito satisfeito', 2: 'Satisfeito', 3: 'Nem satisfeito, nem insatisfeito', 4: 'Insatisfeito', 5: 'Muito insatisfeito', 96: "s/i", 97: "n/a" }
}

const InsatisfactionList = {
  1: "Fendas/fissuras nas paredes",
  2: "Fendas nos aros das portas/janelas",
  3: "Escoamento de água nas latrinas",
  4: "Fendas no pavimento",
  5: "Fendas/fissuras no passeio/varanda",
  6: "Questões ligadas à iluminação",
  7: "Questões ligadas à ventilação",
  8: "Má qualidade do material usado",
  9: "Orientação da casa",
  98: "Outra (especifique)"
}

const G1_3A: IQuestion = { title: "Razão de insatisfação (1)", type: 'list', list: InsatisfactionList }
const G1_3AO: IQuestion = { title: "Outra - specifique", type: "text" }
const G1_3B: IQuestion = { title: "Razão de insatisfação (2)", type: 'list', list: InsatisfactionList }
const G1_3BO: IQuestion = { title: "Outra - specifique", type: "text" }
const G1_3C: IQuestion = { title: "Razão de insatisfação (3)", type: 'list', list: InsatisfactionList }
const G1_3CO: IQuestion = { title: "Outra - specifique", type: "text" }

const G1_2: IQuestion = {
  title: "Comparação infra-estruturas",
  hint: "E se comparar com a situação antes do reassentamento, como avalia as infra-estruturas da sua propriedade e condições da sua habitação?",
  type: "list",
  list: {
    1: "Melhoraram",
    2: "Pioraram",
    3: "Igual",
    96: "s/i", 97: "n/a",
    99: "Não sabe"
  }
}

const G1_4_A: IQuestion = {
  title: "Tipo de compensação segundas esposas",
  hint: "Indique qual foi o tipo de compensação que acordou com o PNL relativamente à habitação das segundas esposas.",
  type: "list",
  list: {
    1: "Casa para cada segunda esposa", 
    2: "Compensação monetária para construção + atribuição de espaço residencial",
    3: "Compensação monetária para construção",
    4: "Não houve acordo formal com PNL",
    96: "s/i", 97: "n/a",
    98: "Outro (especificar)"
  }
}

const G1_4_AO: IQuestion = {
  title: "Outra compensação",
  hint: "Especifique",
  type: "text"
}

const G1_4_B: IQuestion = {
  title: "Situação compensação segundas esposas",
  hint: "Qual destas opções era a situação à data da transferência física para o novo local (mudança no âmbito do reassentamento)?",
  type: "list",
  list: {
    1: "Todas as segundas esposas compensadas",
    2: "Algumas das segundas esposas compensadas",
    3: "Nenhuma das segundas esposas compensadas",
    4: "Não houve acordo formal com PNL",
    96: "s/i", 97: "n/a",
    98: "Outro (especificar)"
  }
}

const G1_4_BO: IQuestion = {
  title: "Outra situação",
  hint: "Especifique",
  type: "text"
}

const G1_4_C1: IQuestion = { title: "Atrasos na construção", type: "check" }
const G1_4_C2: IQuestion = { title: "Falta de espaços residenciais na aldeia de reassentamento", type: "check" }
const G1_4_C3: IQuestion = { title: "Falta de espaços residenciais próximos ao marido", type: "check" }
const G1_4_C4: IQuestion = { title: "Indisponibilidade de fundos do PNL", type: "check" }
const G1_4_C5: IQuestion = { title: "Ainda estão em negociações com o PNL sobre o tipo de compensação que será dada", type: "check" }
const G1_4_C98: IQuestion = { title: "Outra (especifique)", type: "check" }
const G1_4_C999: IQuestion = { title: "Não sabe", type: "check" }

const G1_4_CO: IQuestion = {
  title: "Outra razão",
  hint: "Especifique",
  type: "text"
}

const G1_1_A: IQuestion = {
  title: "DUAT", 
  type: 'list',
  hint: "Possui algum documento que prova que o DUAT da área onde está a vossa casa é sua/da vossa família?",
  list: { 
    1: "Sim, termo de entrega de PNL",
    2: "Sim, DUAT do Governo", 
    3: "Sim, PNL + Governo", 
    4: "Não", 
    96: "s/i", 97: "n/a",
    99: "Não sabe" 
  }
}

const G1_1_B: IQuestion = {
  title: "Título de propriedade", 
  type: 'list',
  hint: "Têm algum documento que comprova a propriedade desta casa (infra-estrutura)?",
  list: { 
    1: "Sim, termo de entrega de PNL",
    2: "Sim, título do Governo", 
    3: "Sim, PNL + Governo", 
    4: "Não", 
    96: "s/i", 97: "n/a",
    99: "Não sabe" 
  }  
}

const G2: IQuestion = {
  title: 'Tipo fonte de água', type: 'list',
  hint: "Qual a principal fonte de água que usa para beber?",
  list: { 1: 'Reservatório de água da chuva no quintal', 2: 'Rio', 
          3: 'Poço público', 4: 'Fontanário/furo público', 
          5: 'Lago/lagoa/barragem', 6: 'Ligação à rede de abastecimento',
          7: 'Furo gerido por entidade com fins lucrativos',
          96: "s/i", 97: "n/a", 99: "Não sabe",
          98: 'Outro' }
}
const G2A: IQuestion = { title: 'Outro tipo de fonte de água', type: 'text', hint: "Especifique" }

const G3: IQuestion = {
  title: "Distância até fonte de água", type: 'list',
  hint: "Qual é a distância que percorre de casa até a fonte de água que mais usa?",
  list: { 1: "Menos de 15 min", 2: "Entre 15 min e 30 min", 3: "Entre 30 min e 1 hora", 4: "Mais de 1 hora", 96: 's/i', 97: 'Fonte de água dentro da casa' }
}

const G2_1: IQuestion = {
  title: "Suficiência da água",
  hint: "A água que conseguem colectar diariamente é suficiente para beber, cozinhar, lavar a roupa e higiene pessoal?",
  type: "list",
  list: { 
    1: "Sim",
    2: "Não",
    96: "s/i", 97: "n/a"
  }
}

const G2_2N: IQuestion = { title: "Nenhum", type: 'check' }
const G2_2A: IQuestion = { title: "Cor turva (com aspecto sujo)", type: 'check' }
const G2_2B: IQuestion = { title: "Mau cheiro", type: 'check' }
const G2_2C: IQuestion = { title: "Mau sabor", type: 'check' }
const G2_2D: IQuestion = { title: "Longo tempo de espera na fonte de água", type: 'check' }
const G2_2E: IQuestion = { title: "Escassez de água", type: 'check' }
const G2_2F: IQuestion = { title: "Fonte de água danificada", type: 'check' }
const G2_2G: IQuestion = { title: "Fonte de água muito distante", type: 'check' }
const G2_2H: IQuestion = { title: "Outro (especificar)", type: 'check' }
const G2_2HO: IQuestion = { title: "Outro problema", hint: "Especifique", type: "text" }

const G3_1: IQuestion = {
  title: "Comparação acesso à água",
  hint: "Comparando com a situação antes do reassentamento, como avalia o seu nível de satisfação relativamente ao seu acesso à fonte de água para consumo doméstico e humano?",
  type: "list",
  list: {
    1: "Melhorou",
    2: "Piorou",
    3: "Igual",
    96: "s/i", 97: "n/a",
    99: "Não sabe"
  }
}


const FontesEnergia = { 
  0: 'Não tem necessidade energética',
  1: 'Electricidade',
  2: 'Petróleo',
  3: 'Gás',
  4: 'Lenha',
  5: 'Velas',
  6: 'Lanterna',
  7: 'Bateria',
  8: 'Gerador',
  9: 'Solar',
  10: 'Carvão',
  11: 'Pilhas',
  96: "s/i", 97: "n/a",
  98: 'Outra',
  99: 'Não sabe'
  // specify
}

const G4A: IQuestion = { title: 'Cozinhar', type: 'list', list: FontesEnergia, hint: "Fonte de energia usada para cozinhar" }
const G4AO: IQuestion = { title: 'Cozinhar (outra fonte)', type: 'text', hint: "Especifique" }
const G4B: IQuestion = { title: 'Iluminação', type: 'list', list: FontesEnergia, hint: "Fonte de energia usada para iluminação" }
const G4BO: IQuestion = { title: 'Iluminação (outra fonte)', type: 'text', hint: "Especifique" }
const G4C: IQuestion = { title: 'Aparelhos elétricos', type: 'list', list: FontesEnergia, hint: "Fonte de energia usada para aparelhos eléctricos" }
const G4CO: IQuestion = { title: 'Aparelhos elétricos (outra fonte)', type: 'text', hint: "Especifique" }

const G4_1: IQuestion = {
  title: "Fornos melhorados",
  hint: "Usa fornos melhorados/eficientes para cozinhar?",
  type: "list",
  list: { 
    1: "Sim",
    2: "Não",
    96: "s/i", 
    97: "n/a",
    99: "Não sabe"
  }
}


const G5: IQuestion = {
  title: "Instalação sanitária", type: 'list', 
  hint: "Qual é o principal tipo de instalação sanitária utilizado pelo agregado familiar?",
  list: { 1: 'Sanita dentro de casa', 2: 'Sanita fora de casa',
          3: 'Latrina simples no quintal', 4: 'Latrina com casa de banho no quintal',
          5: 'Latrina/sanita do vizinho', 6: 'Campo aberto/mato', 96: "s/i", 97: "n/a", 98: 'Outra', 99: 'Não sabe' } }
const G5A: IQuestion = { title: 'Outra instalação sanitária', type: 'text', hint: "Especifique" }

const G6: IQuestion = {
  title: "Lixo",
  hint: "Que tratamento dá ao lixo produzido pelo seu agregado familiar?",
  type: "list",
  list: {
    1: "Enterram no quintal",
    2: "Queimam no quintal",
    3: "Deitam numa lixeira pública",
    4: "Deitam num contentor",
    5: "Deitam na via pública/mato",
    6: "Queimam na via pública/mato",
    96: "s/i", 97: "n/a",
    98: "Outro (especifique)",
    99: "Não sabe"
  }
}
const G6A: IQuestion = { title: 'Outra tratamento de lixo', type: 'text', hint: "Especifique" }

const H1: IQuestion = {
  title: "Número de parcelas", type: 'number',
}
const H2: IQuestion = {
  title: "Tamanho aproximado (ha)", type: 'number',
  hint: "Em campo(s) de futebol/ha"
}
const H3: IQuestion = {
  title: "Uso actual", type: 'list', 
  list: { 0: 'Não está em uso', 1: 'Machamba', 2: 'Baixa/horta', 3: 'Pousio/repouso', 4: 'Pasto', 96: "s/i", 97: "n/a", 98: 'Outro', 99: "Não sabe" } }
const H3O: IQuestion = { title: "Uso actual (outro)", type: 'text' }

const Culturas = { 1: 'Folha de batata-doce', 2: 'Batata-doce', 3: 'Milho', 4: 'Folha de abóbora', 5: 'Abóbora', 6: 'Couve', 
7: 'Repolho', 8: 'Feijão nhemba', 9: 'Amendoim', 10: 'Mapira', 11: 'Folha de mandioca', 
12: 'Mandioca', 13: 'Melancia', 14: 'Ervilhas', 15: 'Arroz', 16: 'Tomate', 
17: 'Cebola', 18: 'Melão', 19: 'Batata', 20: 'Mistura de hortícolas (verduras, hotaliças variadas)',
0: "Nenhuma",
96: "s/i", 97: 'Não está em uso/NA', 98: 'Outra', 99: "Não sabe" };

const Epocas = { 1: 'Abril a Setembro', 2: 'Outubro a Março', 3: 'Todo o ano', 97: 'Não está em uso/NA' };
const Metodos = { 1: 'Enxada', 2: 'Charrua', 3: 'Tractor', 96: "s/i", 97: 'Não está em uso/NA', 98: 'Outro', 99: "Não sabe" };

const H4A: IQuestion = { title: "Principal cultura 1", type: 'list', list: Culturas }
const H4AO: IQuestion = { title: "Especifique", type: 'text' }
const H5A: IQuestion = { title: "Época de colheita", type: 'list', list: Epocas }
const H6A: IQuestion = { title: "Método de lavoura", type: 'list', list: Metodos }
const H6AO: IQuestion = { title: "Especifique", type: 'text' }

const H4B: IQuestion = { title: "Principal cultura 2", type: 'list', list: Culturas }
const H4BO: IQuestion = { title: "Especifique", type: 'text' }
const H5B: IQuestion = { title: "Época de colheita", type: 'list', list: Epocas }
const H6B: IQuestion = { title: "Método de lavoura", type: 'list', list: Metodos }
const H6BO: IQuestion = { title: "Especifique", type: 'text' }

const H4C: IQuestion = { title: "Principal cultura 3", type: 'list', list: Culturas }
const H4CO: IQuestion = { title: "Especifique", type: 'text' }
const H5C: IQuestion = { title: "Época de colheita", type: 'list', list: Epocas }
const H6C: IQuestion = { title: "Método de lavoura", type: 'list', list: Metodos }
const H6CO: IQuestion = { title: "Especifique", type: 'text' }

const H7: IQuestion = {
  title: "DUAT Parcela/machamba", type: 'list',
  list: { 1: 'Termo de entrega (PNL)', 2: 'DUAT (Governo)', 3: 'Termo de entrega (PNL) + DUAT (Governo)', 
          4: 'Terreno emprestado', 5: 'Terreno arrendado', 6: 'Terreno da associação irrigação', 
          7: 'Terreno familiar mas sem documento', 96: "s/i", 97: "n/a", 98: 'Outro', 99: 'Não sabe' } }
const H7O: IQuestion = { title: "DUAT Parcela/machamba (outro)", type: 'text' }

const H22: IQuestion = {
  title: "Sistema de irrigação", type: 'list',
  list: { 1: 'Sequeiro', 2: 'Rega manual', 3: 'Motobomba e mangueira', 4: 'Alagamento', 
          5: 'Canais impermeabilizados', 6: 'Potes de barro', 7: 'Gota-a-gota', 8: 'Aspersão',
         96: "s/i", 97: 'Não está em uso/NA', 98: 'Outro', 99: "Não sabe" } }
const H22O: IQuestion = { title: "Sistema de irrigação (outro)", type: 'text' }        

const H19: IQuestion = {
  title: "Insumos agrícolas", type: 'list',
  list: { 0: 'Não usa insumos agrícolas nesta parcela',
          1: 'Maioritariamente biológicos', 2: 'Igual proporção entre biológico e químico',
          3: 'Maioritariamente químico', 96: "s/i",  97: 'Não está em uso/NA', 99: 'Não sabe' } }

const H10A: IQuestion = { title: "Escassez de chuvas", type: 'check' }
const H10B: IQuestion = { title: "Chuva tardia", type: 'check' }
const H10C: IQuestion = { title: "Falta de semente", type: 'check' }
const H10D: IQuestion = { title: "Falta de mão de obra", type: 'check' }
const H10E: IQuestion = { title: "Falta de equipamento", type: 'check' }
const H10F: IQuestion = { title: "Falta de tractor", type: 'check' }
const H10G: IQuestion = { title: "Terreno não foi preparado", type: 'check' }
const H10H: IQuestion = { title: "Terra não é fértil", type: 'check' }
const H10I: IQuestion = { title: "Outra", type: 'check' }
const H10IO: IQuestion = { title: "Razão não cultivar (outra)", type: 'text' }

const H20: IQuestion = { 
  title: "Satisfação parcelas", type: 'list',
  list: { 1: 'Muito satisfeito', 2: 'Satisfeito', 3: 'Nem satisfeito, nem insatisfeito', 4: 'Insatisfeito', 5: 'Muito insatisfeito', 96: "s/i", 97: "n/a", 99: "Não sabe" },
  hint: "Actualmente, qual é o seu nível de satisfação relativamente às suas parcelas agrícolas/machambas (não considerar áreas de irrigação)?" 
}

const ParcelaInsatisfactionList = {
  1: "Fraca capacidade de retenção de humidade",
  2: "As parcelas são insuficientes/tenho menos terra do que na aldeia de origem",
  3: "As parcelas sao menos produtivas em comparação com as da aldeia de origem",
  4: "O solo é arenoso ou rochoso",
  5: "As parcelas agora ficam muito longe da aldeia, em comparação com a situação de origem",
  98: "Outro (especifique)"
}

const H20A_1: IQuestion = { title: "Motivo de insatisfação (1)", type: 'list', list: ParcelaInsatisfactionList }
const H20A_1O: IQuestion = { title: "Outro - specifique", type: "text" }
const H20A_2: IQuestion = { title: "Motivo de insatisfação (2)", type: 'list', list: ParcelaInsatisfactionList }
const H20A_2O: IQuestion = { title: "Outro - specifique", type: "text" }
const H20A_3: IQuestion = { title: "Motivo de insatisfação (3)", type: 'list', list: ParcelaInsatisfactionList }
const H20A_3O: IQuestion = { title: "Outro - specifique", type: "text" }

const H21: IQuestion = { 
  title: "Satisfação irrigação", type: 'list',
  list: { 1: 'Muito satisfeito', 2: 'Satisfeito', 3: 'Nem satisfeito, nem insatisfeito', 4: 'Insatisfeito', 5: 'Muito insatisfeito', 96: "s/i", 97: "n/a", 99: "Não sabe" },
  hint: "E relativamente às áreas de irrigação, sistema de irrigação e associação de irrigação, actualmente, qual é o seu nível de satisfação?" 
}

const IrrigationInsatisfactionList = {
  1: "Fraca capacidade de retenção de humidade",
  2: "As terras estão muito distantes do rio",
  3: "As terras estão muito distantes da aldeia",
  4: "O solo é arenoso ou rochoso",
  5: "O sistema de rega é defeituoso",
  6: "As motobombas são de baixa cilindrada",
  7: "A água não abrange toda a área de irrigação",
  98: "Outro (especifique)"
}

const H21A_1: IQuestion = { title: "Motivo de insatisfação (1)", type: 'list', list: IrrigationInsatisfactionList }
const H21A_1O: IQuestion = { title: "Outro - specifique", type: "text" }
const H21A_2: IQuestion = { title: "Motivo de insatisfação (2)", type: 'list', list: IrrigationInsatisfactionList }
const H21A_2O: IQuestion = { title: "Outro - specifique", type: "text" }
const H21A_3: IQuestion = { title: "Motivo de insatisfação (3)", type: 'list', list: IrrigationInsatisfactionList }
const H21A_3O: IQuestion = { title: "Outro - specifique", type: "text" }

const CulturasAlimentacao = { 
  0: 'Nenhuma', 1: 'Folha de batata doce', 2: 'Batata doce', 3: 'Milho', 4: 'Folha de abóbora', 
  5: 'Abóbora', 6: 'Couve', 7: 'Repolho', 8: 'Feijão nhemba', 9: 'Amendoim', 10: 'Mapira',
 11: 'Folha de mandioca', 12: 'Mandioca', 13: 'Melancia', 14: 'Ervilhas', 15: 'Arroz',
 16: 'Tomate', 17: 'Cebola', 18: 'Melão', 19: 'Batata', 20: 'Mistura de hortícolas (verduras, hortaliças variadas)', 
 98: 'Outra' }
const H11A: IQuestion = { title: "Cultura de alimentação (1o lugar)", type: 'list', list: CulturasAlimentacao }
const H11AO: IQuestion = { title: "Cultura de alimentação (1o lugar - outra)", type: 'text' }
const H11B: IQuestion = { title: "Cultura de alimentação (2o lugar)", type: 'list', list: CulturasAlimentacao }
const H11BO: IQuestion = { title: "Cultura de alimentação (2o lugar - outra)", type: 'text' }
const H11C: IQuestion = { title: "Cultura de alimentação (3o lugar)", type: 'list', list: CulturasAlimentacao }
const H11CO: IQuestion = { title: "Cultura de alimentação (3o lugar - outra)", type: 'text' }

const H12: IQuestion = { 
  title: "Número de refeições/dia", type: 'list',
  list: { 1: 'Uma por dia', 2: 'Duas por dia', 3: 'Três por dia', 4: 'Quatro ou mais por dia', 96: "s/i" },
  hint: "Regra geral, qual é o número de refeições que o seu agregado familiar tem por dia?" }

const H13: IQuestion = {
  title: "Produção garante subsistência", type: 'check',
  hint: "Regra geral, a produção proveniente da sua machamba garante a subsistência do seu agregado familiar durante o ano?"
}

const H13A: IQuestion = {
  title: "Tem reserva/celeiro", type: 'check'
}

const H13B: IQuestion = {
  title: "Cultura no celeiro",
  type: 'list',
  list: { 1: 'Milho', 2: 'Amendoim', 98: 'Outra (especifique)' },
  hint: "Qual é a principal cultura que constutui o celeiro?"
}

const H13BO: IQuestion = {
  title: "Outra cultra",
  type: 'text'
}

const H13C: IQuestion = {
  title: "Duração reserva/celeiro", type: 'number',
  hint: "Actualmente, a sua reserva/celeiro assegura quanto tempo (em meses) de alimentação no seu agregado familiar?"
}

const H14A: IQuestion = { title: "Cultura de rendimento (1o lugar)", type: 'list', list: CulturasAlimentacao }
const H14AO: IQuestion = { title: "Cultura de rendimento (1o lugar - outra)", type: 'text' }
const H14B: IQuestion = { title: "Cultura de rendimento (2o lugar)", type: 'list', list: CulturasAlimentacao }
const H14BO: IQuestion = { title: "Cultura de rendimento (2o lugar - outra)", type: 'text' }
const H14C: IQuestion = { title: "Cultura de rendimento (3o lugar)", type: 'list', list: CulturasAlimentacao }
const H14CO: IQuestion = { title: "Cultura de rendimento (3o lugar - outra)", type: 'text' }

const H16: IQuestion = {
  title: "Frequência de venda de culturas", type: 'list',
  list: { 1: 'Diariamente', 2: 'Semanalmente', 3: 'Mensalmente', 4: 'Nas épocas, sazonalmente',
          5: 'Ocasionalmente', 0: 'Nunca', 96: "s/i", 97: "n/a", 99: 'Não sabe' },
  hint: "Com que frequência vende culturas agrícolas?" }

const H17: IQuestion = {
  title: "Local de venda de culturas", type: 'list',
  list: { 1: 'Própria machamba', 2: 'Na habitação', 3: 'Local de negócios/venda na vila', 
          4: 'Local de negócios/venda noutra vila', 98: 'Outro', 96: "s/i", 97: 'n/a', 99: 'Não sabe '},
  hint: "Onde vende as culturas agrícolas?" }
const H17A: IQuestion = { title: "Local de venda - outra vila", type: 'text' }
const H17B: IQuestion = { title: "Local de venda - outro", type: 'text' }

const H18: IQuestion = {
  title: "Proveniência de sementes", type: 'list',
  list: { 1: 'Excedente da colheita', 2: 'Distribuição instituiçoes públicas',
          3: 'Distribuição instituições privadas', 4: 'Compra', 5: 'Distribuição pelo PNL', 96: "s/i", 97: 'n/a', 98: 'Outra' },
  hint: "As sementes/mudas (plântulas) que usa na sua machamba são maioritariamente provenientes de onde?" }
const H18O: IQuestion = { title: "Proveniência de sementes (outra)", type: 'text' }

const H18A: IQuestion = { 
  title: "Gastos em sementos", type: 'number', 
  hint: "Quanto gastou aproximadamente nos últimos 12 meses em sementes/mudas (plântulas) para a sua machamba? (Inserir 0 se não gaste nada)"}

const H23: IQuestion = {
  title: "Comparação machambas",
  hint: "Comparando com a situação antes do reassentamento, considera que as suas machambas (considerar todas as parcelas do AF, sejam de sequeiro, irrigação, ou outras) são melhores, iguais ou piores que a situação que tinha antes do reassentamento?",
  type: 'list',
  list: { 1: "Melhoraram", 2: "Pioraram", 3: "A situação mantém-se igual", 97: "Não se aplica", 99: "Não sabe" }
}

const TreeUses = { 
  1: 'Alimentação caseira', 2: 'Venda', 3: 'Alimentação e venda', 4: 'Sombra', 
  0: 'Nenhum', 96: "s/i", 97: "n/a", 98: 'Outro', 99: "Não sabe" };

const I1: IQuestion = { title: "Laranjeira", type: 'number' }
const I1P: IQuestion = { title: "Propósito da Laranjeira", type: 'list', list: TreeUses }
const I1PO: IQuestion = { title: "Outro propósito", type: 'text' }
const I2: IQuestion = { title: "Limoeiro", type: 'number' }
const I2P: IQuestion = { title: "Propósito do Limoeiro", type: 'list', list: TreeUses }
const I2PO: IQuestion = { title: "Outro propósito", type: 'text' }
const I3: IQuestion = { title: "Cajueiro", type: 'number' }
const I3P: IQuestion = { title: "Propósito do Cajueiro", type: 'list', list: TreeUses }
const I3PO: IQuestion = { title: "Outro propósito", type: 'text' }
const I4: IQuestion = { title: "Mangueira", type: 'number' }
const I4P: IQuestion = { title: "Propósito da Mangueira", type: 'list', list: TreeUses }
const I4PO: IQuestion = { title: "Outro propósito", type: 'text' }
const I5: IQuestion = { title: "Bananeira", type: 'number' }
const I5P: IQuestion = { title: "Propósito da Bananeira", type: 'list', list: TreeUses }
const I5PO: IQuestion = { title: "Outro propósito", type: 'text' }
const I6: IQuestion = { title: "Papaieira", type: 'number' }
const I6P: IQuestion = { title: "Propósito da Papaieira", type: 'list', list: TreeUses }
const I6PO: IQuestion = { title: "Outro propósito", type: 'text' }
const I7: IQuestion = { title: "Tangerineira", type: 'number' }
const I7P: IQuestion = { title: "Propósito da Tangerineira", type: 'list', list: TreeUses }
const I7PO: IQuestion = { title: "Outro propósito", type: 'text' }
const I8: IQuestion = { title: "Massala", type: 'number' }
const I8P: IQuestion = { title: "Propósito da Massala", type: 'list', list: TreeUses }
const I8PO: IQuestion = { title: "Outro propósito", type: 'text' }
const I9: IQuestion = { title: "Moringa", type: 'number' }
const I9P: IQuestion = { title: "Propósito da Moringa", type: 'list', list: TreeUses }
const I9PO: IQuestion = { title: "Outro propósito", type: 'text' }
const I10: IQuestion = { title: "Embondeiro", type: 'number' }
const I10P: IQuestion = { title: "Propósito do Embondeiro", type: 'list', list: TreeUses }
const I10PO: IQuestion = { title: "Outro propósito", type: 'text' }
const I11: IQuestion = { title: "Amendoeira", type: 'number' }
const I11P: IQuestion = { title: "Propósito da Amendoeira", type: 'list', list: TreeUses }
const I11PO: IQuestion = { title: "Outro propósito", type: 'text' }
const I12: IQuestion = { title: "Ateira", type: 'number' }
const I12P: IQuestion = { title: "Propósito da Ateira", type: 'list', list: TreeUses }
const I12PO: IQuestion = { title: "Outro propósito", type: 'text' }
const I13: IQuestion = { title: "Mafureira", type: 'number' }
const I13P: IQuestion = { title: "Propósito da Mafureira", type: 'list', list: TreeUses }
const I13PO: IQuestion = { title: "Outro propósito", type: 'text' }
const I14: IQuestion = { title: "Canhueiro", type: 'number' }
const I14P: IQuestion = { title: "Propósito do Canhueiro", type: 'list', list: TreeUses }
const I14PO: IQuestion = { title: "Outro propósito", type: 'text' }
const I15: IQuestion = { title: "Outra árvore", type: 'number' }
const I15O: IQuestion = { title: "Especifique", type: 'text' }
const I15P: IQuestion = { title: "Propósito da árvore", type: 'list', list: TreeUses }
const I15PO: IQuestion = { title: "Outro propósito", type: 'text' }
const I16: IQuestion = { title: "Outra árvore", type: 'number' }
const I16O: IQuestion = { title: "Especifique", type: 'text' }
const I16P: IQuestion = { title: "Propósito da árvore", type: 'list', list: TreeUses }
const I16PO: IQuestion = { title: "Outro propósito", type: 'text' }


const AnimalUses = { 1: 'Consumo de casa', 2: 'Venda', 3: 'Lavoura', 4: 'Consumo e venda',
                      5: 'Consumo, venda e lavoura', 6: 'Troca', 7: 'Transporte', 0: 'Nenhum', 96: 's/i', 97: 'n/a', 98: 'Outro', 99: "Não sabe" };

const J3A: IQuestion = { title: "Galinha", type: 'number'}
const J4A: IQuestion = { title: "Propósito da Galinha", type: 'list', list: AnimalUses }
const J4AO: IQuestion = { title: "Outro propósito", type: 'text' }
const J3B: IQuestion = { title: "Galinha-do-mato", type: 'number'}
const J4B: IQuestion = { title: "Propósito da Galinha-do-mato", type: 'list', list: AnimalUses }
const J4BO: IQuestion = { title: "Outro propósito", type: 'text' }
const J3C: IQuestion = { title: "Peru", type: 'number'}
const J4C: IQuestion = { title: "Propósito do Peru", type: 'list', list: AnimalUses }
const J4CO: IQuestion = { title: "Outro propósito", type: 'text' }
const J3D: IQuestion = { title: "Patos", type: 'number'}
const J4D: IQuestion = { title: "Propósito dos Patos", type: 'list', list: AnimalUses }
const J4DO: IQuestion = { title: "Outro propósito", type: 'text' }
const J3E: IQuestion = { title: "Pombos", type: 'number'}
const J4E: IQuestion = { title: "Propósito dos Pombos", type: 'list', list: AnimalUses }
const J4EO: IQuestion = { title: "Outro propósito", type: 'text' }
const J3F: IQuestion = { title: "Porcos", type: 'number'}
const J4F: IQuestion = { title: "Propósito dos Porcos", type: 'list', list: AnimalUses }
const J4FO: IQuestion = { title: "Outro propósito", type: 'text' }
const J3G: IQuestion = { title: "Cabritos", type: 'number'}
const J4G: IQuestion = { title: "Propósito dos Cabritos", type: 'list', list: AnimalUses }
const J4GO: IQuestion = { title: "Outro propósito", type: 'text' }
const J3H: IQuestion = { title: "Ovelhas", type: 'number'}
const J4H: IQuestion = { title: "Propósito das Ovelhas", type: 'list', list: AnimalUses }
const J4HO: IQuestion = { title: "Outro propósito", type: 'text' }
const J3I: IQuestion = { title: "Gado bovino", type: 'number'}
const J4I: IQuestion = { title: "Propósito do Gado bovino", type: 'list', list: AnimalUses }
const J4IO: IQuestion = { title: "Outro propósito", type: 'text' }
const J3J: IQuestion = { title: "Burros", type: 'number'}
const J4J: IQuestion = { title: "Propósito dos Burros", type: 'list', list: AnimalUses }
const J4JO: IQuestion = { title: "Outro propósito", type: 'text' }
const J3K: IQuestion = { title: "Outro animal", type: 'number'}
const J3KO: IQuestion = { title: "Especifique", type: 'text'}
const J4K: IQuestion = { title: "Propósito do animal", type: 'list', list: AnimalUses }
const J4KO: IQuestion = { title: "Outro propósito", type: 'text' }

const J7: IQuestion = { title: 'Assistência sanitária (vacinação)', type: 'list', list: { 1: 'Sim', 2: 'Não'} }

const J13: IQuestion = {
  title: "Satisfação condições de pasto", type: 'list', 
  hint: "Actualmente, qual é o seu nível de satisfação relativamente às condições de pasto e abeberamento para o seu gado?",
  list: { 1: 'Muito satisfeito', 2: 'Satisfeito', 3: 'Nem satisfeito, nem insatisfeito', 4: 'Insatisfeito', 5: 'Muito insatisfeito' }
}

const PastoInsatisfactionList = {
  1: "A terra de pastagem é insuficiente para a minha comunidade e para a comunidade hospedeira",
  2: "A comunidade hospedeira tem limitado o acesso à terra de pastagem",
  3: "A terra de pastagem para a minha comunidade está demasiado longe da aldeia",
  4: "A água para os animais/bebedouros estão demasiado longe da nossa terra de pastagem",
  5: "O abastecimento de água é insuficiente para animais",
  6: "Os bebedouros construídos pelo PNL não são adequados",
  7: "Os bebedouros são insuficientes",
  8: "Os bebedouros estão a ser usados para outros fins",
  98: "Outro (especifique)",
  99: "Não sabe"
}

const J13_A: IQuestion = { title: "Motivo de insatisfação (1)", type: 'list', list: PastoInsatisfactionList }
const J13_AO: IQuestion = { title: "Outro - specifique", type: "text" }
const J13_B: IQuestion = { title: "Motivo de insatisfação (2)", type: 'list', list: PastoInsatisfactionList }
const J13_BO: IQuestion = { title: "Outro - specifique", type: "text" }
const J13_C: IQuestion = { title: "Motivo de insatisfação (3)", type: 'list', list: PastoInsatisfactionList }
const J13_CO: IQuestion = { title: "Outro - specifique", type: "text" }

const J14: IQuestion = {
  title: "Comparação acesso a pasto",
  hint: "Na sua perspectiva, comparando com a situação antes do reassentamento, considera que actual acesso a pasto e abeberamento é melhor, igual ou pior que a situação que tinha antes do reassentamento?",
  type: 'list',
  list: { 1: "Melhorou", 2: "Piorou", 3: "A situação mantém-se igual", 97: "Não se aplica", 99: "Não sabe" }
}

const J9: IQuestion = { title: "Conflito homen-fauna bravia", type: 'check' }
const J9_1: IQuestion = { 
  title: "Frequência conflito homem-fauna bravia",
  hint: "Qual a frequência?",
  type: 'list',
  list: { 1: "Reduzido", 2: "Médio", 3: "Elevado" }
}

const J100: IQuestion = { title: "Nenhuns", type: 'check' }
const J10A: IQuestion = { title: "Machambas danificadas/destruidas", type: 'check' }
const J10B: IQuestion = { title: "Reserva/celeira danificado/destruído", type: 'check' }
const J10C: IQuestion = { title: "Infra-estuturas danificadas/destruídas", type: 'check' }
const J10D: IQuestion = { title: "Animais de criação feridos", type: 'check' }
const J10E: IQuestion = { title: "Animais de criação mortos", type: 'check' }
const J10F: IQuestion = { title: "Pessoas feridas", type: 'check' }
const J10G: IQuestion = { title: "Pessoas mortas", type: 'check' }
const J10H: IQuestion = { title: "Outro (especifique)", type: 'check' }
const J10HO: IQuestion = { title: "Outros danos", type: 'text' }

const WildAnimalList = { 1: 'Hippopótamo', 2: 'Leão', 3: 'Elefante', 4: 'Crocodilo', 5: 'Búfalo', 6: 'Hiena', 7: 'Macaco', 8: 'Porco espinho', 9: 'Gazela', 0: 'Nenhum', 97: 'Não se aplica', 98: 'Outro'};
const J11A: IQuestion = { title: 'Animal (1o lugar)', type: 'list', list: WildAnimalList }
const J11AO: IQuestion = { title: "Animal selvagem (1o lugar - outro)", type: 'text' }
const J11B: IQuestion = { title: 'Animal (2o lugar)', type: 'list', list: WildAnimalList }
const J11BO: IQuestion = { title: "Animal selvagem (2o lugar - outro)", type: 'text' }
const J11C: IQuestion = { title: 'Animal (3o lugar)', type: 'list', list: WildAnimalList }
const J11CO: IQuestion = { title: "Animal selvagem (3o lugar - outro)", type: 'text' }

const J12: IQuestion = {
  title: "Comparação conflitos homen-fauna bravia",
  hint: "Comparando com a situação antes do reassentamento, esses conflitos Homem - Fauna Bravia diminuíram ou aumentaram?",
  type: 'list',
  list: { 1: "Aumentaram", 2: "Diminuíram", 3: "Mantiveram-se igual", 96: "s/i", 97: "Não se aplica", 99: "Não sabe" }
}

//
// MOBILIDADE
//

const MobilityAccess = { 1: 'Sim', 2: 'Não', 9: 'Inacessível', 96: 'Sem informação '}
const MobilityTime   = { 1: 'Nenhum tempo', 2: 'Menos de 5 minutos', 3: '5-30 minutos', 4: '30min - 1 hora', 5: 'Mais de 1 hora', 96: "s/i", 97: 'NA' }
const MobilityMode   = { 1: 'A pé', 2: 'De bicicleta', 3: 'Em veículo motorizado particular', 4: 'Transporte pago em veículo motorizado particular', 5: 'Transporte rodoviário público (machimbombo ou chapa)', 96: "s/i", 97: 'NA', 98: 'Outro (especificar)' }

const L1_4: IQuestion = { title: 'Unidade sanitária/clínica/hospital', type: 'list', list: MobilityAccess }
const L2_4: IQuestion = { title: 'Tempo de viagem', type: 'list', list: MobilityTime }
const L3_4: IQuestion = { title: 'Modo de viagem', type: 'list', list: MobilityMode }
const L3_4O: IQuestion = { title: 'Outro modo', type: 'text' }

const L1_6: IQuestion = { title: 'Mercado (vender e comprar produtos)', type: 'list', list: MobilityAccess }
const L2_6: IQuestion = { title: 'Tempo de viagem', type: 'list', list: MobilityTime }
const L3_6: IQuestion = { title: 'Modo de viagem', type: 'list', list: MobilityMode }
const L3_6O: IQuestion = { title: 'Outro modo', type: 'text' }

const L1_7: IQuestion = { title: 'Moagem', type: 'list', list: MobilityAccess }
const L2_7: IQuestion = { title: 'Tempo de viagem', type: 'list', list: MobilityTime }
const L3_7: IQuestion = { title: 'Modo de viagem', type: 'list', list: MobilityMode }
const L3_7O: IQuestion = { title: 'Outro modo', type: 'text' }

const L1_8: IQuestion = { title: 'Paragem machimbombo/chapa', type: 'list', list: MobilityAccess }
const L2_8: IQuestion = { title: 'Tempo de viagem', type: 'list', list: MobilityTime }
const L3_8: IQuestion = { title: 'Modo de viagem', type: 'list', list: MobilityMode }
const L3_8O: IQuestion = { title: 'Outro modo', type: 'text' }

const L1_12: IQuestion = { title: 'Polícia', type: 'list', list: MobilityAccess }
const L2_12: IQuestion = { title: 'Tempo de viagem', type: 'list', list: MobilityTime }
const L3_12: IQuestion = { title: 'Modo de viagem', type: 'list', list: MobilityMode }
const L3_12O: IQuestion = { title: 'Outro modo', type: 'text' }

const L1_13: IQuestion = { title: 'Escritórios da Administração/Governo local', type: 'list', list: MobilityAccess }
const L2_13: IQuestion = { title: 'Tempo de viagem', type: 'list', list: MobilityTime }
const L3_13: IQuestion = { title: 'Modo de viagem', type: 'list', list: MobilityMode }
const L3_13O: IQuestion = { title: 'Outro modo', type: 'text' }

const L1_11: IQuestion = { title: 'Machamba principal', type: 'list', list: MobilityAccess }
const L2_11: IQuestion = { title: 'Tempo de viagem', type: 'list', list: MobilityTime }
const L3_11: IQuestion = { title: 'Modo de viagem', type: 'list', list: MobilityMode }
const L3_11O: IQuestion = { title: 'Outro modo', type: 'text' }

const L1_22: IQuestion = { title: 'Área de irrigação', type: 'list', list: MobilityAccess }
const L2_22: IQuestion = { title: 'Tempo de viagem', type: 'list', list: MobilityTime }
const L3_22: IQuestion = { title: 'Modo de viagem', type: 'list', list: MobilityMode }
const L3_22O: IQuestion = { title: 'Outro modo', type: 'text' }

const L1_9: IQuestion = { title: 'Combustível para cozinhar', type: 'list', list: MobilityAccess }
const L2_9: IQuestion = { title: 'Tempo de viagem', type: 'list', list: MobilityTime }
const L3_9: IQuestion = { title: 'Modo de viagem', type: 'list', list: MobilityMode }
const L3_9O: IQuestion = { title: 'Outro modo', type: 'text' }

const L1_19: IQuestion = { title: 'Floresta/mata (principal usada)', type: 'list', list: MobilityAccess }
const L2_19: IQuestion = { title: 'Tempo de viagem', type: 'list', list: MobilityTime }
const L3_19: IQuestion = { title: 'Modo de viagem', type: 'list', list: MobilityMode }
const L3_19O: IQuestion = { title: 'Outro modo', type: 'text' }

const L1_20: IQuestion = { title: 'Rio/barragem/lago (principal usado)', type: 'list', list: MobilityAccess }
const L2_20: IQuestion = { title: 'Tempo de viagem', type: 'list', list: MobilityTime }
const L3_20: IQuestion = { title: 'Modo de viagem', type: 'list', list: MobilityMode }
const L3_20O: IQuestion = { title: 'Outro modo', type: 'text' }

const L1_21: IQuestion = { title: 'Área de pasto principal', type: 'list', list: MobilityAccess }
const L2_21: IQuestion = { title: 'Tempo de viagem', type: 'list', list: MobilityTime }
const L3_21: IQuestion = { title: 'Modo de viagem', type: 'list', list: MobilityMode }
const L3_21O: IQuestion = { title: 'Outro modo', type: 'text' }

const L1_23: IQuestion = { title: 'Principal ponto de abeberamento para o gado', type: 'list', list: MobilityAccess }
const L2_23: IQuestion = { title: 'Tempo de viagem', type: 'list', list: MobilityTime }
const L3_23: IQuestion = { title: 'Modo de viagem', type: 'list', list: MobilityMode }
const L3_23O: IQuestion = { title: 'Outro modo', type: 'text' }

const N1_4S: IQuestion = { 
  title: "Satisfação e bem-estar", type: 'list',
  list: { 1: 'Muito satisfeito', 2: 'Satisfeito', 3: 'Nem satisfeito, nem insatisfeito', 4: 'Insatisfeito', 5: 'Muito insatisfeito '},
  hint: "Actualmente, no geral, qual é o seu nível de satisfação e bem-estar na vida da sua família?" }

const N1_0: IQuestion = { title: "Nenhuma preocupação", type: 'check' }
const N1_1: IQuestion = { title: "Oportunidades de trabalho", type: 'check' }
const N1_2: IQuestion = { title: "Oportunidades de negócio", type: 'check' }
const N1_3: IQuestion = { title: "Mercados/lojas insuficientes", type: 'check' }
const N1_4: IQuestion = { title: "Falta de insumos agrícolas", type: 'check' }
const N1_5: IQuestion = { title: "Produção insuficiente/fome", type: 'check' }
const N1_6: IQuestion = { title: "Unidades sanitárias insuficientes", type: 'check' }
const N1_7: IQuestion = { title: "Qualidade dos serviços de saúde insuficiente", type: 'check' }
const N1_8: IQuestion = { title: "Escolas insuficientes", type: 'check' }
const N1_9: IQuestion = { title: "Apenas níveis mais básicos de escolaridade estão disponíveis", type: 'check' }
const N1_10: IQuestion = { title: "Falta de transportes", type: 'check' }
const N1_11: IQuestion = { title: "Estradas/acessos com más condições", type: 'check' }
const N1_12: IQuestion = { title: "Acesso a água", type: 'check' }
const N1_13: IQuestion = { title: "Acesso a energia", type: 'check' }
const N1_14: IQuestion = { title: "Criminalidade na comunidade", type: 'check' }
const N1_15: IQuestion = { title: "Instabilidade nas remessas, pensões, fontes de rendimento", type: 'check' }
const N1_17: IQuestion = { title: "Condições de habitação", type: 'check' }
const N1_18: IQuestion = { title: "Acesso a recursos naturais, florestais, pastagens", type: 'check' }
const N1_19: IQuestion = { title: "Falta de machamba", type: 'check' }
const N1_20: IQuestion = { title: "Outras preocupações", type: 'check' }
const N1_20O: IQuestion = { title: "Outras preocupações (especifique)", type: 'text', hint: "Especifique" }

const ImprovementList = {
  0: 'Nenhum',
  1: 'Maior disponibilidade de alimentos', 2: 'Maior segurança alimentar', 
  3: 'Factores não utilitários que geram mais bem-estar (tais como tempo livre, boas relações sociais e familiares, ou outros factores pessoais, emocionais ou espirituais, etc)',
  4: 'Maior rendimento', 5: 'Maior segurança em situações de crise/calamidade', 
  6: 'Mais disponibilidade e acesso a terras para cultivo, florestas e pastos', 
  7: 'Educação', 98: 'Outro' }
const N1_2A: IQuestion = { title: "Factor de melhoria (1o lugar)", type: 'list', list: ImprovementList }
const N1_2AO: IQuestion = { title: "Factor de melhoria (1o lugar - outro)", type: 'text', hint: "Especifique" }
const N1_2B: IQuestion = { title: "Factor de melhoria (2o lugar)", type: 'list', list: ImprovementList }
const N1_2BO: IQuestion = { title: "Factor de melhoria (2o lugar - outro)", type: 'text', hint: "Especifique" }
const N1_2C: IQuestion = { title: "Factor de melhoria (3o lugar)", type: 'list', list: ImprovementList }
const N1_2CO: IQuestion = { title: "Factor de melhoria (3o lugar - outro)", type: 'text', hint: "Especifique" }

const ImprovementActorList = {
  0: 'Nenhum', 1: 'Família', 2: 'População', 3: 'Líderes religiosos', 4: 'Líderes (tradicionais, políticos, anciãos)',
  5: 'Parque (PNL)', 6: 'Governo', 7: 'Polícia', 8: 'Tribunais', 98: 'Outro' }
const N1_3A: IQuestion = { title: "Actor de melhoria (1o lugar)", type: 'list', list: ImprovementActorList }
const N1_3AO: IQuestion = { title: "Actor de melhoria (1o lugar - outro)", type: 'text', hint: "Especifique" }
const N1_3B: IQuestion = { title: "Actor de melhoria (2o lugar)", type: 'list', list: ImprovementActorList }
const N1_3BO: IQuestion = { title: "Actor de melhoria (2o lugar - outro)", type: 'text', hint: "Especifique" }
const N1_3C: IQuestion = { title: "Actor de melhoria (3o lugar)", type: 'list', list: ImprovementActorList }
const N1_3CO: IQuestion = { title: "Actor de melhoria (3o lugar - outro)", type: 'text', hint: "Especifique" }
  
const N3: IQuestion = { title: 'Sabe fazer reclamação', type: 'check' }

const N3_1_1: IQuestion = { title: 'Linha do PNL', type: 'check' }
const N3_1_2: IQuestion = { title: 'Comissão Local de Reassentamento', type: 'check' }
const N3_1_3: IQuestion = { title: 'Líder comunitário', type: 'check' }
const N3_1_4: IQuestion = { title: 'Outra estrutura de liderança local', type: 'check' }
const N3_1_5: IQuestion = { title: 'Falar com pessoal do PNL durante as visitas de campo', type: 'check' }
const N3_1_6: IQuestion = { title: 'Usar caixa de reclamações', type: 'check' }
const N3_1_98: IQuestion = { title: 'Outro (especifique)', type: 'check' }
const N3_1_98O: IQuestion = { title: 'Outro', type:  'text' }

const N4: IQuestion = { title: "Teve alguma situação inconveniente/insatisfatória durante o processo de reassentamento?", type: 'check' }
const N4_1: IQuestion = { title: "Pôde comunicá-la ao Governo/PNL?", type: 'check' }
const N4_2: IQuestion = {
  title: "Ficou satisfeito com a resolução proporcionada pelo Governo/PNL?",
  type: 'list',
  list: { 1: 'Muito satisfeito', 2: 'Satisfeito', 3: 'Nem satisfeito, nem insatisfeito', 4: 'Insatisfeito', 5: 'Muito insatisfeito' }
}

const N5: IQuestion = {
  title: "Satisfação reassentamento", type: 'list', 
  hint: "Actualmente, qual é o seu nível de satisfação relativamente ao processo de reassentamento?",
  list: { 1: 'Muito satisfeito', 2: 'Satisfeito', 3: 'Nem satisfeito, nem insatisfeito', 4: 'Insatisfeito', 5: 'Muito insatisfeito' }
}

const ReassentamentoInsatisfactionList = {
  1: "O processo foi muito demorado",
  2: "As consultas públicas não foram devidamente feitas",
  3: "A comunicação/engajamento não é eficaz/adequada",
  4: "As principais necessidades não foram satisfeitas",
  5: "O calendário de transferência não foi apropriado",
  6: "A vida tornou-se precária/é mais precária do que na Aldeia de origem",
  10: "Nenhum motivo",
  98: "Outro (especifique)"
}

const N5_1: IQuestion = { title: "Razão de insatisfação (1)", type: 'list', list: ReassentamentoInsatisfactionList }
const N5_1O: IQuestion = { title: "Outra - specifique", type: "text" }
const N5_2: IQuestion = { title: "Razão de insatisfação (2)", type: 'list', list: ReassentamentoInsatisfactionList }
const N5_2O: IQuestion = { title: "Outra - specifique", type: "text" }
const N5_3: IQuestion = { title: "Razão de insatisfação (3)", type: 'list', list: ReassentamentoInsatisfactionList }
const N5_3O: IQuestion = { title: "Outra - specifique", type: "text" }

const N6: IQuestion = { title: "Considera reassentamento 100% concluído?", type: 'list', list: { 1: 'Sim', 2: 'Não'} }

const IncompleteList = {
  1: "Nem todas as compensações foram pagas/alocadas pelo PNL",
  2: "Há compromissos do Governo que ainda não foram cumpridos",
  3: "O apoio pós reassentamento não foi suficiente",
  4: "O processo de adaptação ainda não terminou",
  5: "As principais necessidades/expectativas não foram satisfeitas",
  98: "Outro (especifique)"
}

const N6_1: IQuestion = { title: "Razão (1)", type: 'list', list: IncompleteList }
const N6_1O: IQuestion = { title: "Outra - specifique", type: "text" }
const N6_2: IQuestion = { title: "Razão (2)", type: 'list', list: IncompleteList }
const N6_2O: IQuestion = { title: "Outra - specifique", type: "text" }
const N6_3: IQuestion = { title: "Razão (3)", type: 'list', list: IncompleteList }
const N6_3O: IQuestion = { title: "Outra - specifique", type: "text" }

const N7: IQuestion = { 
  title: "Comparação pós-reassentamento",
  hint: "Na sua perspectiva, comparando com a situação antes do reassentamento, considera que os seus meios de vida (as várias opções de formas e actividades para a sua família ganhar a vida) se encontram numa situação melhor, igual ou pior que a situação que tinha antes do reassentamento?", 
  type: 'list', list: { 1: 'Melhoraram', 2: 'Pioraram', 3: 'A situação mantém-se igual', 97: 'NA', 99: 'Não sabe'} }

const ReassentamentoComparisonList = {
  1: "Algumas oportunidades de negócio que tinha na aldeia de origem não são exequíveis na aldeia de reassentamento.",
  2: "O meu rendimento baixou após o reassentamento",
  3: "Ainda não consegui retomar as minhas actividades de subsistência",
  4: "As terras agrícolas foram alocadas tardiamente/não foram alocadas",
  5: "Não consigo produzir tão bem quanto na aldeia de origem",
  6: "Os projectos de geração de renda prometidos foram implementados tardiamente/não foram implementados",
  7: "Os projectos de geração de renda não foram bem implementados",
  98: "Outro (especifique)"
}

const N7_1: IQuestion = { title: "Razão (1)", type: 'list', list: ReassentamentoComparisonList }
const N7_1O: IQuestion = { title: "Outra - specifique", type: "text" }
const N7_2: IQuestion = { title: "Razão (2)", type: 'list', list: ReassentamentoComparisonList }
const N7_2O: IQuestion = { title: "Outra - specifique", type: "text" }
const N7_3: IQuestion = { title: "Razão (3)", type: 'list', list: ReassentamentoComparisonList }
const N7_3O: IQuestion = { title: "Outra - specifique", type: "text" }

/*
 * Questionnaire 
 */

const QuestionTree: { [key: string]: any } = {
  GEN_SURVEY_NUM,
  GEN_COORDENATOR_CODE,
  GEN_INTERVIEWER_CODE,
  GEN_WEIGHT,
  GEN_DATE,
  GEN_TIME_START,
  GEN_TIME_END,
  GEN_CAF_NAME,
  GEN_CAF_CONTACT,
  GEN_CAF_AGE,
  GEN_CAF_GENDER,
  AFMONOPARENTAL,
  AFPOLIGAMO,
  GEN_NAME,
  GEN_CONTACT,
  GEN_RELATION,
  GEN_CONTACT_CONSENT,
  GEN_COORD_LAT,
  GEN_COORD_LON,
  community_id,
  GEN_RESIDENCE,
  GEN_BAIRRO,
  GEN_COMMENTS,
  A1,
  members: {
    A2, A3, A5, B2, B3, B4, B4A, B5, B5A
  },
  B6,
  C1_1, C1_1A, C1_1AO, C1_1B, C1_1BO, 
  C1_2, C1_2A, C1_2AO, C1_2B, C1_2BO, 
  C1_3, C1_3A, C1_3AO, C1_3B, C1_3BO, 
  C1_4, C1_4A, C1_4AO, C1_4B, C1_4BO, 
  C1_5, C1_5A, C1_5AO, C1_5B, C1_5BO, 
  C1_6, C1_6A, C1_6AO, C1_6B, C1_6BO, 
  C1_7, C1_7A, C1_7AO, C1_7B, C1_7BO, 
  C1_8, C1_8O, C1_8A, C1_8AO, C1_8B, C1_8BO, 
  C1_9, C1_9O, C1_9A, C1_9AO, C1_9B, C1_9BO, 
  C4A, C4B, C4C, C4D, C4E, C4F, C4G, C4H, C4H1, C4I, C4I1, C8,
  D1A, D1B, D1C, D1D, D1E, D1F, D1G, D1H, D1I, D1J, D1JO,
  D2A, D2B, D2C, D2D, D2E, D2F, D2G, D2H, D2I, D2J, D2_OUTRO, D2KO, D2K,
  D4A, D4B, D4C, D4D, D4E, D4F, D4_OUTRO, D4G, D4GA, 
  D6, 
  D8A, D8AO, D8B, D8BO, D8C, D8CO,
  E1A, E1AO, E1B, E1BO, E1C, E1CO,
  F1A, F1B, F1C, F1D, F1E, F1F, F1G, F1H, F1I,
  G1_3, G1_3A, G1_3AO, G1_3B, G1_3BO, G1_3C, G1_3CO,
  G1_2, G1_4_A, G1_4_AO, G1_4_B, G1_4_BO, 
  G1_4_C1, G1_4_C2, G1_4_C3, G1_4_C4, G1_4_C5, G1_4_C98, G1_4_C999, G1_4_CO,
  G1_1_A, G1_1_B, G2, G2A, G3, G2_1,
  G2_2N, G2_2A, G2_2B, G2_2C, G2_2D, G2_2E, G2_2F, G2_2G, G2_2H, G2_2HO, G3_1,
  G4A, G4AO, G4B, G4BO, G4C, G4CO, G4_1, G5, G5A, G6, G6A,
  H1,
  fields: {
    H2, H3, H3O,
    H4A, H4AO, H5A, H6A, H6AO,
    H4B, H4BO, H5B, H6B, H6BO,
    H4C, H4CO, H5C, H6C, H6CO,
    H7, H7O, H22, H22O, H19
  },
  H10A, H10B, H10C, H10D, H10E, H10F, H10G, H10H, H10I, H10IO,
  H20, H20A_1, H20A_1O, H20A_2, H20A_2O, H20A_3, H20A_3O,
  H21, H21A_1, H21A_1O, H21A_2, H21A_2O, H21A_3, H21A_3O,
  H11A, H11AO, H11B, H11BO, H11C, H11CO, H12, H13, H13A, H13B, H13BO, H13C, 
  H14A, H14AO, H14B, H14BO, H14C, H14CO, H16, H17, H17A, H17B,
  H18, H18O, H18A, H23,
  I1, I1P, I1PO, I2, I2P, I2PO, I3, I3P, I3PO, I4, I4P, I4PO,
  I5, I5P, I5PO, I6, I6P, I6PO, I7, I7P, I7PO, I8, I8P, I8PO,
  I9, I9P, I9PO, I10, I10P, I10PO, I11, I11P, I11PO, I12, I12P, I12PO,
  I13, I13P, I13PO, I14, I14P, I14PO, I15, I15O, I15P, I15PO, I16, I16O, I16P, I16PO,
  J3A, J4A, J4AO, J3B, J4B, J4BO, J3C, J4C, J4CO, J3D, J4D, J4DO, J3E, J4E, J4EO,
  J3F, J4F, J4FO, J3G, J4G, J4GO, J3H, J4H, J4HO, J3I, J4I, J4IO, J3J, J4J, J4JO,
  J3K, J3KO, J4K, J4KO,
  J7, 
  J13, J13_A, J13_AO, J13_B, J13_BO, J13_C, J13_CO,
  J14, J9, J9_1, 
  J100, J10A, J10B, J10C, J10D, J10E, J10F, J10G, J10H, J10HO, 
  J11A, J11AO, J11B, J11BO, J11C, J11CO, J12,
  L1_4, L2_4, L3_4, L3_4O,
  L1_6, L2_6, L3_6, L3_6O,
  L1_7, L2_7, L3_7, L3_7O,
  L1_8, L2_8, L3_8, L3_8O,
  L1_12, L2_12, L3_12, L3_12O,
  L1_13, L2_13, L3_13, L3_13O,
  L1_11, L2_11, L3_11, L3_11O,
  L1_22, L2_22, L3_22, L3_22O,
  L1_9, L2_9, L3_9, L3_9O,
  L1_19, L2_19, L3_19, L3_19O,
  L1_20, L2_20, L3_20, L3_20O,
  L1_21, L2_21, L3_21, L3_21O,
  L1_23, L2_23, L3_23, L3_23O,
  N1_4S,
  N1_0, N1_1, N1_2, N1_3, N1_4, N1_5, N1_6, N1_7, N1_8, N1_9, N1_10, 
  N1_11, N1_12, N1_13, N1_14, N1_15, N1_17, N1_18, N1_19, N1_20, N1_20O,
  N1_2A, N1_2AO, N1_2B, N1_2BO, N1_2C, N1_2CO,
  N1_3A, N1_3AO, N1_3B, N1_3BO, N1_3C, N1_3CO,
  N3, N3_1_1, N3_1_2, N3_1_3, N3_1_4, N3_1_5, N3_1_6, N3_1_98, N3_1_98O,
  N4, N4_1, N4_2,
  N5, N5_1, N5_1O, N5_2, N5_2O, N5_3, N5_3O,
  N6, N6_1, N6_1O, N6_2, N6_2O, N6_3, N6_3O,
  N7, N7_1, N7_1O, N7_2, N7_2O, N7_3, N7_3O
}

export { IQuestion, QuestionTree };
