import * as React from 'react';
import * as domtoimage from 'dom-to-image';

import styled from '@independent-software/typeui/styles/Theme'
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Label } from '@independent-software/typeui/controls/Label';

interface IProps {
  className?: string;
  children?: React.ReactNode;
}

interface IState {
  zoomed: boolean;
}

class ChartHolder extends React.Component<IProps, IState> {
  private wrapperRef: HTMLElement;

  constructor(props: IProps) {
    super(props);
    this.state = {
      zoomed: false
    }
  }

  // Uses dom-to-image
  // See: https://github.com/tsayen/dom-to-image
  private handleExport = () => {
    // Get width and height from chart wrapper div:
    //let ratio = this.wrapperRef.clientWidth / this.wrapperRef.clientHeight;
    //let width = 1500;
    //let height = Math.floor(width / ratio);

    (domtoimage as any).toBlob(this.wrapperRef.children[2], { bgcolor: '#ffffff', cacheBust: true })
    .then(function (blob:any) {
        window.saveAs(blob, 'chart.png');
    });
  }

  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        <InnerBorder ref={(el:any) => this.wrapperRef = el}>
          <IconGroup>
            <Label basic>
              <Icon name="download" title="Download" onClick={this.handleExport}/>
            </Label>
          </IconGroup>
          {p.children}
        </InnerBorder>
      </div>
    )
  }
}

const InnerBorder = styled('div')`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
`

const IconGroup = styled('div')`
  position: absolute;
  z-index: 1;
  right: 10px;
  bottom: 10px;
`

export { ChartHolder }