import * as React from 'react'
import axios from 'axios';
import { Post } from '../../resource'
import { Markdown } from '@independent-software/typeui/formatters/Markdown';
import { Label } from '@independent-software/typeui/controls/Label';
import { Datum } from '@independent-software/typeui/formatters/Datum';
import { Card } from '@independent-software/typeui/controls/Card';
import { IAuthProps } from '../../services';
import { Button } from '@independent-software/typeui/controls/Button';
import { Loc, Section } from '../../modules';
import { EditPost } from './EditPost';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { App } from '../../App';
import { Gravatar } from '@independent-software/typeui/controls/Gravatar';
import { LocalizedDatum } from '../../formatters';

interface IProps {
  post: Post;
  onSave: (post: Post) => void;
  onDelete: (post: Post) => void;
}

interface IState {
  editing: boolean;
  deleting: boolean;
}

class PostView extends React.Component<IAuthProps & IProps, IState> {

  constructor(props: IAuthProps & IProps) {
    super(props);
    this.state = {
      editing: false,
      deleting: false
    };
  }

  handleEdit = () => {
    // Enter edit mode
    this.setState({ editing: true });
  }

  handleCancelEdit = () => {
    // Stop editing
    this.setState({ editing: false });
  }

  handleSave = (post: Post) => {
    // Stop editing
    this.setState({ editing: false });
    // Notify parent
    this.props.onSave(post);
  }

  handleConfirmDelete = () => {
    this.setState({ deleting: true });
  }

  handleCancelDelete = () => {
    this.setState({ deleting: false });
  }

  handleDelete = () => {
    axios.delete(`${App.apiURL}post/${this.props.post.id}?api_token=${this.props.auth.token}`)
    .then(response => {
      this.setState({ deleting: false });
      this.props.onDelete(this.props.post);
    })   
  }

  render() {
    let p = this.props;
    return (
      <React.Fragment>
        <Card fluid>
          <Card.Header>
            <div style={{float:'right'}}>
              <Label basic size="tiny"><Gravatar email={p.post.created_by.email}/> {p.post.created_by.name}</Label>
              <Label basic size="tiny"><LocalizedDatum.Distance value={p.post.updated_at}/></Label>
              {p.post.created_by.id == p.auth.id && <Button secondary compact size="mini" onClick={this.handleEdit}><Loc msg="btn_edit"/></Button>}
              {p.post.created_by.id == p.auth.id && <Button negative compact size="mini" onClick={this.handleConfirmDelete}><Loc msg="btn_delete"/></Button>}
            </div>
            {p.post.title}
          </Card.Header>
          <Card.Content>
            <Markdown source={p.post.text}/>
            {this.state.editing && 
              <EditPost
                post={p.post} 
                auth={p.auth} 
                onCancel={this.handleCancelEdit}
                onSave={this.handleSave}
                />              
            }
          </Card.Content>
        </Card>
        <Dialog open={this.state.deleting} onClose={this.handleCancelDelete}>
          <Dialog.Header><Loc msg="posts_confirm_delete_title"/></Dialog.Header>
          
          <Section padded>
            <Loc msg="posts_confirm_delete"/>
          </Section>
          
          <Dialog.Footer>
            <Button primary onClick={this.handleDelete}><Loc msg="btn_ok"/></Button>
            <Button secondary onClick={this.handleCancelDelete}><Loc msg="btn_cancel"/></Button>          
          </Dialog.Footer>
        </Dialog>        
      </React.Fragment>
    );
  }

}

export { PostView }