import { ShortDate } from "./ShortDate"
import { LongDate } from "./LongDate"
import { DistanceDate } from "./Distance"
import { ShortDateTime } from "./ShortDateTime"
import { LongDateTime } from "./LongDateTime"
import { CustomDateTime } from "./CustomDateTime"
import { MediumDate } from "./MediumDate"

class LocalizedDatum {
  public static ShortDate = ShortDate;
  public static LongDate = LongDate;
  public static Distance = DistanceDate;
  public static ShortDateTime = ShortDateTime;
  public static LongDateTime = LongDateTime;
  public static Custom = CustomDateTime;
  public static MediumDate = MediumDate;
}

export { LocalizedDatum }
