import * as React from 'react';
import { css } from 'styled-components';
import styled from '@independent-software/typeui/styles/Theme'

class StatsBottomBase extends React.Component<{ className?: string, children?: React.ReactNode, alert?: boolean }, {}> {
  render() {
    let p = this.props;
    return <div className={p.className}>
      {p.children}
    </div>
  }
}

const StatsBottom = styled(StatsBottomBase)`
  text-align: center;
  ${p => p.alert && css`color: red`}
`

export { StatsBottom }