import * as React from 'react';
import { Section, Viewer, Map, AttachmentManager, Block, Loc } from '../../modules';
import { Community, CommunityFactory, SurveyCommunity, Stakeholder, Household, Action } from '../../resource/'; 
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';

import { Markdown } from '@independent-software/typeui/formatters/Markdown';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Header } from '@independent-software/typeui/controls/Header';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Label } from '@independent-software/typeui/controls/Label';
import { Gps } from '../../formatters';
import { Divider } from '@independent-software/typeui/controls/Divider';
import { ListSurveyCommunities } from '../Survey/ListSurveyCommunities';
import { ListStakeholders } from '../Stakeholder';
import { ListHouseholds } from '../Household';
import { ListActions } from '../Action';

class ViewCommunity extends React.Component<IAuthProps & RouteComponentProps<{id:string}>, {}> {
  private canEdit = (community: Community): boolean => {
    return this.props.auth && this.props.auth.hasRight('can_edit_communities');
  }  

  private handleEdit = () => {
    this.props.history.push(`/communities/edit/${parseInt(this.props.match.params.id)}`);
  }

  private handleDelete = () => {
    ToastService.toast(<Loc msg="communities_msg_deleted"/>);
    this.props.history.push('/communities');        
  }    

  private handleSurveyCommunityClick = (item: SurveyCommunity) => {
    this.props.history.push(`/surveys/${item.survey_id}`);
  }  

  private handleStakeholderClick = (item: Stakeholder) => {
    this.props.history.push(`/stakeholders/${item.id}`);
  }  

  private handleHouseholdClick = (item: Household) => {
    this.props.history.push(`/households/${item.id}`);
  } 

  private handleActionClick = (item: Action) => {
    this.props.history.push(`/actions/${item.id}`);
  }   

  private handleDistrictClick = (id: number) => {
    this.props.history.push(`/districts/${id}`);
  }

  render() {
    let p = this.props;
    return (
      <Viewer<Community> 
        auth={p.auth}
        id={parseInt(p.match.params.id)}
        factory={CommunityFactory}
        canEdit={this.canEdit}
        onEdit={this.handleEdit}
        onDelete={this.handleDelete}
        content={(community: Community) => 
          <React.Fragment>
            <Section dark padded>
               <div style={{float:'right', marginTop: '10px'}}>
                 {p.auth.hasRight('can_view_districts') && 
                 <Label onClick={() => this.handleDistrictClick(community.district.id)}>{community.district.name}</Label>}
                 {!p.auth.hasRight('can_view_districts') && 
                 <Label>{community.district.name}</Label>}                 
               </div>
               <Header size="h2">{community.name}</Header>
            </Section>
            <Tabs underlined nohiddenrender>
              <Tabs.Pane label={<Loc msg="communities_form_tab_data"/>}>
                <Section padded>
                  <Flex.Columns count={2}>
                    <Block title={<Loc msg="communities_fields_description_en_label"/>}>
                      {community.description_en &&
                        <Markdown source={community.description_en}/>
                      }
                      {!community.description_en &&
                        <Loc msg="communities_fields_description_en_none"/>
                      }
                    </Block>
                    <Block title={<Loc msg="communities_fields_description_pt_label"/>}>
                      {community.description_pt &&
                        <Markdown source={community.description_pt}/>
                      }
                      {!community.description_pt &&
                        <Loc msg="communities_fields_description_pt_none"/>
                      }
                    </Block>                    
                  </Flex.Columns>
                </Section>
              </Tabs.Pane>
              <Tabs.Pane label={<Loc msg="communities_form_tab_location"/>}>
                <Section padded>
                  <Gps latitude={community.latitude} longitude={community.longitude}/>
                  <Divider hidden/>
                  <Map latitude={community.latitude} longitude={community.longitude}/>
                </Section>
              </Tabs.Pane>
              <Tabs.Pane label={<span><Loc msg="communities_form_tab_stakeholders"/> <Label size="small">{community.stakeholders_count}</Label></span>}>
                <ListStakeholders auth={p.auth} name="community_stakeholders" communityId={community.id} onClick={this.handleStakeholderClick}/>
              </Tabs.Pane>              
              <Tabs.Pane label={<span><Loc msg="communities_form_tab_households"/> <Label size="small">{community.households_count}</Label></span>}>
                <ListHouseholds auth={p.auth} name="community_households" communityId={community.id} onClick={this.handleHouseholdClick}/>
              </Tabs.Pane>
              <Tabs.Pane label={<span><Loc msg="communities_form_tab_actions"/> <Label size="small">{community.actions_count}</Label></span>}>
                <ListActions auth={p.auth} name="communities_actions" communityId={community.id} onClick={this.props.auth.hasRight('can_view_actions') ?  this.handleActionClick : null}/>
              </Tabs.Pane>              
              <Tabs.Pane label={<span><Loc msg="communities_form_tab_surveys"/> <Label size="small">{community.surveys_count}</Label></span>}>
                <ListSurveyCommunities auth={p.auth} name="community_surveycommunities" communityId={community.id} onClick={this.handleSurveyCommunityClick}/>
              </Tabs.Pane>              
              <Tabs.Pane label={<span>{<Loc msg="communities_form_tab_attachments"/>} <Label size="small">{community.attachments.length}</Label></span>}>
                <Section padded>
                  <AttachmentManager auth={p.auth} attachments={community.attachments}/>
                </Section>
              </Tabs.Pane>                
            </Tabs>
          </React.Fragment>}>
      </Viewer>
    );
  }
}

export { ViewCommunity };
