import * as React from 'react';
import { LanguageContext, ILanguageContext } from './LanguageContext';
import { Button } from '@independent-software/typeui/controls/Button';

class LanguageSwitch extends React.Component<{}, {}> {
  render() {
    return (
      <React.Fragment>
        <LanguageContext.Consumer>
          {(ctx: ILanguageContext) => 
            <React.Fragment>
              <Button.Group icon size="tiny">
                <Button noripple secondary={ctx.language == 'en'} onClick={() => ctx.setLanguage('en')}>
                  EN
                </Button>
                <Button icon noripple secondary={ctx.language == 'pt'} onClick={() => ctx.setLanguage('pt')}>
                  PT
                </Button>
              </Button.Group>
            </React.Fragment>
          }
        </LanguageContext.Consumer>
      </React.Fragment>
    );
  }
}

export { LanguageSwitch }