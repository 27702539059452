import * as React from 'react'
import axios from 'axios';
import { Grievance } from '../../../resource';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Button } from '@independent-software/typeui/controls/Button';
import { IAuthProps } from '../../../services';
import { App } from '../../../App';
import { Form } from '@independent-software/typeui/controls/Form';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Loc, Section } from '../../../modules';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { TLocalization } from '../../../modules/Loc/TLocalization';
import { Input } from '@independent-software/typeui/controls/Input';

interface IProps {
  grievance: Grievance;
  open: boolean;
  onClose: () => void;
  onExec: () => void;
  onFail: (error: any) => void;
}

interface IData {
  contact_type: string;
  contact_date: string;
}

interface IState {
  data: IData;
  dirty: boolean;
  isValid: boolean;
  saving: boolean;
}

class LitigationDialog extends React.Component<IAuthProps & IProps, IState> {

  constructor(props: IAuthProps & IProps) {
    super(props);
    this.state = { 
      data: {
        contact_type: null,
        contact_date: null
      },
      dirty: false,
      isValid: false,
      saving: false
    }
  }

  private exec = () => {
    let grievance = this.props.grievance;
    this.setState({ saving: true });

    axios.post(`${App.apiURL}grievance/move/${grievance.id}/litigation`, { api_token: this.props.auth.token, ... this.state.data })
    .then(response => {
      grievance.newstate = 'litigation';
      this.props.onExec();
    })
    .catch(error => {
      this.props.onFail(error);
    })
    .finally(() => {
      this.setState({ saving: false });
    });
  }

  handleChange = (data: IData, forceupdate: boolean) => {
    this.setState({ data: data });
  }

  handleValidate = (valid: boolean) => {
    this.setState({
      isValid: valid
    })
  }  


  render() {
    return (
      <Dialog width={800} open={this.props.open} onClose={this.props.onClose} canClose={!this.state.saving}>
        <Dialog.Header><Loc msg="grievance_workflow_litigation_title"/></Dialog.Header>
        
        <Section padded>
          <Form data={this.state.data} dirty={this.state.dirty} onChange={this.handleChange} onValidate={this.handleValidate}>

            <Form.Field 
              required={{message: <Loc msg="grievance_workflow_contact_date_required"/>}}
              width={1} label={<Loc msg="grievance_workflow_contact_date_label"/>}
              name="contact_date" 
              value={this.state.data.contact_date}
              control={(<Input type="date" nofuture placeholder="Contact date" fluid/>)}
              hint={(<React.Fragment><Loc msg="grievance_workflow_contact_date_hint"/></React.Fragment>)}/>            

            <Form.Field 
              required={{message: <Loc msg="grievance_workflow_contact_type_required"/>}}
              width={1} label={<Loc msg="grievance_workflow_contact_type_label"/>}
              name="contact_type" 
              value={this.state.data.contact_type}
              control={(
                <Dropdown fluid data={[ "phone", "presential" ]} placeholder="Contact type" label={(item: string) => <Loc msg={("grievance_workflow_contact_type_" + item) as TLocalization}/>}>
                  <Dropdown.Column>{(item:string) => <Loc msg={("grievance_workflow_contact_type_" + item) as TLocalization}/>}</Dropdown.Column>
                </Dropdown>
              )}
              hint={(<React.Fragment><Loc msg="grievance_workflow_contact_type_hint"/></React.Fragment>)}/>

          </Form>

        </Section>
        
        <Dialog.Footer>
          <Button disabled={!this.state.isValid || this.state.saving} primary onClick={() => this.exec()}>
            {this.state.saving && <Icon name="circle-notch" loading/>}
            <Loc msg="btn_ok"/>
          </Button>
          <Button disabled={this.state.saving} secondary onClick={this.props.onClose}><Loc msg="btn_cancel"/></Button>          
        </Dialog.Footer>
      </Dialog>
    )
  }
}

export { LitigationDialog }