import * as React from 'react';

import { Container, Content, BottomBar, Block, Section, Loc } from '../../modules';
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';
import { ChartEvolution } from './ChartEvolution';
import { ChartHolder } from './ChartHolder';
import { ChartGender } from './ChartGender';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { ChartCategory } from './ChartCategory';
import { Table } from '@independent-software/typeui/controls/Table';
import { ListGrievanceCountByCommunity } from './ListGrievanceCountByCommunity';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { CardStats } from './CardStats';
import { ListTimings } from './ListTimings';


class GrievanceAnalysis extends React.Component<IAuthProps & RouteComponentProps<any>, {}> {

  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
  }

  render() {
    let p = this.props;

    return (
      <Container>
        <Content>
          <Tabs underlined nohiddenrender>
            <Tabs.Pane label={<Loc msg="analysis_grievances_tab_evolution"/>}>
              <Section padded>
                <Block title={<Loc msg="analysis_grievances_tab_evolution"/>}>
                  <div style={{position: 'relative', width: '100%', height: '600px'}}>
                    <ChartHolder>
                      <ChartEvolution auth={p.auth}/>
                    </ChartHolder>
                  </div>
                </Block>
              </Section>
            </Tabs.Pane>
            <Tabs.Pane label={<Loc msg="analysis_grievances_tab_systemic_issues"/>}>
              <Section padded>
                <Flex.Columns count={2}>
                  <React.Fragment>
                    <Block title={<Loc msg="analysis_grievances_title_by_gender"/>}>
                      <div style={{position: 'relative', width: '100%', height: '400px'}}>
                        <ChartHolder>
                          <ChartGender auth={p.auth}/>
                        </ChartHolder>
                      </div>
                    </Block>
                    <Block title={<Loc msg="analysis_grievances_title_by_community"/>}>
                      <ListGrievanceCountByCommunity auth={p.auth} {...p}/>
                    </Block>                  
                  </React.Fragment>
                  <Block title={<Loc msg="analysis_grievances_title_by_category"/>}>
                    <div style={{position: 'relative', width: '100%', height: '600px'}}>
                      <ChartHolder>
                        <ChartCategory auth={p.auth}/>
                      </ChartHolder>
                    </div>
                  </Block>
                </Flex.Columns>
              </Section>
            </Tabs.Pane>
            <Tabs.Pane label={<Loc msg="analysis_grievances_tab_phase"/>}>
              <Section padded>
                <CardStats auth={p.auth}/>
                <Block title={<Loc msg="analysis_grievances_title_timings"/>}>
                  <ListTimings auth={p.auth}/>
                </Block>
              </Section>
            </Tabs.Pane>
          </Tabs>  

        </Content>
        <BottomBar>
          <div>
          </div>
        </BottomBar>

      </Container>
    );
  }
}

export { GrievanceAnalysis };
