import * as React from 'react';
import axios from 'axios';
import styled from '@independent-software/typeui/styles/Theme'

import { IAuthProps } from '../../services/Auth';
import { RouteComponentProps } from 'react-router-dom';
import { App } from '../../App';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { Form } from '@independent-software/typeui/controls/Form';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Table } from '@independent-software/typeui/controls/Table';
import { ListOptions } from './ListOptions';
import { Loc } from '../../modules';

interface IProps {
}

interface IState {
  days: number;
  data: any;
}

class ListGrievanceCountByCommunity extends React.Component<IAuthProps & RouteComponentProps<any> & IProps, IState> {
   constructor(props: IAuthProps & RouteComponentProps<any> & IProps) {
     super(props);
     this.state = {
       days: -1,
       data: []
     }
   } 

   componentDidMount() {
     this.loadData();
   }

   componentDidUpdate(newProps: IProps, newState: IState) {
    if(this.state.days != newState.days) {
      this.loadData();
    }
  }

   loadData = () => {
    let path = `${App.apiURL}grievances/list-grievance-count-by-community?api_token=${this.props.auth.token}`;
    if(this.state.days) { path = path + `&days=${this.state.days}`; }
    axios.get(path)
    .then(response => {
      let data = response.data;
      this.setState({
        data: data
      })
    })
    .catch(error => {
    });   
  }

  handleChangeDays = (value: number) => {
    this.setState({days: value});
  }    

  handleClickCommunity = (id:number) => {
    if(!this.props.auth.hasRight('can_view_communities')) return;
    this.props.history.push(`/communities/${id}`);
  }

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Form.Uncontrolled hint={<Loc msg="analysis_grievances_hint_period"/>}>
          <Dropdown name="days" fluid value={this.state.days} data={[30,90,180,365,-1]} placeholder="Period" label={(item:number) => item == -1 ? <Loc msg="analysis_grievances_hint_period_all_time"/> : <span>{item} {<Loc msg="analysis_grievances_hint_period_days"/>}</span>} onChange={this.handleChangeDays}>
            <Dropdown.Column>{(item:number) => item == -1 ? <Loc msg="analysis_grievances_hint_period_all_time"/> : <span>{item} {<Loc msg="analysis_grievances_hint_period_days"/>}</span>}</Dropdown.Column>
          </Dropdown>
        </Form.Uncontrolled>        
      </Panel.Content>
    </React.Fragment>    

    return (
      <div>
        <ListOptions>
          <Panel.Icon icon="tools" width={400}>
            {filter}
          </Panel.Icon>
        </ListOptions>
        <Table transparent>
          <thead>
            <tr>
              <th><Loc msg="analysis_grievances_hdr_community"/></th>
              <th style={{width: '100px'}}><Loc msg="analysis_grievances_hdr_grievances"/></th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((item:any, idx:number) => 
              <ListItem key={idx} onClick={() => this.handleClickCommunity(item.id)}>
                <td>
                  {item.name}
                </td>
                <td>
                  {item.total}
                </td>
              </ListItem>
            )}
          </tbody>
        </Table>
      </div>
    );
  }
}

const ListItem = styled('tr')`
  cursor: pointer;
  &:hover {
    background: #E7E7e7;
  }
`

export { ListGrievanceCountByCommunity }


