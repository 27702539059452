import * as React from 'react'
import axios from 'axios';
import { Grievance, TStates } from '../../../resource';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Button } from '@independent-software/typeui/controls/Button';
import { IAuthProps } from '../../../services';
import { App } from '../../../App';
import { Form } from '@independent-software/typeui/controls/Form';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Loc, Section } from '../../../modules';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { TLocalization } from '../../../modules/Loc/TLocalization';

interface IProps {
  grievance: Grievance;
  open: boolean;
  onClose: () => void;
  onExec: () => void;
  onFail: (error: any) => void;
}

interface IState {
  destination: TStates;
  saving: boolean;
}

class ForceMoveDialog extends React.Component<IAuthProps & IProps, IState> {

  constructor(props: IAuthProps & IProps) {
    super(props);
    this.state = { 
      destination: null,
      saving: false
    }
  }

  private exec = () => {
    let grievance = this.props.grievance;
    this.setState({ saving: true });

    axios.post(`${App.apiURL}grievance/force/${grievance.id}/${this.state.destination}`, { api_token: this.props.auth.token })
    .then(response => {
      grievance.state = this.state.destination;
      this.props.onExec();
    })
    .catch(error => {
      this.props.onFail(error);
    })
    .finally(() => {
      this.setState({ saving: false });
    });
  }


  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.onClose} canClose={!this.state.saving}>
        <Dialog.Header><Loc msg="grievance_workflow_forcemove_title"/></Dialog.Header>
        <Section padded>
          <Form.Uncontrolled hint={<Loc msg="grievance_workflow_forcemove_hint"/>}>
            <Dropdown 
              data={this.props.grievance ? Grievance.getPreceding(this.props.grievance.state) : []}
              placeholder="State"
              fluid
              value={this.state.destination}
              label={(item) => <Loc msg={("grievance_workflow_" + item) as TLocalization}/>}
              onChange={(item:any) => this.setState({destination: item})}>
              <Dropdown.Column>{(item) => <Loc msg={("grievance_workflow_" + item) as TLocalization}/>}</Dropdown.Column>
            </Dropdown>
          </Form.Uncontrolled>
        </Section>
        
        <Dialog.Footer>
          <Button disabled={this.state.destination == null || this.state.saving} primary onClick={() => this.exec()}>
            {this.state.saving && <Icon name="circle-notch" loading/>}
            <Loc msg="btn_ok"/>
          </Button>
          <Button disabled={this.state.saving} secondary onClick={this.props.onClose}><Loc msg="btn_cancel"/></Button>          
        </Dialog.Footer>
      </Dialog>
    )
  }
}

export { ForceMoveDialog }