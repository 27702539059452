import * as React from 'react';
import { Container, Content, BottomBar, Loc } from '../../modules';
import { Issuetype } from '../../resource/';
import { RouteComponentProps } from 'react-router';
import { ListIssuetypes } from './ListIssuetypes';
import { IAuthProps } from '../../services/Auth';

import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';

class IssuetypeList extends React.Component<IAuthProps & RouteComponentProps<any>, {}> {

  // Go to add issue type.
  handleAdd = () => {
    this.props.history.push('/issuetypes/add');
  }  

  // Go to view issue type view.
  handleClick = (item: Issuetype) => {
    this.props.history.push(`/issuetypes/${item.id}`);
  }  

  render() {
    let p = this.props;
    return (
      <Container>
        <Content>
          <ListIssuetypes auth={p.auth} name="issuetypes" onClick={this.handleClick}/>
        </Content>
        <BottomBar>
          {p.auth && p.auth.hasRight('can_edit_issuetypes') && <Button primary onClick={this.handleAdd}><Icon name="plus"/> <Loc msg="issuetypes_btn_add"/></Button>}
        </BottomBar>
      </Container>
    );
  }
}

export { IssuetypeList };
