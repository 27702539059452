import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { App } from './App';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { LanguageProvider } from './modules/Loc'
import { StyleReset, StyleBase, Theme } from '@independent-software/typeui/styles';
import styledLeaflet from 'styled-leaflet'
import { AppContainer } from './AppContainer';
import { IThemeInterface } from '@independent-software/typeui/styles/Theme';

const MyTheme: IThemeInterface = {
  ...Theme,
  radius: 0
}

const StyledLeaflet = createGlobalStyle`
  ${styledLeaflet}
`;

App.run(() => 
  ReactDOM.render(
    (<React.Fragment>
      <StyleReset/>
      <StyleBase/>
      <StyledLeaflet/>
      <LanguageProvider>
        <ThemeProvider theme={MyTheme}>
          <AppContainer/>
        </ThemeProvider>
      </LanguageProvider>
    </React.Fragment>),
    document.getElementById('root')
  ));

// Whenever webpack rebuilds the project, refresh the browser.
declare let module: any;
if (module.hot) {
  module.hot.accept(); 
}
