import * as React from 'react';

import { Container, Content, BottomBar, Block, Section, Loc } from '../../modules';
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { ListActionsTime } from './ListActionsTime';
import { ListActionsImplementationByCommunity } from './ListActionsImplementationByCommunity';

class ActionAnalysis extends React.Component<IAuthProps & RouteComponentProps<any>, {}> {

  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
  }

  render() {
    let p = this.props;

    return (
      <Container>
        <Content>
          <Tabs underlined nohiddenrender>
            <Tabs.Pane label={<Loc msg="analysis_actions_tab_time"/>}>
              <Section padded>
                <Block title={<Loc msg="analysis_actions_title_time"/>}>
                  <ListActionsTime auth={p.auth}/>
                </Block>
              </Section>
            </Tabs.Pane>
            <Tabs.Pane label={<Loc msg="analysis_actions_tab_implementation"/>}>
              <Section padded>
                <Block title={<Loc msg="analysis_actions_title_implementation"/>}>
                <ListActionsImplementationByCommunity auth={p.auth}/>
                </Block>
              </Section>
            </Tabs.Pane>
          </Tabs>  
        </Content>
        <BottomBar>
          <div>
          </div>
        </BottomBar>

      </Container>
    );
  }
}

export { ActionAnalysis };
