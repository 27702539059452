import * as React from 'react';
import axios from 'axios';
import styled from '@independent-software/typeui/styles/Theme'

import { IAuthProps } from '../../services/Auth';
import { RouteComponentProps } from 'react-router-dom';
import { App } from '../../App';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { Form } from '@independent-software/typeui/controls/Form';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Table } from '@independent-software/typeui/controls/Table';
import { ListOptions } from './ListOptions';
import { Loc } from '../../modules';
import { TLocalization } from '../../modules/Loc/TLocalization';

interface IProps {
}

interface IItem {
  name: string,
  unresolved: number;
  resolved: number;
  age: number;
  overdue: number,
  target: number;
}

interface IState {
  days: number;
  data: IItem[];
}

class ListTimings extends React.Component<IAuthProps & IProps, IState> {
   constructor(props: IAuthProps & IProps) {
     super(props);
     this.state = {
       days: -1,
       data: []
     }
   } 

   componentDidMount() {
     this.loadData();
   }

   componentDidUpdate(newProps: IProps, newState: IState) {
    if(this.state.days != newState.days) {
      this.loadData();
    }
  }

   loadData = () => {
    let path = `${App.apiURL}grievances/list-timings?api_token=${this.props.auth.token}`;
    if(this.state.days) { path = path + `&days=${this.state.days}`; }
    axios.get(path)
    .then(response => {
      let data = response.data;
      this.setState({
        data: data
      })
    })
    .catch(error => {
    });   
  }

  handleChangeDays = (value: number) => {
    this.setState({days: value});
  }    

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Form.Uncontrolled hint={<Loc msg="analysis_grievances_hint_period"/>}>
          <Dropdown name="days" fluid value={this.state.days} data={[30,90,180,365,-1]} placeholder="Period" label={(item:number) => item == -1 ? <Loc msg="analysis_grievances_hint_period_all_time"/> : <span>{item} {<Loc msg="analysis_grievances_hint_period_days"/>}</span>} onChange={this.handleChangeDays}>
            <Dropdown.Column>{(item:number) => item == -1 ? <Loc msg="analysis_grievances_hint_period_all_time"/> : <span>{item} {<Loc msg="analysis_grievances_hint_period_days"/>}</span>}</Dropdown.Column>
          </Dropdown>
        </Form.Uncontrolled>        
      </Panel.Content>
    </React.Fragment>    

    // Calculate total average time:
    let total_grievances = 0;
    let total_time = 0;
    this.state.data.map((item: IItem) => {
      total_grievances += item.unresolved + item.resolved;
      total_time += item.age * (item.unresolved + item.resolved);
    });
    let total_avg = total_grievances == 0 ? 0 : Math.round(total_time / total_grievances);

    return (
      <div>
        <ListOptions>
          <Panel.Icon icon="tools" width={400}>
            {filter}
          </Panel.Icon>
        </ListOptions>
        <Table transparent>
          <thead>
            <tr>
              <th><Loc msg="analysis_grievances_hdr_phase"/></th>
              <th style={{textAlign: 'right', width: '100px'}}><Loc msg="analysis_grievances_hdr_unresolved"/></th>
              <th style={{textAlign: 'right', width: '100px'}}><Loc msg="analysis_grievances_hdr_resolved"/></th>
              <th style={{textAlign: 'right', width: '100px'}}><Loc msg="analysis_grievances_hdr_avg_time"/></th>
              <th style={{textAlign: 'right', width: '100px'}}><Loc msg="analysis_grievances_hdr_overdue"/></th>
              <th style={{textAlign: 'right', width: '100px'}}><span><Loc msg="analysis_grievances_hdr_target"/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((item: IItem, idx:number) => 
              <tr key={idx}>
                <td>
                  <Loc msg={('grievance_phase_' + item.name) as TLocalization}/>
                </td>
                <td style={{textAlign: 'right'}}>
                  {item.unresolved}
                </td>
                <td style={{textAlign: 'right'}}>
                  {item.resolved}
                </td>
                <td style={{textAlign: 'right'}}>
                  {item.age}
                </td>
                <td style={{textAlign: 'right'}}>
                  {item.overdue}
                </td>
                <td style={{textAlign: 'right'}}>
                  {item.target}
                </td>
              </tr>
            )}
            <tr>
              <td style={{fontWeight: 500}}><Loc msg='grievance_phase_total'/></td>
              <td style={{fontWeight: 500, textAlign: 'right'}}>{this.state.data.reduce((a: number, b: IItem) => a + b.unresolved, 0)}</td>
              <td style={{fontWeight: 500, textAlign: 'right'}}>{this.state.data.reduce((a: number, b: IItem) => a + b.resolved, 0)}</td>
              <td style={{fontWeight: 500, textAlign: 'right'}}>{total_avg}</td>
              <td style={{fontWeight: 500, textAlign: 'right'}}>{this.state.data.reduce((a: number, b: IItem) => a + b.overdue, 0)}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

const ListItem = styled('tr')`
  cursor: pointer;
  &:hover {
    background: #E7E7e7;
  }
`

export { ListTimings }


