import { TRight } from '../config/Config';
import axios from 'axios';
import { App } from '../App';

interface IAuthProps {
  auth: Auth;
}

class Auth {
  public id: number;
  public name: string;
  public email: string;
  public token: string;
  public rights: string[];
  
  constructor(id: number, name: string, email: string, token: string, rights: TRight[]) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.token = token;
    this.rights = rights;

    // Store auth in session storage.
    sessionStorage.setItem('auth', JSON.stringify(this));
  }

  /**
   * When the app first loads, this method is used to restore an Auth
   * from local storage. If it's not in local storage, then NULL 
   * is returned. 
   * 
   * This way, we can persist authorization across sessions (including
   * page refreshes).
   */
  public static restore(): Auth {
    let authStr = sessionStorage.getItem('auth');
    if(!authStr) return null;
    let auth = JSON.parse(authStr);
    return new Auth(
      auth.id,
      auth.name,
      auth.email,
      auth.token,
      auth.rights
    );
  }

  /**
   * Returns true if authenticated user is authorized for specified right.
   * @param right 
   */
  public hasRight(right: TRight | boolean) {
    if(right === true) return true;
    return this.rights.includes(right as TRight);
  }

  /**
   * Perform signout by removing session from session storage.
   */
  public signout(onSuccess: () => void) {
    axios.get(`${App.apiURL}signout?api_token=${this.token}`)
    .then(response => {
      sessionStorage.removeItem('auth');
      onSuccess();
    })
    .catch(error => {
    });    
  }
}

export { Auth, IAuthProps, TRight };