import { IIndicator } from "./Types";

const INDICATORS: IIndicator[] = [
  {
    dimension: 'dim_food_security',
    issue: 'issue_availability',
    name: 'meals_per_day',
    src: 'H12',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'relative',
    stacked: true,
  }, {
    dimension: 'dim_food_security',
    issue: 'issue_availability',
    name: 'key_crops',
    src: 'H11A,H11B,H11C',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: true    
  }, {
    dimension: 'dim_food_security',
    issue: 'issue_availability',
    name: "self_sufficiency",
    src: 'H13',
    mode: 'cat',
    type: 'pie',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false    
  }, {
    dimension: 'dim_food_security',
    issue: 'issue_food_reserve',
    name: 'reserve',
    src: 'H13A',
    mode: 'cat',
    type: 'pie',
    layout: 'horizontal',
    calc: 'absolute',
    stacked: false
  }, {
    dimension: 'dim_food_security',
    issue: 'issue_food_reserve',
    name: 'crop',
    src: 'H13B',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'absolute',
    stacked: false
  }, {
    dimension: 'dim_food_security',
    issue: 'issue_food_reserve',
    name: 'duration',
    src: 'H13C',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'absolute',
    stacked: false
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_income_generation',
    name: 'activities',
    src: 'D1A,D1B,D1C,D1D,D1E,D1F,D1G,D1H,D1I,D1J',
    mode: 'num',
    type: 'bar',
    layout: 'horizontal',
    calc: 'absolute',
    stacked: false    
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_income_generation',
    name: 'crops',
    src: 'H14A,H14B,H14C',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: true        
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_income_generation',
    name: 'crop_sell_frequency',
    src: 'H16',
    mode: 'cat',
    type: 'pie',
    layout: 'vertical',
    calc: 'absolute',
    stacked: true       
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_income_generation',
    name: 'crop_sell_location',
    src: 'H17',
    mode: 'cat',
    type: 'pie',
    layout: 'vertical',
    calc: 'absolute',
    stacked: true       
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_agriculture',
    name: 'current_use',
    src: 'H3',
    mode: 'cat',
    array: 'fields',
    type: 'pie',
    layout: 'vertical',
    calc: 'absolute',
    stacked: true       
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_agriculture',
    name: 'non_cultivation',
    src: 'H10A,H10B,H10C,H10D,H10E,H10F,H10G,H10H,H10I',
    mode: 'num',
    type: 'pie',
    layout: 'vertical',
    calc: 'absolute',
    stacked: true      
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_agriculture',
    name: 'farm_area',
    src: 'H2',
    mode: 'catsum',
    array: 'fields',
    type: 'bar',
    ignore: '99',
    layout: 'horizontal',
    calc: 'absolute',
    stacked: false      
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_agriculture',
    name: 'main_crops',
    src: 'H4A,H4B,H4C',
    mode: 'catsum',
    array: 'fields',
    type: 'bar',
    layout: 'horizontal',
    calc: 'absolute',
    stacked: false      
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_agriculture',
    name: 'season',
    src: 'H5A,H5B,H5C',
    mode: 'catsum',
    array: 'fields',
    type: 'bar',
    layout: 'horizontal',
    calc: 'absolute',
    stacked: false      
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_agriculture',
    name: 'tilling',
    src: 'H6A,H6B,H6C',
    mode: 'catsum',
    array: 'fields',
    type: 'bar',
    layout: 'horizontal',
    calc: 'absolute',
    stacked: false      
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_agriculture',
    name: 'irrigation',
    src: 'H22',
    array: 'fields',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false      
  },{
    dimension: 'dim_livelihoods',
    issue: 'issue_agriculture',
    name: 'inputs',
    src: 'H19',
    array: 'fields',
    mode: 'cat',
    type: 'pie',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false       
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_agriculture',
    name: 'seeds_seedlings',
    src: 'H18',
    mode: 'cat',
    type: 'pie',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false  
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_agriculture',
    name: 'seeds_expenditure',
    src: 'H18A',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false  
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_agriculture',
    name: 'farmland_security',
    src: 'H7',
    array: "fields",
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false  
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_agriculture',
    name: 'distance_farmland',
    src: 'L2_11',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: false      
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_agriculture',
    name: 'transport_farmland',
    src: 'L3_11',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false    
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_agriculture',
    name: 'distance_irrigation',
    src: 'L2_22',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: false      
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_agriculture',
    name: 'transport_irrigation',
    src: 'L3_22',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false    
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_agriculture',
    name: 'satisfaction_rainfed',
    src: 'H20',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false     
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_agriculture',
    name: 'dissatisfaction_factors_rainfed',
    src: 'H20A_1,H20A_2,H20A_3',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false     
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_agriculture',
    name: 'satisfaction_irrigation',
    src: 'H21',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false     
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_agriculture',
    name: 'dissatisfaction_factors_irrigation',
    src: 'H21A_1,H21A_2,H21A_3',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false     
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_agriculture',
    name: 'comparison_farmland',
    src: 'H23',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: false     
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_trees',
    name: 'trees_owned',
    src: 'I1,I2,I3,I4,I5,I6,I7,I8,I9,I10,I11,I12,I13,I14,I15',
    mode: 'avgnum',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    decimals: 2,
    stacked: false         
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_trees',
    name: 'trees_purpose',
    src: 'I1P,I2P,I3P,I4P,I5P,I6P,I7P,I8P,I9P,I10P,I11P,I12P,I13P,I14P,I15P',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'absolute',
    stacked: false         
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_animals',
    name: 'animals_owned',
    src: 'J3A,J3B,J3C,J3D,J3E,J3F,J3G,J3H,J3I,J3J,J3K',
    mode: 'avgnum',
    ignore: '999',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false         
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_animals',
    name: 'animals_purpose',
    src: 'J4A,J4B,J4C,J4D,J4E,J4F,J4G,J4H,J4I,J4J,J4K',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false         
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_animals',
    name: 'veterinary_assistance',
    src: 'J7',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'relative',
    stacked: false      
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_animals',
    name: 'satisfaction_grazing',
    src: 'J13',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false      
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_animals',
    name: 'dissatisfaction_factors_grazing',
    src: 'J13_A,J13_B,J13_C',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false      
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_animals',
    name: 'comparison_grazing',
    src: 'J14',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: false      
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_animals',
    name: 'distance_grazing',
    src: 'L2_21',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: false      
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_animals',
    name: 'transport_grazing',
    src: 'L3_21',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false      
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_animals',
    name: 'distance_watering',
    src: 'L2_23',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: false      
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_animals',
    name: 'transport_watering',
    src: 'L3_23',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false      
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_local_market',
    name: 'distance_local_market',
    src: 'L2_6',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: false      
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_local_market',
    name: 'transport_local_market',
    src: 'L3_6',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false      
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_mill',
    name: 'distance_mill',
    src: 'L2_7',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: false      
  }, {
    dimension: 'dim_livelihoods',
    issue: 'issue_mill',
    name: 'transport_mill',
    src: 'L3_7',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false      
  }, {
    dimension: 'dim_human_capital',
    issue: 'issue_education',
    name: 'school_enrolment',
    src: 'B2',
    array: 'members',
    mode: 'cat',
    type: 'pie',
    layout: 'vertical',
    calc: 'relative',
    stacked: false         
  }, {
    dimension: 'dim_human_capital',
    issue: 'issue_education',
    name: 'non_enrolment',
    src: 'B5',
    array: 'members',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'relative',
    stacked: true         
  }, {
    dimension: 'dim_human_capital',
    issue: 'issue_education',
    name: 'distance_school',
    src: 'B3',
    mode: 'cat',
    array: 'members',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: false      
  }, {
    dimension: 'dim_human_capital',
    issue: 'issue_education',
    name: 'transport_school',
    src: 'B4',
    mode: 'cat',
    array: 'members',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false      
  }, {
    dimension: 'dim_human_capital',
    issue: 'issue_education',
    name: 'comparison_education',
    src: 'B6',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: false      
  }, {
    dimension: 'dim_human_capital',
    issue: 'issue_health',
    name: 'illnesses',
    src: 'C1_1,C1_2,C1_3,C1_4,C1_5,C1_6,C1_7,C1_8',
    mode: 'num',
    type: 'pie',
    layout: 'horizontal',
    calc: 'relative',
    stacked: false      
  }, {
    dimension: 'dim_human_capital',
    issue: 'issue_health',
    name: 'treatment',
    src: 'C1_1A,C1_2A,C1_3A,C1_4A,C1_5A,C1_6A,C1_7A,C1_8A',
    mode: 'catsum',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false      
  }, {
    dimension: 'dim_human_capital',
    issue: 'issue_health',
    name: 'access_limitations_health',
    src: 'C1_1B,C1_2B,C1_3B,C1_4B,C1_5B,C1_6B,C1_7B,C1_8B',
    mode: 'catsum',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false      
  }, {
    dimension: 'dim_human_capital',
    issue: 'issue_health',
    name: 'chronic_illnesses',
    src: 'C4A,C4B,C4C,C4D,C4E,C4F,C4G,C4H',
    mode: 'num',
    type: 'bar',
    layout: 'horizontal',
    calc: 'absolute',
    stacked: false      
  }, {
    dimension: 'dim_human_capital',
    issue: 'issue_health',
    name: 'distance_health',
    src: 'L2_4',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: false      
  }, {
    dimension: 'dim_human_capital',
    issue: 'issue_health',
    name: 'transport_health',
    src: 'L3_4',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false      
  }, {
    dimension: 'dim_human_capital',
    issue: 'issue_health',
    name: 'comparison_health',
    src: 'C8',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: false      
  }, {
    dimension: 'dim_social_capital',
    issue: 'issue_safety_nets',
    name: 'actors',
    src: 'E1A,E1B,E1C',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: true    
  }, {
    dimension: 'dim_natural_capital',
    issue: 'issue_forest',
    name: 'distance_forest',
    src: 'L2_19',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: false      
  }, {
    dimension: 'dim_natural_capital',
    issue: 'issue_forest',
    name: 'transport_forest',
    src: 'L3_19',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false      
  }, {
    dimension: 'dim_natural_capital',
    issue: 'issue_cooking_fuel',
    name: 'distance_cooking_fuel',
    src: 'L2_9',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: false      
  }, {
    dimension: 'dim_natural_capital',
    issue: 'issue_cooking_fuel',
    name: 'transport_cooking_fuel',
    src: 'L3_9',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false      
  }, {
    dimension: 'dim_natural_capital',
    issue: 'issue_hydric_resources',
    name: 'distance_river',
    src: 'L2_20',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: false      
  }, {
    dimension: 'dim_natural_capital',
    issue: 'issue_hydric_resources',
    name: 'transport_river',
    src: 'L3_20',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false      
  }, {
    dimension: 'dim_natural_capital',
    issue: 'issue_hwc',
    name: 'conflict',
    src: 'J9',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'absolute',
    stacked: false
  }, {
    dimension: 'dim_natural_capital',
    issue: 'issue_hwc',
    name: 'conflict_frequency',
    src: 'J9_1',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: false      
  }, {
    dimension: 'dim_natural_capital',
    issue: 'issue_hwc',
    name: 'conflict_damage',
    src: 'J100,J10A,J10B,J10C,J10D,J10E,J10F,J10G,J10H',
    mode: 'num',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false      
  }, {
    dimension: 'dim_natural_capital',
    issue: 'issue_hwc',
    name: 'conflict_animals',
    src: 'J11A,J11B,J11C',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false      
  }, {
    dimension: 'dim_natural_capital',
    issue: 'issue_hwc',
    name: 'comparison_hwc',
    src: 'J12',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: false      
  }, {
    dimension: 'dim_physical_capital',
    issue: 'issue_water',
    name: 'source',
    src: 'G2',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false      
  }, {
    dimension: 'dim_physical_capital',
    issue: 'issue_water',
    name: 'distance_water',
    src: 'G3',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'absolute',
    stacked: false,
    allow_97: true      
  }, {
    dimension: 'dim_physical_capital',
    issue: 'issue_water',
    name: 'sufficiency_water',
    src: 'G2_1',
    mode: 'cat',
    type: 'pie',
    layout: 'horizontal',
    calc: 'absolute',
    stacked: false
  }, {
    dimension: 'dim_physical_capital',
    issue: 'issue_water',
    name: 'water_issues',
    src: 'G2_2N,G2_2A,G2_2B,G2_2C,G2_2D,G2_2E,G2_2F,G2_2G,G2_2H',
    mode: 'num',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false
  }, {
    dimension: 'dim_physical_capital',
    issue: 'issue_water',
    name: 'comparison_water',
    src: 'G3_1',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: false
  }, {
    dimension: 'dim_physical_capital',
    issue: 'issue_sanitation',
    name: 'sanitation',
    src: 'G5',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false
  }, {
    dimension: 'dim_physical_capital',
    issue: 'issue_solid_waste',
    name: 'waste_treatment',
    src: 'G6',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false
  }, {
    dimension: 'dim_physical_capital',
    issue: 'issue_energy',
    name: 'energy_source',
    src: 'G4A,G4B,G4C',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'absolute',
    stacked: false
  }, {
    dimension: 'dim_physical_capital',
    issue: 'issue_energy',
    name: 'improved_ovens',
    src: 'G4_1',
    mode: 'cat',
    type: 'pie',
    layout: 'horizontal',
    calc: 'relative',
    stacked: false
  }, {
    dimension: 'dim_physical_capital',
    issue: 'issue_housing',
    name: 'satisfaction_housing',
    src: 'G1_3',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false
  }, {
    dimension: 'dim_physical_capital',
    issue: 'issue_housing',
    name: 'dissatisfaction_housing',
    src: 'G1_3A,G1_3B,G1_3C',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false
  }, {
    dimension: 'dim_physical_capital',
    issue: 'issue_housing',
    name: 'comparison_housing',
    src: 'G1_2',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    allow_97: true,
    stacked: false
  }, {
    dimension: 'dim_physical_capital',
    issue: 'issue_polygamous_houses',
    name: 'compensation_type',
    src: 'G1_4_A',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false
  }, {
    dimension: 'dim_physical_capital',
    issue: 'issue_polygamous_houses',
    name: 'polygamous_situation',
    src: 'G1_4_B',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false
  }, {
    dimension: 'dim_physical_capital',
    issue: 'issue_polygamous_houses',
    name: 'polygamous_no_compensation',
    src: 'G1_4_C1,G1_4_C2,G1_4_C3,G1_4_C4,G1_4_C5,G1_4_C98,G1_4_C999',
    mode: 'num',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false
  }, {
    dimension: 'dim_physical_capital',
    issue: 'issue_housing_security',
    name: 'residential_land_use_rights',
    src: 'G1_1_A',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false
  }, {
    dimension: 'dim_physical_capital',
    issue: 'issue_housing_security',
    name: 'house_property_titles',
    src: 'G1_1_B',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false
  }, {
    dimension: 'dim_physical_capital',
    issue: 'issue_mobility',
    name: 'distance_bus',
    src: 'L2_8',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: false      
  }, {
    dimension: 'dim_physical_capital',
    issue: 'issue_mobility',
    name: 'distance_police',
    src: 'L2_12',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: false      
  }, {
    dimension: 'dim_physical_capital',
    issue: 'issue_mobility',
    name: 'transport_police',
    src: 'L3_12',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false    
  }, {
    dimension: 'dim_physical_capital',
    issue: 'issue_mobility',
    name: 'distance_local_admin',
    src: 'L2_13',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: false      
  }, {
    dimension: 'dim_physical_capital',
    issue: 'issue_mobility',
    name: 'transport_local_admin',
    src: 'L3_13',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false    
  }, {
    dimension: 'dim_financial_capital',
    issue: 'issue_flows_of_money',
    name: 'income',
    src: 'D2A,D2B,D2C,D2D,D2E,D2F,D2G,D2H,D2I,D2J,D2K',
    mode: 'avgnum',
    type: 'bar',
    layout: 'horizontal',
    calc: 'absolute',
    stacked: false       
  }, {
    dimension: 'dim_financial_capital',
    issue: 'issue_flows_of_money',
    name: 'expenditure',
    src: 'D4A,D4B,D4C,D4D,D4E,D4F,D4G',
    mode: 'avgnum',
    type: 'bar',
    layout: 'horizontal',
    calc: 'absolute',
    stacked: false       
  }, {
    dimension: 'dim_financial_capital',
    issue: 'issue_investments',
    name: 'investments',
    src: 'D8A,D8B,D8C',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false         
  }, {
    dimension: 'dim_financial_capital',
    issue: 'issue_stocks',
    name: 'bank_account',
    src: 'D6',
    mode: 'cat',
    type: 'pie',
    layout: 'vertical',
    calc: 'relative',
    stacked: false,
    allow_97: true
  }, {
    dimension: 'dim_financial_capital',
    issue: 'issue_stocks',
    name: 'durable_goods',
    src: 'F1A,F1B,F1C,F1D,F1E,F1F,F1G,F1H,F1I',
    mode: 'num',
    type: 'bar',
    layout: 'horizontal',
    calc: 'absolute',
    stacked: false
  }, {
    dimension: 'dim_perception',
    issue: 'issue_wellbeing',
    name: 'perceived_wellbeing',
    src: 'N1_4S',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false
  }, {
    dimension: 'dim_perception',
    issue: 'issue_concerns',
    name: 'concerns',
    src: 'N1_0,N1_1,N1_2,N1_3,N1_4,N1_5,N1_6,N1_7,N1_8,N1_9,N1_10,N1_11,N1_12,N1_13,N1_14,N1_15,N1_17,N1_18,N1_19,N1_20',
    mode: 'num',
    type: 'pie',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false
  }, {
    dimension: 'dim_perception',
    issue: 'issue_perception_change',
    name: 'key_factors',
    src: 'N1_2A,N1_2B,N1_2C',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false
  }, {
    dimension: 'dim_perception',
    issue: 'issue_perception_change',
    name: 'key_actors',
    src: 'N1_3A,N1_3B,N1_3C',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false
  },{
    dimension: 'dim_perception',
    issue: 'issue_grievances',
    name: 'knowledge_of_grm',
    src: 'N3',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: true
  },{
    dimension: 'dim_perception',
    issue: 'issue_grievances',
    name: 'known_means',
    src: 'N3_1_1,N3_1_2,N3_1_3,N3_1_4,N3_1_5,N3_1_6,N3_1_98',
    mode: 'num',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false
  }, {
    dimension: 'dim_perception',
    issue: 'issue_grievances',
    name: 'occurrence_of_issues',
    src: 'N4',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: true
  }, {
    dimension: 'dim_perception',
    issue: 'issue_grievances',
    name: 'communication_of_issues',
    src: 'N4_1',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: true
  }, {
    dimension: 'dim_perception',
    issue: 'issue_grievances',
    name: 'resolution_satisfaction',
    src: 'N4_2',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: true
  }, {
    dimension: 'dim_perception',
    issue: 'issue_resettlement',
    name: 'resettlement_satisfaction',
    src: 'N5',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: true
  }, {
    dimension: 'dim_perception',
    issue: 'issue_resettlement',
    name: 'resettlement_dissatisfaction',
    src: 'N5_1,N5_2,N5_3',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false
  }, {
    dimension: 'dim_perception',
    issue: 'issue_resettlement',
    name: 'perception_conclusion',
    src: 'N6',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: true    
  }, {
    dimension: 'dim_perception',
    issue: 'issue_resettlement',
    name: 'perception_no_conclusion',
    src: 'N6_1,N6_2,N6_3',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false    
  }, {
    dimension: 'dim_perception',
    issue: 'issue_lrdp',
    name: 'comparison_livelihoods',
    src: 'N7',
    mode: 'cat',
    type: 'bar',
    layout: 'horizontal',
    calc: 'relative',
    stacked: true    
  }, {
    dimension: 'dim_perception',
    issue: 'issue_lrdp',
    name: 'dissatisfaction_factors',
    src: 'N7_1,N7_2,N7_3',
    mode: 'cat',
    type: 'bar',
    layout: 'vertical',
    calc: 'absolute',
    stacked: false    
  }
];

export { INDICATORS };
