import * as React from 'react';
import axios from 'axios';
import { Container, Content, BottomBar, Section, Loc } from '../../modules';
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';
import { AccountForm } from './AccountForm';
import { IAccount } from './IAccount';
import { Button } from '@independent-software/typeui/controls/Button';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { App } from '../../App';

interface MatchParams {
  id: string;
}

interface IState {
  data: IAccount;
  isValid?: boolean;
  dirty?: boolean;
  error: { 
    status: number;
    data: {[key:string]: string[] };
  };
}

class Account extends React.Component<IAuthProps & RouteComponentProps<MatchParams>, IState> {
  constructor(props: IAuthProps & RouteComponentProps<MatchParams>) {
    super(props);
    this.state = {
      data: {
        name: props.auth.name,
        email: props.auth.email,
        password: '',
        newpassword: ''
      },
      isValid: false,
      error: null
    }    
  }

  handleChange = (account: IAccount, forceupdate: boolean) => {
    this.setState({
      data: account
    });
  }  

  handleValidate = (valid: boolean) => {
    this.setState({
      isValid: valid
    })
  }    

  handleSubmit = () => {
    this.setState({ dirty: true });
    if(!this.state.isValid) return;
    
    this.setState({error: null});
    axios.post(`${App.apiURL}account`, {
      api_token: this.props.auth.token,
      name: this.state.data.name,
      email: this.state.data.email,
      password: this.state.data.password,
      newpassword: this.state.data.newpassword
    })
    .then(response => {
      ToastService.toast(<Loc msg="account_msg_updated"/>);
      this.props.history.goBack();
    })    
    .catch(error => {
      this.setState({
        error: error
      });
    });
  }

  render() {
    return (
      <Container>
        <Content>
          <Section padded>
            <AccountForm dirty={this.state.dirty} data={this.state.data} onChange={this.handleChange} onValidate={this.handleValidate}/>
          </Section>
        </Content>
        <BottomBar>
          <Button primary onClick={this.handleSubmit}><Loc msg="btn_save"/></Button>
        </BottomBar>
        {this.state.error && (
          <Dialog.Xhr error={this.state.error} onClose={() => {}}/>
        )}
        </Container>      
    );
  }
}

export { Account };
