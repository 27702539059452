import * as React from 'react';
import { Community, CommunityFactory, Household } from '../../resource/';
import { IAuthProps, Query } from '../../services/';

import { Input } from '@independent-software/typeui/controls/Input';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Form } from '@independent-software/typeui/controls/Form';
import { Section, Loc, Block } from '../../modules';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { TLocalization } from '../../modules/Loc/TLocalization';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { MarkdownTextarea } from '@independent-software/typeui/modules/MarkdownTextarea';

interface IProps {
  /** Initial form data. */
  data: Household;
  /** Called whenever form changes. */
  onChange: (data: Household, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */  
  dirty?: boolean;
}

interface IState {
  /** Current form data. */
  data: Household;
  /** Data for communities dropdown */
  communities: Community[];    
}

class HouseholdForm extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);

    // Intialize state.
    this.state = {
      data: props.data,
      communities: []
    };    
  }

  componentDidMount() {
    this.handleCommunitySearch();
  }

  handleCommunitySearch = (q?:string) => {
    // Retrieve a list of communities:
    let query = new Query('name', 'asc');
    if(q) query.setFilter('q', 'like', q);
    CommunityFactory.getSome(this.props.auth, 0, 999, query)
      .then((res) => this.setState({ communities: res.items }));
  }    

  render() {
    let p = this.props;
    return (
      <Form
        data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
        <Section padded>
          <Block title={<Loc msg="households_block_general"/>}>
            <Flex.Columns count={2}>
              <Form.Field 
                required={{message: <Loc msg="households_fields_code_required"/>}}
                minLength={{length: 3, message: <Loc msg="households_fields_code_minlength"/>}}
                maxLength={{length: 255, message: <Loc msg="households_fields_code_maxlength"/>}}
                width={1} label={<Loc msg="households_fields_code_label"/>}
                name="code" 
                value={this.state.data.code}
                control={(<Input type="text" placeholder="Code" fluid/>)}
                hint={<Loc msg="households_fields_code_hint"/>}/>

              <Form.Field 
                required={{message: <Loc msg="households_fields_name_required"/>}}
                minLength={{length: 3, message: <Loc msg="households_fields_name_minlength"/>}}
                maxLength={{length: 255, message: <Loc msg="households_fields_name_maxlength"/>}}
                width={1} label={<Loc msg="households_fields_name_label"/>}
                name="name" 
                value={this.state.data.name}
                control={(<Input type="text" placeholder="Name" fluid/>)}
                hint={<Loc msg="households_fields_name_hint"/>}/>

              <Form.Field
                required={{message: <Loc msg="households_fields_community_required"/>}}
                width={1} label={<Loc msg="households_fields_community_label"/>}
                name="community" 
                value={this.state.data.community}
                control={(<Dropdown onSearch={this.handleCommunitySearch} fluid data={this.state.communities} placeholder="Community" label={(item:Community) => item.name}>
                  <Dropdown.Column weight={3}>{(item:Community) => item.name}</Dropdown.Column>
                </Dropdown>)}
                hint={<Loc msg="households_fields_community_hint"/>}/>

              <Form.Field
                required={{message: <Loc msg="households_fields_state_required"/>}}
                width={1} label={<Loc msg="households_fields_state_label"/>}
                name="state" 
                value={this.state.data.state}
                control={(<Dropdown fluid data={['toresettle','resettled','host']} placeholder="State" label={(item:string) => <Loc msg={("households_states_" + item) as TLocalization}/>}>
                  <Dropdown.Column>{(item:string) => <Loc msg={("households_states_" + item) as TLocalization}/>}</Dropdown.Column>
                </Dropdown>)}
                hint={<Loc msg="households_fields_state_hint"/>}/>                

              <React.Fragment/>

              <Form.Field 
                forceupdate
                name="after_resettlement"
                value={this.state.data.after_resettlement}
                control={(<Checkbox type='slider' checked={this.state.data.after_resettlement} label={<Loc msg="households_fields_after_resettlement_label"/>}/>)}/>

              {this.state.data.after_resettlement == true && 
              <Form.Field 
                width={1} label={<Loc msg="households_fields_after_resettlement_code_label"/>}
                name="after_resettlement_code" 
                value={this.state.data.after_resettlement_code}
                control={(<Input type="text" placeholder="Code" fluid/>)}
                hint={<Loc msg="households_fields_after_resettlement_code_hint"/>}/>}
            </Flex.Columns>
          </Block>

          <Flex.Columns count={2}>
             <Block title={<Loc msg="households_block_head"/>}>
              <Form.Field 
                required={{message: <Loc msg="households_fields_hhh_name_required"/>}}
                minLength={{length: 3, message: <Loc msg="households_fields_hhh_name_minlength"/>}}
                maxLength={{length: 255, message: <Loc msg="households_fields_hhh_name_maxlength"/>}}
                width={1} label={<Loc msg="households_fields_hhh_name_label"/>}
                name="hhh_name" 
                value={this.state.data.hhh_name}
                control={(<Input type="text" placeholder="Name" fluid/>)}
                hint={<Loc msg="households_fields_hhh_name_hint"/>}/>

              <Form.Field 
                required={{message: <Loc msg="households_fields_hhh_age_required"/>}}
                pattern={{pattern: '^(([1-9][0-9])|(1[0-1][0-9])|120|999)$', message: <Loc msg="households_fields_hhh_age_pattern"/>}}
                width={1} label={<Loc msg="households_fields_hhh_age_label"/>}
                name="hhh_age" 
                value={this.state.data.hhh_age}
                control={(<Input type="text" placeholder="Age" fluid/>)}
                hint={<Loc msg="households_fields_hhh_age_hint"/>}/>

              <Form.Field
                forceupdate
                required={{message: <Loc msg="households_fields_hhh_gender_required"/>}}
                label={<Loc msg="households_fields_hhh_gender_label"/>}
                name="hhh_gender"
                value={this.state.data.hhh_gender}
                control={<React.Fragment>
                  <div><Checkbox radio type="circle" name="hhh_gender" value="m" label={<Loc msg="households_genders_m"/>} checked={this.state.data.hhh_gender=='m'} onChange={() => this.props.onChange({...this.state.data, hhh_gender: 'm'}, true)}/></div>
                  <div><Checkbox radio type="circle" name="hhh_gender" value="f" label={<Loc msg="households_genders_f"/>} checked={this.state.data.hhh_gender=='f'} onChange={() => this.props.onChange({...this.state.data, hhh_gender: 'f'}, true)}/></div>
                </React.Fragment>}
                hint={<Loc msg="households_fields_hhh_gender_hint"/>}
              />

              <Form.Field 
                required={{message: <Loc msg="households_fields_hhh_phone_required"/>}}
                maxLength={{length: 255, message: <Loc msg="households_fields_hhh_phone_maxlength"/>}}
                width={1} label={<Loc msg="households_fields_hhh_phone_label"/>}
                name="hhh_phone" 
                value={this.state.data.hhh_phone}
                control={(<Input type="text" placeholder="Phone" fluid/>)}
                hint={<Loc msg="households_fields_hhh_phone_hint"/>}/>              

              <Form.Field 
                required={{message: <Loc msg="households_fields_hhh_contact_required"/>}}
                maxLength={{length: 255, message: <Loc msg="households_fields_hhh_contact_maxlength"/>}}
                width={1} label={<Loc msg="households_fields_hhh_contact_label"/>}
                name="hhh_contact" 
                value={this.state.data.hhh_contact}
                control={(<Input type="text" placeholder="Contact" fluid/>)}
                hint={<Loc msg="households_fields_hhh_contact_hint"/>}/>                    

              <Form.Field 
                maxLength={{length: 255, message: <Loc msg="households_fields_hhh_civil_id_maxlength"/>}}
                width={1} label={<Loc msg="households_fields_hhh_civil_id_label"/>}
                name="hhh_civil_id" 
                value={this.state.data.hhh_civil_id}
                control={(<Input type="text" placeholder="ID" fluid/>)}
                hint={<Loc msg="households_fields_hhh_civil_id_hint"/>}/>                    

              <Form.Field 
                maxLength={{length: 255, message: <Loc msg="households_fields_hhh_account_maxlength"/>}}
                width={1} label={<Loc msg="households_fields_hhh_account_label"/>}
                name="hhh_account" 
                value={this.state.data.hhh_account}
                control={(<Input type="text" placeholder="Account" fluid/>)}
                hint={<Loc msg="households_fields_hhh_account_hint"/>}/>                    

              <Form.Field 
                forceupdate
                name="hhh_leader"
                value={this.state.data.hhh_leader}
                control={(<Checkbox checked={this.state.data.hhh_leader} label={<Loc msg="households_fields_hhh_leader_label"/>}/>)}/>

            </Block>

            <React.Fragment>
              <Block title={<Loc msg="households_block_household"/>}>
                <Form.Field 
                  required={{message: <Loc msg="households_fields_members_required"/>}}
                  isInt={{message: <Loc msg="households_fields_members_int"/>}}
                  min={{value: 1, message: <Loc msg="households_fields_members_min"/>}}
                  max={{value: 100, message: <Loc msg="households_fields_members_max"/>}}
                  width={1} label={<Loc msg="households_fields_members_label"/>}
                  name="members" 
                  value={this.state.data.members}
                  control={(<Input type="text" placeholder="Members" fluid/>)}
                  hint={<Loc msg="households_fields_members_hint"/>}/>

                <Form.Field 
                  required={{message: <Loc msg="households_fields_graves_required"/>}}
                  isInt={{message: <Loc msg="households_fields_graves_int"/>}}
                  min={{value: 0, message: <Loc msg="households_fields_graves_min"/>}}
                  max={{value: 999, message: <Loc msg="households_fields_graves_max"/>}}
                  width={1} label={<Loc msg="households_fields_graves_label"/>}
                  name="graves" 
                  value={this.state.data.graves}
                  control={(<Input type="text" placeholder="Graves" fluid/>)}
                  hint={<Loc msg="households_fields_graves_hint"/>}/>

                <Form.Field 
                  forceupdate
                  name="polygamous"
                  value={this.state.data.polygamous}
                  control={(<Checkbox checked={this.state.data.polygamous} label={<Loc msg="households_fields_polygamous_label"/>}/>)}/>

                {this.state.data.polygamous == true && 
                <Form.Field 
                  required={{message: <Loc msg="households_fields_polygamous_spouses_required"/>}}
                  isInt={{message: <Loc msg="households_fields_polygamous_spouses_int"/>}}
                  min={{value: 1, message: <Loc msg="households_fields_polygamous_spouses_min"/>}}
                  max={{value: 100, message: <Loc msg="households_fields_polygamous_spouses_max"/>}}
                  width={1} label={<Loc msg="households_fields_polygamous_spouses_label"/>}
                  name="polygamous_spouses" 
                  value={this.state.data.polygamous_spouses}
                  control={(<Input type="text" placeholder="Spouses" fluid/>)}
                  hint={<Loc msg="households_fields_polygamous_spouses_hint"/>}/>}

                <Form.Field 
                  forceupdate
                  name="inas"
                  value={this.state.data.inas}
                  control={(<Checkbox checked={this.state.data.inas} label={<Loc msg="households_fields_inas_label"/>}/>)}/>
              </Block>

              <Block title={<Loc msg="households_block_vulnerabilities"/>}>
                <Form.Field 
                  name="vulnerability_singleheaded" value={this.state.data.vulnerability_singleheaded} forceupdate
                  control={(<Checkbox checked={this.state.data.vulnerability_singleheaded} label={<Loc msg="households_vulnerabilities_singleheaded"/>}/>)}/>
                <Form.Field 
                  name="vulnerability_femaleheaded" value={this.state.data.vulnerability_femaleheaded} forceupdate
                  control={(<Checkbox checked={this.state.data.vulnerability_femaleheaded} label={<Loc msg="households_vulnerabilities_femaleheaded"/>}/>)}/>
                <Form.Field 
                  name="vulnerability_orphan" value={this.state.data.vulnerability_orphan} forceupdate
                  control={(<Checkbox checked={this.state.data.vulnerability_orphan} label={<Loc msg="households_vulnerabilities_orphan"/>}/>)}/>
                <Form.Field 
                  name="vulnerability_elderly" value={this.state.data.vulnerability_elderly} forceupdate
                  control={(<Checkbox checked={this.state.data.vulnerability_elderly} label={<Loc msg="households_vulnerabilities_elderly"/>}/>)}/>
                <Form.Field 
                  name="vulnerability_mental" value={this.state.data.vulnerability_mental} forceupdate
                  control={(<Checkbox checked={this.state.data.vulnerability_mental} label={<Loc msg="households_vulnerabilities_mental"/>}/>)}/>
                <Form.Field 
                  name="vulnerability_physical" value={this.state.data.vulnerability_physical} forceupdate
                  control={(<Checkbox checked={this.state.data.vulnerability_physical} label={<Loc msg="households_vulnerabilities_physical"/>}/>)}/>
                <Form.Field 
                  name="vulnerability_illness" value={this.state.data.vulnerability_illness} forceupdate
                  control={(<Checkbox checked={this.state.data.vulnerability_illness} label={<Loc msg="households_vulnerabilities_illness"/>}/>)}/>
                <Form.Field 
                  name="vulnerability_income" value={this.state.data.vulnerability_income} forceupdate
                  control={(<Checkbox checked={this.state.data.vulnerability_income} label={<Loc msg="households_vulnerabilities_income"/>}/>)}/>
                <Form.Field 
                  name="vulnerability_other" value={this.state.data.vulnerability_other} forceupdate
                  control={(<Checkbox checked={this.state.data.vulnerability_other} label={<Loc msg="households_vulnerabilities_other"/>}/>)}/>
                {this.state.data.vulnerability_other == true && <Form.Field 
                  required={{message: <Loc msg="households_fields_vulnerability_other_required"/>}}
                  width={1} label={<Loc msg="households_fields_vulnerability_other_label"/>}
                  name="vulnerability_other_description" 
                  value={this.state.data.vulnerability_other_description}
                  control={(<Input type="text" placeholder="Description" fluid/>)}
                  hint={<Loc msg="households_fields_vulnerability_other_hint"/>}/>}
              </Block>

            </React.Fragment>  

            <Block title={<Loc msg="households_block_programs"/>}>
              <Form.Field 
                required={{message: <Loc msg="households_fields_programs_required"/>}}
                label={<Loc msg="households_fields_programs_label"/>}
                name="programs" value={this.state.data.programs}
                control={(<MarkdownTextarea rows={20} fluid label_text={<Loc msg="markdown_text"/>} label_preview={<Loc msg="markdown_preview"/>} label_markdown={<Loc msg="markdown_is_supported"/>}/>)}
                hint={<Loc msg="households_fields_programs_hint"/>}/>
            </Block>

          </Flex.Columns>
        </Section>
      </Form>
    )
  }
}

export { HouseholdForm };
