import * as React from 'react'
import axios from 'axios';

import { Card } from '@independent-software/typeui/controls/Card';
import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Loc } from '../../modules';
import { App } from '../../App';
import { Action } from '../../resource';
import { IAuthProps } from '../../services';
import styled from '@independent-software/typeui/styles/Theme';
import { Label } from '@independent-software/typeui/controls/Label';

interface IProps {
  children?: React.ReactNode;
  action: Action;
  doctype: string;
  available: boolean;
}

class DocumentCard extends React.Component<IAuthProps & IProps> {
  private handleDownload = (doctype: string) => {
    axios.get(`${App.apiURL}action/download/${this.props.action.id}/${doctype}?api_token=${this.props.auth.token}`, { responseType: 'blob'}) 
    .then(response => {
      // Find the content-disposition header.
      let disposition:string = response.headers['content-disposition'];
      // Using a regexp, retrieve the filename from it.
      let regexp = new RegExp('\"(.*)\"');
      let res:RegExpExecArray = regexp.exec(disposition);
      let filename = res[1];
      // Download the file.
      saveAs(response.data, filename);
    })
    .catch(error => {
    });    
  }
  
  render() {
    let p = this.props;
    return (
      <Card>
        <Card.Header>
          <TitleBlock>
            {p.children}
          </TitleBlock>
        </Card.Header>
        <Card.Meta>
          {p.available == true && <div style={{textAlign: 'right'}}>
            <Button size="small" icon onClick={() => this.handleDownload(p.doctype)}><Icon title="Download" name="download"/>&nbsp;<Loc msg="btn_download"/></Button>
          </div>}
          {p.available == false && <Label basic><Loc msg="actions_text_document_unavailable"/></Label>}
        </Card.Meta>
      </Card>
    );
  }
}

const TitleBlock = styled('div')`
  min-height: 55px;
`

export { DocumentCard }