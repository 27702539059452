import * as React from 'react';
import axios from 'axios';
import { Accordion } from '@independent-software/typeui/controls/Accordion';
import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Image } from '@independent-software/typeui/controls/Image';
import { Label } from '@independent-software/typeui/controls/Label';
import { Message } from '@independent-software/typeui/controls/Message';
import { Table } from '@independent-software/typeui/controls/Table';
import { Filesize } from '@independent-software/typeui/formatters/Filesize';
import { App } from '../../App';
import { Loc, MultiUpload } from '../../modules';
import { Action, IActionFile } from "../../resource";
import { IAuthProps } from '../../services';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Divider } from '@independent-software/typeui/controls/Divider';
import { Form } from '@independent-software/typeui/controls/Form';
import { Input } from '@independent-software/typeui/controls/Input';

interface IProps {
  action:  Action;
  onChange: () => void;
}

interface IState {
  previewOpen: boolean;
  uploadOpen: boolean;
  deleteOpen: boolean;
  editOpen: boolean;
  file: IActionFile;
  filename: string;
}


class ImplementationManager extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);
    this.state = {
      previewOpen: false,
      uploadOpen: false,
      deleteOpen: false,
      editOpen: false,
      file: null,
      filename: null
    }
  }

  handleDownload = (file: IActionFile) => {
    axios.get(`${App.apiURL}action/download-implementation/${file.id}?api_token=${this.props.auth.token}`, { responseType: 'blob'}) 
    .then(response => {
      // Find the content-disposition header.
      let disposition:string = response.headers['content-disposition'];
      // Using a regexp, retrieve the filename from it.
      let regexp = new RegExp('\"(.*)\"');
      let res:RegExpExecArray = regexp.exec(disposition);
      let filename = res[1];
      // Download the file.
      saveAs(response.data, filename);
    })
    .catch(error => {
    });    
  }

  private isFilenameValid = () => {
    if (!/^[a-zA-Z0-9À-ž\-_, ]+\.[a-zA-Z0-9]+$/.test(this.state.filename)) return false;
    return this.state.filename.length >= 3;
  }

  private handleOpenPreview = (file: IActionFile) => {
    this.setState({
      previewOpen: true,
      file: file
    });
  }

  private handleClosePreview = () => {
    this.setState({
      previewOpen: false
    });
  }

  handleOpenUpload = () => {
    this.setState({
      uploadOpen: true
    })
  }    

  handleCloseUpload = () => {
    this.setState({
      uploadOpen: false
    });
  }

  handleUpload = () => {
    this.props.onChange();
  }

  handleOpenDelete = (file: IActionFile) => {
    this.setState({
      previewOpen: false,
      deleteOpen: true,
      file: file
    });
  }

  handleOpenDeletePreview = () => {
    this.setState({
      previewOpen: false,
      deleteOpen: true
    });    
  }

  handleOpenEdit = (file: IActionFile) => {
    this.setState({
      previewOpen: false,
      editOpen: true,
      file: file,
      filename: file.filename
    })
  }

  handleOpenEditPreview = () => {
    this.setState({
      previewOpen: false,
      editOpen: true,
      filename: this.state.file.filename
    });        
  }

  handleDelete = () => {
    this.setState({
      deleteOpen: false
    });
    axios.get(`${App.apiURL}action/delete-implementation/${this.state.file.id}?api_token=${this.props.auth.token}`) 
    .then(response => {
      this.props.onChange();
    })
    .catch(error => {
    });    
  }

  handleSaveEdit = () => {
    this.setState({
      editOpen: false
    });
    axios.get(`${App.apiURL}action/edit-implementation/${this.state.file.id}?filename=${this.state.filename}&api_token=${this.props.auth.token}`) 
    .then(response => {
      this.props.onChange();
    })
    .catch(error => {
    });    
  }

  render() {
    let p = this.props;

    let documents = p.action.files.filter(a => !a.is_image);
    let images = p.action.files.filter(a => a.is_image);

    return (
      <React.Fragment>
        <Accordion multiple active={[0,1]}>
          <Accordion.Tab title={<span><Loc msg="attachmentmanager_documents"/> <Label size="tiny">{documents.length}</Label></span>}>
            {documents.length == 0 && 
              <Message type="info">
                <Loc msg="attachmentmanager_no_documents"/>
              </Message>}
            {documents.length > 0 && 
              <Table striped>
                <thead>
                  <tr>
                    <th>File</th>
                    <th>Size</th>
                    <th style={{width:'120px'}}>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {documents.map((file: any, index: number) => 
                    <tr key={index}>
                      <td>{file.filename}</td>
                      <td><Filesize value={file.size}/></td>
                      <td>
                        <Button icon size="small" onClick={() => this.handleDownload(file)}><Icon title="Download" name="download"/></Button>
                        {p.auth && p.auth.hasRight('can_edit_actions') &&
                          <Button icon size="small" onClick={() => this.handleOpenEdit(file)}><Icon title="Edit" name="edit"/></Button>}
                        {p.auth && p.auth.hasRight('can_edit_actions') &&
                          <Button negative icon size="small" onClick={() => this.handleOpenDelete(file)}><Icon title="Delete" name="trash"/></Button>}
                      </td>
                    </tr>)}
                </tbody>
              </Table>}
          </Accordion.Tab>

          <Accordion.Tab title={<span><Loc msg="attachmentmanager_images"/> <Label size="tiny">{images.length}</Label></span>}>
            {images.length == 0 && 
              <Message type="info">
                <Loc msg="attachmentmanager_no_images"/>
              </Message>}
            {images.length > 0 && 
              <Image.Group 
                size="small"
                bordered
                rounded>
              {images.map((file: any) => 
                <Image key={file.id} onClick={() => this.handleOpenPreview(file)} src={`${App.apiURL}action/download-implementation/${file.id}?api_token=${p.auth.token}&size=t`}/>
                )}
              </Image.Group>
            }
          </Accordion.Tab>
        </Accordion>

        {/*
          * Upload attachment
          */}
        {p.auth && p.auth.hasRight('can_edit_actions') && 
        <React.Fragment>
          <Divider hidden/>
          <div style={{textAlign:'right'}}>
            <Button onClick={this.handleOpenUpload}><Icon name="plus"/> <Loc msg="attachmentmanager_add_attachment"/></Button>
          </div>
          <MultiUpload auth={p.auth} onSuccess={this.handleUpload} open={this.state.uploadOpen} title={<Loc msg="attachmentmanager_upload_attachments"/>} url={`${App.apiURL}action/upload/${p.action.id}`} onClose={this.handleCloseUpload}/>
        </React.Fragment>}

        {/* 
          * Image preview dialog
          */}
        <Dialog width={800} open={this.state.previewOpen} onClose={this.handleClosePreview}>
          <Dialog.Header>
            {this.state.file && this.state.file.filename}
          </Dialog.Header>
          <Dialog.Content>
            {this.state.file && 
              <Image rounded src={`${App.apiURL}action/download-implementation/${this.state.file.id}?api_token=${p.auth.token}&size=p`}/>
            }
          </Dialog.Content>
          <Dialog.Footer>
            {this.state.file && 
              <div style={{float: 'left'}}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Button primary onClick={() => this.handleDownload(this.state.file)}><Icon name="download"/> <Loc msg="btn_download"/> <Label attached="right"><Filesize value={this.state.file.size}/></Label></Button>
                </div>
              </div>}
            {p.auth && p.auth.hasRight('can_edit_actions') && 
              <Button onClick={this.handleOpenEditPreview}><Loc msg="btn_edit"/></Button>}
            {p.auth && p.auth.hasRight('can_edit_actions') && 
              <Button negative onClick={this.handleOpenDeletePreview}><Loc msg="btn_delete"/></Button>}
            <Button secondary onClick={this.handleClosePreview}><Loc msg="btn_close"/></Button>
          </Dialog.Footer>
        </Dialog>    

        {/*
          * Delete file dialog
          */}
        <Dialog width={800} open={this.state.deleteOpen} onClose={() => this.setState({ deleteOpen: false })}>
          <Dialog.Header><Loc msg="attachmentmanager_edit_attachment"/></Dialog.Header>
          <Dialog.Content>
            Are you sure you wish to delete this file?
          </Dialog.Content>
          <Dialog.Footer>
            <div style={{float:'left'}}>
              <Button onClick={() => this.setState({ deleteOpen: false })}><Loc msg="btn_cancel"/></Button>
            </div>
            <Button negative onClick={this.handleDelete}><Loc msg="btn_ok"/></Button>            
          </Dialog.Footer>
        </Dialog>

        {/*
          * Rename file dialog
          */}
        <Dialog width={800} open={this.state.editOpen} onClose={() => this.setState({ editOpen: false })}>
          <Dialog.Header><Loc msg="attachmentmanager_edit_attachment"/></Dialog.Header>
          <Dialog.Content>
            <Form.Uncontrolled 
              hint={this.isFilenameValid() ? <Loc msg="attachmentmanager_edit_attachment_hint" /> : <Loc msg="attachmentmanager_edit_attachment_error"/>}>
              <Input fluid placeholder="Filename" value={this.state.filename} error={this.isFilenameValid() ? null : true} onChange={(value: string) => this.setState({ filename: value })}/>
            </Form.Uncontrolled>
          </Dialog.Content>
          <Dialog.Footer>
            <div style={{float:'left'}}>
              <Button onClick={() => this.setState({ editOpen: false })}><Loc msg="btn_cancel"/></Button>
            </div>
            <Button secondary disabled={this.isFilenameValid() ? null : true} onClick={this.handleSaveEdit}><Loc msg="btn_ok"/></Button>            
          </Dialog.Footer>
        </Dialog>

      </React.Fragment>
    );
  }

}

export { ImplementationManager }