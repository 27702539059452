import * as React from 'react';
import { LanguageContext } from '../../modules';
import { Datum } from '@independent-software/typeui/formatters/Datum';

interface IProps {
  value: Date | string;
  strict?: boolean;
}

class DistanceDate extends React.Component<IProps, {}> {
  render() {
    return (
      <LanguageContext.Consumer>
        {({locale}) => {
          return <Datum.Distance value={this.props.value} locale={locale} strict={this.props.strict}/>
        }}
      </LanguageContext.Consumer>
    );   
  }
}

export { DistanceDate };
