import * as React from 'react';
import * as L from 'leaflet';
import styled from '@independent-software/typeui/styles/Theme'
import { css } from 'styled-components';

interface IMapProps {
  className?: string;
  children?: React.ReactNode;
  height?: number;
  latitude: number;
  longitude: number;
}

class MapBase extends React.Component<IMapProps, {}> {
  private mapElement: HTMLDivElement;
  private map: L.Map;
  private marker: L.CircleMarker;

  // Parse latitude or longitude to a number.
  // Unparsable values return 0.
  parseNumber = (number: any) => {
    let value = parseFloat(number);
    if(isNaN(value)) value = 0;
    return value;
  }

  componentDidMount() {
    this.map = L.map(this.mapElement, {
      center: this.props.latitude == null ? [0,0] : [this.props.latitude, this.props.longitude],
      zoom: 10,
      layers: [
        L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          maxZoom: 18
        }),
      ]
    });
    this.createMarker();
    this.setMarker(L.latLng(this.parseNumber(this.props.latitude), this.parseNumber(this.props.longitude)));
  }

  componentDidUpdate(prevProps: IMapProps) {
    // Move the marker when lat/lng change:
    if(this.props.latitude !== prevProps.latitude || this.props.longitude !== prevProps.longitude) {
      this.setMarker(L.latLng(this.parseNumber(this.props.latitude), this.parseNumber(this.props.longitude)));
    }
  }

  private createMarker = () => {
    let circleOptions = {
      radius: 5, 
      fillColor: 'slateblue', 
      color: 'darkblue', 
      weight: 1
    };
    this.marker = L.circleMarker([0,0], circleOptions);
  }

  private setMarker = (position: L.LatLng) => {
    if(position) {
      this.marker.setLatLng(position).addTo(this.map);
      this.map.panTo(position);
    } else {
      this.marker.remove();
    }
  }

  render() {
    let p = this.props;
    return (
      <div className={p.className} ref={(el:any) => this.mapElement = el}>{p.children}</div>
    );
  }
}

const MapStyled = styled(MapBase)`
  width: 100%;
  height: ${p => p.height ? p.height : 500}px;
  border-radius: ${p => p.theme.radius}px;
`;

class Map extends React.Component<IMapProps, {}> {
  public static displayName = "Map";

  render() {
    let p = this.props;
    return (
      <MapStyled {...p}></MapStyled>
    )
  }
}

export { Map };