import * as React from 'react';
import { CurrentPngProps } from "recharts-to-png";

import { COLORS, TCalculation } from '../definitions';
import { ResponsiveContainer, Tooltip, Legend, PieChart, Pie, Cell, LegendPayload, Label, Text, TextProps } from 'recharts';
import { Loc } from '../../../modules';
import { ChartData } from '../ChartData';
import { CustomTooltip } from './CustomTooltip';

interface IProps {
  name: string;
  unit?: string;
  chartdata: ChartData;
  calc: TCalculation;
  donut: boolean;
  langdata: any;
  onRegister: (f: () => void) => void; // Register export method with parent.
}

class IndicatorPieChart extends React.Component<IProps & CurrentPngProps> {
  componentDidMount = () => {
    this.props.onRegister(this.handleExport);
  }

  handleExport = async () => {
    const png = await this.props.getPng();
    if (png) saveAs(png, "chart.png");
  }

  // If there are multiple pies, then the legend may contain duplicate values.
  // To resolve this, we extract all categories from the data into a set
  // (to retain only unique values), which is then fed into Legend.  
  getLegendPayload = (langdata: any) => {
    let legendData: LegendPayload[] = this.props.chartdata.getUniqueCategories().map((k, index) => {
      let translation = Loc.getText(langdata, ("cat_" + this.props.name + "_" + k) as any);
      if(translation == '?' && k == "98") translation = Loc.getText(langdata, "cat_98" as any);
      if(translation == '?' && (k == "99" || k == "999")) translation = Loc.getText(langdata, "cat_99" as any);
      if(translation == '?') translation = k.toString();             
      return {
        value: <>{translation} <Loc msg={this.props.unit as any}/></>,
        type: 'circle',
        id: index,
        color: COLORS[index % COLORS.length]
      };
    });    
    return legendData;
  }

  render() {
    let p = this.props;

    // The outer radius of each pie depends on the number of pies.
    let outerRadius = Math.min(80,80/(p.chartdata.length()/1.5));

    // For a pie, the innerRadius is 0.
    // For a donut, the innerRadius is 80% of the outerRadius.
    let innerRadius = p.donut ? outerRadius * 0.8 : 0;

    return (
      <>
      <ResponsiveContainer width="100%" height={500}>
        <PieChart ref={this.props.chartRef} width={500} height={300} margin={{ top: 20, right: 20, left: 20, bottom: 20 }}>
          <Legend payload={this.getLegendPayload(p.langdata)}/>
          <Tooltip isAnimationActive={false} content={<CustomTooltip calc={p.calc}/>}/>
          {p.chartdata.getData(p.calc, p.langdata /*langdata*/, true).map((dp, index) => {
            const pdata = Object.keys(dp).filter((c) => c != 'name').map((k) => {
              let translation = Loc.getText(p.langdata, ("cat_" + p.name + "_" + k) as any);
              if(translation == '?' && k == "98") translation = Loc.getText(p.langdata, "cat_98" as any);
              if(translation == '?' && (k == "99" || k == "999")) translation = Loc.getText(p.langdata, "cat_99" as any);
              if(translation == '?') translation = k.toString();                
              return {
                cat: k,
                name: <>{translation} <Loc msg={this.props.unit as any}/></>,
                value: dp[k]
              }
            });
            return <Pie
              key={index}
              data={pdata}
              dataKey="value"
              cx={`${100/p.chartdata.length()*(index+1)-100/(p.chartdata.length()*2)}%`}
              cy="50%"
              outerRadius={`${outerRadius}%`}
              innerRadius={`${innerRadius}%`}
              label
            >
              {pdata.map((entry, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]}/>
              ))}

              {/* 
                Draw several shadow labels for good contrast.
                Use different offsets.
              */}
              {[ {dx: -1, dy:-1}, {dx:  1, dy: 1}, {dx: -1, dy: 1}, {dx:  1, dy:-1} ].map((offset, index) => 
              <Label
                key={index}
                content={props => {
                  const {viewBox: {cx, cy}} = props
                  const positioningProps = {
                    x: cx + offset.dx,
                    y: cy + offset.dy,
                    textAnchor: 'middle',
                    verticalAnchor: 'middle'
                  } as TextProps
                  const presentationProps = {
                    fill: "black",
                  }

                  return (
                    <Text {...positioningProps} {...presentationProps}>{dp.name}</Text>
                  )
                }}
              />)}

              {/* Draw a white label. */}
              <Label
                content={props => {
                  const {viewBox: {cx, cy}} = props
                  const positioningProps = {
                    x: cx,
                    y: cy,
                    textAnchor: 'middle',
                    verticalAnchor: 'middle'
                  } as TextProps
                  const presentationProps = {
                    fill: "white",
                  }

                  return (
                    <Text {...positioningProps} {...presentationProps}>{dp.name}</Text>
                  )
                }}
              />
          
            </Pie>
          })}
        </PieChart>
      </ResponsiveContainer>   
      </>
    );
  }
}

export { IndicatorPieChart}