import * as React from 'react';
import { Segment } from '@independent-software/typeui/controls/Segment';

class Block extends React.Component<{title: string}, {}> {
  render = () => {
    return (
      <React.Fragment>
        <Segment raised attached="top">
          {this.props.title}
        </Segment>
        <Segment raised attached="bottom">
          {this.props.children}
        </Segment>
      </React.Fragment>
    )
  }
}

export { Block }