import { StampedModel, ResourceFactory } from '../services/';
import { Issuecategory } from './Issuecategory';

class Issuetype extends StampedModel {
  public name: string = '';
  public issuecategory: Issuecategory = null;
  public grievances_count: number = 0;
}

const IssuetypeFactory = ResourceFactory.create<Issuetype>(Issuetype, 'issuetype');

export { Issuetype, IssuetypeFactory };