import { StampedModel, ResourceFactory } from '../services/';
import { Community } from './Community';

interface ISurveyCommunity {
  community_id: number;
  community: Community;
  //factor: number;
  selected: boolean;
}

class Survey extends StampedModel {
  public name: string = '';
  public date: Date = null;
  public communities_count: number = 0;
  public responses_count: number = 0;
  public surveycommunities: ISurveyCommunity[] = [];
}

const SurveyFactory = ResourceFactory.create<Survey>(Survey, 'survey');

export { Survey, SurveyFactory };