import * as React from 'react';

// The QuestionContext stores the data
// and the question group/index that questions
// within the Context will work with.

interface IQuestionContext {
  data: any;
  group?: string;
  index?: number;
  group2?: string;
  index2?: number;
}

const QuestionContext = React.createContext<IQuestionContext | null>(null);

export { IQuestionContext, QuestionContext };
