import * as React from 'react';
import { Request, Attachment, Stakeholder, StakeholderFactory } from '../../resource/';
import { IAuthProps, Query } from '../../services/';

import { Input } from '@independent-software/typeui/controls/Input';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Form } from '@independent-software/typeui/controls/Form';
import { Section, AttachmentManager, Loc } from '../../modules';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Label } from '@independent-software/typeui/controls/Label';
import { MarkdownTextarea } from '@independent-software/typeui/modules/MarkdownTextarea';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';

interface IProps {
  /** Initial form data. */
  data: Request;
  /** Called whenever form changes. */
  onChange: (data: Request, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */  
  dirty?: boolean;
}

interface IState {
  /** Current form data. */
  data: Request;
  /** Data for Stakeholders dropdown */
  stakeholders: Stakeholder[];    
}

class RequestForm extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);

    // Intialize state.
    this.state = {
      data: props.data,
      stakeholders: []
    };    

    // Retrieve a list of Stakeholders:
    StakeholderFactory.getSome(this.props.auth, 0, 16, new Query('name', 'asc'))
      .then((res) => this.setState({ stakeholders: res.items }));    
  }

  handleStakeholderSearch = (q:string) => {
    // Retrieve a list of Stakeholders:
    let query = new Query('name', 'asc');
    query.setFilter('q', 'like', q);
    StakeholderFactory.getSome(this.props.auth, 0, 16, query)
      .then((res) => this.setState({ stakeholders: res.items }));
  }  

  handleChangeAttachments = (attachments: Attachment[]) => {
    this.setState((prevState) => {
      prevState.data.attachments = attachments;
      return prevState;
    });
  }  

  render() {
    let p = this.props;
    return (
      <Form
        data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
        <Tabs underlined>
          <Tabs.Pane label={<Loc msg="requests_form_tab_data"/>}>
            <Section padded>
              <Flex.Columns count={2}>
                <React.Fragment>
                  <Form.Field 
                    required={{message: <Loc msg="requests_fields_title_required"/>}}
                    minLength={{length: 3, message: <Loc msg="requests_fields_title_minlength"/>}}
                    maxLength={{length: 1000, message: <Loc msg="requests_fields_title_maxlength"/>}}
                    width={1} label={<Loc msg="requests_fields_title_label"/>}
                    name="title" 
                    value={this.state.data.title}
                    control={(<Input type="text" placeholder="Title" fluid/>)}
                    hint={<Loc msg="requests_fields_title_hint"/>}/>
                  <Form.Field 
                    required={{message: <Loc msg="requests_fields_date_required"/>}}
                    width={1} label={<Loc msg="requests_fields_date_label"/>}
                    name="date" 
                    value={this.state.data.date}
                    control={(<Input type="date" format="eee, d MMMM yyyy" placeholder="Date"/>)}
                    hint={<Loc msg="requests_fields_date_hint"/>}/>
                    
                  <Form.Field
                    required={{message: <Loc msg="requests_fields_stakeholder_required"/>}}
                    width={1} label={<Loc msg="requests_fields_stakeholder_label"/>}
                    name="stakeholder" 
                    value={this.state.data.stakeholder}
                    control={(<Dropdown onSearch={this.handleStakeholderSearch} fluid data={this.state.stakeholders} placeholder="Stakeholder" label={(item:Stakeholder) => item.name}>
                      <Dropdown.Column weight={2}>{(item:Stakeholder) => item.name}</Dropdown.Column>
                      <Dropdown.Column weight={1}>{(item:Stakeholder) => item.phone ? item.phone : '-'}</Dropdown.Column>
                    </Dropdown>)}
                    hint={<Loc msg="requests_fields_stakeholder_hint"/>}/>

                </React.Fragment>
                <React.Fragment>
                  <Form.Field 
                    label={<Loc msg="requests_fields_description_label"/>}
                    name="description" value={this.state.data.description}
                    control={(<MarkdownTextarea rows={20} fluid label_text={<Loc msg="markdown_text"/>} label_preview={<Loc msg="markdown_preview"/>} label_markdown={<Loc msg="markdown_is_supported"/>}/>)}
                    hint={<Loc msg="requests_fields_description_hint"/>}/>
                </React.Fragment>
              </Flex.Columns>
            </Section>
          </Tabs.Pane>
          <Tabs.Pane label={<React.Fragment>{<Loc msg="requests_form_tab_attachments"/>} <Label size="small">{this.state.data.attachments.length}</Label></React.Fragment>}>
            <Section padded>
              <AttachmentManager auth={p.auth} attachments={this.state.data.attachments} onChange={this.handleChangeAttachments}/>
            </Section>
          </Tabs.Pane>
        </Tabs>

      </Form>
    )
  }
}

export { RequestForm };
