import * as React from 'react';
import { Container, Content, BottomBar, Section, Timestamp } from '../../modules';
import { IAuthProps } from '../../services/Auth';
import { Resource, StampedModel, Model } from '../../services';

import { Button } from '@independent-software/typeui/controls/Button';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Loader } from '@independent-software/typeui/controls/Loader';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Message } from '@independent-software/typeui/controls/Message';
import { Loc } from '../Loc';

type TStep = 'loading' | 'loadError' | 'ready' | 'confirm' | 'deleting' | 'deleteError' | 'error';

interface IViewerProps<T> {
  className?: string;
  /** Unique ID of resource to load using Factory. */
  id: number;
  /** Factory to use to load resource. */
  factory: Resource<T>;
  /** Given a resource instance, this callback determines if it can be edited. */
  canEdit: (item: any) => boolean;
  canDelete?: (item: any) => boolean;
  /** Callback is called with a resource instance. It should return a view template. */
  content: (item: any) => React.ReactNode;
  /** Callback is called with a resource instance. It should return any controls
   *  to be shown in the bottom bar. */
  controls?: (item: any) => React.ReactNode;
  /** Message to show for deletion confirmation */
  confirmMessage?: React.ReactNode;
  item?: T;

  // Callbacks
  /** Callback is called when editing is requested. */
  onEdit: () => void;
  /** Callback is called after deletion. */
  onDelete: () => void;
  /** Callback is called after successful load. */
  onLoad?: (item: any) => void;
}

interface IViewerState {
  item: Model;
  step: TStep;
  error: any;
}

class Viewer<T extends Model> extends React.Component<IViewerProps<T> & IAuthProps, IViewerState> {
  constructor(props: IViewerProps<T> & IAuthProps) {
    super(props);
    this.state = {
      item: null,
      step: 'loading',
      error: null
    };
  }

  componentDidMount = () => {
    this.loadItem();
  }

  componentDidUpdate = (prevProps: IViewerProps<T>) => {
    if(prevProps.item != this.props.item) this.setState({ item: this.props.item });
  }

  private loadItem = () => {
    this.props.factory.get(this.props.auth, this.props.id)
    .then((item) => {
      this.setState({
        step: 'ready',
        item: item
      });
      if(this.props.onLoad) this.props.onLoad(item);
    })
    .catch(error => {
      this.setState({
        step: 'loadError',
        error: error
      })
    });
  }

  private goto = (step:TStep) => {
    this.setState({ step: step});
  }  

  private handleEdit = () => {
    this.props.onEdit();
  }

  private handleCancelLoad = () => {
    this.setState({ step: 'error' });
  }

  private handleRetry = () => {
    this.setState({error: null, step: 'loading'});
    this.loadItem();
  }  

  private handleDelete = () => {
    this.setState({ step: 'deleting', error: null});
    this.state.item.$delete(this.props.auth)
      .then(res => {
        this.props.onDelete();
      })
      .catch(error => {
        this.setState({
          step: 'deleteError',
          error: error
        })
      })
  }      

  render() {
    let p = this.props;
    return (
      <Container>
        {(this.state.step == 'loading' || this.state.step == 'loadError' || this.state.step == 'deleting') && 
          <Loader/>}
        {this.state.step != 'loading' && this.state.step != 'loadError' && this.state.step != 'error' && 
        <React.Fragment>
          <Content>
            {p.content(this.state.item)}
          </Content>
          <BottomBar>
            <div>
              {p.canEdit(this.state.item) && <Button primary onClick={this.handleEdit}><Icon name="edit"/> <Loc msg="btn_edit"/></Button>}
              {p.controls && p.controls(this.state.item)}
            </div>
            <div>
              {(p.canEdit(this.state.item) || (p.canDelete && p.canDelete(this.state.item))) && <Button negative onClick={() => this.goto('confirm')}><Icon name="trash"/> <Loc msg="btn_delete"/></Button>}
              {this.state.item instanceof StampedModel && <Timestamp model={this.state.item}/>}
            </div>
          </BottomBar>
        </React.Fragment>}
        {this.state.step == 'error' && 
        <Content>
          <Section padded>
            <Message type="error"><Loc msg="msg_data_not_found"/></Message>
          </Section>
        </Content>}        
        <Dialog.Xhr open={this.state.step == 'loadError'} error={this.state.error} onClose={this.handleCancelLoad} onRetry={this.handleRetry}/>    
        <Dialog.Xhr open={this.state.step == 'deleteError'} error={this.state.error} onClose={() => this.goto('ready')} onRetry={this.handleDelete}/>
        <Dialog.Confirm open={this.state.step == 'confirm'} onClose={() => this.goto('ready')} onConfirm={this.handleDelete}>
          {p.confirmMessage && <React.Fragment>{p.confirmMessage}</React.Fragment>}
          {!p.confirmMessage && <React.Fragment><Loc msg="msg_confirm_deletion"/></React.Fragment>}
        </Dialog.Confirm>

      </Container>
    );
  }  
}

export { Viewer };

