import * as React from 'react';
import axios from 'axios';
import { Section, Block, Loc, AttachmentViewer, Container, BottomBar, Timestamp, Content } from '../../modules';
import { Action, ActionFactory, Post, TActionStates } from '../../resource/'; 
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';
import { TLocalization } from '../../modules/Loc/TLocalization';

import { Markdown } from '@independent-software/typeui/formatters/Markdown';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Header } from '@independent-software/typeui/controls/Header';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Label } from '@independent-software/typeui/controls/Label';
import { Button } from '@independent-software/typeui/controls/Button';
import { Table } from '@independent-software/typeui/controls/Table';
import { AddPost } from '../Post/AddPost';
import { PostView } from '../Post/PostView';
import { Loader } from '@independent-software/typeui/controls/Loader';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Message } from '@independent-software/typeui/controls/Message';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { DocumentCard } from './DocumentCard';
import { CloseDialog } from './dialogs/CloseDialog';
import { OpenDialog } from './dialogs/OpenDialog';
import { ImplementationManager } from './ImplementationManager';
import { LocalizedDatum } from '../../formatters';
import { differenceInDays } from 'date-fns/esm';
import { parse } from 'date-fns';
import { Number } from '@independent-software/typeui/formatters/Number';
import { StepTable } from './StepTable';
import { App } from '../../App';
import { ReopenDialog } from './dialogs/ReopenDialog';

type TStep = 'loading' | 'loadError' | 'ready' | 'confirm' | 'deleting' | 'deleteError' | 'error' | 'saveError';

interface IState {
  step: TStep;
  action: Action;
  error: any;
  opening: boolean;
  closing: boolean;
  stateDialog: TActionStates | 'reopen';
  validateDialog: boolean;
  rollbackDialog: boolean;
  forceMoveDialog: boolean;
  forcemove: TActionStates;
}

class ViewAction extends React.Component<IAuthProps & RouteComponentProps<{id:string}>, IState> {
  constructor(props: IAuthProps & RouteComponentProps<{id:string}>) {
    super(props);
    this.state = {
      step: 'loading',
      action: null,
      error: null,
      opening: false,
      closing: false,
      stateDialog: null,
      validateDialog: false,
      rollbackDialog: false,
      forceMoveDialog: false,
      forcemove: null
    };
  }

  componentDidMount() {
    this.loadItem();
  }

  private loadItem = () => {
    this.setState({ step: 'loading' });
    ActionFactory.get(this.props.auth, parseInt(this.props.match.params.id))
    .then((item) => {
      this.setState({
        step: 'ready',
        action: item
      });
    })
    .catch(error => {
      this.setState({
        step: 'loadError',
        error: error
      })
    });
  }

  private goto = (step:TStep) => {
    this.setState({ step: step});
  }  

  private handleCancelLoad = () => {
    this.setState({ step: 'error' });
  }

  private handleCancelSave = () => {
    this.setState({ step: 'ready' });
  }

  private handleRetry = () => {
    this.setState({error: null, step: 'loading'});
    this.loadItem();
  }  

  private handleEdit = () => {
    this.props.history.push(`/actions/edit/${parseInt(this.props.match.params.id)}`);
  }

  private handleDelete = () => {
    this.setState({ step: 'deleting', error: null});
    this.state.action.$delete(this.props.auth)
      .then(res => {
        ToastService.toast(<Loc msg="actions_msg_deleted"/>);
        this.props.history.push('/actions');        
      })
      .catch(error => {
        this.setState({
          step: 'deleteError',
          error: error
        })
      })
  }      

  private handleFail = (error: any) => {
    this.setState({ closing: false, opening: false, error: error, step: 'saveError'});
  }

  handleUpdateState = () => {
    ToastService.toast(<Loc msg="actions_msg_updated"/>);
    this.loadItem();
    this.setState({ stateDialog: null, action: this.state.action });
  }  

  handleValidate = () => {
    this.setState({ step: 'loading', validateDialog: false });
    let action = this.state.action;
    axios.post(`${App.apiURL}action/validate/${action.id}`, { api_token: this.props.auth.token })
    .then(response => {
      action.state = action.newstate;
      action.newstate = null;
      this.setState({ action: action });
      ToastService.toast(<Loc msg="actions_msg_validated"/>);
      this.loadItem();
    })
    .catch(error => {
      this.setState({ stateDialog: null, error: error, step: 'saveError'});
    })
    .finally(() => {
      this.setState({ step: 'ready' });
    });
  }

  handleRollback = () => {
    this.setState({ step: 'loading', rollbackDialog: false });
    let action = this.state.action;
    axios.post(`${App.apiURL}action/rollback/${action.id}`, { api_token: this.props.auth.token })
    .then(response => {
      action.newstate = null;
      this.setState({ action: action });
      ToastService.toast(<Loc msg="actions_msg_rolled_back"/>);
      this.loadItem();
    })
    .catch(error => {
      this.setState({ stateDialog: null, error: error, step: 'saveError'});
    })
    .finally(() => {
      this.setState({ step: 'ready' });
    });
  }

  private handleClickCommunity = (id: number) => {
    this.props.history.push(`/communities/${id}`);
  }

  private handleClickHousehold = (id: number) => {
    this.props.history.push(`/households/${id}`);
  }

  private handleAddPost = (post: Post) => {
    this.setState({
      action: {
        ...this.state.action,
        posts: [ ...this.state.action.posts, post ]
      }
    });
  }

  private handleUpdatePost = (post: Post) => {
    this.setState({
      action: {
        ...this.state.action,
        posts: [ ...this.state.action.posts ]
      }
    })
  }

  private handleDeletePost = (post: Post) => {
    let posts = this.state.action.posts;
    let idx = posts.indexOf(post);
    posts.splice(idx, 1);
    this.setState({ 
      action: {
        ...this.state.action,
        posts: posts
      }
    })
  }

  private handleUpload = () => {
    this.loadItem();
  }

  private handleTabChange = (idx: number) => {
    sessionStorage.setItem('action-view-tab', idx.toString());
  }
  
  private getActiveTab = (): number => {
    if(sessionStorage.getItem('action-view-tab') == null) return 0;
    let idx = parseInt(sessionStorage.getItem('action-view-tab'));
    return idx;
  }

  render() {
    let p = this.props;
    let action = this.state.action;
    return (
      <React.Fragment>
        <Container>
          {(this.state.step == 'loading' || this.state.step == 'loadError' || this.state.step == 'deleting') && 
            <Loader/>}
          {this.state.step != 'loading' && this.state.step != 'loadError' && this.state.step != 'error' && 
          <React.Fragment>
            <Content>
              <Section dark padded>
                <div style={{float: 'right', marginTop: '8px' }}>
                  <Label basic size="tiny"><Loc msg={("actions_workflow_" + action.state) as TLocalization}/></Label>
                </div>
                <Header size="h2">
                  {action.title}
                </Header>
              </Section>
              <Tabs underlined nohiddenrender onTabChange={this.handleTabChange} active={this.getActiveTab()}> 
                <Tabs.Pane label={<Loc msg="actions_form_tab_data"/>}>
                  <Section padded>
                    <Flex.Columns count={2}>
                      <React.Fragment>
                        {action.community && 
                        <Block title={<Loc msg ="actions_fields_community_label"/>}>
                          {action.community.name}
                          {p.auth.hasRight('can_view_communities') && 
                            <div style={{textAlign:'right'}}>
                              <Button secondary size="small" onClick={() => this.handleClickCommunity(action.community.id)}><Loc msg="btn_view"/></Button>
                            </div>
                          }
                        </Block>}
                        {action.household && 
                        <Block title={<Loc msg ="actions_fields_household_label"/>}>
                          <Label size="small">{action.household.code}</Label> {action.household.name} ({action.household.hhh_name})
                          {p.auth.hasRight('can_view_households') && 
                            <div style={{textAlign:'right'}}>
                              <Button secondary size="small" onClick={() => this.handleClickHousehold(action.household.id)}><Loc msg="btn_view"/></Button>
                            </div>
                          }
                        </Block>}                      

                        <Block title={<Loc msg ="actions_block_data"/>}>
                          <Table transparent striped>
                            <tbody>
                              <tr>
                                <td><Loc msg ="actions_fields_responsibility_label"/></td>
                                <td>
                                  {action.responsibility ? <Loc msg={("actions_responsibility_" + action.responsibility) as TLocalization}/> : '-'}
                                  {action.responsibility == 'provider' && <React.Fragment><br/>({action.serviceprovider})</React.Fragment>}
                                </td>
                              </tr>
                              <tr>
                                <td><Loc msg ="actions_fields_phase_label"/></td>
                                <td>{action.phase ? <Loc msg={("actions_phases_" + action.phase) as TLocalization}/> : '-'}</td>
                              </tr>
                              <tr>
                                <td><Loc msg ="actions_fields_startdate_label"/></td>
                                <td>{action.startdate ? <LocalizedDatum.LongDate value={action.startdate}/> : '-'}</td>
                              </tr>
                              <tr>
                                <td><Loc msg ="actions_fields_enddate_label"/></td>
                                <td>{action.enddate ? <LocalizedDatum.LongDate value={action.enddate}/> : '-'}</td>
                              </tr>
                              <tr>
                              <td><Loc msg ="actions_list_hdr_duration"/></td>
                                <td>{(action.enddate && action.startdate) ? <React.Fragment><Number value={differenceInDays(parse(action.enddate, 'yyyy-mm-dd', new Date()), parse(action.startdate, 'yyyy-mm-dd', new Date()))} decimals={0}/>d</React.Fragment> : '-'}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Block>                      

                        <Block title={<Loc msg ="actions_block_type"/>}>
                          <Table transparent striped>
                            <tbody>
                              <tr>
                                <td><Loc msg ="actiontypes_fields_level_label"/></td>
                                <td>{action.level ? <Loc msg={("actiontypes_level_" + action.level) as TLocalization}/> : '-'}</td>
                              </tr>                                                                                        
                              <tr>
                                <td><Loc msg ="actiontypes_fields_delivery_label"/></td>
                                <td>{action.delivery ? <Loc msg={("actiontypes_delivery_" + action.delivery) as TLocalization}/> : '-'}</td>
                              </tr>                                                          
                              <tr>
                                <td><Loc msg ="actions_fields_actiontype_label"/></td>
                                <td>{action.actiontype ? action.actiontype.name : '-'}</td>
                              </tr>                            
                            </tbody>
                          </Table>
                        </Block>                      

                      </React.Fragment>
                      <React.Fragment>
                        <Block title={<Loc msg ="actions_fields_description_label"/>}>
                          {action.description &&
                            <Markdown source={action.description}/>
                          }
                          {!action.description &&
                            <Loc msg="actions_fields_description_none"/>
                          }
                        </Block>
                      </React.Fragment>
                    </Flex.Columns>
                  </Section>
                </Tabs.Pane>

                <Tabs.Pane label={<Loc msg="actions_form_tab_documents"/>}>
                  <div style={{background:'#eee'}}>
                  <Section padded>
                    <DocumentCard available={(action.state != 'created' || action.newstate == 'open')} auth={this.props.auth} action={action} doctype="agreement"><Loc msg={"actions_docs_agreement"}/></DocumentCard>
                    <DocumentCard available={action.newstate == 'closed' || action.state == 'closed'} auth={this.props.auth} action={action} doctype="proof"><Loc msg={"actions_docs_proof"}/></DocumentCard>
                  </Section>
                  </div>
                </Tabs.Pane>

                <Tabs.Pane label={<span><Loc msg="actions_form_tab_implementation"/> <Label size="small">{action.files.length}</Label></span>}>
                  <Section padded>
                    <ImplementationManager action={action} auth={p.auth} onChange={this.handleUpload}/>
                  </Section>
                </Tabs.Pane>

                {/* <Tabs.Pane label={<span><Loc msg="actions_form_tab_attachments"/> <Label size="small">{action.attachments.length}</Label></span>}>
                  <Section padded>
                    <AttachmentViewer auth={p.auth} attachments={action.attachments}/>
                  </Section>
                </Tabs.Pane> */}

                <Tabs.Pane label={<span>{<Loc msg="actions_form_tab_discussion"/>} <Label size="small">{action.posts.length}</Label></span>}>
                  <Section padded>
                    {action.posts.map((post, index) => 
                      <PostView key={index} auth={p.auth} post={post} 
                        onSave={this.handleUpdatePost}
                        onDelete={this.handleDeletePost}/>
                    )}
                    <AddPost
                      auth={p.auth} onSave={this.handleAddPost}
                      type="action" type_id={this.state.action.id}/>
                  </Section>
                </Tabs.Pane>

                <Tabs.Pane label={<Loc msg="grievances_form_tab_history"/>}>
                  <Section padded>
                    <StepTable auth={p.auth} history={p.history} steps={action.steps}/>
                  </Section>
                </Tabs.Pane>                
              
              </Tabs>
            </Content>
            <BottomBar>
              <div>
                {p.auth && p.auth.hasRight('can_edit_actions') && <Button primary onClick={this.handleEdit}><Loc msg="btn_edit"/></Button>}
                {/* move actions */}
                {action.newstate == null && p.auth.hasRight('can_edit_actions') &&
                  <>
                    {this.state.action.state == 'created' && <Button primary onClick={() => this.setState({stateDialog: 'open'})}><Loc msg="actions_step_to_open"/></Button>}
                    {this.state.action.state == 'open' && <Button primary onClick={() => this.setState({stateDialog: 'closed'})}><Loc msg="actions_step_to_close"/></Button>}
                    {this.state.action.state == 'closed' && <Button primary onClick={() => this.setState({stateDialog: 'reopen'})}><Loc msg="actions_step_to_reopen"/></Button>}
                  </>}
                {/* validation actions */}
                {action.newstate != null && p.auth.hasRight('can_validate_actions') && 
                  <>
                    <Button positive onClick={() => this.setState({validateDialog: true})}><><Loc msg={"btn_validate"}/>&nbsp;<Icon name="chevron"/> <Loc msg={("actions_workflow_" + action.newstate) as TLocalization}/></></Button>
                    <Button negative onClick={() => this.setState({rollbackDialog: true})}><Loc msg={"btn_rollback"}/></Button>
                  </>}
              </div>
              <div>
                {p.auth && p.auth.hasRight('can_edit_actions') && <Button negative onClick={() => this.goto('confirm')}><Icon name="trash"/> <Loc msg="btn_delete"/></Button>}
                <Timestamp model={action}/>
              </div>
            </BottomBar>
          </React.Fragment>}
          {this.state.step == 'error' && 
          <Content>
            <Section padded>
              <Message type="error"><Loc msg="msg_data_not_found"/></Message>
            </Section>
          </Content>}        
        </Container>

        <Dialog.Xhr open={this.state.step == 'loadError'} error={this.state.error} onClose={this.handleCancelLoad} onRetry={this.handleRetry}/>    
        <Dialog.Xhr open={this.state.step == 'saveError'} error={this.state.error} onClose={this.handleCancelSave}/>    
        <Dialog.Xhr open={this.state.step == 'deleteError'} error={this.state.error} onClose={() => this.goto('ready')} onRetry={this.handleDelete}/>
        <Dialog.Confirm open={this.state.step == 'confirm'} onClose={() => this.goto('ready')} onConfirm={this.handleDelete}>
          <Loc msg="msg_confirm_deletion"/>
        </Dialog.Confirm>
        <Dialog.Confirm open={this.state.validateDialog == true} onClose={() => this.setState({validateDialog: false})} onConfirm={this.handleValidate}>
          <Loc msg="actions_text_confirm_validation"/>
        </Dialog.Confirm>
        <Dialog.Confirm open={this.state.rollbackDialog == true} onClose={() => this.setState({rollbackDialog: false})} onConfirm={this.handleRollback}>
          <Loc msg="actions_text_confirm_rollback"/>
        </Dialog.Confirm>
        <OpenDialog auth={p.auth} action={action} open={this.state.stateDialog == 'open'} onClose={() => this.setState({ stateDialog: null })} onExec={this.handleUpdateState} onFail={this.handleFail}/>
        <CloseDialog auth={p.auth} action={action} open={this.state.stateDialog == 'closed'} onClose={() => this.setState({ stateDialog: null })} onExec={this.handleUpdateState} onFail={this.handleFail}/>
        <ReopenDialog auth={p.auth} action={action} open={this.state.stateDialog == 'reopen'} onClose={() => this.setState({ stateDialog: null })} onExec={this.handleUpdateState} onFail={this.handleFail}/>
      </React.Fragment>              
    );
  }
}

export { ViewAction };
