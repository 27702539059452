import * as React from 'react';
import axios from 'axios';
import { IAuthProps } from '../../services';

import { Button } from '@independent-software/typeui/controls/Button'
import { Icon } from '@independent-software/typeui/controls/Icon'
import { Dialog } from '@independent-software/typeui/controls/Dialog'
import { Dropzone } from '@independent-software/typeui/controls/Dropzone'
import { Message } from '@independent-software/typeui/controls/Message'
import { Divider } from '@independent-software/typeui/controls/Divider'
import { Progress } from '@independent-software/typeui/controls/Progress'
import { Loc } from '../Loc';

interface IUploaderProps {
  className?: string;
  children?: React.ReactNode;
  open: boolean;
  url: string;
  title: React.ReactNode;
  onClose: () => void;
  onSuccess: () => void;
}

type TStep = 'input' | 'uploading' | 'error' | 'success';

interface IUploaderState {
  step: TStep;
  progress: number,
  response: string;
}

class Upload extends React.Component<IAuthProps & IUploaderProps, IUploaderState> {
  constructor(props: IAuthProps & IUploaderProps) {
    super(props);
    this.state = {
      step: 'input',
      progress: 0,
      response: null
    }
  }

  private handleClose = () => {
    // Reinitialize state. This is given a small timeout
    // so that state update isn't seen by user while dialog
    // is closing.
    setTimeout(() => this.setState({
      step: 'input'
    }), 300);
    // Close the dialog.
    this.props.onClose();
  }

  private handleUploadProgress = (e: ProgressEvent) => {
    this.setState({ 
      progress: Math.round(e.loaded * 100 / e.total)
    });
  }

  private handleAddFiles = (files: File[]) => {
    let file: File = files[0];
    let config = { onUploadProgress: this.handleUploadProgress };
    let data = new FormData();
    data.append('api_token', this.props.auth.token);
    data.append('file', file);
    axios.post(this.props.url, data, config)
      .then((res) => {
        this.props.onSuccess();
        this.setState({
          response: res.data,
          step: 'success'
        })
      })
      .catch((err) => {
        let response = "";
        if(err.response) {
          response = err.response.data;
        } else if (err.request) {
          response = "Error building server request.";
        } else {
          response = err.message;
        }
        this.setState({
          response: response,
          step: 'error'
        });
      });

    this.setState({
      step: 'uploading'
    });
  }

  render() {
    let p = this.props;
    return (
      <Dialog open={p.open}>
        <Dialog.Header>{p.title}</Dialog.Header>
        <Dialog.Content>
          {this.state.step == 'input' && <Dropzone onAddFiles={this.handleAddFiles}/>}
          {this.state.step != 'input' && 
            <Progress value={this.state.progress} numbered/>}
          {this.state.step == 'uploading' && 
            <React.Fragment>
              <Divider hidden/>
              <Message type="info" icon>
                <Icon loading name="circle-notch" size="big"/>              
                <Message.Content>
                  <Message.Header><Loc msg="upload_wait"/></Message.Header>
                  <Loc msg="upload_wait_info"/>
                </Message.Content>
              </Message>
            </React.Fragment>}
          {this.state.step == 'error' &&
            <React.Fragment>
              <Divider hidden/>
              <Message type="error">
                <Message.Header><Loc msg="upload_server_error"/></Message.Header>
                {this.state.response}
              </Message>
            </React.Fragment>}
          {this.state.step == 'success' && 
            <React.Fragment>
              <Divider hidden/>
              <Message type="success">
                <Message.Header><Loc msg="upload_success"/></Message.Header>
                {this.state.response}
              </Message>
            </React.Fragment>}          
        </Dialog.Content>
        <Dialog.Footer>
          <Button secondary onClick={this.handleClose}>
            {this.state.step == 'success' ? <Loc msg="btn_ok"/> : <Loc msg="btn_cancel"/>}
          </Button>
        </Dialog.Footer>
      </Dialog>
    );
  }
}

export { Upload };
