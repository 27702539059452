import * as React from 'react';
import { LanguageContext } from '../../modules';
import { TStates, Grievance } from '../../resource/Grievance'

interface IProps {
  state: TStates,
  newstate: TStates;
}

class StateDiagram extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }

  private getRectColor = (state: TStates) => {
    const history = '#1c75bc';
    const inactive = '#aaaaaa';
    const active = '#007700';
    const pending = '#aa0000'
    if(Grievance.isPreceding(state, this.props.state)) {
      return history;
    } else if (this.props.state == state) {
      return active;
    } else if (this.props.newstate == state) {
      return pending;
    } else {
      return inactive;
    }
  }

  private getCircleColor = (state: TStates) => {
    const inactive = '#888888';
    const active = '#007700';
    const pending = '#aa0000'
    if (this.props.state == state) {
      return active;
    } else if (this.props.newstate == state) {
      return pending;
    } else {
      return inactive;
    }
  }

  render() {
    let p = this.props;
    let arrow_inactive = '#262262';
    return (
      <LanguageContext.Consumer>
      {({language, data}) => {      
        if(language == 'pt')
        return <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="299.75" viewBox="0 0 1291.9 299.75" fontFamily="Roboto" fontSize="12">
          {/* Dashed lines */}
          <line x1="88.81" y1="173.06" x2="1291.9" y2="173.06" fill="none" stroke="#58595b" strokeMiterlimit="10" strokeDasharray="5"/>
          <line x1="88.81" y1="110.79" x2="1291.9" y2="110.79" fill="none" stroke="#58595b" strokeMiterlimit="10" strokeDasharray="5"/>
          <line x1="88.81" y1="48.86" x2="1291.9" y2="48.86" fill="none" stroke="#58595b" strokeMiterlimit="10" strokeDasharray="5"/>
          {/* Invervenient labels */}
          <text transform="translate(0 212.31)" fill="#231f20">PNL DAC</text>
          <text transform="translate(0 139.31)" fill="#231f20">Equipa<tspan x="0" y="14.4">Multisectorial</tspan></text>
          <text transform="translate(0 67.51)" fill="#231f20">Painel de<tspan x="0" y="14.4">Mediação</tspan><tspan x="0" y="28.8">Independente</tspan></text>
          <text transform="translate(0 14.48)" fill="#231f20">Admin Local<tspan x="0" y="14.4">/IPAJ</tspan></text>
          {/* Bottom arrow */}
          <g>
            <line x1="88.81" y1="240.51" x2="1281.85" y2="240.51" fill="none" stroke="#1c75bc" strokeMiterlimit="10" strokeWidth="4"/>
            <polygon points="1279.81 247.5 1291.9 240.51 1279.81 233.53 1279.81 247.5" fill="#1c75bc"/>
          </g>
          {/* Phases */}
          {/* Grievance notification */}
          <rect x="90.01" y="254.67" width="97.75" height="38.2" rx="7.91" ry="7.91" fill="#58595b"/>
          <text transform="translate(109.42 270.28)" fill="#fff">Notificação<tspan x="4" y="14.4">da queixa</tspan></text>
          {/* Triage and cataloguing */}
          <rect x="226.15" y="254.67" width="97.75" height="38.2" rx="7.91" ry="7.91" fill="#58595b"/>
          <text transform="translate(249 270.28)" fill="#fff">Triagem e<tspan x="-6.21" y="14.4">catalogação</tspan></text>
          {/* Amicable resolution: PNL */}
          <rect x="366.46" y="254.67" width="228.39" height="38.2" rx="7.91" ry="7.91" fill="#58595b"/>
          <text transform="translate(411.57 277.28)" fill="#fff">Resolução Amigável: PNL</text>
          {/* Amicable resolution: MT */}
          <rect x="637.04" y="254.67" width="234.7" height="38.2" rx="7.91" ry="7.91" fill="#58595b"/>
          <text transform="translate(688.01 277.28)" fill="#fff">Resolução Amigável: MT</text>
          {/* Amicable resolution: IMP */}
          <rect x="910.99" y="254.67" width="234.7" height="38.2" rx="7.91" ry="7.91" fill="#58595b"/>
          <text transform="translate(959.64 277.28)" fill="#fff">Resolução Amigável: IMP</text>
          {/* Ligigation */}
          <rect x="1184.78" y="254.67" width="97.75" height="38.2" rx="7.91" ry="7.91" fill="#58595b"/>
          <text transform="translate(1218 277.28)" fill="#fff">Litígio</text>
          {/* Transition arrows */}
          <line x1="186.96" y1="207.54" x2="220.18" y2="207.54" fill="none" stroke="#262262" strokeMiterlimit="10"/>
          <polygon points="217.37 211.25 218.94 207.54 217.37 203.84 226.15 207.54 217.37 211.25" fill="#262262"/>
          <line x1="323.9" y1="207.54" x2="357.12" y2="207.54" fill="none" stroke="#262262" strokeMiterlimit="10"/>
          <polygon points="354.31 211.25 355.88 207.54 354.31 203.84 363.09 207.54 354.31 211.25" fill="#262262"/>
          <line x1="457.47" y1="189.89" x2="631.27" y2="143.58" fill="none" stroke="#262262" strokeMiterlimit="10"/>
          <polygon points="629.51 147.88 630.08 143.9 627.6 140.72 637.04 142.04 629.51 147.88" fill="#262262"/>
          <line x1="731.42" y1="157.14" x2="770.05" y2="201.3" fill="none" stroke="#262262" strokeMiterlimit="10"/>
          <polygon points="765.41 201.62 769.24 200.37 770.99 196.75 773.98 205.79 765.41 201.62" fill="#262262"/>
          <line x1="734.79" y1="126.68" x2="905.2" y2="82.84" fill="none" stroke="#262262" strokeMiterlimit="10"/>
          <polygon points="903.41 87.13 904.01 83.15 901.56 79.95 910.99 81.35 903.41 87.13" fill="#262262"/>
          <line x1="1008.74" y1="207.1" x2="1041.96" y2="207.1" fill="none" stroke="#262262" strokeMiterlimit="10"/>
          <polygon points="1039.15 210.8 1040.72 207.1 1039.15 203.4 1047.93 207.1 1039.15 210.8" fill="#262262"/>
          <line x1="1005.77" y1="188.44" x2="1181.93" y2="39.04" fill="none" stroke="#262262" strokeMiterlimit="10"/>
          <polygon points="1182.18 43.68 1180.99 39.84 1177.39 38.03 1186.48 35.18 1182.18 43.68" fill="#262262"/>
          <line x1="460.9" y1="207.54" x2="494.12" y2="207.54" fill="none" stroke="#262262" strokeMiterlimit="10"/>
          <polygon points="491.32 211.25 492.89 207.54 491.32 203.84 500.09 207.54 491.32 211.25" fill="#262262"/>
          <line x1="960.27" y1="100.45" x2="960.27" y2="180.72" fill="none" stroke="#262262" strokeMiterlimit="10"/>
          <polygon points="956.57 177.91 960.27 179.49 963.97 177.91 960.27 186.69 956.57 177.91" fill="#262262"/>
          {/* STATES: */}
          {/* Reception */}
          <rect x="89.2" y="188.44" width="97.75" height="38.2" rx="7.91" ry="7.91" fill={this.getRectColor('reception')}/>
          <text transform="translate(109.42 204.06)" fill="#fff">Notificação<tspan x="4" y="14.4">da queixa</tspan></text>
          {/* Triage */}
          <rect x="226.15" y="188.44" width="97.75" height="38.2" rx="7.91" ry="7.91" fill={this.getRectColor('triage')}/>
          <text transform="translate(249 204.06)" fill="#fff">Triagem e<tspan x="-6.21" y="14.4">catalogação</tspan></text>
          {/* Triage conclusion */}
          <circle cx="323.9" cy="226.52" r="8.11" fill={this.getCircleColor('triage_conclusion')}/>
          {/* Communication 1 */}
          <rect x="363.09" y="188.44" width="97.75" height="38.2" rx="7.91" ry="7.91" fill={this.getRectColor('communication1')}/>
          <text transform="translate(376 204.06)" fill="#fff">Comunicação<tspan x="-1" y="14.4">com queixoso</tspan></text>
          {/* Implementation1 */}
          <rect x="500.09" y="188.44" width="97.75" height="38.2" rx="7.91" ry="7.91" fill={this.getRectColor('implementation1')}/>
          <text transform="translate(507.33 204.06)" fill="#fff">Implementação<tspan x="15" y="14.4">do acordo</tspan></text>
          {/* Implementation 1 conclusion */}
          <circle cx="597.85" cy="226.52" r="8.11" fill={this.getCircleColor('implementation1_conclusion')}/>
          {/* Assessment1 */}
          <rect x="637.04" y="122.94" width="97.75" height="38.2" rx="7.91" ry="7.91" fill={this.getRectColor('assessment1')}/>
          <text transform="translate(660 138.56)" fill="#fff">Avaliação<tspan x="0" y="14.4">e parecer</tspan></text>
          {/* Implementation 2 */}
          <rect x="773.98" y="186.69" width="97.75" height="38.2" rx="7.91" ry="7.91" fill={this.getRectColor('implementation2')}/>
          <text transform="translate(780.85 202.36)" fill="#fff">Implementação<tspan x="15" y="14.4">do acordo</tspan></text>
          {/* Implementation 2 conclusion */}
          <circle cx="871.8" cy="224.89" r="8.11" fill={this.getCircleColor('implementation2_conclusion')}/>
          {/* Assessment2 */}
          <rect x="910.99" y="62.25" width="97.75" height="38.2" rx="7.91" ry="7.91" fill={this.getRectColor('assessment2')}/>
          <text transform="translate(935 77.87)" fill="#fff">Avaliação<tspan x="0" y="14.4">e parecer</tspan></text>          
          {/* Communication 2 */}
          <rect x="910.99" y="186.69" width="97.75" height="38.2" rx="7.91" ry="7.91" fill={this.getRectColor('communication2')}/>
          <text transform="translate(923 202.31)" fill="#fff">Comunicação<tspan x="-1" y="14.4">com queixoso</tspan></text>
          {/* Implementation 3 */}
          <rect x="1047.93" y="188" width="97.75" height="38.2" rx="7.91" ry="7.91" fill={this.getRectColor('implementation3')}/>
          <text transform="translate(1054.79 203.16)" fill="#fff">Implementação<tspan x="15" y="14.4">do acordo</tspan></text>
          {/* Implementation 3 conclusion */}
          <circle cx="1145.68" cy="226.2" r="8.11" fill={this.getCircleColor('implementation3')}/>
          {/* Ligitation */}
          <rect x="1184.78" width="97.75" height="38.2" rx="7.91" ry="7.91" fill={this.getRectColor('litigation')}/>
          <text transform="translate(1216 23.62)" fill="#fff">Litígio</text>
          {/* Lititagion conclusion */}
          <circle cx="1280.53" cy="38.2" r="8.11" fill={this.getCircleColor('litigation_conclusion')}/>
        </svg>

        if(language == 'en')
        return <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="299.75" viewBox="0 0 1291.9 299.75" fontFamily="Roboto" fontSize="12">
        {/* Dashed lines */}
        <line x1="88.81" y1="173.06" x2="1291.9" y2="173.06" fill="none" stroke="#58595b" strokeMiterlimit="10" strokeDasharray="5"/>
        <line x1="88.81" y1="110.79" x2="1291.9" y2="110.79" fill="none" stroke="#58595b" strokeMiterlimit="10" strokeDasharray="5"/>
        <line x1="88.81" y1="48.86" x2="1291.9" y2="48.86" fill="none" stroke="#58595b" strokeMiterlimit="10" strokeDasharray="5"/>
        {/* Invervenient labels */}
        <text transform="translate(0 212.31)" fill="#231f20">PNL DAC</text>
        <text transform="translate(0 139.31)" fill="#231f20">Multisectorial<tspan x="0" y="14.4">Team</tspan></text>
        <text transform="translate(0 67.51)" fill="#231f20">Independent<tspan x="0" y="14.4">Mediation</tspan><tspan x="0" y="28.8">Panel</tspan></text>
        <text transform="translate(0 14.48)" fill="#231f20">Local Admin<tspan x="0" y="14.4">/IPAJ</tspan></text>
        {/* Bottom arrow */}
        <g>
          <line x1="88.81" y1="240.51" x2="1281.85" y2="240.51" fill="none" stroke="#1c75bc" strokeMiterlimit="10" strokeWidth="4"/>
          <polygon points="1279.81 247.5 1291.9 240.51 1279.81 233.53 1279.81 247.5" fill="#1c75bc"/>
        </g>
        {/* Phases */}
        {/* Grievance notification */}
        <rect x="90.01" y="254.67" width="97.75" height="38.2" rx="7.91" ry="7.91" fill="#58595b"/>
        <text transform="translate(112.42 270.28)" fill="#fff">Grievance<tspan x="-4.74" y="14.4">Notification</tspan></text>
        {/* Triage and cataloguing */}
        <rect x="226.15" y="254.67" width="97.75" height="38.2" rx="7.91" ry="7.91" fill="#58595b"/>
        <text transform="translate(246.92 270.28)" fill="#fff">Triage and<tspan x="-3.21" y="14.4">cataloguing</tspan></text>
        {/* Amicable resolution: PNL */}
        <rect x="366.46" y="254.67" width="228.39" height="38.2" rx="7.91" ry="7.91" fill="#58595b"/>
        <text transform="translate(411.57 277.28)" fill="#fff">Amicable Resolution: PNL</text>
        {/* Amicable resolution: MT */}
        <rect x="637.04" y="254.67" width="234.7" height="38.2" rx="7.91" ry="7.91" fill="#58595b"/>
        <text transform="translate(688.01 277.28)" fill="#fff">Amicable Resolution: MT</text>
        {/* Amicable resolution: IMP */}
        <rect x="910.99" y="254.67" width="234.7" height="38.2" rx="7.91" ry="7.91" fill="#58595b"/>
        <text transform="translate(959.64 277.28)" fill="#fff">Amicable Resolution: IMP</text>
        {/* Ligigation */}
        <rect x="1184.78" y="254.67" width="97.75" height="38.2" rx="7.91" ry="7.91" fill="#58595b"/>
        <text transform="translate(1209.59 277.28)" fill="#fff">Litigation</text>
        {/* Transition arrows */}
        <line x1="186.96" y1="207.54" x2="220.18" y2="207.54" fill="none" stroke="#262262" strokeMiterlimit="10"/>
        <polygon points="217.37 211.25 218.94 207.54 217.37 203.84 226.15 207.54 217.37 211.25" fill="#262262"/>
        <line x1="323.9" y1="207.54" x2="357.12" y2="207.54" fill="none" stroke="#262262" strokeMiterlimit="10"/>
        <polygon points="354.31 211.25 355.88 207.54 354.31 203.84 363.09 207.54 354.31 211.25" fill="#262262"/>
        <line x1="457.47" y1="189.89" x2="631.27" y2="143.58" fill="none" stroke="#262262" strokeMiterlimit="10"/>
        <polygon points="629.51 147.88 630.08 143.9 627.6 140.72 637.04 142.04 629.51 147.88" fill="#262262"/>
        <line x1="731.42" y1="157.14" x2="770.05" y2="201.3" fill="none" stroke="#262262" strokeMiterlimit="10"/>
        <polygon points="765.41 201.62 769.24 200.37 770.99 196.75 773.98 205.79 765.41 201.62" fill="#262262"/>
        <line x1="734.79" y1="126.68" x2="905.2" y2="82.84" fill="none" stroke="#262262" strokeMiterlimit="10"/>
        <polygon points="903.41 87.13 904.01 83.15 901.56 79.95 910.99 81.35 903.41 87.13" fill="#262262"/>
        <line x1="1008.74" y1="207.1" x2="1041.96" y2="207.1" fill="none" stroke="#262262" strokeMiterlimit="10"/>
        <polygon points="1039.15 210.8 1040.72 207.1 1039.15 203.4 1047.93 207.1 1039.15 210.8" fill="#262262"/>
        <line x1="1005.77" y1="188.44" x2="1181.93" y2="39.04" fill="none" stroke="#262262" strokeMiterlimit="10"/>
        <polygon points="1182.18 43.68 1180.99 39.84 1177.39 38.03 1186.48 35.18 1182.18 43.68" fill="#262262"/>
        <line x1="460.9" y1="207.54" x2="494.12" y2="207.54" fill="none" stroke="#262262" strokeMiterlimit="10"/>
        <polygon points="491.32 211.25 492.89 207.54 491.32 203.84 500.09 207.54 491.32 211.25" fill="#262262"/>
        <line x1="960.27" y1="100.45" x2="960.27" y2="180.72" fill="none" stroke="#262262" strokeMiterlimit="10"/>
        <polygon points="956.57 177.91 960.27 179.49 963.97 177.91 960.27 186.69 956.57 177.91" fill="#262262"/>
        {/* STATES: */}
        {/* Reception */}
        <rect x="89.2" y="188.44" width="97.75" height="38.2" rx="7.91" ry="7.91" fill={this.getRectColor('reception')}/>
        <text transform="translate(111.61 204.06)" fill="#fff">Grievance<tspan x="-4.74" y="14.4">Notification</tspan></text>
        {/* Triage */}
        <rect x="226.15" y="188.44" width="97.75" height="38.2" rx="7.91" ry="7.91" fill={this.getRectColor('triage')}/>
        <text transform="translate(246.92 204.06)" fill="#fff">Triage and<tspan x="-3.21" y="14.4">cataloguing</tspan></text>
        {/* Triage conclusion */}
        <circle cx="323.9" cy="226.52" r="8.11" fill={this.getCircleColor('triage_conclusion')}/>
        {/* Communication 1 */}
        <rect x="363.09" y="188.44" width="97.75" height="38.2" rx="7.91" ry="7.91" fill={this.getRectColor('communication1')}/>
        <text transform="translate(369.5 204.06)" fill="#fff">Communication<tspan x="0.8" y="14.4">w/ complainant</tspan></text>
        {/* Implementation1 */}
        <rect x="500.09" y="188.44" width="97.75" height="38.2" rx="7.91" ry="7.91" fill={this.getRectColor('implementation1')}/>
        <text transform="translate(507.33 204.06)" fill="#fff">Implementation<tspan x="6.35" y="14.4">of agreement</tspan></text>
        {/* Implementation 1 conclusion */}
        <circle cx="597.85" cy="226.52" r="8.11" fill={this.getCircleColor('implementation1_conclusion')}/>
        {/* Assessment1 */}
        <rect x="637.04" y="122.94" width="97.75" height="38.2" rx="7.91" ry="7.91" fill={this.getRectColor('assessment1')}/>
        <text transform="translate(652.74 138.56)" fill="#fff">Assessment<tspan x="3.93" y="14.4">and Report</tspan></text>
        {/* Implementation 2 */}
        <rect x="773.98" y="186.69" width="97.75" height="38.2" rx="7.91" ry="7.91" fill={this.getRectColor('implementation2')}/>
        <text transform="translate(780.85 202.36)" fill="#fff">Implementation<tspan x="6.35" y="14.4">of agreement</tspan></text>
        {/* Implementation 2 conclusion */}
        <circle cx="871.8" cy="224.89" r="8.11" fill={this.getCircleColor('implementation2_conclusion')}/>
        {/* Assessment2 */}
        <rect x="910.99" y="62.25" width="97.75" height="38.2" rx="7.91" ry="7.91" fill={this.getRectColor('assessment2')}/>
        <text transform="translate(926.69 77.87)" fill="#fff">Assessment<tspan x="3.93" y="14.4">and Report</tspan></text>          
        {/* Communication 2 */}
        <rect x="910.99" y="186.69" width="97.75" height="38.2" rx="7.91" ry="7.91" fill={this.getRectColor('communication2')}/>
        <text transform="translate(917.39 202.31)" fill="#fff">Communication<tspan x="0.8" y="14.4">w/ complainant</tspan></text>
        {/* Implementation 3 */}
        <rect x="1047.93" y="188" width="97.75" height="38.2" rx="7.91" ry="7.91" fill={this.getRectColor('implementation3')}/>
        <text transform="translate(1054.79 203.16)" fill="#fff">Implementation<tspan x="6.35" y="14.4">of agreement</tspan></text>
        {/* Implementation 3 conclusion */}
        <circle cx="1145.68" cy="226.2" r="8.11" fill={this.getCircleColor('implementation3')}/>
        {/* Ligitation */}
        <rect x="1184.78" width="97.75" height="38.2" rx="7.91" ry="7.91" fill={this.getRectColor('litigation')}/>
        <text transform="translate(1209.59 23.62)" fill="#fff">Litigation</text>
        {/* Lititagion conclusion */}
        <circle cx="1280.53" cy="38.2" r="8.11" fill={this.getCircleColor('litigation_conclusion')}/>
        </svg>

      }}
      </LanguageContext.Consumer>
    );
  }
}

export { StateDiagram };
