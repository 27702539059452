import * as React from 'react';
import { Container, Content, BottomBar, Loc } from '../../modules';
import { Survey } from '../../resource/';
import { RouteComponentProps } from 'react-router';
import { ListSurveys } from './ListSurveys';
import { IAuthProps } from '../../services/Auth';

import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';

class SurveyList extends React.Component<IAuthProps & RouteComponentProps<any>, {}> {

  // Go to add survey view.
  handleAdd = () => {
    this.props.history.push('/surveys/add');
  }  

  // Go to view survey view.
  handleClick = (item: Survey) => {
    this.props.history.push(`/surveys/${item.id}`);
  }  

  render() {
    let p = this.props;
    return (
      <Container>
        <Content>
          <ListSurveys auth={p.auth} name="surveys" onClick={this.handleClick}/>
        </Content>
        <BottomBar>
          {p.auth && p.auth.hasRight('can_edit_surveys') && <Button primary onClick={this.handleAdd}><Icon name="plus"/> <Loc msg="surveys_btn_add"/></Button>}
        </BottomBar>
      </Container>
    );
  }
}

export { SurveyList };
