import * as React from 'react';
import axios from 'axios';
import styled from '@independent-software/typeui/styles/Theme'

import { IAuthProps } from '../../services/Auth';
import { RouteComponentProps } from 'react-router-dom';
import { App } from '../../App';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { Form } from '@independent-software/typeui/controls/Form';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Table } from '@independent-software/typeui/controls/Table';
import { ListOptions } from './ListOptions';
import { Export, Loc } from '../../modules';
import { TLocalization } from '../../modules/Loc/TLocalization';
import { Number } from '@independent-software/typeui/formatters/Number';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { Community, CommunityFactory } from '../../resource';
import { Query } from '../../services';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Divider } from '@independent-software/typeui/controls/Divider';

interface IProps {
}

interface IDelivery {
  name: string;
  prerelocation_created: number,
  relocation_created: number,
  postrelocation_created: number,
  total_created: number,
  prerelocation_completed: number,
  relocation_completed: number,
  postrelocation_completed: number,
  total_completed: number,  
  prerelocation_avg: number,
  relocation_avg: number,
  postrelocation_avg: number,
  total_avg: number,    
}

interface IState {
  days: number;
  responsibility: string;
  vulnerability: string;
  polygamous: boolean;
  inas: boolean;
  vulnerability_singleheaded: boolean,
  vulnerability_femaleheaded: boolean,
  vulnerability_orphan: boolean,
  vulnerability_elderly: boolean,
  vulnerability_mental: boolean,
  vulnerability_physical: boolean,
  vulnerability_illness: boolean,
  vulnerability_income: boolean,
  community: Community;
  communities: Community[];
  data: IDelivery[];
}

class ListActionsTime extends React.Component<IAuthProps & IProps, IState> {
   constructor(props: IAuthProps & IProps) {
     super(props);
     this.state = {
       days: -1,
       responsibility: null,
       vulnerability: null,
       polygamous: null,
       inas: null,
       vulnerability_singleheaded: null,
       vulnerability_femaleheaded: null,
       vulnerability_orphan: null,
       vulnerability_elderly: null,
       vulnerability_mental: null,
       vulnerability_physical: null,
       vulnerability_illness: null,
       vulnerability_income: null,
       community: null,
       communities: [],
       data: []
     }
   } 

   componentDidMount() {
     this.loadData();
     this.handleCommunitySearch();
   }

   handleCommunitySearch = (q?:string) => {
     // Retrieve a list of communities:
     let query = new Query('name', 'asc');
     if(q) query.setFilter('q', 'like', q);
     CommunityFactory.getSome(this.props.auth, 0, 999, query)
       .then((res) => this.setState({ communities: res.items }));
   }  

   componentDidUpdate(newProps: IProps, newState: IState) {
    if(this.state.days != newState.days || 
       this.state.responsibility != newState.responsibility ||
       this.state.vulnerability != newState.vulnerability ||
       this.state.polygamous != newState.polygamous ||
       this.state.inas != newState.inas ||
       this.state.vulnerability_singleheaded != newState.vulnerability_singleheaded ||
       this.state.vulnerability_femaleheaded != newState.vulnerability_femaleheaded ||
       this.state.vulnerability_orphan != newState.vulnerability_orphan ||
       this.state.vulnerability_elderly != newState.vulnerability_elderly ||
       this.state.vulnerability_mental != newState.vulnerability_mental ||
       this.state.vulnerability_physical != newState.vulnerability_physical ||
       this.state.vulnerability_illness != newState.vulnerability_illness ||
       this.state.vulnerability_income != newState.vulnerability_income ||
       this.state.community != newState.community) {
      this.loadData();
    }
  }

  getPath = () => {
    let path = `${App.apiURL}actions/list-times?api_token=${this.props.auth.token}`;
    if(this.state.days) path = path + `&days=${this.state.days}`;
    if(this.state.responsibility) path += `&responsibility=${this.state.responsibility}`;
    if(this.state.polygamous) path = path + `&polygamous=1`;
    if(this.state.inas) path = path + `&inas=1`;
    if(this.state.vulnerability_singleheaded) path = path + `&vulnerability_singleheaded=1`;
    if(this.state.vulnerability_femaleheaded) path = path + `&vulnerability_femaleheaded=1`;
    if(this.state.vulnerability_orphan) path = path + `&vulnerability_orphan=1`;
    if(this.state.vulnerability_elderly) path = path + `&vulnerability_elderly=1`;
    if(this.state.vulnerability_mental) path = path + `&vulnerability_mental=1`;
    if(this.state.vulnerability_physical) path = path + `&vulnerability_physical=1`;
    if(this.state.vulnerability_illness) path = path + `&vulnerability_illness=1`;
    if(this.state.vulnerability_income) path = path + `&vulnerability_income=1`;
    if(this.state.community) path = path + `&community=${this.state.community.id}`;
    return path;
  }

  loadData = () => {
    axios.get(this.getPath())
    .then(response => {
      let data = response.data;
      this.setState({
        data: data
      })
    })
    .catch(error => {
    });   
  }

  handleExport = (format: string) => {
    axios.get(this.getPath() + `&format=${format}`, { responseType: 'blob'}) 
    .then(response => {
      // Find the content-disposition header.
      let disposition:string = response.headers['content-disposition'];
      // Using a regexp, retrieve the filename from it.
      let regexp = new RegExp('\"(.*)\"');
      let res:RegExpExecArray = regexp.exec(disposition);
      let filename = res[1];
      // Download the file.
      saveAs(response.data, filename);
    })
    .catch(error => {
    });
  }

  handleChangeDays = (value: number) => {
    this.setState({days: value});
  }    

  handleChangeResponsibility = (value: string) => {
    this.setState({responsibility: value});
  }

  handlePolygamous = (value: boolean) => {
    this.setState({polygamous: value});
  }

  handleINAS = (value: boolean) => {
    this.setState({inas: value});
  }    

  handleChangeCommunity = (value: Community) => {
    this.setState({community: value});
  }

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Flex.Columns count={2}>
          <React.Fragment>
            <Form.Uncontrolled hint={<Loc msg="analysis_grievances_hint_period"/>}>
              <Dropdown name="days" fluid value={this.state.days} data={[30,90,180,365,-1]} placeholder="Period" label={(item:number) => item == -1 ? <Loc msg="analysis_grievances_hint_period_all_time"/> : <span>{item} {<Loc msg="analysis_grievances_hint_period_days"/>}</span>} onChange={this.handleChangeDays}>
                <Dropdown.Column>{(item:number) => item == -1 ? <Loc msg="analysis_grievances_hint_period_all_time"/> : <span>{item} {<Loc msg="analysis_grievances_hint_period_days"/>}</span>}</Dropdown.Column>
              </Dropdown>
            </Form.Uncontrolled>        
            <Form.Uncontrolled hint={<Loc msg="actions_filter_community_hint"/>}>
              <Dropdown value={this.state.community} clearable onSearch={this.handleCommunitySearch} fluid data={this.state.communities} placeholder="Community" label={(item:Community) => item.name} onChange={this.handleChangeCommunity}>
                <Dropdown.Column>{(item:Community) => item.name}</Dropdown.Column>
              </Dropdown>          
            </Form.Uncontrolled>        
            <Form.Uncontrolled hint={<Loc msg="actions_filter_responsibility_hint"/>}>
              <Dropdown name="responsibility" fluid clearable value={this.state.responsibility} data={['pnl','government','provider']} placeholder="Responsibility" label={(item:string) => <Loc msg={("actions_responsibility_" + item) as TLocalization}/>} onChange={this.handleChangeResponsibility}>
                <Dropdown.Column>{(item:string) => <Loc msg={("actions_responsibility_" + item) as TLocalization}/>}</Dropdown.Column>
              </Dropdown>
            </Form.Uncontrolled>        
            <Form.Uncontrolled>
              <Checkbox type="slider" checked={this.state.polygamous} label={<Loc msg="households_fields_polygamous_label"/>} onChange={this.handlePolygamous}/>
            </Form.Uncontrolled>
            <Form.Uncontrolled>
              <Checkbox type="slider" checked={this.state.inas} label={<Loc msg="households_fields_inas_label"/>} onChange={this.handleINAS}/>
            </Form.Uncontrolled>   
          </React.Fragment>
          <React.Fragment>
            <Form.Uncontrolled><Checkbox type="slider" checked={this.state.vulnerability_singleheaded} onChange={(v: boolean) => this.setState({vulnerability_singleheaded: v})} label={<Loc msg="households_vulnerabilities_singleheaded"/>}/></Form.Uncontrolled>
            <Form.Uncontrolled><Checkbox type="slider" checked={this.state.vulnerability_femaleheaded} onChange={(v: boolean) => this.setState({vulnerability_femaleheaded: v})} label={<Loc msg="households_vulnerabilities_femaleheaded"/>}/></Form.Uncontrolled>
            <Form.Uncontrolled><Checkbox type="slider" checked={this.state.vulnerability_orphan} onChange={(v: boolean) => this.setState({vulnerability_orphan: v})} label={<Loc msg="households_vulnerabilities_orphan"/>}/></Form.Uncontrolled>
            <Form.Uncontrolled><Checkbox type="slider" checked={this.state.vulnerability_elderly} onChange={(v: boolean) => this.setState({vulnerability_elderly: v})} label={<Loc msg="households_vulnerabilities_elderly"/>}/></Form.Uncontrolled>
            <Form.Uncontrolled><Checkbox type="slider" checked={this.state.vulnerability_mental} onChange={(v: boolean) => this.setState({vulnerability_mental: v})} label={<Loc msg="households_vulnerabilities_mental"/>}/></Form.Uncontrolled>
            <Form.Uncontrolled><Checkbox type="slider" checked={this.state.vulnerability_physical} onChange={(v: boolean) => this.setState({vulnerability_physical: v})} label={<Loc msg="households_vulnerabilities_physical"/>}/></Form.Uncontrolled>
            <Form.Uncontrolled><Checkbox type="slider" checked={this.state.vulnerability_illness} onChange={(v: boolean) => this.setState({vulnerability_illness: v})} label={<Loc msg="households_vulnerabilities_illness"/>}/></Form.Uncontrolled>
            <Form.Uncontrolled><Checkbox type="slider" checked={this.state.vulnerability_income} onChange={(v: boolean) => this.setState({vulnerability_income: v})} label={<Loc msg="households_vulnerabilities_income"/>}/></Form.Uncontrolled>
          </React.Fragment>
        </Flex.Columns>
      </Panel.Content>
      <Panel.Footer>
        <Export onExport={this.handleExport}/>
      </Panel.Footer>
    </React.Fragment>    

    return (
      <div>
        <ListOptions>
          <Panel.Icon icon="tools" width={600}>
            {filter}
          </Panel.Icon>
        </ListOptions>
        <Divider hidden/>
        <Table transparent striped>
          <thead>
            <tr>
              <th style={{width: '200px'}}>&nbsp;</th>
              <th style={{textAlign: "center", borderRight: "solid 1px #ccc"}} colSpan={3}><Loc msg="actions_phases_prerelocation"/></th>
              <th style={{textAlign: "center", borderRight: "solid 1px #ccc"}} colSpan={3}><Loc msg="actions_phases_relocation"/></th>
              <th style={{textAlign: "center", borderRight: "solid 1px #ccc"}} colSpan={3}><Loc msg="actions_phases_postrelocation"/></th>
              <th style={{textAlign: "center"}} colSpan={3}>Total</th>
            </tr>
            <tr>
              <th style={{width: '200px'}}>&nbsp;</th>
              <th style={{textAlign: "center"}}><Loc msg="analysis_actions_times_created"/></th>
              <th style={{textAlign: "center"}}><Loc msg="analysis_actions_times_completed"/></th>
              <th style={{textAlign: "center", borderRight: "solid 1px #ccc"}}><Loc msg="analysis_actions_times_average"/></th>
              <th style={{textAlign: "center"}}><Loc msg="analysis_actions_times_created"/></th>
              <th style={{textAlign: "center"}}><Loc msg="analysis_actions_times_completed"/></th>
              <th style={{textAlign: "center", borderRight: "solid 1px #ccc"}}><Loc msg="analysis_actions_times_average"/></th>              
              <th style={{textAlign: "center"}}><Loc msg="analysis_actions_times_created"/></th>
              <th style={{textAlign: "center"}}><Loc msg="analysis_actions_times_completed"/></th>
              <th style={{textAlign: "center", borderRight: "solid 1px #ccc"}}><Loc msg="analysis_actions_times_average"/></th>
              <th style={{textAlign: "center"}}><Loc msg="analysis_actions_times_created"/></th>
              <th style={{textAlign: "center"}}><Loc msg="analysis_actions_times_completed"/></th>
              <th style={{textAlign: "center"}}><Loc msg="analysis_actions_times_average"/></th>                            
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((item: IDelivery, idx:number) => 
              <tr key={idx} style={{ fontWeight: !item.name ? 500 : 'normal', background: !item.name ? "#eee" : null }}>
                <td>{item.name ? <Loc msg={("actiontypes_delivery_" + item.name) as TLocalization}/> : "Total" }</td>
                <td style={{textAlign: "center"}}><Number value={item.prerelocation_created} decimals={0}/></td>
                <td style={{textAlign: "center"}}><Number value={item.prerelocation_completed} decimals={0}/></td>
                <td style={{textAlign: "center", borderRight: "solid 1px #ccc"}}>{item.prerelocation_avg ? <Number value={item.prerelocation_avg} decimals={0}/> : "-"}</td>
                <td style={{textAlign: "center"}}><Number value={item.relocation_created} decimals={0}/></td>
                <td style={{textAlign: "center"}}><Number value={item.relocation_completed} decimals={0}/></td>
                <td style={{textAlign: "center", borderRight: "solid 1px #ccc"}}>{item.relocation_avg ? <Number value={item.relocation_avg} decimals={0}/> : "-"}</td>
                <td style={{textAlign: "center"}}><Number value={item.postrelocation_created} decimals={0}/></td>
                <td style={{textAlign: "center"}}><Number value={item.postrelocation_completed} decimals={0}/></td>
                <td style={{textAlign: "center", borderRight: "solid 1px #ccc"}}>{item.postrelocation_avg ? <Number value={item.postrelocation_avg} decimals={0}/> : "-"}</td>
                <td style={{textAlign: "center"}}><Number value={item.total_created} decimals={0}/></td>
                <td style={{textAlign: "center"}}><Number value={item.total_completed} decimals={0}/></td>
                <td style={{textAlign: "center"}}>{item.total_avg ? <Number value={item.total_avg} decimals={0}/> : "-"}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  }
}

const ListItem = styled('tr')`
  cursor: pointer;
  &:hover {
    background: #E7E7e7;
  }
`

export { ListActionsTime }
