import * as React from 'react';
import { css } from 'styled-components';
import styled from '@independent-software/typeui/styles/Theme'

class StatsTitleBase extends React.Component<{ className?: string, children?: React.ReactNode }, {}> {
  render() {
    let p = this.props;
    return <div className={p.className}>
      {p.children}
    </div>
  }
}

const StatsTitle = styled(StatsTitleBase)`
`

export { StatsTitle }