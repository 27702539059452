import * as React from 'react';
import { Community, Attachment, District, DistrictFactory } from '../../resource/';
import { IAuthProps, Query } from '../../services/';

import { Input } from '@independent-software/typeui/controls/Input';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Form } from '@independent-software/typeui/controls/Form';
import { Map, Section, AttachmentManager, Loc } from '../../modules';
import { Segment } from '@independent-software/typeui/controls/Segment';
import { Divider } from '@independent-software/typeui/controls/Divider';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Label } from '@independent-software/typeui/controls/Label';
import { MarkdownTextarea } from '@independent-software/typeui/modules/MarkdownTextarea';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';

interface IProps {
  /** Initial form data. */
  data: Community;
  /** Called whenever form changes. */
  onChange: (data: Community, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */  
  dirty?: boolean;
}

interface IState {
  /** Current form data. */
  data: Community;
  districts: District[];  
}

class CommunityForm extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);

    // Intialize state.
    this.state = {
      data: props.data,
      districts: []
    };    
    
    // Retrieve a list of profiles:
    DistrictFactory.getSome(this.props.auth, 0, 8, new Query('name', 'asc'))
      .then((res) => this.setState({ districts: res.items }));    
  }

  handleDistrictSearch = (q:string) => {
    // Retrieve a list of districts:
    let query = new Query('name', 'asc');
    query.setFilter('q', 'like', q);
    DistrictFactory.getSome(this.props.auth, 0, 8, query)
      .then((res) => this.setState({ districts: res.items }));
  }  

  handleChangeAttachments = (attachments: Attachment[]) => {
    this.setState((prevState) => {
      prevState.data.attachments = attachments;
      return prevState;
    });
  }  

  render() {
    let p = this.props;
    return (
      <Form
        data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
        <Tabs underlined nohiddenrender>
          <Tabs.Pane label={<Loc msg="communities_form_tab_data"/>}>
            <Section padded>
              <Flex.Columns count={2}>
                <Form.Field 
                  required={{message: <Loc msg="communities_fields_name_required"/>}}
                  minLength={{length: 3, message: <Loc msg="communities_fields_name_minlength"/>}}
                  maxLength={{length: 30, message: <Loc msg="communities_fields_name_maxlength"/>}}
                  width={1} label={<Loc msg="communities_fields_name_label"/>}
                  name="name" 
                  value={this.state.data.name}
                  control={(<Input type="text" placeholder="Name" fluid/>)}
                  hint={<Loc msg="communities_fields_name_hint"/>}/>
                <Form.Field
                  required={{message: <Loc msg="communities_fields_district_required"/>}}
                  width={1} label={<Loc msg="communities_fields_district_label"/>}
                  name="district" 
                  value={this.state.data.district}
                  control={(<Dropdown onSearch={this.handleDistrictSearch} fluid data={this.state.districts} placeholder="District" label={(item:District) => item.name}>
                    <Dropdown.Column weight={3}>{(item:District) => item.name}</Dropdown.Column>
                    <Dropdown.Column align='right'>{(item:District) => item.communities_count}</Dropdown.Column>
                  </Dropdown>)}
                  hint={<Loc msg="communities_fields_district_hint"/>}/>
              </Flex.Columns>
              <Divider/>
              <Flex.Columns count={2}>
                <Form.Field 
                  label={<Loc msg="communities_fields_description_en_label"/>}
                  name="description_en" value={this.state.data.description_en}
                  control={(<MarkdownTextarea rows={16} fluid label_text={<Loc msg="markdown_text"/>} label_preview={<Loc msg="markdown_preview"/>} label_markdown={<Loc msg="markdown_is_supported"/>}/>)}
                  hint={<Loc msg="communities_fields_description_en_hint"/>}/>
                <Form.Field 
                  label={<Loc msg="communities_fields_description_pt_label"/>}
                  name="description_pt" value={this.state.data.description_pt}
                  control={(<MarkdownTextarea rows={16} fluid label_text={<Loc msg="markdown_text"/>} label_preview={<Loc msg="markdown_preview"/>} label_markdown={<Loc msg="markdown_is_supported"/>}/>)}
                  hint={<Loc msg="communities_fields_description_pt_hint"/>}/>
              </Flex.Columns>
            </Section>
          </Tabs.Pane>

          <Tabs.Pane label={<Loc msg="communities_form_tab_location"/>}>
            <Map latitude={this.state.data.latitude} longitude={this.state.data.longitude} height={400}/>
            <Divider hidden/>
            <Section padded>
              <Segment raised attached="top">
                <Loc msg="communities_block_coordinates"/>
              </Segment>
              <Segment raised attached="bottom">
                <Flex.Columns count={2}>
                  <Form.Field 
                    isFloat={{message: <Loc msg="communities_fields_latitude_isfloat"/>}}
                    forceupdate
                    width={1} label={<Loc msg="communities_fields_latitude_label"/>}
                    name="latitude" 
                    value={this.state.data.latitude}
                    control={(<Input type="text" placeholder="Latitude" fluid><Label attached="right" basic pointing="left">deg</Label></Input>)}
                    hint={(<React.Fragment><Loc msg="communities_fields_latitude_hint"/> <code><Loc msg="communities_fields_latitude_hint_ex"/></code></React.Fragment>)}/>
                  <Form.Field 
                    isFloat={{message: <Loc msg="communities_fields_longitude_isfloat"/>}}
                    forceupdate
                    width={1} label={<Loc msg="communities_fields_longitude_label"/>}
                    name="longitude" 
                    value={this.state.data.longitude}
                    control={(<Input type="text" placeholder="Longitude" fluid><Label attached="right" basic pointing="left">deg</Label></Input>)}
                    hint={(<React.Fragment><Loc msg="communities_fields_longitude_hint"/> <code><Loc msg="communities_fields_longitude_hint_ex"/></code></React.Fragment>)}/>
                </Flex.Columns>
              </Segment>
            </Section>        
          </Tabs.Pane>

          <Tabs.Pane label={<React.Fragment>{<Loc msg="communities_form_tab_attachments"/>} <Label size="small">{this.state.data.attachments.length}</Label></React.Fragment>}>
            <Section padded>
              <AttachmentManager auth={p.auth} attachments={this.state.data.attachments} onChange={this.handleChangeAttachments}/>
            </Section>
          </Tabs.Pane>
        </Tabs>

      </Form>
    )
  }
}

export { CommunityForm };
