import * as React from 'react';
import { LanguageContext } from '../../modules';
import { Datum } from '@independent-software/typeui/formatters/Datum';

interface IProps {
  value: Date | string;
  format: string;
}

class CustomDateTime extends React.Component<IProps, {}> {
  render() {
    return (
      <LanguageContext.Consumer>
        {({locale}) => {
          return <Datum.Custom format={this.props.format} value={this.props.value} locale={locale}/>
        }}
      </LanguageContext.Consumer>
    );   
  }
}

export { CustomDateTime };
