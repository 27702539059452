import * as React from 'react';
import { Grievance, GrievanceFactory, StakeholderFactory, Stakeholder, Issuetype, IssuetypeFactory } from '../../resource/'; 
import { IAuthProps, List, IListState, IListProps, SearchFilter, Query } from '../../services/';
import { IconBar, Export, Loc } from '../../modules';

import { Form } from '@independent-software/typeui/controls/Form';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { DataTable } from '@independent-software/typeui/controls/DataTable';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { LocalizedDatum } from '../../formatters/LocalizedDatum';
import { TLocalization } from '../../modules/Loc/TLocalization';
import { Label } from '@independent-software/typeui/controls/Label';
import { Theme } from '@independent-software/typeui/styles';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { Number } from '@independent-software/typeui/formatters/Number';

interface IListGrievancesProps extends IListProps {
  /**
   * If set, list is prefiltered by a Stakeholder and
   * Stakeholder column and filter are hidden.
   */
  stakeholderId?: number;
  /* If set, list is prefiltered by an issue type and issue type
   * column and filter are hidden.
   */
  issuetypeId?: number;
  /*
   * If set, list is prefiltered with pending grienvances only.
   * Filter will be hidden.
   */
  pending?: boolean;
  /*
   * If set, list is prefiltered with unresolved grievances only.
   * Filter will be hidden.
   */
  unresolved?: boolean;
  /*
   * If set, list is prefiltered with overdue grievances only.
   * Filter will be hidden.
   */
  overdue?: boolean;
  /**
   * Event is fired when a grievance is clicked.
   */
  onClick?: (grievance:Grievance) => void;
}

interface IListGrievancesState extends IListState<Grievance> {
  /**
   * Data for Stakeholders dropdown
   */
  stakeholders: Stakeholder[];
  /* 
   * Data for issuetype dropdown
   */
  issuetypes: Issuetype[];
}

class ListGrievances extends List<Grievance, IListGrievancesProps, IListGrievancesState> {
  constructor(props: IAuthProps & IListGrievancesProps) {
    super(props, GrievanceFactory, 'title', 'asc');

    // Initialize state (list initializes its own part of the state):
    this.state = {
      ...this.state,
      stakeholders: [],
      issuetypes: []
    };

    // The stakeholder stored in the Query is an ID, not a stakeholder.
    // Is the list prefiltered by a stakeholder? Then set a filter.
    if(this.props.stakeholderId) {
      this.setFilter('stakeholder', 'eq', { id: this.props.stakeholderId }, true);
    }

    if(this.props.issuetypeId) {
      this.setFilter('issuetype', 'eq', { id: this.props.issuetypeId }, true);
    }

    if(this.props.pending) {
      this.setFilter('newstate', 'neq', 0, true);
    }

    if(this.props.overdue) {
      this.setFilter('overdue', 'eq', 1, true);
    }

    if(this.props.unresolved) {
      this.setFilter('resolved', 'eq', 0, true);
    }
  }

  componentDidMount() {
    this.handleStakeholderSearch();
    this.handleIssuetypeSearch();
  }

  handleSearch = (value:string) => {
    this.setFilter('q', 'like', value);
  }  

  handleChangeStakeholder = (value: Stakeholder) => {
    this.setFilter('stakeholder', 'eq', value);
  }

  handleStakeholderSearch = (q?:string) => {
    // Retrieve a list of stakeholders:
    let query = new Query('name', 'asc');
    if (q) query.setFilter('q', 'like', q);
    StakeholderFactory.getSome(this.props.auth, 0, 8, query)
      .then((res) => this.setState({ stakeholders: res.items }));
  }  

  handleChangeIssuetype = (value: Issuetype) => {
    this.setFilter('issuetype', 'eq', value);
  }

  handleIssuetypeSearch = (q?:string) => {
    // Retrieve a list of issue types:
    let query = new Query('name', 'asc');
    if (q) query.setFilter('q', 'like', q);
    IssuetypeFactory.getSome(this.props.auth, 0, 8, query)
      .then((res) => this.setState({ issuetypes: res.items }));
  }  

  handleChangeState = (value: string) => {
    this.setFilter('state', 'eq', value);
  }

  handleChangeFilterPending = (value: boolean) => {
    this.setFilter('newstate', 'neq', value ? 0 : null);
  }

  handleChangeFilterOverdue = (value: boolean) => {
    this.setFilter('overdue', 'eq', value ? 1 : null);
  }

  handleChangeFilterResolved = (value: boolean) => {
    this.setFilter('resolved', 'eq', value ? 0 : null);
  }

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Form.Uncontrolled hint={<Loc msg="grievances_filter_search_hint"/>}>
          <SearchFilter value={this.getFilter('q', 'like')} onSearch={this.handleSearch}/>
        </Form.Uncontrolled>
        {!p.stakeholderId && 
          <Form.Uncontrolled hint={<Loc msg="grievances_filter_stakeholder_hint"/>}>
            <Dropdown onSearch={this.handleStakeholderSearch} name="stakeholder" fluid clearable value={this.getFilter('stakeholder', 'eq')} data={this.state.stakeholders} placeholder="Stakeholder" label={(item:Stakeholder) => item.name} onChange={this.handleChangeStakeholder}>
              <Dropdown.Column>{(item:Stakeholder) => item.name}</Dropdown.Column>
            </Dropdown>
          </Form.Uncontrolled>}        
        {!p.issuetypeId && 
        <Form.Uncontrolled hint={<Loc msg="grievances_filter_issuetype_hint"/>}>
          <Dropdown onSearch={this.handleIssuetypeSearch} name="issuetype" fluid clearable value={this.getFilter('issuetype', 'eq')} data={this.state.issuetypes} placeholder="Issue type" label={(item:Issuetype) => item.name} onChange={this.handleChangeIssuetype}>
            <Dropdown.Column>{(item:Issuetype) => item.name}</Dropdown.Column>
          </Dropdown>
        </Form.Uncontrolled>}                  
        <Form.Uncontrolled hint={<Loc msg="grievances_filter_state_hint"/>}>
          <Dropdown name="state" fluid clearable value={this.getFilter('state', 'eq')} data={[
            'reception', 'triage', 'triage_conclusion', 'communication1', 'implementation1', 'implementation1_conclusion', 
            'assessment1', 'implementation2', 'implementation2_conclusion', 'assessment2', 'communication2', 
            'implementation3', 'implementation3_conclusion', 'litigation', 'litigation_conclusion'
            ]} placeholder="State" label={(item:string) => <Loc msg={('grievance_workflow_' + item) as TLocalization}/>} onChange={this.handleChangeState}>
            <Dropdown.Column>{(item:string) => <Loc msg={('grievance_workflow_' + item) as TLocalization}/>}</Dropdown.Column>
          </Dropdown>
        </Form.Uncontrolled>
        {!p.pending && <Form.Uncontrolled hint="">
          <Checkbox label={<Loc msg="grievances_filter_only_pending_hint"/>} name='empty' type="toggle" checked={this.getFilter('newstate', 'neq') != null} onChange={this.handleChangeFilterPending}/>
        </Form.Uncontrolled>}
        {!p.overdue && <Form.Uncontrolled hint="">
          <Checkbox label={<Loc msg="grievances_filter_only_overdue_hint"/>} name='empty' type="toggle" checked={this.getFilter('overdue', 'eq') != null} onChange={this.handleChangeFilterOverdue}/>
        </Form.Uncontrolled>}
        {!p.unresolved && <Form.Uncontrolled hint="">
          <Checkbox label={<Loc msg="grievances_filter_only_unresolved_hint"/>} name='empty' type="toggle" checked={this.getFilter('resolved', 'eq') != null} onChange={this.handleChangeFilterResolved}/>
        </Form.Uncontrolled>}

      </Panel.Content>
      <Panel.Footer>
        <Export onExport={this.handleExport}/>
      </Panel.Footer>      
    </React.Fragment>

    return (
      <React.Fragment>
        <IconBar>
          <Panel.Icon icon="tools" width={300}>
            {filter}
          </Panel.Icon>
        </IconBar> 
        <DataTable error={this.state.error} loading={this.state.loading} scrollTop={this.state.scrollTop} onScroll={this.handleScroll} data={this.state.items} onFetch={this.handleFetch} onClick={p.onClick} onOrder={this.handleOrder} order={this.getOrder()} dir={this.getDir()}>
          <DataTable.Column weight={2} label="ID" order="id" dir="asc">{(item:Grievance) => item.id}</DataTable.Column>
          <DataTable.Column weight={8} label={<Loc msg="grievances_list_hdr_title"/>} order="title" dir="asc">{(item:Grievance) => <span>{item.title}</span>}</DataTable.Column>
          {/* <DataTable.Column weight={5} label={<Loc msg="grievances_list_hdr_date"/>} order="date" dir="desc">{(item:Grievance) => <LocalizedDatum.ShortDate value={item.date}/>}</DataTable.Column> */}
          {!p.stakeholderId && <DataTable.Column weight={8} label={<Loc msg="grievances_list_hdr_stakeholder"/>} order="stakeholder" dir="asc">{(item:Grievance) => item.stakeholder ? item.stakeholder.name : '-'}</DataTable.Column>}
          {!p.issuetypeId && <DataTable.Column weight={6} label={<Loc msg="grievances_list_hdr_issuetype"/>}>{(item:Grievance) => item.issuetype != null ? <span title={item.issuetype.name}>{item.issuetype.name}</span> : '-'}</DataTable.Column>}
          <DataTable.Column weight={10} label={<Loc msg="grievances_list_hdr_state"/>}>{(item:Grievance) => <Loc msg={("grievance_workflow_" + item.state) as TLocalization}/>}</DataTable.Column>
          {!p.unresolved && <DataTable.Column weight={4} label={<Loc msg="grievances_list_hdr_resolved"/>}>{(item:Grievance) => item.resolved ? <Icon name="check"/> : <Icon name="times"/>}</DataTable.Column>}
          <DataTable.Column weight={4} label={<Loc msg="grievances_list_hdr_last_update"/>} order="updated_at" dir="asc">{(item:Grievance) => <LocalizedDatum.Distance strict value={item.updated_at}/>}</DataTable.Column>
          <DataTable.Column weight={4} align="right" label={<Loc msg="grievances_list_hdr_age"/>} order="age" dir="asc">{(item:Grievance) => <span><Number value={item.age} decimals={0}/>d</span>}</DataTable.Column>
          {!p.overdue && <DataTable.Column weight={4} label={<Loc msg="grievances_list_hdr_overdue"/>}>{(item:Grievance) => item.overdue ? <Label color='darkred'>overdue</Label> : ''}</DataTable.Column>}
        </DataTable>
        <Dialog.Xhr open={this.state.exportError != null} error={this.state.exportError} onClose={this.handleCloseDialog}/>
      </React.Fragment>
    );
  }
}

export { ListGrievances };
