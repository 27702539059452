import * as React from 'react';
import { Survey, Community, CommunityFactory } from '../../resource/';
import { IAuthProps, Query } from '../../services/';

import { Input } from '@independent-software/typeui/controls/Input';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Form } from '@independent-software/typeui/controls/Form';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Label } from '@independent-software/typeui/controls/Label';
import { Table } from '@independent-software/typeui/controls/Table';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { Loc } from '../../modules';


interface IProps {
  /** Initial form data. */
  data: Survey;
  /** Called whenever form changes. */
  onChange: (data: Survey, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */  
  dirty?: boolean;
}

interface IState {
  /** Current form data. */
  data: Survey;
  /** Data for communities dropdown */
  communities: Community[];    
}

class SurveyForm extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);

    // Intialize state.
    this.state = {
      data: props.data,
      communities: []
    };    

    // Retrieve a list of communities:
    CommunityFactory.getSome(this.props.auth, 0, 999, new Query('name', 'asc'))
      .then((res) => this.fillCommunities(res.items));        
  }

  //
  // The "surveycommunties" array of a Survey includes on the communities
  // that the survey takes place in. In order to show a full list of communities,
  // with checkboxes, we download the list of existing communities and create
  // a surveycommunity for each one, with a "selected" boolean flag.
  // 
  fillCommunities = (communities: Community[]) => {
    this.setState({ communities: communities });

    // For all communities, check whether they are in the
    // surveycommunities list. If so, mark community as 'selected'.
    let survey = this.state.data;
    for(let i = 0; i < communities.length; i++) {
      // Is community among selected communities?
      let sc = survey.surveycommunities.find(sc => sc.community_id == communities[i].id);
      // If present, then just mark as selected.
      if(sc) {
        sc.selected = true;
      // If not present, add community to survey communities as unselected.
      } else {
        survey.surveycommunities.push({
          community_id: communities[i].id,
          community: communities[i],
          //factor: 1,
          selected: false
        });
      }
    }
    // Unselected communities come after selected communities.
    // Sort the whole list.
    survey.surveycommunities.sort((a, b) => (a.community.name > b.community.name) ? 1 : ((b.community.name > a.community.name) ? -1 : 0));

    this.setState({ 
      data: survey
    });
  }

  handleCommunitySearch = (q:string) => {
    // Retrieve a list of communities:
    let query = new Query('name', 'asc');
    query.setFilter('q', 'like', q);
    CommunityFactory.getSome(this.props.auth, 0, 5, query)
      .then((res) => this.setState({ communities: res.items }));
  }  
  
  handleAddCommunity = () => {
    this.setState((prevState) => {
      prevState.data.surveycommunities.push({} as any);
      return prevState;
    });
  }

  handleDeleteCommunity = (index:number) => {
    this.setState((prevState) => {
      prevState.data.surveycommunities.splice(index, 1);
      return prevState;
    });
  }

  render() {
    let p = this.props;
    return (
      <Form
        data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
        <Tabs underlined>
          <Tabs.Pane label={<Loc msg="surveys_form_tab_data"/>}>
            <Flex.Columns count={2}>
              <React.Fragment>
                <Form.Field 
                  required={{message: <Loc msg="surveys_fields_name_required"/>}}
                  minLength={{length: 3, message: <Loc msg="surveys_fields_name_minlength"/>}}
                  maxLength={{length: 30, message: <Loc msg="surveys_fields_name_maxlength"/>}}
                  width={1} label={<Loc msg="surveys_fields_name_label"/>}
                  name="name" 
                  value={this.state.data.name}
                  control={(<Input type="text" placeholder="Name" fluid/>)}
                  hint={<Loc msg="surveys_fields_name_hint"/>}/>
              </React.Fragment>
              <React.Fragment>
                <Form.Field 
                  required={{message: <Loc msg="surveys_fields_date_required"/>}}
                  width={1} label={<Loc msg="surveys_fields_date_label"/>}
                  name="date" 
                  value={this.state.data.date}
                  control={(<Input type="date" placeholder="Date" fluid/>)}
                  hint={<Loc msg="surveys_fields_date_hint"/>}/>
              </React.Fragment>              
            </Flex.Columns>
          </Tabs.Pane>
          <Tabs.Pane label={<React.Fragment><Loc msg="surveys_form_tab_communities"/> <Label>{this.state.data.surveycommunities.filter(sc => sc.selected).length}</Label></React.Fragment>}>
            <Table transparent striped>
              <thead>
                <tr>
                  <th><Loc msg="surveys_fields_community_label"/></th>
                  {/*<th><Loc msg="surveys_fields_weight_factor_label"/></th>*/}
                </tr>
              </thead>
              <tbody>
                {this.state.data.surveycommunities.map((sc, index) =>
                  <tr key={index}>
                    <td>
                      <Form.Field 
                        width={1}
                        forceupdate
                        name={`surveycommunities[${index}].selected`}
                        value={sc.selected}
                        control={<Checkbox label={sc.community.name} checked={sc.selected}/>}
                      />
                    </td>
                    {/*<td>
                      <Form.Field 
                        required={{message: <Loc msg="surveys_fields_weight_factor_required"/>}}
                        isFloat={{message: <Loc msg="surveys_fields_weight_factor_isfloat"/>}}
                        width={1}
                        disabled={!sc.selected}
                        name={`surveycommunities[${index}].factor`}
                        value={sc.factor}
                        control={(<Input type="text" placeholder="Weight factor" fluid/>)}/>   
                    </td>*/}
                  </tr>
                )}
              </tbody>
            </Table>
          </Tabs.Pane>
        </Tabs>
      </Form>
    )
  }
}

export { SurveyForm };
