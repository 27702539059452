import * as React from 'react';
import { css } from 'styled-components';

import { StatsSum } from './StatsSum';
import { StatsTitle } from './StatsTitle';
import { StatsBottom } from './StatsBottom';
import { StatsCenter } from './StatsCenter';
import { StatsBigNumber } from  './StatsBigNumber';
import { Card } from '@independent-software/typeui/controls/Card';

interface IProps {
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

class Stats extends React.Component<IProps, {}> {

  public static Sum = StatsSum;
  public static Title = StatsTitle;
  public static Bottom = StatsBottom;
  public static Center = StatsCenter;
  public static BigNumber = StatsBigNumber;

  findChildren = (type: string) => {
    return React.Children.map(this.props.children, (child:any) => {
      if (child.type && child.type.displayName == type) return child;
    })    
  }

  render() {
    let p = this.props;
    return (
      <Card raised onClick={p.onClick}>
        <Card.Header>
          <div style={{float: 'right'}}>
            {this.findChildren('Stats.Sum')}
          </div>          
          {this.findChildren('Stats.Title')}
        </Card.Header>
        <Card.Content align="center">
          {this.findChildren('Stats.Center')}
        </Card.Content>
        <Card.Meta>
          {this.findChildren('Stats.Bottom')}
        </Card.Meta>
      </Card>
    );
  }
}

(Stats.Sum as any).displayName = "Stats.Sum";
(Stats.Title as any).displayName = "Stats.Title";
(Stats.Bottom as any).displayName = "Stats.Bottom";
(Stats.Center as any).displayName = "Stats.Center";

export { Stats };

