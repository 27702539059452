import * as React from 'react';
import * as Excel from 'exceljs';

import { Segment } from '@independent-software/typeui/controls/Segment';
import { Table } from '@independent-software/typeui/controls/Table';

import { TCalculation } from '../definitions';
import { Loc } from '../../../modules';
import { ChartData, IDataPoint } from '../ChartData';

interface IProps {
  name: string;
  unit?: string;
  decimals?: number;
  chartdata: ChartData;
  calc: TCalculation;
  langdata: any;
  onRegister: (f: () => void) => void; // Register export method with parent.
}

interface IEntry {
  category: string;
  value: number;
}

class IndicatorTable extends React.Component<IProps> {

  componentDidMount = () => {
    this.props.onRegister(this.handleExport);
  }

  private setCell = (ws: Excel.Worksheet, r: number, c: number, value: any, bold?: boolean) => {
    const cell = ws.getCell(r, c);
    cell.value = value;
    if(bold) cell.font = { bold: true };
  }

  handleExport = () => {
    let y = 1;
    const p = this.props;
    const workbook = new Excel.Workbook();
    const ws = workbook.addWorksheet('Table');    

    p.chartdata.getData(p.calc, p.langdata).forEach((dp) => {
      // Header if multiple tables
      if(p.chartdata.length() > 1) {
        this.setCell(ws, y, 1, dp[Object.keys(dp).find((c) => c == 'name')], true);
        y++;
      }
      // Table
      // Ignore entry named "name".
      // Translate entry names.
      const entries = Object.keys(dp).filter((c) => c != 'name').map((cat) => {
        let translation = Loc.getText(p.langdata, ("cat_" + this.props.name + "_" + cat) as any);
        if(translation == '?' && cat == "98") translation = Loc.getText(p.langdata, "cat_98" as any);
        if(translation == '?' && (cat == "99" || cat == "999")) translation = Loc.getText(p.langdata, "cat_99" as any);
        if(translation == '?') translation = cat.toString();      
        return { category: translation, value: dp[cat] };
      })
      // Sort entries by value, descending.
      .sort((a: IEntry, b: IEntry) => b.value - a.value);
      // Format entries as table.
      entries.forEach(e => {
        this.setCell(ws, y, 1, e.category);
        this.setCell(ws, y, 2, e.value);
        y++;
      });
      if(p.calc == "absolute") {
        this.setCell(ws, y, 1, "Total");
        this.setCell(ws, y, 2, entries.map(e => e.value).reduce((p, c) => p + c));
        y++;
      }
      // Skip row at end of table.
      y++;
    });

    ws.getColumn(1).width = 60;
    workbook.xlsx.writeBuffer().then(function (data) {
      var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
      saveAs(blob, "Table.xlsx");
    }); 
  }  

  //
  // Render a single table
  //
  renderSubtable = (dp: IDataPoint, decimals: number) => {
    const p = this.props;
    // Ignore entry named "name".
    // Translate entry names.
    const entries = Object.keys(dp).filter((c) => c != 'name').map((cat) => {
      let translation = Loc.getText(p.langdata, ("cat_" + this.props.name + "_" + cat) as any);
      if(translation == '?' && cat == "98") translation = Loc.getText(p.langdata, "cat_98" as any);
      if(translation == '?' && (cat == "99" || cat == "999")) translation = Loc.getText(p.langdata, "cat_99" as any);
      if(translation == '?') translation = cat.toString();      
      return { category: translation, value: dp[cat] };
    })
    // Sort entries by value, descending.
    .sort((a: IEntry, b: IEntry) => b.value - a.value);

    // Format entries as table.
    return <Table striped transparent>
      <tbody>
        {entries.map(e => <tr key={e.category}>
          <td style={{width:"80%"}}>{e.category}</td>
          <td style={{width:"20%", textAlign:'right'}}>{e.value.toFixed(decimals ? decimals : 0)}{this.props.calc == 'relative' && "%"} <Loc msg={p.unit as any}/></td>
        </tr>)}
        {p.calc == "absolute" && <tr>
          <td style={{width:"80%"}}>Total</td>
          <td style={{width:"20%", textAlign:'right'}}>
            {entries.map(e => e.value).reduce((p, c) => p + c).toFixed(decimals ? decimals : 0)} <Loc msg={p.unit as any}/>
          </td>
        </tr>}
      </tbody>
    </Table>
  }

  //
  // Render all tables in chartdata.
  // Tables get a header if there is more than one table.
  // 
  renderTables = () => {
    const p = this.props;
    return p.chartdata.getData(p.calc, p.langdata).map((dp, index) => 
      <React.Fragment key={index}>
        {p.chartdata.length() > 1 && <Segment secondary attached="top">
          {dp[Object.keys(dp).find((c) => c == 'name')]}
        </Segment>}
        <Segment attached={p.chartdata.length() > 1 ? "bottom" : null}>
          {this.renderSubtable(dp, p.decimals ? p.decimals : (p.calc == 'relative' ? 2 : 0) )}
        </Segment>
      </React.Fragment>
    );
  }

  render() {
    return this.renderTables();
  }
}

export { IndicatorTable}