import * as React from 'react';
import { Container, Content, BottomBar, Loc } from '../../modules';
import { RequestForm } from './RequestForm';
import { RequestFactory, Request } from '../../resource/';
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';

import { Button } from '@independent-software/typeui/controls/Button';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Loader } from '@independent-software/typeui/controls/Loader';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { ToastService } from '@independent-software/typeui/controls/Toast';

type TStep = 'ready' | 'saving' | 'saveError';

interface IState {
  request: Request;
  isValid: boolean;
  step: TStep;
  error: any;
  dirty?: boolean;
}

class AddRequest extends React.Component<IAuthProps & RouteComponentProps<any>, IState> {
  private request: Request;

  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
    this.request = RequestFactory.create();
    this.state = {
      step: 'ready',
      isValid: false,
      error: null,
      request: this.request
    };
  }

  handleChange = (request: Request, forceupdate: boolean) => {
    this.request = Object.assign(this.request, request);
    if(forceupdate) { 
      this.setState({ request: this.request });
    }        
  }

  handleValidate = (valid: boolean) => {
    this.setState({
      isValid: valid
    })
  }  

  handleSubmit = () => {
    this.setState({ request: this.request, dirty: true });
    if(!this.state.isValid) return;    

    this.setState({error: null, step: 'saving'});
    this.request.$save(this.props.auth)
      .then(res => {
        ToastService.toast(<Loc msg="requests_msg_created"/>);
        this.props.history.push(`/requests/${res.data}`);
      })
      .catch(error => {
        this.setState({
          step: 'saveError',
          error: error
        })
      });
  }

  private handleCancelSave = () => {
    this.setState({ step: 'ready' });
  }

  render() {
    let p = this.props;
    return (
      <Container>
        {this.state.step == 'saving' && <Loader></Loader>}
        <React.Fragment>
          <Content>
            <RequestForm auth={p.auth} dirty={this.state.dirty} data={this.state.request} onChange={this.handleChange}  onValidate={this.handleValidate}/>
          </Content>
          <BottomBar>
            <Button primary onClick={this.handleSubmit}><Icon name="save"/> <Loc msg="btn_save"/></Button>
            <Button secondary onClick={() => p.history.goBack()}><Loc msg="btn_cancel"/></Button>
          </BottomBar>
        </React.Fragment>
        <Dialog.Xhr open={this.state.step == 'saveError'} error={this.state.error} onClose={this.handleCancelSave} onRetry={this.handleSubmit}/>
      </Container>
    );
  }
}

export { AddRequest };
