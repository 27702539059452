import * as React from 'react';
import { CurrentPngProps } from "recharts-to-png";

import { COLORS, TCalculation } from '../definitions';
import { BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend, Bar, LayoutType } from 'recharts';
import { Loc } from '../../../modules';
import { CustomTooltip } from './CustomTooltip';
import { ChartData } from '../ChartData';

interface IProps {
  name: string;
  unit?: string;
  chartdata: ChartData;
  calc: TCalculation;
  layout: LayoutType;
  stacked: boolean;
  grid: boolean;
  langdata: any;
  onRegister: (f: () => void) => void; // Register export method with parent.
}

class IndicatorBarChart extends React.Component<IProps & CurrentPngProps> {
  componentDidMount = () => {
    this.props.onRegister(this.handleExport);
  }

  handleExport = async () => {
    const png = await this.props.getPng();
    if (png) saveAs(png, "chart.png");
  }

  render() {
    let p = this.props;

    // Chart height is:
    // 500 if the chart is horizontal (vertical bars).
    // 200 * #bars if the chart is vertical.
    let height = 500;
    if(p.layout == 'vertical') height = p.chartdata.length() * 200;

    return (
      <ResponsiveContainer width="100%" height={height}>
        <BarChart
          ref={this.props.chartRef}
          layout={p.layout}
          data={p.chartdata.getData(p.calc, p.langdata)}
          margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
        >
          {p.grid && <CartesianGrid strokeDasharray="3 3" />}
          {/* domain and allowDataOverflow are used to force axis to 0..100 when showing relative values */}
          {p.layout == 'horizontal' && <XAxis tickLine={false} dataKey="name"/>}
          {p.layout == 'horizontal' && <YAxis width={70} domain={p.calc == 'relative' ? [0,100] : [0,"auto"]} allowDataOverflow={p.calc == 'absolute' ? false : true}/>}
          {p.layout == 'vertical' && <XAxis type="number" domain={p.calc == 'relative' ? [0,100] : [0,"auto"]} allowDataOverflow={p.calc == 'absolute' ? false : true}/>}
          {p.layout == 'vertical' && <YAxis tickLine={false} width={30} dataKey="name" type="category"/>}
          <Legend iconType='circle'/>
          <Tooltip isAnimationActive={false} content={<CustomTooltip calc={p.calc}/>}/>
          {/* Each DataPoint object has cat:value members. Special key "name" is
              used to set the Y-axis category. */}
          {p.chartdata.getUniqueCategories().map((c, index) => {
            let translation = Loc.getText(p.langdata, ("cat_" + p.name + "_" + c) as any);
            if(translation == '?' && c == "98") translation = Loc.getText(p.langdata, "cat_98" as any);
            if(translation == '?' && (c == "99" || c == "999")) translation = Loc.getText(p.langdata, "cat_99" as any);
            if(translation == '?') translation = c.toString();
            if(p.unit) translation += " " + Loc.getText(p.langdata, p.unit as any);
            return <Bar key={c} dataKey={c} name={translation} stackId={p.stacked ? 'a' : null} fill={COLORS[index % COLORS.length]} />
          })}
        </BarChart>
      </ResponsiveContainer> 
    );
  }
}

export { IndicatorBarChart}

