import * as React from 'react';
import { Segment } from '@independent-software/typeui/controls/Segment';

interface IProps {
  title: React.ReactNode;
  children?: React.ReactNode;
  dark?: boolean;
}

class Block extends React.Component<IProps, {}> {
  render() {
    let p = this.props;
    return (
      <React.Fragment>
        <Segment raised color={p.dark ? '#ddd' : '#eee'} attached="top">
          <b>{p.title}</b>
        </Segment>
        <Segment raised attached="bottom">
          {p.children}
        </Segment>
      </React.Fragment>
    );
  }
}

export { Block };