import * as React from 'react';
import axios from 'axios';
import { Section, AttachmentManager, Block, Loc, Container, Content, BottomBar, Timestamp } from '../../modules';
import { Grievance, GrievanceFactory, Post, TStates } from '../../resource/'; 
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';

import { Markdown } from '@independent-software/typeui/formatters/Markdown';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Header } from '@independent-software/typeui/controls/Header';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Label } from '@independent-software/typeui/controls/Label';
import { Button } from '@independent-software/typeui/controls/Button';
import { LocalizedDatum } from '../../formatters';
import { PostView } from '../Post/PostView';
import { StateDiagram } from './StateDiagram';
import { Divider } from '@independent-software/typeui/controls/Divider';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Loader } from '@independent-software/typeui/controls/Loader';
import { Message } from '@independent-software/typeui/controls/Message';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { TriageDialog } from './dialogs/TriageDialog';
import { TriageConclusionDialog } from './dialogs/TriageConclusionDialog';
import { App } from '../../App';
import { StepTable } from './StepTable';
import { Table } from '@independent-software/typeui/controls/Table';
import { TLocalization } from '../../modules/Loc/TLocalization';
import { Assessment1Dialog } from './dialogs/Assessment1Dialog';
import { Implementation1Dialog } from './dialogs/Implementation1Dialog';
import { Implementation1ConclusionDialog } from './dialogs/Implementation1ConclusionDialog';
import { Assessment2Dialog } from './dialogs/Assessment2Dialog';
import { Communication1Dialog } from './dialogs/Communication1Dialog';
import { Communication2Dialog } from './dialogs/Communication2Dialog';
import { LitigationDialog } from './dialogs/LitigationDialog';
import { Implementation2Dialog } from './dialogs/Implementation2Dialog';
import { Implementation2ConclusionDialog } from './dialogs/Implementation2ConclusionDialog';
import { Theme } from '@independent-software/typeui/styles';
import { ForceMoveDialog } from './dialogs/ForceMoveDialog';
import { DocumentCard } from './DocumentCard';
import { AddPost } from '../Post/AddPost';
import { Implementation3Dialog } from './dialogs/Implementation3Dialog';
import { Implementation3ConclusionDialog } from './dialogs/Implementation3ConclusionDialog';
import { LitigationConclusionDialog } from './dialogs/LitigationConclusionDialog';

type TStep = 'loading' | 'loadError' | 'ready' | 'confirm' | 'deleting' | 'deleteError' | 'error' | 'saveError';

interface IState {
  step: TStep;
  grievance: Grievance;
  error: any;
  stateDialog: TStates;
  validateDialog: boolean;
  rollbackDialog: boolean;
  forceMoveDialog: boolean;
  forcemove: TStates;
}

class ViewGrievance extends React.Component<IAuthProps & RouteComponentProps<{id:string}>, IState> {

  constructor(props: IAuthProps & RouteComponentProps<{id:string}>) {
    super(props);
    this.state = {
      step: 'loading',
      grievance: null,
      error: null,
      stateDialog: null,
      validateDialog: false,
      rollbackDialog: false,
      forceMoveDialog: false,
      forcemove: null
    };
  }

  componentDidMount() {
    this.loadItem();
  }

  private loadItem = () => {
    this.setState({ step: 'loading' });
    GrievanceFactory.get(this.props.auth, parseInt(this.props.match.params.id))
    .then((item) => {
      this.setState({
        step: 'ready',
        grievance: item
      });
    })
    .catch(error => {
      this.setState({
        step: 'loadError',
        error: error
      })
    });
  }

  private goto = (step:TStep) => {
    this.setState({ step: step});
  }  

  private handleCancelLoad = () => {
    this.setState({ step: 'error' });
  }

  private handleCancelSave = () => {
    this.setState({ step: 'ready' });
  }

  private handleRetry = () => {
    this.setState({error: null, step: 'loading'});
    this.loadItem();
  }  

  private handleEdit = () => {
    this.props.history.push(`/grievances/edit/${parseInt(this.props.match.params.id)}`);
  }

  private handleDelete = () => {
    this.setState({ step: 'deleting', error: null});
    this.state.grievance.$delete(this.props.auth)
      .then(res => {
        ToastService.toast(<Loc msg="grievances_msg_deleted"/>);
        this.props.history.push('/grievances');        
      })
      .catch(error => {
        this.setState({
          step: 'deleteError',
          error: error
        })
      })
  }      

  private handleClickStakeholder = (id: number) => {
    this.props.history.push(`/stakeholders/${id}`);
  }

  handleUpdateState = () => {
    ToastService.toast(<Loc msg="grievances_msg_updated"/>);
    this.loadItem();
    this.setState({ stateDialog: null, grievance: this.state.grievance });
  }

  handleForceMove = () => {
    ToastService.toast(<Loc msg="grievances_msg_updated"/>);
    this.setState({ forceMoveDialog: false, grievance: this.state.grievance });
    this.loadItem();
  }

  handleFail = (error: any) => {
    this.setState({ stateDialog: null, forceMoveDialog: false, error: error, step: 'saveError'});
  }

  handleCloseDialog = () => {
    this.setState({ stateDialog: null });
  }

  handleValidate = () => {
    this.setState({ step: 'loading', validateDialog: false });
    let grievance = this.state.grievance;
    axios.post(`${App.apiURL}grievance/validate/${grievance.id}`, { api_token: this.props.auth.token })
    .then(response => {
      grievance.state = grievance.newstate;
      grievance.newstate = null;
      this.setState({ grievance: grievance });
      ToastService.toast(<Loc msg="grievances_msg_validated"/>);
      this.loadItem();
    })
    .catch(error => {
      this.setState({ stateDialog: null, error: error, step: 'saveError'});
    })
    .finally(() => {
      this.setState({ step: 'ready' });
    });
  }

  handleRollback = () => {
    this.setState({ step: 'loading', rollbackDialog: false });
    let grievance = this.state.grievance;
    axios.post(`${App.apiURL}grievance/rollback/${grievance.id}`, { api_token: this.props.auth.token })
    .then(response => {
      grievance.newstate = null;
      this.setState({ grievance: grievance });
      ToastService.toast(<Loc msg="grievances_msg_rolled_back"/>);
      this.loadItem();
    })
    .catch(error => {
      this.setState({ stateDialog: null, error: error, step: 'saveError'});
    })
    .finally(() => {
      this.setState({ step: 'ready' });
    });
  }

  handleForm = () => {
    let grievance = this.state.grievance;
    axios.get(`${App.apiURL}grievance/form/${grievance.id}?api_token=${this.props.auth.token}`, { responseType: 'blob'}) 
    .then(response => {
      // Download the file.
      saveAs(response.data, 'form.xlsx');
    })   
  }

  private handleAddPost = (post: Post) => {
    this.setState({
      grievance: {
        ...this.state.grievance,
        posts: [ ...this.state.grievance.posts, post ]
      }
    });
  }

  private handleUpdatePost = (post: Post) => {
    this.setState({
      grievance: {
        ...this.state.grievance,
        posts: [ ...this.state.grievance.posts ]
      }
    })
  }

  private handleDeletePost = (post: Post) => {
    let posts = this.state.grievance.posts;
    let idx = posts.indexOf(post);
    posts.splice(idx, 1);
    this.setState({ 
      grievance: {
        ...this.state.grievance,
        posts: posts
      }
    })
  }  

  render() {
    let p = this.props;
    let grievance = this.state.grievance;
    return (
      <React.Fragment>
        <Container>
          {(this.state.step == 'loading' || this.state.step == 'loadError' || this.state.step == 'deleting') && 
            <Loader/>}
          {this.state.step != 'loading' && this.state.step != 'loadError' && this.state.step != 'error' && 
          <React.Fragment>
            <Content>
              <Section dark padded>
                <Flex>
                  <Flex.Row>
                    <Flex.Column>
                      <Header size="h2">
                        #{grievance.id}: {grievance.title} 
                        &nbsp;<Label basic size="tiny"><Loc msg={("grievance_workflow_" + grievance.state) as TLocalization}/></Label>
                        {grievance.isDone() && 
                          <Label basic size="tiny" color={Theme.positiveColor}><Loc msg={"grievances_state_concluded"}/></Label>}
                        {!grievance.isDone() && 
                          <Label basic size="tiny" color={Theme.negativeColor}><Loc msg={"grievances_state_ongoing"}/></Label>}
                      </Header>
                    </Flex.Column>
                  </Flex.Row>
                </Flex>
              </Section>
              <Tabs underlined nohiddenrender>
                <Tabs.Pane label={<Loc msg="grievances_form_tab_state"/>}>
                  <Section padded>
                    <Flex.Columns count={2}>
                      <Block title={<Loc msg="grievances_fields_stakeholder_label"/>}>
                        {grievance.stakeholder == null && <Loc msg="grievances_text_anomymous"/>}
                        {grievance.stakeholder != null && <React.Fragment>
                          {grievance.stakeholder.name}
                          {p.auth.hasRight('can_view_stakeholders') && 
                            <div style={{float:'right'}}>
                              <Button compact basic size="mini" onClick={() => this.handleClickStakeholder(grievance.stakeholder.id)}><Loc msg="btn_view"/></Button>
                            </div>
                          }
                          {/* Data sharing yes or no */}
                          {grievance.datasharing == true && <div style={{marginTop: '10px'}}>
                            <Icon name="check" color="#0a0"/> <Loc msg="grievances_text_datasharing_allowed"/>
                          </div>}
                          {grievance.datasharing == false && <div style={{marginTop: '10px'}}>
                            <Icon name="times" color="#c00"/> <Loc msg="grievances_text_datasharing_disallowed"/>
                          </div>}
                        </React.Fragment>}
                      </Block>

                      <Block title={<Loc msg="grievances_fields_date_label"/>}>
                        <LocalizedDatum.LongDate value={grievance.date}/>
                      </Block>
                    </Flex.Columns>

                    <Block title={<React.Fragment>
                      <Loc msg={"grievances_hdr_workflow"}/>
                      {p.auth.hasRight('can_edit_workflow') &&
                        <div style={{float: 'right'}}>
                          <Button size="mini" secondary onClick={() => this.setState({forceMoveDialog: true})}><Loc msg={"grievance_workflow_forcemove_btn"}/></Button>
                        </div>}
                    </React.Fragment>}>
                      <StateDiagram state={grievance.state} newstate={grievance.newstate}/>
                      {/* move actions */}
                      {!grievance.isDone() && grievance.newstate == null && p.auth.hasRight('can_edit_grievances') &&
                        <React.Fragment>
                          <Divider><Loc msg={"grievances_hdr_actions"}/></Divider>
                          <div style={{textAlign:'center'}}>
                            {grievance.state == 'reception' && <Button primary onClick={() => this.setState({stateDialog: 'triage'})}><Loc msg="grievances_step_to_triage"/></Button>}
                            {grievance.state == 'triage' && <Button primary onClick={() => this.setState({stateDialog: 'communication1'})}><Loc msg="grievances_step_to_communication"/></Button>}
                            {grievance.state == 'triage' && <Button primary onClick={() => this.setState({stateDialog: 'triage_conclusion'})}><Loc msg="grievances_step_to_triage_conclusion"/></Button>}
                            {grievance.state == 'communication1' && <Button primary onClick={() => this.setState({stateDialog: 'implementation1'})}><Loc msg="grievances_step_to_implementation"/></Button>}
                            {grievance.state == 'communication1' && grievance.anonymous == false && <Button primary onClick={() => this.setState({stateDialog: 'assessment1'})}><Loc msg="grievances_step_to_assessment"/></Button>}
                            {grievance.state == 'implementation1' && <Button primary onClick={() => this.setState({stateDialog: 'implementation1_conclusion'})}><Loc msg="grievances_step_to_implementation_conclusion"/></Button>}
                            {grievance.state == 'assessment1' && <Button primary onClick={() => this.setState({stateDialog: 'implementation2'})}><Loc msg="grievances_step_to_implementation"/></Button>}
                            {grievance.state == 'assessment1' && <Button primary onClick={() => this.setState({stateDialog: 'assessment2'})}><Loc msg="grievances_step_to_assessment"/></Button>}
                            {grievance.state == 'implementation2' && <Button primary onClick={() => this.setState({stateDialog: 'implementation2_conclusion'})}><Loc msg="grievances_step_to_implementation_conclusion"/></Button>}
                            {grievance.state == 'assessment2' && <Button primary onClick={() => this.setState({stateDialog: 'communication2'})}><Loc msg="grievances_step_to_communication"/></Button>}
                            {grievance.state == 'communication2' && <Button primary onClick={() => this.setState({stateDialog: 'litigation'})}><Loc msg="grievances_step_to_litigation"/></Button>}
                            {grievance.state == 'communication2' && <Button primary onClick={() => this.setState({stateDialog: 'implementation3'})}><Loc msg="grievances_step_to_implementation"/></Button>}
                            {grievance.state == 'implementation3' && <Button primary onClick={() => this.setState({stateDialog: 'implementation3_conclusion'})}><Loc msg="grievances_step_to_implementation_conclusion"/></Button>}
                            {grievance.state == 'litigation' && <Button primary onClick={() => this.setState({stateDialog: 'litigation_conclusion'})}><Loc msg="grievances_step_to_litigation_conclusion"/></Button>}
                          </div>
                        </React.Fragment>}
                      {/* validation actions */}
                      {grievance.newstate != null && p.auth.hasRight('can_validate_grievances') && 
                        <React.Fragment>
                          <Divider><Loc msg={"grievances_hdr_actions"}/></Divider>
                          <div style={{textAlign:'center'}}>
                            <p><Loc msg={"grievances_text_pending_validation"}/></p>
                            <Button positive onClick={() => this.setState({validateDialog: true})}><Loc msg={"btn_validate"}/></Button>
                            <Button negative onClick={() => this.setState({rollbackDialog: true})}><Loc msg={"btn_rollback"}/></Button>
                          </div>
                        </React.Fragment>}
                      {/* Validation needed, but no right */}
                      {grievance.newstate != null && !p.auth.hasRight('can_validate_grievances') &&
                        <React.Fragment>
                          <Divider/>
                          <div style={{textAlign:'center'}}>
                            <p><Loc msg="grievances_text_needs_validation"/></p>
                          </div>
                        </React.Fragment>}
                    </Block>

                  </Section>
                </Tabs.Pane>

                <Tabs.Pane label={<Loc msg="grievances_form_tab_history"/>}>
                  <Section padded>
                    <StepTable auth={p.auth} history={p.history} steps={grievance.steps}/>
                  </Section>
                </Tabs.Pane>

                <Tabs.Pane label={<Loc msg="grievances_form_tab_data"/>}>
                  <div style={{background:'#eee'}}>
                  <Section padded>

                    {grievance.hasState('triage') && 
                      <DocumentCard auth={this.props.auth} grievance={grievance} doctype="grievance-form"><Loc msg={"grievances_doc_grievance_form"}/></DocumentCard>}
                    {grievance.hasState('assessment1') && grievance.has_consent_form && 
                      <DocumentCard auth={this.props.auth} grievance={grievance} doctype="data-sharing-consent-form"><Loc msg={"grievances_doc_data_sharing_consent_form"}/></DocumentCard>}
                    {(grievance.hasState('implementation1') || grievance.hasState('implementation2')) && 
                      <DocumentCard auth={this.props.auth} grievance={grievance} doctype="agreement-form"><Loc msg={"grievances_doc_agreement_form"}/></DocumentCard>}
                    {(grievance.hasState('assessment2') || grievance.hasState('implementation2')) && 
                      <DocumentCard auth={this.props.auth} grievance={grievance} doctype="committee-report"><Loc msg={"grievances_doc_committee_report"}/></DocumentCard>}
                    {(grievance.hasState('implementation1_conclusion') || grievance.hasState('implementation2_conclusion') || grievance.hasState('implementation3_conclusion')) && 
                      <DocumentCard auth={this.props.auth} grievance={grievance} doctype="implementation-evidence"><Loc msg={"grievances_doc_implementation_evidence"}/></DocumentCard>}
                    {grievance.hasState('communication2') && 
                      <DocumentCard auth={this.props.auth} grievance={grievance} doctype="panel-report"><Loc msg={"grievances_doc_panel_report"}/></DocumentCard>}
                    {grievance.hasState('litigation_conclusion') && 
                      <DocumentCard auth={this.props.auth} grievance={grievance} doctype="litigation-report"><Loc msg={"grievances_doc_litigation_report"}/></DocumentCard>}
                  </Section>
                </div>

                <Divider hidden/>

                <Section padded>

                    <Flex.Columns count={2}>
                      <React.Fragment>
                        <Block title={<Loc msg="grievances_fields_submission_label"/>}>
                          {grievance.submission ? <Loc msg={('grievances_fields_submission_' + grievance.submission) as TLocalization}/> : '-'}
                          {grievance.submission == 'cso-other' && <div>
                            ({grievance.cso})
                          </div>}
                        </Block>

                        <Block title={<Loc msg="grievances_fields_description_label"/>}>
                          {grievance.description &&
                            <Markdown source={grievance.description}/>
                          }
                          {!grievance.description &&
                            <Loc msg="grievances_fields_description_none"/>
                          }
                        </Block>
                      </React.Fragment>
                      <React.Fragment>

                      {/* Reception data */}
                      {grievance.hasState('triage') && 
                        <Block title={<React.Fragment><Icon color={Theme.primaryColor} name="circle" float="right"/> <Loc msg="grievance_workflow_reception"/></React.Fragment>}>
                          <Table striped transparent>
                            <tbody>
                              <tr>
                                <td><Loc msg="grievance_workflow_contact_type_label"/></td>
                                <td>{grievance.reception_contact_type ? <Loc msg={("grievance_workflow_contact_type_" + grievance.reception_contact_type) as TLocalization}/>: '-'}</td>
                              </tr>
                              <tr>
                                <td><Loc msg="grievance_workflow_contact_date_label"/></td>
                                <td>{grievance.reception_contact_date ? <LocalizedDatum.LongDate value={grievance.reception_contact_date}/> : '-'}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Block>}

                      {/* Triage data */}
                      {(grievance.hasState('triage_conclusion') || grievance.hasState('communication1')) && grievance.issuetype && 
                        <Block title={<React.Fragment><Icon color={Theme.primaryColor} name="circle" float="right"/> <Loc msg="grievance_workflow_triage"/></React.Fragment>}>
                          <Table striped transparent>
                            <tbody>
                              <tr>
                                <td><Loc msg="grievance_workflow_triage_conclusion_issuetype_label"/></td>
                                <td>{grievance.issuetype.name}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Block>}

                      {/* Triage conclusion data */}
                      {grievance.hasState('triage_conclusion') && 
                        <Block title={<React.Fragment><Icon color={Theme.primaryColor} name="circle" float="right"/> <Loc msg="grievance_workflow_triage_conclusion"/></React.Fragment>}>
                          <Table striped transparent>
                            <tbody>
                              <tr>
                                <td><Loc msg="grievance_workflow_triage_conclusion_reason_label"/></td>
                                <td>{grievance.reason}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Block>}

                      {/* Communcation 1 data */}                        
                      {grievance.hasState('communication1') && 
                        <Block title={<React.Fragment><Icon color={Theme.primaryColor} name="circle" float="right"/> <Loc msg="grievance_workflow_communication1"/></React.Fragment>}>
                          <Table striped transparent>
                            <tbody>
                              <tr>
                                <td><Loc msg="grievance_workflow_contact_type_label"/></td>
                                <td>{grievance.communication1_contact_type ? <Loc msg={("grievance_workflow_contact_type_" + grievance.communication1_contact_type) as TLocalization}/> : '' }</td>
                              </tr>
                              <tr>
                                <td><Loc msg="grievance_workflow_contact_date_label"/></td>
                                <td>{grievance.communication1_contact_date ? <LocalizedDatum.LongDate value={grievance.communication1_contact_date}/> : '-'}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Block>}

                      {/* Implementation I data */}
                      {grievance.hasState('implementation1_conclusion') && 
                        <Block title={<React.Fragment><Icon color={Theme.primaryColor} name="circle" float="right"/><Loc msg="grievance_workflow_implementation"/></React.Fragment>}>
                          <Table striped transparent>
                            <tbody>
                              <tr>
                                <td><Loc msg="grievance_workflow_contact_type_label"/></td>
                                <td>{grievance.implementation_contact_type ? <Loc msg={("grievance_workflow_contact_type_" + grievance.implementation_contact_type) as TLocalization}/> : '-'}</td>
                              </tr>
                              <tr>
                                <td><Loc msg="grievance_workflow_contact_date_label"/></td>
                                <td>{grievance.implementation_contact_date ? <LocalizedDatum.LongDate value={grievance.implementation_contact_date}/> : '-'}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Block>}                                                

                      {/* Assessment I data */}
                      {grievance.hasState('assessment1') && 
                        <Block title={<React.Fragment><Icon color={Theme.primaryColor} name="circle" float="right"/><Loc msg="grievance_workflow_assessment1"/></React.Fragment>}>
                          <Table striped transparent>
                            <tbody>
                              <tr>
                                <td><Loc msg="grievances_fields_committee_members_label"/></td>
                                <td>{grievance.committee_members}</td>                                
                              </tr>
                              {(grievance.hasState('implementation2') || grievance.hasState('assessment2')) &&
                              <tr>
                                <td><Loc msg="grievance_workflow_contact_type_label"/></td>
                                <td>{grievance.assessment1_contact_type ? <Loc msg={("grievance_workflow_contact_type_" + grievance.assessment1_contact_type) as TLocalization}/> : '-'}</td>
                              </tr>}
                              {(grievance.hasState('implementation2') || grievance.hasState('assessment2')) &&
                              <tr>
                                <td><Loc msg="grievance_workflow_contact_date_label"/></td>
                                <td>{grievance.assessment1_contact_date ? <LocalizedDatum.LongDate value={grievance.assessment1_contact_date}/> : '-'}</td>
                              </tr>}
                            </tbody>
                          </Table>
                        </Block>}

                      {/* Implementation II data */}
                      {grievance.hasState('implementation2_conclusion') && 
                        <Block title={<React.Fragment><Icon color={Theme.primaryColor} name="circle" float="right"/><Loc msg="grievance_workflow_implementation"/></React.Fragment>}>
                          <Table striped transparent>
                            <tbody>
                              <tr>
                                <td><Loc msg="grievance_workflow_contact_type_label"/></td>
                                <td>{grievance.implementation_contact_type ? <Loc msg={("grievance_workflow_contact_type_" + grievance.implementation_contact_type) as TLocalization}/> : '-'}</td>
                              </tr>
                              <tr>
                                <td><Loc msg="grievance_workflow_contact_date_label"/></td>
                                <td>{grievance.implementation_contact_date ? <LocalizedDatum.LongDate value={grievance.implementation_contact_date}/> : '-'}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Block>}                                                


                      {/* Assessment II data */}
                      {grievance.hasState('assessment2') && 
                        <Block title={<React.Fragment><Icon color={Theme.primaryColor} name="circle" float="right"/><Loc msg="grievance_workflow_assessment2"/></React.Fragment>}>
                          <Table striped transparent> 
                            <tbody>
                              <tr>
                                <td><Loc msg="grievances_fields_panel_members_label"/></td>
                                <td>{grievance.panel_members}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Block>}

                      {/* Implementation III data */}
                      {grievance.hasState('implementation3_conclusion') && 
                        <Block title={<React.Fragment><Icon color={Theme.primaryColor} name="circle" float="right"/><Loc msg="grievance_workflow_implementation"/></React.Fragment>}>
                          <Table striped transparent>
                            <tbody>
                              <tr>
                                <td><Loc msg="grievance_workflow_contact_type_label"/></td>
                                <td>{grievance.implementation_contact_type ? <Loc msg={("grievance_workflow_contact_type_" + grievance.implementation_contact_type) as TLocalization}/> : '-'}</td>
                              </tr>
                              <tr>
                                <td><Loc msg="grievance_workflow_contact_date_label"/></td>
                                <td>{grievance.implementation_contact_date ? <LocalizedDatum.LongDate value={grievance.implementation_contact_date}/> : '-'}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Block>}                                                


                      {/* Communication data */}
                      {grievance.hasState('communication2') && 
                        <Block title={<React.Fragment><Icon color={Theme.primaryColor} name="circle" float="right"/><Loc msg="grievance_workflow_communication2"/></React.Fragment>}>
                          <Table striped transparent>
                            <tbody>
                              <tr>
                                <td><Loc msg="grievance_workflow_assessment_decision_date_label"/></td>
                                <td>{grievance.decision_date ? <LocalizedDatum.LongDate value={grievance.decision_date}/> : '-'}</td>
                              </tr>
                              {(grievance.hasState('implementation3') || grievance.hasState('litigation')) &&
                              <tr>
                                <td><Loc msg="grievance_workflow_contact_type_label"/></td>
                                <td>{grievance.communication2_contact_type ? <Loc msg={("grievance_workflow_contact_type_" + grievance.communication2_contact_type) as TLocalization}/> : '-'}</td>
                              </tr>}
                              {(grievance.hasState('implementation3') || grievance.hasState('litigation')) &&
                              <tr>
                                <td><Loc msg="grievance_workflow_contact_date_label"/></td>
                                <td>{grievance.communication2_contact_date ? <LocalizedDatum.LongDate value={grievance.communication2_contact_date}/> : '-'}</td>
                              </tr>}                              
                            </tbody>
                          </Table>
                        </Block>}                        

                      {/* Litigation data */}
                      {grievance.hasState('litigation_conclusion') && 
                        <Block title={<React.Fragment><Icon color={Theme.primaryColor} name="circle" float="right"/><Loc msg="grievance_workflow_litigation"/></React.Fragment>}>
                          <Table striped transparent>
                            <tbody>
                              <tr>
                                <td><Loc msg="grievance_workflow_contact_type_label"/></td>
                                <td>{grievance.litigation_contact_type ? <Loc msg={("grievance_workflow_contact_type_" + grievance.litigation_contact_type) as TLocalization}/> : '-'}</td>
                              </tr>
                              <tr>
                                <td><Loc msg="grievance_workflow_contact_date_label"/></td>
                                <td>{grievance.litigation_contact_date ? <LocalizedDatum.LongDate value={grievance.litigation_contact_date}/> : '-'}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Block>}                                              
 
                      </React.Fragment>
                    </Flex.Columns>

                  </Section>
                </Tabs.Pane>

                {/* <Tabs.Pane label={<span><Loc msg="grievances_form_tab_attachments"/> <Label size="small">{grievance.attachments.length}</Label></span>}>
                  <Section padded>
                    <Message type="warning">
                      <Loc msg="grievances_text_warning"/>
                    </Message>
                    <AttachmentManager auth={p.auth} attachments={grievance.attachments}/>
                  </Section>
                </Tabs.Pane> */}

                <Tabs.Pane label={<span>{<Loc msg="grievances_form_tab_discussion"/>} <Label size="small">{grievance.posts.length}</Label></span>}>
                  <Section padded>
                    {grievance.posts.map((post, index) => 
                      <PostView key={index} auth={p.auth} post={post} 
                        onSave={this.handleUpdatePost}
                        onDelete={this.handleDeletePost}/>
                    )}
                    <AddPost 
                      auth={p.auth} onSave={this.handleAddPost}
                      type="grievance" type_id={this.state.grievance.id}/>
                  </Section>
                </Tabs.Pane>

              </Tabs>
            </Content>
            <BottomBar>
              <div>
                {p.auth && p.auth.hasRight('can_edit_grievances') && <Button primary onClick={this.handleEdit}><Loc msg="btn_edit"/></Button>}
                <Button secondary onClick={this.handleForm}><Loc msg="grievances_btn_form"/></Button>
              </div>
              <div>
                {p.auth && p.auth.hasRight('can_edit_grievances') && <Button negative onClick={() => this.goto('confirm')}><Icon name="trash"/> <Loc msg="btn_delete"/></Button>}
                <Timestamp model={grievance}/>
              </div>
            </BottomBar>
          </React.Fragment>}
          {this.state.step == 'error' && 
          <Content>
            <Section padded>
              <Message type="error"><Loc msg="msg_data_not_found"/></Message>
            </Section>
          </Content>}        
        </Container>

        <Dialog.Xhr open={this.state.step == 'loadError'} error={this.state.error} onClose={this.handleCancelLoad} onRetry={this.handleRetry}/>    
        <Dialog.Xhr open={this.state.step == 'saveError'} error={this.state.error} onClose={this.handleCancelSave}/>    
        <Dialog.Xhr open={this.state.step == 'deleteError'} error={this.state.error} onClose={() => this.goto('ready')} onRetry={this.handleDelete}/>
        <Dialog.Confirm open={this.state.step == 'confirm'} onClose={() => this.goto('ready')} onConfirm={this.handleDelete}>
          <Loc msg="msg_confirm_deletion"/>
        </Dialog.Confirm>
        <Dialog.Confirm open={this.state.validateDialog == true} onClose={() => this.setState({validateDialog: false})} onConfirm={this.handleValidate}>
          <Loc msg="grievances_text_confirm_validation"/>
        </Dialog.Confirm>
        <Dialog.Confirm open={this.state.rollbackDialog == true} onClose={() => this.setState({rollbackDialog: false})} onConfirm={this.handleRollback}>
          <Loc msg="grievances_text_confirm_rollback"/>
        </Dialog.Confirm>

        <ForceMoveDialog auth={p.auth} grievance={grievance} open={this.state.forceMoveDialog} onClose={() => this.setState({forceMoveDialog: false})} onExec={this.handleForceMove} onFail={this.handleFail}/>

        <TriageDialog auth={p.auth} grievance={grievance} open={this.state.stateDialog == 'triage'} onClose={this.handleCloseDialog} onExec={this.handleUpdateState} onFail={this.handleFail}/>
        <TriageConclusionDialog auth={p.auth} grievance={grievance} open={this.state.stateDialog == 'triage_conclusion'} onClose={this.handleCloseDialog} onExec={this.handleUpdateState} onFail={this.handleFail}/>
        <Communication1Dialog auth={p.auth} grievance={grievance} open={this.state.stateDialog == 'communication1'} onClose={this.handleCloseDialog} onExec={this.handleUpdateState} onFail={this.handleFail}/>
        <Implementation1Dialog auth={p.auth} grievance={grievance} open={this.state.stateDialog == 'implementation1'} onClose={this.handleCloseDialog} onExec={this.handleUpdateState} onFail={this.handleFail}/>
        <Implementation1ConclusionDialog auth={p.auth} grievance={grievance} open={this.state.stateDialog == 'implementation1_conclusion'} onClose={this.handleCloseDialog} onExec={this.handleUpdateState} onFail={this.handleFail}/>
        <Assessment1Dialog auth={p.auth} grievance={grievance} open={this.state.stateDialog == 'assessment1'} onClose={this.handleCloseDialog} onExec={this.handleUpdateState} onFail={this.handleFail}/>
        <Implementation2Dialog auth={p.auth} grievance={grievance} open={this.state.stateDialog == 'implementation2'} onClose={this.handleCloseDialog} onExec={this.handleUpdateState} onFail={this.handleFail}/>
        <Implementation2ConclusionDialog auth={p.auth} grievance={grievance} open={this.state.stateDialog == 'implementation2_conclusion'} onClose={this.handleCloseDialog} onExec={this.handleUpdateState} onFail={this.handleFail}/>
        <Assessment2Dialog auth={p.auth} grievance={grievance} open={this.state.stateDialog == 'assessment2'} onClose={this.handleCloseDialog} onExec={this.handleUpdateState} onFail={this.handleFail}/>
        <Communication2Dialog auth={p.auth} grievance={grievance} open={this.state.stateDialog == 'communication2'} onClose={this.handleCloseDialog} onExec={this.handleUpdateState} onFail={this.handleFail}/>
        <Implementation3Dialog auth={p.auth} grievance={grievance} open={this.state.stateDialog == 'implementation3'} onClose={this.handleCloseDialog} onExec={this.handleUpdateState} onFail={this.handleFail}/>
        <Implementation3ConclusionDialog auth={p.auth} grievance={grievance} open={this.state.stateDialog == 'implementation3_conclusion'} onClose={this.handleCloseDialog} onExec={this.handleUpdateState} onFail={this.handleFail}/>
        <LitigationDialog auth={p.auth} grievance={grievance} open={this.state.stateDialog == 'litigation'} onClose={this.handleCloseDialog} onExec={this.handleUpdateState} onFail={this.handleFail}/>
        <LitigationConclusionDialog auth={p.auth} grievance={grievance} open={this.state.stateDialog == 'litigation_conclusion'} onClose={this.handleCloseDialog} onExec={this.handleUpdateState} onFail={this.handleFail}/>

      </React.Fragment>
    );
  }
}

export { ViewGrievance };
