import { ResourceFactory, StampedModel } from '../services/';
import { Community } from './Community';
import { Action } from './Action';
import { Grievance } from './Grievance';
import { Stakeholder } from './Stakeholder';

class Attachment extends StampedModel {
  public id: number;
  public filename: string;
  public is_image: boolean;
  public size: number;
  public type: string;
  public mime: string;

  public communities: Community[];
  public actions: Action[];
  public grievances: Grievance[];
  public stakeholders: Stakeholder[];
}

const AttachmentFactory = ResourceFactory.create<Attachment>(Attachment, `attachment`);

export { Attachment, AttachmentFactory }
