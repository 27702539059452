import { StampedModel, ResourceFactory } from '../services/';
import { Attachment } from './Attachment';

class Community extends StampedModel {
  public name: string = '';
  public description_en: string = '';
  public description_pt: string = '';
  public stakeholders_count: number = 0;
  public households_count: number = 0;
  public actions_count: number = 0;
  public surveys_count: number = 0;
  public attachments_count: number = 0;
  public latitude: number = null;
  public longitude: number = null;
  public district: { 
    id: number;
    name: string;
  } = null;
  public attachments: Attachment[] = [];
}

const CommunityFactory = ResourceFactory.create<Community>(Community, 'community');

export { Community, CommunityFactory };