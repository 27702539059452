import * as React from 'react';
import { Section, Viewer, Block, Loc } from '../../modules';
import { District, DistrictFactory, Community } from '../../resource/'; 
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';

import { Markdown } from '@independent-software/typeui/formatters/Markdown';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Header } from '@independent-software/typeui/controls/Header';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Label } from '@independent-software/typeui/controls/Label';
import { ListCommunities } from '../Community';

class ViewDistrict extends React.Component<IAuthProps & RouteComponentProps<{id:string}>, {}> {
  private canEdit = (district: District): boolean => {
    return this.props.auth && this.props.auth.hasRight('can_edit_districts');
  }  

  private handleEdit = () => {
    this.props.history.push(`/districts/edit/${parseInt(this.props.match.params.id)}`);
  }

  private handleDelete = () => {
    ToastService.toast(<Loc msg="districts_msg_deleted"/>);
    this.props.history.push('/districts');        
  }    

  private handleCommunityClick = (item:Community) => {
    this.props.history.push(`/communities/${item.id}`);
  }    

  render() {
    let p = this.props;
    return (
      <Viewer<District> 
        auth={p.auth}
        id={parseInt(p.match.params.id)}
        factory={DistrictFactory}
        canEdit={this.canEdit}
        onEdit={this.handleEdit}
        onDelete={this.handleDelete}
        content={(district: District) => 
          <React.Fragment>
            <Section dark padded>
              <Flex>
                <Flex.Row>
                  <Flex.Column>
                    <Header size="h2">{district.name}</Header>
                  </Flex.Column>
                </Flex.Row>
              </Flex>
            </Section>
            <Tabs underlined>
              <Tabs.Pane label="Data">
                <Section padded>
                  <Flex.Columns count={2}>
                    <React.Fragment>
                      <Block title={<Loc msg="districts_fields_notes_label"/>}>
                        {district.notes &&
                          <Markdown source={district.notes}/>
                        }
                        {!district.notes &&
                          <Loc msg="districts_fields_notes_none"/>
                        }
                      </Block>
                    </React.Fragment>
                  </Flex.Columns>
                </Section>
              </Tabs.Pane>
              <Tabs.Pane label={<React.Fragment>Communities <Label size="small">{district.communities_count}</Label></React.Fragment>}>
                <ListCommunities auth={p.auth} name="district_communities" districtId={district.id} onClick={this.handleCommunityClick}/>
              </Tabs.Pane>
            </Tabs>
          </React.Fragment>}>
      </Viewer>
    );
  }
}

export { ViewDistrict };
