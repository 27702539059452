import * as React from 'react';
import axios from 'axios';
import styled from '@independent-software/typeui/styles/Theme';
import { Attachment } from '../../resource';
import { IAuthProps } from '../../services';

import { Accordion } from '@independent-software/typeui/controls/Accordion';
import { Button } from '@independent-software/typeui/controls/Button';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Image } from '@independent-software/typeui/controls/Image';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Label } from '@independent-software/typeui/controls/Label';
import { Message } from '@independent-software/typeui/controls/Message';
import { Table } from '@independent-software/typeui/controls/Table';
import { Filesize } from '@independent-software/typeui/formatters/Filesize';
import { App } from '../../App';
import { Loc } from '../Loc';

interface IProps {
  className?: string;
  /**
   * List of attachments shown in AttachmentManager.
   */
  attachments: Attachment[];
}

interface IState {
  previewOpen: boolean;   // Is the preview dialog open?
  attachment: Attachment; // Attachment currently previewed or edited
}

/**
 * The AttachmentManager provides a GUI listing, uploading, previewing, downloading and removing of Attachments.
 * Uploaded and removed attachments only take effect when the parent control save the object it manages.
 */
class AttachmentViewerBase extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);
    this.state = {
      previewOpen: false,
      attachment: null
    }
  }

  handleDownload = (attachment: Attachment) => {
    axios.get(`${App.apiURL}attachment/download/${attachment.id}?api_token=${this.props.auth.token}`, { responseType: 'blob'}) 
    .then(response => {
      // Find the content-disposition header.
      let disposition:string = response.headers['content-disposition'];
      // Using a regexp, retrieve the filename from it.
      let regexp = new RegExp('\"(.*)\"');
      let res:RegExpExecArray = regexp.exec(disposition);
      let filename = res[1];
      // Download the file.
      saveAs(response.data, filename);
    })
    .catch(error => {
    });    
  }

  handleOpenPreview = (attachment: Attachment) => {
    this.setState({
      previewOpen: true,
      attachment: attachment
    })
  }

  handleClosePreview = () => {
    this.setState({ previewOpen: false });
  }

  render() {
    let p = this.props;

    let documents = p.attachments.filter(a => !a.is_image);
    let images = p.attachments.filter(a => a.is_image);

    return (
      <div className={p.className}>
        <Accordion multiple active={[0,1]}>

          <Accordion.Tab title={<span><Loc msg="attachmentmanager_documents"/> <Label size="tiny">{documents.length}</Label></span>}>
            {documents.length == 0 && 
              <Message type="info">
                <Loc msg="attachmentmanager_no_documents"/>
              </Message>}
            {documents.length > 0 && 
              <Table striped>
                <thead>
                  <tr>
                    <th>File</th>
                    <th>Size</th>
                    <th style={{width:'120px'}}>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {documents.map((attachment: Attachment, index) => 
                    <tr key={index}>
                      <td>{attachment.filename}</td>
                      <td><Filesize value={attachment.size}/></td>
                      <td>
                        <Button icon size="small" onClick={() => this.handleDownload(attachment)}><Icon title="Download" name="download"/></Button>
                      </td>
                    </tr>)}
                </tbody>
              </Table>}
          </Accordion.Tab>

          <Accordion.Tab title={<span><Loc msg="attachmentmanager_images"/> <Label size="tiny">{images.length}</Label></span>}>
            {images.length == 0 && 
              <Message type="info">
                <Loc msg="attachmentmanager_no_images"/>
              </Message>}
            {images.length > 0 && 
              <Image.Group 
                size="small"
                bordered
                rounded>
              {images.map((attachment: Attachment) => 
                <Image key={attachment.id} onClick={() => this.handleOpenPreview(attachment)} src={`${App.apiURL}attachment/download/${attachment.id}?api_token=${p.auth.token}&size=t`}/>
                )}
              </Image.Group>
            }
          </Accordion.Tab>

        </Accordion>

        {/* 
          * Image preview dialog
          */}
        <Dialog width={800} open={this.state.previewOpen} onClose={this.handleClosePreview}>
          <Dialog.Header>
            {this.state.attachment && this.state.attachment.filename}
          </Dialog.Header>
          <Dialog.Content>
            {this.state.attachment && 
              <Image rounded src={`${App.apiURL}attachment/download/${this.state.attachment.id}?api_token=${p.auth.token}&size=p`}/>
            }
          </Dialog.Content>
          <Dialog.Footer>
            {this.state.attachment && 
              <div style={{float: 'left'}}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Button primary onClick={() => this.handleDownload(this.state.attachment)}><Icon name="download"/> <Loc msg="btn_download"/> <Label attached="right"><Filesize value={this.state.attachment.size}/></Label></Button>
                </div>
              </div>}
            <Button secondary onClick={this.handleClosePreview}><Loc msg="btn_close"/></Button>
          </Dialog.Footer>
        </Dialog>    

      </div>
    );
  }
}

const AttachmentViewer = styled(AttachmentViewerBase)`
  td:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th:not(:first-child), td:not(:first-child) {
    width: 100px;
    text-align: right;
  }
`

export { AttachmentViewer };
