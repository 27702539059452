import axios from 'axios';

class App {
  static apiURL: string;

  static run(f: () => void) {
    App.loadConfig()
      .then(response => {
        App.apiURL = (response as any).apiURL;
        if(!App.apiURL.endsWith('/')) App.apiURL += '/';
        f();
      })
      .catch(error => {
        console.log(error);
      });    
  }

  /** 
   * Load local JSON config file. This promise
   * returns either an IConfig object, or
   * an Axios error.
   */
  static loadConfig = (): Promise<object> => {
    return new Promise((resolve, reject) => {
      axios.get("config.json") 
        .then(response => {
          let config = response.data;
          resolve(config);
        })
        .catch(error => {
          reject(error);
        });
    });
  }  
}

export { App };