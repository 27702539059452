import * as React from 'react';
import { DataTable } from '@independent-software/typeui/controls/DataTable';
import { Form } from '@independent-software/typeui/controls/Form';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { Attachment, AttachmentFactory } from '../../resource/'; 
import { List, IListState, IListProps, SearchFilter } from '../../services/';
import { IconBar, Loc } from '../../modules';
import { Filesize } from '@independent-software/typeui/formatters/Filesize';
import { Image } from '@independent-software/typeui/controls/Image';
import { App } from '../../App';
import { Button } from '@independent-software/typeui/controls/Button';
import { LocalizedDatum } from '../../formatters';

interface IProps extends IListProps {
  /**
   * Timestamp is a random number. When it changes,
   * the list refetches its data.
   */
  timestamp?: number;
  /**
   * Event is fired when an attachment is clicked.
   */
  onClick?: (attachment:Attachment) => void;
}

interface IState extends IListState<Attachment> {
}

class ListAttachments extends List<Attachment, IProps, IState> {
  constructor(props: IProps) {
    super(props, AttachmentFactory, 'filename', 'asc');

    // Initialize state (list initializes its own part of the state):
    this.state = {
      ...this.state
    };
  }

  handleSearch = (value: string) => {
    this.setFilter('q', 'like', value);
  }

  handleAllAttachments = () => {
    this.setFilter('is_image', 'eq', null);
  }

  handleOnlyImages = () => {
    this.setFilter('is_image', 'eq', 1);
  }

  handleOnlyDocuments = () => {
    this.setFilter('is_image', 'eq', 0);
  }

  componentDidUpdate = (prevProps: IProps) => {
    // If the timestamp updates, then fake a filter
    // change to make the list refetch.
    if(this.props.timestamp != prevProps.timestamp) {
      let o = this.getFilter('q', 'like');
      this.setFilter('q','like', o);
    }
  }

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Form.Uncontrolled hint={<Loc msg="attachments_filter_search_hint"/>}>
          <SearchFilter value={this.getFilter('q', 'like')} onSearch={this.handleSearch}/>
        </Form.Uncontrolled>
        <Form.Uncontrolled hint={<Loc msg="attachments_filter_type_hint"/>}>
          <Button.Group>
            <Button noripple onClick={this.handleAllAttachments} primary={this.getFilter('is_image', 'eq') == null}><Loc msg="attachments_filter_type_all"/></Button>
            <Button noripple onClick={this.handleOnlyImages} primary={this.getFilter('is_image', 'eq') == 1}><Loc msg="attachments_filter_type_images"/></Button>
            <Button noripple onClick={this.handleOnlyDocuments} primary={this.getFilter('is_image', 'eq') == 0}><Loc msg="attachments_filter_type_documents"/></Button>
          </Button.Group>
        </Form.Uncontrolled>
      </Panel.Content>
    </React.Fragment>    
    
    return (
      <React.Fragment>
        <IconBar>
          <Panel.Icon icon="tools" width={400}>
            {filter}
          </Panel.Icon>
        </IconBar>  
        <DataTable error={this.state.error} loading={this.state.loading} scrollTop={this.state.scrollTop} onScroll={this.handleScroll} data={this.state.items} onFetch={this.handleFetch} onClick={p.onClick} onOrder={this.handleOrder} order={this.getOrder()} dir={this.getDir()}>
          <DataTable.Column weight={1} label=''>{(item:Attachment) => item.is_image ? <Image rounded inline size="mini" src={`${App.apiURL}attachment/download/${item.id}?api_token=${p.auth.token}&size=t`}/> : null}</DataTable.Column>
          <DataTable.Column weight={8} label={<Loc msg="attachments_list_hdr_file"/>} order="filename" dir="asc">{(item:Attachment) => item.filename}</DataTable.Column>
          <DataTable.Column weight={2} label={<Loc msg="attachments_list_hdr_date"/>} order="created_at" dir="desc">{(item:Attachment) => <LocalizedDatum.ShortDateTime value={item.created_at}/>}</DataTable.Column>
          <DataTable.Column weight={2} label={<Loc msg="attachments_list_hdr_size"/>} order="size" dir="asc" align="right">{(item:Attachment) => <Filesize value={item.size}/>}</DataTable.Column>
        </DataTable>
      </React.Fragment>
    );
  }
}

export { ListAttachments };
