import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import axios from 'axios';
import { Auth } from '../services/Auth';
import { History } from 'history';

import { Button } from '@independent-software/typeui/controls/Button'
import { Icon } from '@independent-software/typeui/controls/Icon'
import { Panel } from '@independent-software/typeui/controls/Panel'
import { Gravatar } from '@independent-software/typeui/controls/Gravatar'
import { App } from '../App';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Loc } from '../modules';


interface ICurrentUserProps {
  className?: string;
  auth: Auth;
  onSignout: () => void;
  history: History;
}

interface ICurrentUserState {
  // Panel currently open?
  open: boolean
}

class CurrentUserBase extends React.Component<ICurrentUserProps, ICurrentUserState> {
  readonly state = {
    open: false
  }

  handleUserClick = () => {
    this.setState((prevState) => { return {
      open: !prevState.open
    }});
  }

  handleClose = () => {
    this.setState({
      open: false
    });
  }

  handleSignout = () => {
    this.setState({open: false});
    axios.get(`${App.apiURL}signout?api_token=${this.props.auth.token}`)
    .then(response => {
      this.props.onSignout();
    })
    .catch(error => {
      ToastService.toast("Signout failed.");
    });    
  }    

  handleAccount = () => {
    this.setState({open: false});
    this.props.history.push("/account");
  }

  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        {p.auth != null && 
          <React.Fragment>
            <Gravatar email={p.auth.email} onClick={this.handleUserClick}/>
            <Panel open={this.state.open} onClose={this.handleClose} padded>
              <UserData>
                <Gravatar size="tiny" email={p.auth.email}/>
                <div>
                  <Line><Icon name="id-card" color="#ccc"/> <Username>{p.auth.name}</Username></Line>
                  <Line><Icon name="envelope" color="#ccc"/> {p.auth.email}</Line>
                </div>
              </UserData>
              <Buttons>
                <Button negative onClick={this.handleSignout}><Loc msg="btn_sign_out"/></Button>
                <Button onClick={this.handleAccount}><Loc msg="btn_account"/></Button>
              </Buttons>
            </Panel>
          </React.Fragment>}
      </div>
    );
  }
}

const Username = styled('div')`
  font-weight: 500;
`

const UserData = styled('div')`
  display: flex;
  align-items: flex-start;
  white-space: nowrap;
  margin-bottom: 10px;
  & > *:first-child {
    margin-right: 10px;
  }
`

const Line = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    margin-right: 8px;
  }
`

const Buttons = styled('div')`
  white-space: nowrap;
`

const CurrentUser = styled(CurrentUserBase)`
  position: relative;
  display: inline-block;  
`

export { CurrentUser };
